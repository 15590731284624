import { KobetsuActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

function kobetsu(state = {}, action) {
  switch (action.type) {
    case KobetsuActionTypes.GET_ZENKAI_RECORD: {
      openNotificationWithIcon("success", "読み込みが完了しました");

      return {
        ...state,
        kobetsuDetail: action.kobetsu,
      };
    }

    case KobetsuActionTypes.LOAD_KOBETU_KINOU_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        kobetsuDetails: action.kobetsuDetails,
      };
    }

    case KobetsuActionTypes.SET_ZENKAI_RECORD: {
      return {
        ...state,
        kobetsuDetail: action.data,
      };
    }

    case KobetsuActionTypes.GET_GOTORECORD_RECORD: {
      return {
        ...state,
        kobetsuDetail:
          action.kobetsu &&
          action.kobetsu.kobetukinouKunren2 &&
          action.kobetsu.kobetukinouKunren2.id
            ? action.kobetsu.kobetukinouKunren2
            : state.kobetsuDetail,
        isFirst: action.kobetsu ? action.kobetsu.isFirst : false,
        isLast: action.kobetsu ? action.kobetsu.isLast : false,
        loading: false,
      };
    }

    case KobetsuActionTypes.GET_FIRST_LOAD_DATA: {
      return {
        ...state,
        fistLoadData: action.data,
      };
    }
    // get all kobetsu by user id
    case KobetsuActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "getAll",
      };
    }

    case KobetsuActionTypes.GET_KOBETSU_DETAIL_SUCESS: {
      return {
        ...state,
        loading: false,
        kobetsuDetail: action.kobetsu,
      };
    }
    case KobetsuActionTypes.GET_KOBETSU_DETAIL_ERROR: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // create kobetsu
    case KobetsuActionTypes.CREATE_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "create",
      };
    }
    case KobetsuActionTypes.CREATE_KOBETSU_SUCCESS: {
      openNotificationWithIcon("success", "保存しました", action.callback);

      return {
        ...state,
        loading: false,
        kobetsuDetail: action.kobetsu,
        type: "",
      };
    }
    case KobetsuActionTypes.CREATE_KOBETSU_ERROR: {
      openNotificationWithIcon("error", action.error.message);

      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    // update kobetsu
    case KobetsuActionTypes.UPDATE_PLAN1_REQUEST: {
      return {
        ...state,
        loading: true,
        type: "update",
      };
    }
    case KobetsuActionTypes.UPDATE_KOBETSU_SUCCESS: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }
    case KobetsuActionTypes.UPDATE_KOBETSU_ERROR: {
      return {
        ...state,
        loading: false,
        type: "",
      };
    }

    case KobetsuActionTypes.GET_LIST_IDS_KOBETU: {
      return {
        ...state,
        loading: false,
        kobetuIds: action.ids,
      };
    }

    case KobetsuActionTypes.LOAD_KOBETSU_BY_IDS_REQUEST:
      return { ...state, loading: true };
    case KobetsuActionTypes.LOAD_KOBETSU_BY_IDS_SUCCESS: {
      return {
        ...state,
        KobetsuList: action.KobetsuList,
        loading: false,
      };
    }
    case KobetsuActionTypes.LOAD_KOBETSU_BY_IDS_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        KobetsuList: [],
        loading: false,
      };
    case KobetsuActionTypes.CLEAR_KOBETSU_BY_IDS:
      return {
        ...state,
        KobetsuList: [],
      };

    // remove kobetsu
    // case KobetsuActionTypes.REMOVE_PLAN1_REQUEST: {
    //   return {
    //     ...state,
    //     loading: true,
    //     type: "remove",
    //   };
    // }
    // case KobetsuActionTypes.REMOVE_PLAN1_SUCCESS: {
    //   CommonUtils.openNotificationWithIcon("success", "削除しました");
    //   return {
    //     ...state,
    //     loading: false,
    //     dataDelete: action.data,
    //     reload: true,
    //     type: "",
    //   };
    // }
    // case KobetsuActionTypes.REMOVE_PLAN1_FAILURE: {
    //   CommonUtils.openNotificationWithIcon("error", action.error.message);
    //   return {
    //     ...state,
    //     loading: false,
    //     type: "",
    //   };
    // }

    default: {
      return { ...state };
    }
  }
}

export default kobetsu;

/* eslint-disable react/prop-types */
import { Form } from "@ant-design/compatible";
import { SaveOutlined, SettingOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Radio, Row, Spin } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TantoInput from "../../common/component/control/TantoInput";
import { ConstSet } from "../../common/configs/constset";
import {
  formatStorage,
  getMenu,
  getPath,
  getValueLocalstorage,
} from "../../common/function_common/functionCommon";
import ButtonGroup from "./UI/ButtonGroup";
import InputNo from "./UI/InputNo";
import ReportAdlByDateModal from "./UI/ReportAdlByDateModal";
dayjs.extend(dayjsPluginUTC);

// eslint-disable-next-line react/display-name
const withResize = (WrappedComponent) => (props) => {
  function autoResize() {
    const element = document.querySelector(
      `textarea[element=${props.element}]`
    );
    element.style.minHeight = "50px";
    if (!element) return;
    const offset = element.offsetHeight - element.clientHeight;
    element.style.height = "0px";
    element.style.boxSizing = "border-box";
    element.style.height = element.scrollHeight + offset + "px";
    element.addEventListener("input", function (event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + offset + "px";
    });
  }

  useEffect(() => {
    autoResize();
  }, [props.value]);

  return <WrappedComponent {...props} />;
};

const TextAreaResize = withResize(Input.TextArea);

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmVisible: false, // 戻る確認モーダル
      reportAdlByDateVisible: false, // 前回引用モーダル
      tantoData: {}, // 担当者
      selectTab: 0, // 現在選択しているタブ ※default:特記
      selectTabName: null,
      path: getPath(),

      // 登録に使うstate
      adlSubData: [],
      comment: "",
      tokki: "",
      dataTime: "",
      tanto: null,
    };
  }

  componentDidMount() {
    const { data } = this.props;

    if (data && data.id) {
      this.parseAttendaceData(data);
    }
  }

  componentDidUpdate(prevProps) {
    const { data, tantoFormat, adlId, adlMaster } = this.props;

    // 変更があればstateを更新
    if (
      data &&
      prevProps.data &&
      (data.id !== prevProps.data.id || data !== prevProps.data)
    ) {
      if (Object.keys(data).length) {
        this.parseAttendaceData(data);
      }
    }

    if (adlId === "new" && !this.state.dataTime) {
      const data = dayjs.utc(new Date(), ConstSet.DATE_FORMAT);
      this.setState({
        dataTime: data,
      });
    }

    if (tantoFormat && adlId === "new" && !this.state.tantoData.id) {
      const id = parseInt(getValueLocalstorage("tid"));
      const index = tantoFormat.findIndex((item) => item.id === id);
      this.setState({
        tantoData: { ...tantoFormat[index] },
        tanto: id,
      });
    }

    // ページ開いたときadl記録タブがあればタブの変更
    if (adlMaster !== prevProps.adlMaster) {
      let name = null;
      if (adlMaster) {
        for (const i in adlMaster) {
          name = adlMaster[i].find((x) => x.id == 1);
          if (name) {
            this.tabChange(name.bunrui, 1);
          }
        }
      }
    }

    if (data && prevProps.data && data.id !== prevProps.data.id) {
      // this.parseAttendaceData(data);

      const id =
        adlId === "new" ? parseInt(getValueLocalstorage("tid")) : data.tantoId;
      if (id && tantoFormat) {
        const index = tantoFormat.findIndex((item) => item.id === id);
        this.setState({
          tantoData: { ...tantoFormat[index] },
          tanto: id,
        });
      }
    }
  }

  /**
   * render時にdataのadlSubをstateに保存
   * @param {*} data
   */
  parseAttendaceData(data) {
    this.setState({
      adlSubData: data.reportAdlSub,
      tokki: data.tokki,
      comment: data.comment,
      tanto: data && data.tantoId ? data.tantoId : null,
      dataTime:
        data && data.evaluationDate
          ? dayjs.utc(data.evaluationDate, ConstSet.DATE_FORMAT)
          : dayjs.utc(new Date(), ConstSet.DATE_FORMAT),
    });
  }

  /**
   * 戻る
   * @param {*} e
   */
  handleGoBack = (e) => {
    const { riyousyaId } = this.props;
    e.preventDefault();
    if (this.props.form.isFieldsTouched() || this.props.isFieldsTouched)
      this.showGoBackConfirm();
    // this.props.history.goBack() //追加からだと2回押す為
    else this.props.history.push(`${this.state.path}/report-adl/${riyousyaId}`);
  };

  /**
   * 変更があった場合に出る確認モーダル
   */
  showGoBackConfirm() {
    this.setState({
      confirmVisible: true,
    });
  }

  /**
   * 「戻る」確認モーダルキャンセル
   */
  closeGoBackConfirm() {
    this.setState({
      confirmVisible: false,
    });
  }

  /**
   * 「戻る」確認モーダルOK
   */
  handleConfirmGoBack() {
    const { riyousyaId } = this.props;
    this.props.onChangeValue(false);
    this.setState({
      confirmVisible: false,
    });
    this.props.history.push(`${this.state.path}/report-adl/${riyousyaId}`);
  }

  /**
   * 登録
   * formからの値とstateからの値を合わせて登録をする
   * @param {*} e
   * @param {*} type 通常の登録 or 閲覧からの登録
   */
  handleSave = (e, type) => {
    e.preventDefault();

    const { form } = this.props;
    const { adlSubData, comment, tokki, tanto, dataTime } = this.state;
    let valuesTmp;

    form.validateFields((err, values) => {
      valuesTmp = {
        ...values,
        comment,
        tokki,
        tantoId: tanto,
        evaluationDate: dataTime,
        reportAdlSub: adlSubData,
      };

      valuesTmp["evaluationDate"] = dayjs(dataTime).isValid()
        ? dayjs(dataTime).format(ConstSet.DATE_FORMAT)
        : null;

      this.props.onChangeValue(false); // form変更の確認off

      if (type === "save") {
        // 通常のセーブ
        this.props.regData(err, valuesTmp);
      }
      // else if (type === "save_and_new_tab") { //観覧のセーブだった場合
      //   // this.props.regDataAndCheck(err, values);
      // }
    });
  };

  /**
   * 担当者選択
   * @param {*} item
   */
  handleSelectTantoItem(id) {
    const { tantoFormat } = this.props;
    const index = tantoFormat.findIndex((item) => item.id === id);
    this.setState({
      tantoData: { ...tantoFormat[index] },
      tanto: id,
    });
  }

  /**
   * 評価日選択
   * @param {*} item
   */
  handleSelectData(item, st) {
    this.setState({
      // dataTime: dayjs.utc(item, ConstSet.DATE_FORMAT)
      dataTime: dayjs(st),
    });
  }

  /**
   * 前回引用レコード選択
   */
  handleSelectReportAdl(data) {
    if (data && data.id) {
      // 引用してきたreportAdlSubのidを消す
      if (data.reportAdlSub && data.reportAdlSub.length > 0) {
        for (const i of data.reportAdlSub) {
          i.id = null;
        }
      }
      this.setState({
        adlSubData: data.reportAdlSub,
        tokki: data.tokki,
        comment: data.comment,
      });
      //   form.setFieldsValue({
      //     formのdataはstateで管理してるのでなし
      //   });
      this.props.onChangeValue(true);
    }
  }

  /**
   * 前回引用モーダルclose
   */
  hideModal() {
    this.setState({
      reportAdlByDateVisible: false,
    });
  }

  /**
   * タブ切り替え
   * @param {*} item
   * @param {*} index
   */
  tabChange(item, index) {
    this.setState({
      selectTab: index,
      selectTabName: item,
    });
  }

  /**
   * stateのadlSub管理とitemIdが合う値をreturn
   * @param {*} item
   * @param {*} type
   * @returns
   */
  valueSerch(item, type) {
    const { adlSubData } = this.state;
    if (adlSubData && adlSubData.length > 0) {
      for (const i of adlSubData) {
        if (i.adlMasterId == item.id) {
          if (type == "point") {
            return i.point;
          }
          if (type == "comment") {
            return i.comment;
          }
        }
      }
      return null;
    }
    return null;

  }
  adlValueSerch(type) {
    const { comment, tokki } = this.state;
    if (type == "comment") {
      return comment ? comment : "";
    }
    if (type == "tokki") {
      return tokki ? tokki : "";
    }
  }

  /**
   * reportAdlSubの変更したpoint or commentをstateに登録
   * @param {*} value radioのチェックした番号
   * @param {*} type "point", "comment"
   * @param masterId adlMasterId
   */
  adlSubUpdate(val, type, masterId, naiyou) {
    const { adlSubData } = this.state;
    const { data } = this.props;
    const { value } = val.target; // 値
    const dataTmp = [...adlSubData];
    const objTmp = {
      // 新規用の追加オブジェクト
      id: null,
      reportAdlId: data && data.id ? data.id : 0,
      adlMasterId: masterId,
      answer: "",
      point: null,
      comment: "",
    };

    if (adlSubData.some((sub) => sub.adlMasterId == masterId)) {
      // 上書き
      const index = adlSubData.findIndex((sub) => sub.adlMasterId == masterId);
      if (type == "point") {
        const naiyouTmp = naiyou.split(",");
        dataTmp[index].point = value;
        dataTmp[index].answer = naiyouTmp[value - 1];
      }
      if (type == "comment") {
        dataTmp[index].comment = value;
      }
    }
    else {
      // 新規adlSub
      if (type == "point") {
        const naiyouTmp = naiyou.split(",");
        objTmp.point = value;
        objTmp.answer = naiyouTmp[value - 1];
      }
      if (type == "comment") {
        objTmp.comment = value;
      }
      dataTmp.push(objTmp);
    }

    this.setState({
      adlSubData: dataTmp, // 上書き
    });
    this.props.onChangeValue(true);
  }

  /**
   * reportAdlの特記、コメ変更時にstateに登録
   * @param {*} val
   * @param {*} type tokki or comment
   */
  adlUpdate(val, type) {
    const { value } = val.target;
    const key = type;

    this.setState({
      [key]: value,
    });
    this.props.onChangeValue(true);
  }

  /**
   * 選択肢の削除
   * @param {*} id adlMasterId
   */
  handleDeleteRadio(id) {
    const { adlSubData } = this.state;
    const objTmp = [...adlSubData];

    if (adlSubData && adlSubData.length > 0) {
      const index = adlSubData.findIndex((sub) => sub.adlMasterId == id);
      if (objTmp[index] && objTmp[index].point) {
        objTmp[index].point = null;
      }

      this.setState({
        adlSubData: objTmp,
      });
      this.props.onChangeValue(true);
    }
  }

  render() {
    const { selectTab, selectTabName, tanto, dataTime } = this.state; // 現在選択しているタブ '基本動作'
    const { adlMaster, form, loading, adlId } = this.props; // tabの情報をわたし、mapで項目をセットする
    const { getFieldDecorator } = form;
    let tabContent = null;
    if (selectTabName && adlMaster) {
      tabContent = adlMaster[selectTabName];
    }

    // console.log(this.props)
    // console.log(this.state)

    return (
      (<div>
        <Spin spinning={loading}>
          <Col className={"submenu fixed-header"}>
            <Row>
              <Col span={12} className={"sm-c-left"}>
                <span style={{ display: "flex", fontWeight: "bold" }}>
                  <span
                    style={{
                      margin: "9px 3px 0 0",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    <SettingOutlined />
                  </span>
                  <h2
                    style={{
                      margin: "8px 0 0 0",
                      color: "white",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {"ADL記録入力"}
                  </h2>
                </span>
              </Col>
              <Col span={12} className={"sm-c-right"}>
                <Link
                  to={"/"}
                  onClick={this.handleGoBack}
                  style={{ color: "white", textAlign: "center", float: "right" }}
                >
                  <span style={{ display: "block", fontWeight: "bold", marginBottom: '5px' }}>
                    <UndoOutlined />
                  </span>
                  <span>{"戻る"}</span>
                </Link>
                <Link to={"/"} onClick={(e) => this.handleSave(e, "save")}>
                  <span
                    style={{
                      color: "white",
                      textAlign: "center",
                      float: "right",
                      paddingRight: "10px",
                    }}
                  >
                    <span
                      style={{
                        display: "block",
                        fontWeight: "bold",
                        color: "red",
                        marginBottom: "5px"
                      }}
                    >
                      <SaveOutlined />
                    </span>
                    <span>{"登録"}</span>
                  </span>
                </Link>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={2}></Col>
              <Col span={20} className="tab">
                {/* <Col span={20} className="tab"> */}
                <ButtonGroup
                  key={"titleTab"}
                  onClick={(item, index) => this.tabChange(item, index)}
                  tab={
                    this.props.tab && this.props.tab != null
                      ? this.props.tab.split(",")
                      : null
                  }
                  select={this.state.selectTabName}
                />
              </Col>
              <Col span={2}></Col>
            </Row>
          </Col>

          <div
            style={{
              maxWidth: "1081px",
              margin: "0 auto",
              padding: "20px 15px 30px 15px",
            }}
          >
            {/* ADL記録タブ */}
            <div className="report-adl-button-group-m">
              <ButtonGroup
                key={"pageTab"}
                onClick={(item, index) => this.tabChange(item, index)}
                tab={
                  this.props.tab && this.props.tab != null
                    ? this.props.tab.split(",")
                    : null
                }
                select={this.state.selectTabName}
              />
            </div>

            {/* Buttons  */}
            <div className="report-adl-buttons">
              <Row style={{ marginBottom: 15 }} className="report-adl-buttons2">
                {/* lg screen ≥ 992px */}
                {/* md screen ≥ 768px */}
                {/* sm screen ≥ 576px */}
                {/* xs screen < 576px */}
                <Col
                  md={24}
                  lg={7}
                  className="report-adl-button report-adl-button--1"
                >
                  <span className="text-bold riyouTitle">利用者名</span>
                  <span className="name-bold">
                    {this.props.riyousya ? this.props.riyousya.name : ""}
                  </span>
                </Col>

                {/* hyouka_date */}
                <Col
                  md={24}
                  lg={5}
                  className="report-adl-button report-adl-button--1"
                >
                  <span className="text-bold">評価日</span>
                  <DatePicker
                    style={{ width: 120 }}
                    value={dataTime ? dataTime : ""}
                    onChange={(item, st) => this.handleSelectData(item, st)}
                  />
                </Col>

                {/* tanto_id */}
                <Col
                  md={24}
                  lg={10}
                  className="report-adl-button report-adl-button--2"
                >
                  <span className="text-bold">担当者</span>
                  <TantoInput
                    allowBlank={true}
                    style={{ display: "inline-block" }}
                    containerId={"input-report-adl-page"}
                    onChange={(item) => this.handleSelectTantoItem(item)}
                    value={tanto || ""}
                  />
                </Col>
              </Row>
            </div>

            {/* Quote button */}
            <div className="report-adl-quote-button">
              <Button
                type="primary"
                onClick={() => this.setState({ reportAdlByDateVisible: true })}
              >
                前回引用
              </Button>
            </div>

            {/*  ------------ここからタブ別ページ------------- */}

            <div className="">
              {
                // タブごとの切り替え

                selectTab && selectTab != 0 ? (
                  <div>
                    {
                      // 特記以外のタブの要素の確認

                      tabContent && tabContent.length > 0 // 各項目
                        ? tabContent.map(
                          (
                            item,
                            index // タブ分け
                          ) => (
                            <div className="" key={`tabPage_${index}`}>
                              {/* lg screen ≥ 992px ウィンドウサイズの幅 */}
                              {/* md screen ≥ 768px */}
                              {/* sm screen ≥ 576px */}
                              {/* xs screen < 576px */}
                              <Row className="report-adl-kiroku-container">
                                {/* タイトル */}
                                <Col
                                  lg={3}
                                  sm={3}
                                  className="report-adl-textarea-title-area"
                                >
                                  <span className="report-adl-textarea-title">
                                    {item.naiyou}
                                  </span>
                                </Col>

                                {/* 消すボタン */}
                                <Col lg={1} sm={24}>
                                  <Button
                                    className="report-adl-select-box-delete-button"
                                    type="primary"
                                    onClick={() =>
                                      this.handleDeleteRadio(item.id)
                                    }
                                  >
                                    <p className="report-adl-select-box-delete-button-name">
                                      消
                                    </p>
                                  </Button>
                                </Col>

                                {/* ラジオ選択 */}
                                <Col
                                  lg={7}
                                  sm={11}
                                  className="report-adl-select-box"
                                >
                                  <Radio.Group
                                    onChange={(value) =>
                                      this.adlSubUpdate(
                                        value,
                                        "point",
                                        item.id,
                                        item.answerNaiyou
                                      )
                                    }
                                    value={this.valueSerch(item, "point")}
                                  >
                                    {item.answerNaiyou &&
                                      item.answerNaiyou.split(",").length > 0
                                      ? item.answerNaiyou
                                        .split(",")
                                        .map((item2, index2) => (
                                          <div
                                            className="report-adl-select-box-naiyou"
                                            key={`radio_${index2}`}
                                          >
                                            <Radio value={index2 + 1}>
                                              {item2}
                                            </Radio>
                                          </div>
                                        ))
                                      : null}
                                  </Radio.Group>
                                </Col>

                                {/* コメント */}
                                <Col lg={11} sm={11}>
                                  <TextAreaResize
                                    element={`comment_${item.id}`}
                                    rows={2}
                                    onChange={(e) =>
                                      this.adlSubUpdate(e, "comment", item.id)
                                    }
                                    value={this.valueSerch(item, "comment")}
                                  />
                                </Col>
                              </Row>
                            </div>
                          )
                        )
                        : null
                    }
                  </div>
                ) : (
                  <div>
                    {/* 特記 */}
                    <Row className="report-adl-kiroku-container">
                      <Col
                        lg={3}
                        sm={24}
                        className="report-adl-textarea-title-area"
                      >
                        <span className="report-adl-textarea-title">特記</span>
                      </Col>
                      <Col lg={20} sm={24}>
                        <TextAreaResize
                          element="tokki"
                          rows={2}
                          onChange={(e) => this.adlUpdate(e, "tokki")}
                          value={this.adlValueSerch("tokki")}
                        />
                      </Col>
                    </Row>

                    {/* コメント */}
                    <Row className="report-adl-textarea">
                      <Col
                        lg={3}
                        sm={24}
                        className="report-adl-textarea-title-area"
                      >
                        <span className="report-adl-textarea-title">
                          コメント
                        </span>
                      </Col>
                      <Col lg={20} sm={24}>
                        <TextAreaResize
                          element="comment"
                          rows={2}
                          onChange={(e) => this.adlUpdate(e, "comment")}
                          value={this.adlValueSerch("comment")}
                        />
                      </Col>
                    </Row>
                  </div>
                )
              }
            </div>
          </div>
        </Spin>
        {/* formにレコードの情報を持たせる */}
        <div style={{ display: "none" }}>
          {getFieldDecorator("id", {
            rules: [{ required: false }],
            value: 0,
          })(<InputNo />)}
          {getFieldDecorator("riyousyaId", {
            rules: [{ required: false }],
            value: 0,
          })(<InputNo />)}
          {getFieldDecorator("serviceKikanMasterId", {
            rules: [{ required: false }],
            value: 0,
          })(<InputNo />)}
        </div>
        <ReportAdlByDateModal // 前回引用
          visible={this.state.reportAdlByDateVisible}
          reportAdlId={adlId && adlId !== "new" ? adlId : null}
          riyousyaId={this.props.riyousyaId}
          onSelect={(data) => this.handleSelectReportAdl(data)}
          onHide={() => this.hideModal()}
        />
        <Modal // 戻る確認
          open={this.state.confirmVisible}
          title="キャンセル確認"
          onCancel={() => this.closeGoBackConfirm()}
          onOk={() => this.handleConfirmGoBack()}
        >
          <span style={{ display: "block" }}>
            {"キャンセルしてもよろしいですか？"}
          </span>
        </Modal>
      </div>)
    );
  }
}

const InputFormWrapper = Form.create({
  name: "reportAdl",
  mapPropsToFields(props) {
    // const tantoId = (props.data && props.data.tantoId) || null;
    return {
      // id
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      // //評価日
      // evaluationDate: Form.createFormField({
      //   value: props.data && props.data.evaluationDate
      //     ? dayjs.utc(props.data.evaluationDate, ConstSet.DATE_FORMAT)
      //     : dayjs.utc(new Date(), ConstSet.DATE_FORMAT)
      // }),
      // //担当者
      // tantoId: Form.createFormField({
      //   value: props.adlId === "new" ? parseInt(getValueLocalstorage("tid")) : tantoId
      // }),
      // 利用者
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : Number(props.riyousyaId),
      }),
      // sid
      serviceKikanMasterId: Form.createFormField({
        value:
          props.data && props.data.serviceKikanMasterId
            ? props.data.serviceKikanMasterId
            : Number(formatStorage(getMenu()).si),
      }),
    };
  },
})(InputForm);

const mapStateToProps = () => () => ({});

const mapDispatchToProps = () => () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InputFormWrapper);

/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import MediaQuery from "react-responsive";
import TimeKBCus from "../../../common/component/TimeKBCus";
import { ConstSet } from "../../../common/configs/constset";
import VitalForm from "../../../common/form-kiroku/VitalForm";
import {
  checkTableField,
  convertArrayToString,
  extractRangeVital,
  getMenu,
  getValueIskeep,
  getValueLocalstorage,
  handleInputNumber,
  handleSpo2,
  vitalColSetting,
  zenkaiMerge,
} from "../../../common/function_common/functionCommon";
import {
  checkIkkatsuOkBtn,
  ikkatsuCheckboxReset,
} from "../../../common/function_common/functionDisplay";
import IkkatsuModal from "../../../common/modals/ikkatsuModal";
import CheckKB from "../../../common/model-kiroku/CheckKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";
import NumberKB from "../../../common/model-kiroku/NumberKB";
import SelectKB from "../../../common/model-kiroku/SelectKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import TantoMasterKB2 from "../../../common/model-kiroku/TantoMasterKB2";
import TextareaKB from "../../../common/model-kiroku/TextareaKB";
import TimeKB from "../../../common/model-kiroku/TimeKB";
import TableRowButton from "./TableRowButton";

// const { TextArea } = Input;
const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };
const FormItem = Form.Item;

export default class Vital extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Table Setting
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      rowActive: null,

      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      // edit add function
      inputMode: this.props.inputMode,
      showDrawer: false,
      visibleMInput: false,
      modalType: null,

      riyousya: null,
      record: null,
      time: null,
      indexRecord: 0,

      field: "",
      isZendkaiDis: false,
      suggestValues: "",

      //
      inputType: null,
      inputValue: null,
      inputField: null,

      count: 0,
      rowsSelect: 0,
      visibleMulState: false,
      mulInputData: {
        vitalIds: null,
        time: null,
        comment: null,
        tantoId: null,
      },
      mulInputCheckbox: [false, false, false],
      mulInputNotification: "",
      arrCheckBox: [], // array index checkbox for multiple add and edit,
      isClickAll: false,
      delIds: [],
      isConfirmMulDel: false,

      formData: null,
      isFormUpdate: false,

      // handle for active OK SAVE Button when user start input value
      okSaveButton: true,

      zenkaiValue: "",

      tableCurrentPage: 1, // current page of table
    };

    // bind place
    // this.handleClickRow = this.handleClickRow.bind(this);

    // Copy, Del local, Del Server record
    this.handleCopyRecord = this.handleCopyRecord.bind(this);
    this.handleDelVital = this.handleDelVital.bind(this);
    this.handleServerDelVital = this.handleServerDelVital.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.handleMInput = this.handleMInput.bind(this);
    this.handleMInputCancel = this.handleMInputCancel.bind(this);

    this.getHandleHourInput = this.getHandleHourInput.bind(this);
    this.getHandleMinuteLeftInput = this.getHandleMinuteLeftInput.bind(this);
    this.getHandleMinuteInput = this.getHandleMinuteInput.bind(this);
    this.getHandleReportCaseTyuif = this.getHandleReportCaseTyuif.bind(this);

    this.getHandleMainf = this.getHandleMainf.bind(this);
    this.getHandleInputTemp = this.getHandleInputTemp.bind(this);
    this.getHandleInputPresureHigh = this.getHandleInputPresureHigh.bind(this);
    this.getHandleInputPresureLow = this.getHandleInputPresureLow.bind(this);
    this.getHandleInputPulsefNo = this.getHandleInputPulsefNo.bind(this);
    this.getHandleInputPulsefType = this.getHandleInputPulsefType.bind(this);
    this.getHandleInputSpo2B = this.getHandleInputSpo2B.bind(this);
    this.getHandleInputSpo2A = this.getHandleInputSpo2A.bind(this);
    this.getHandleInputKokyuNo = this.getHandleInputKokyuNo.bind(this);
    this.getHandleInputKokyuType = this.getHandleInputKokyuType.bind(this);
    this.getHandleInputEtc1 = this.getHandleInputEtc1.bind(this);
    this.getHandleInputNumEtc1 = this.getHandleInputNumEtc1.bind(this);
    this.getHandleInputEtc2 = this.getHandleInputEtc2.bind(this);
    this.getHandleInputNumEtc2 = this.getHandleInputNumEtc2.bind(this);
    this.getHandleInputEtc3 = this.getHandleInputEtc3.bind(this);
    this.getHandleInputNumEtc3 = this.getHandleInputNumEtc3.bind(this);

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);

    this.handleOkUpdate = this.handleOkUpdate.bind(this);
    // END

    // add, edit Setting Comment
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // add, edit Setting Ect
    this.getYougoMasterEctState = this.getYougoMasterEctState.bind(this);
    this.getYougoMasterEctCancel = this.getYougoMasterEctCancel.bind(this);
    this.getYougoMasterEctSave = this.getYougoMasterEctSave.bind(this);

    // 一括入力
    this.handleMulInputModeOk = this.handleMulInputModeOk.bind(this);
    this.getHandleOnChangeMIC = this.getHandleOnChangeMIC.bind(this); // Active Clickable 一括入力 button
    this.handleMulInputModeCancel = this.handleMulInputModeCancel.bind(this);
    this.onChangeTimeMulMode = this.onChangeTimeMulMode.bind(this);
    this.getHandleMulInputComment = this.getHandleMulInputComment.bind(this);
    this.getHandleMulTantoMasterKB = this.getHandleMulTantoMasterKB.bind(this);
  }
  /**
   *
   * @param {*} preProps
   */
  componentDidUpdate(preProps) {
    const { count } = this.state;
    const { nyutaisho, mulInputAllMode } = this.props;

    //
    if (nyutaisho !== preProps.nyutaisho) {
      // case update vitalId when insert value to DOM
      if (this.state.record) {
        this.setState({
          record: nyutaisho[this.state.record.index],
        });
      }

      // case multiple input
      if (count > 0) {
        this.ikkatsu();
      }
    }

    // reset multiple input mode
    if (mulInputAllMode !== preProps.mulInputAllMode) {
      this.setState({
        count: 0,
        rowsSelect: 0,
        visibleMulState: false,
        mulInputData: {
          vitalIds: null,
          time: null,
          comment: null,
          tantoId: null,
        },

        mulInputCheckbox: [false, false, false],
        mulInputNotification: "",
        arrCheckBox: [], // array index checkbox for multiple add and edit,
        isClickAll: false,
        expandRowClick: [], // reset expand row on multiple input mode
        rowActive: null,
        activeRowKey: null,
        delIds: [],
        isConfirmMulDel: false,
      });

      this.props.handleIkkatsuBtn(true); // active popup click button,
    }
  }

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} event
   */
  onRowClick(key) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    const { mulInputMode } = this.props;
    if (mulInputMode) {
      return;
    }

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  /**
   *
   * @param {*} baitaruRecord
   */
  handleCopyRecord(baitaruRecord) {
    // Clone data
    const cloneBaitaruCopy = {
      nyuType: baitaruRecord.nyuType,
      kubun: baitaruRecord.kubun,
      riyousya: baitaruRecord.riyousya,
      bunrui: baitaruRecord.bunrui,
      room: baitaruRecord.room,
      time: baitaruRecord.time,
      index: baitaruRecord.index,
      modeRecord: "copy",
    };

    this.props.handleCopyRecord(cloneBaitaruCopy);
  }

  /**
   *
   * @param {*} baitaruRecord
   */
  handleDelVital(baitaruRecord) {
    this.props.handleDelVital(baitaruRecord);
  }

  handleServerDelVital(vitalId) {
    this.props.deleteRecord(vitalId);
  }

  /**
   * Edit, Update function with single field
   *
   */

  /**
   * function handle when click to edit field
   * (1)
   * @param {int} MType // display modal type corresponding
   * @param {*} report // record for row
   * @param {*} index // index of row
   */
  handleMInput(MType, record, index) {
    const { vitalEctType, vitalEct, vitalHighLow, jikantai } = this.props;

    if (!record.time && jikantai && jikantai != "全表示") {
      record.time = jikantai.split("~")[0] + ":00";
    }
    const extractCurrent = checkTableField(
      MType,
      record,
      vitalHighLow,
      vitalEct,
      vitalEctType
    );

    let okSaveButton = true;
    if (
      MType === ConstSet.MODAL_B_ETC0 ||
      MType === ConstSet.MODAL_B_ETC1 ||
      MType === ConstSet.MODAL_B_ETC2 ||
      MType === ConstSet.MODAL_B_COMMENT
    ) {
      okSaveButton = false;
    }

    // handle ectType from setting: number, text, selection
    let ectNumberMode = false;
    if (vitalEctType) {
      const vitalEctTypeConvert = vitalEctType.toString().split(";");
      if (MType === ConstSet.MODAL_B_ETC0) {
        // ect1 Setting
        if (vitalEctTypeConvert[0]) {
          if (vitalEctTypeConvert[0] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }

      if (MType === ConstSet.MODAL_B_ETC1) {
        // ect1 Setting
        if (vitalEctTypeConvert[1]) {
          if (vitalEctTypeConvert[1] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }

      if (MType === ConstSet.MODAL_B_ETC2) {
        // ect1 Setting
        if (vitalEctTypeConvert[2]) {
          if (vitalEctTypeConvert[2] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }
    }
    // load pre value
    this.props.zendkaiLoad(record.riyousya.id, record.time);

    // Convert Type to Parameter
    this.setState({
      rowActive: index,
      expandRowClick: [],

      visibleMInput: true,
      modalType: MType,

      riyousya: record.riyousya.name,
      time: record.time,
      record,
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,

      ectNumberMode, // ect inpyt type from setting
    });
  }

  /**
   * (2)
   */
  handleMInputCancel() {
    this.setState({
      visibleMInput: false,
    });
  }

  /**
   * (3.4) Next field
   */
  nextInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { vitalEct, vitalEctType, vitalCol } = this.props;

    const etcVitalList = vitalEct.split(",");

    let modalType = this.state.modalType;

    if (
      modalType >= ConstSet.MODAL_B_TIME &&
      modalType < ConstSet.MODAL_B_TANTOMASTER
    ) {
      modalType += 1;

      // Check display col setting
      modalType = vitalColSetting(vitalCol, modalType, "next");

      if (
        modalType === ConstSet.MODAL_B_ETC0 &&
        (!etcVitalList[0].split("=")[1] || etcVitalList[0].split("=")[1] == "")
      ) {
        modalType += 1;
      }
      if (
        modalType === ConstSet.MODAL_B_ETC1 &&
        (!etcVitalList[1].split("=")[1] || etcVitalList[1].split("=")[1] == "")
      ) {
        modalType += 1;
      }
      if (
        modalType === ConstSet.MODAL_B_ETC2 &&
        (!etcVitalList[2].split("=")[1] || etcVitalList[2].split("=")[1] == "")
      ) {
        modalType += 1;
      }
      if (
        modalType !== ConstSet.MODAL_B_TANTOMASTER &&
        modalType > ConstSet.MODAL_B_ETC2
      ) {
        modalType = ConstSet.MODAL_B_COMMENT;
      }
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_B_ETC0 ||
      modalType === ConstSet.MODAL_B_ETC1 ||
      modalType === ConstSet.MODAL_B_ETC2 ||
      modalType === ConstSet.MODAL_B_COMMENT
    ) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(
      modalType,
      this.state.record,
      this.props.vitalHighLow,
      this.props.vitalEct
    );

    // handle ectType from setting: number, text, selection
    let ectNumberMode = false;
    if (vitalEctType) {
      const vitalEctTypeConvert = vitalEctType.toString().split(";");
      if (modalType === ConstSet.MODAL_B_ETC0) {
        // ect1 Setting
        if (vitalEctTypeConvert[0]) {
          if (vitalEctTypeConvert[0] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }

      if (modalType === ConstSet.MODAL_B_ETC1) {
        // ect1 Setting
        if (vitalEctTypeConvert[1]) {
          if (vitalEctTypeConvert[1] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }

      if (modalType === ConstSet.MODAL_B_ETC2) {
        // ect1 Setting
        if (vitalEctTypeConvert[2]) {
          if (vitalEctTypeConvert[2] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }
    }

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,

      ectNumberMode, // ect inpyt type from setting
    });
  };

  /**
   * (3.1) Pre field
   */
  preInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { vitalEct, vitalEctType, vitalCol } = this.props;
    const etcVitalList = vitalEct.split(",");

    let modalType = this.state.modalType;

    if (
      modalType > ConstSet.MODAL_B_TIME &&
      modalType <= ConstSet.MODAL_B_TANTOMASTER
    ) {
      if (modalType === ConstSet.MODAL_B_COMMENT) {
        modalType = ConstSet.MODAL_B_ETC2;
      }
      else {
        modalType -= 1;
      }

      if (
        modalType === ConstSet.MODAL_B_ETC2 &&
        (!etcVitalList[2].split("=")[1] || etcVitalList[2].split("=")[1] == "")
      ) {
        modalType -= 1;
      }
      if (
        modalType === ConstSet.MODAL_B_ETC1 &&
        (!etcVitalList[1].split("=")[1] || etcVitalList[1].split("=")[1] == "")
      ) {
        modalType -= 1;
      }
      if (
        modalType === ConstSet.MODAL_B_ETC0 &&
        (!etcVitalList[0].split("=")[1] || etcVitalList[0].split("=")[1] == "")
      ) {
        modalType -= 1;
      }

      // Check display col setting
      modalType = vitalColSetting(vitalCol, modalType, "pre");
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_B_ETC0 ||
      modalType === ConstSet.MODAL_B_ETC1 ||
      modalType === ConstSet.MODAL_B_ETC2 ||
      modalType === ConstSet.MODAL_B_COMMENT
    ) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(
      modalType,
      this.state.record,
      this.props.vitalHighLow,
      vitalEct
    );

    // handle ectType from setting: number, text, selection
    let ectNumberMode = false;
    if (vitalEctType) {
      const vitalEctTypeConvert = vitalEctType.toString().split(";");
      if (modalType === ConstSet.MODAL_B_ETC0) {
        // ect1 Setting
        if (vitalEctTypeConvert[0]) {
          if (vitalEctTypeConvert[0] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }

      if (modalType === ConstSet.MODAL_B_ETC1) {
        // ect1 Setting
        if (vitalEctTypeConvert[1]) {
          if (vitalEctTypeConvert[1] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }

      if (modalType === ConstSet.MODAL_B_ETC2) {
        // ect1 Setting
        if (vitalEctTypeConvert[2]) {
          if (vitalEctTypeConvert[2] === "1") {
            // virtual keybroad
            ectNumberMode = true; // display input value when click to virtual number keybroad
          }
        }
      }
    }

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,

      ectNumberMode, // ect inpyt type from setting
    });
  };

  /**
   * (3.2) Upper field
   */
  upperInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;
    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_B_ETC0 ||
      modalType === ConstSet.MODAL_B_ETC1 ||
      modalType === ConstSet.MODAL_B_ETC2 ||
      modalType === ConstSet.MODAL_B_COMMENT
    ) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index],
      this.props.vitalHighLow,
      this.props.vitalEct
    );

    // load pre value
    this.props.zendkaiLoad(nyutaisho[index].riyousya.id);

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      record: nyutaisho[index],
      time: nyutaisho[index].time,

      inputField: extractCurrent.type,
      inputValue: extractCurrent.value,
      inputType: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.3) lower field
   */
  lowerInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;
    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_B_ETC0 ||
      modalType === ConstSet.MODAL_B_ETC1 ||
      modalType === ConstSet.MODAL_B_ETC2 ||
      modalType === ConstSet.MODAL_B_COMMENT
    ) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index],
      this.props.vitalHighLow,
      this.props.vitalEct
    );

    // load pre value
    this.props.zendkaiLoad(nyutaisho[index].riyousya.id);

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      time: nyutaisho[index].time,

      record: nyutaisho[index],

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * 時間ところ
   */

  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.setState({
      inputType: ConstSet.MODAL_B_TIME,
      inputValue: currentTime,
      inputField: "time",
      okSaveButton: false,
    });
  };

  /**
   * (1)
   * @param {(*)} value
   */
  getHandleHourInput(value) {
    let preData = this.state.inputValue;
    if (!preData) {
      preData = value + ":00";
    }
    else {
      preData = value + preData.substring(2, 5);
    }
    this.setState({
      inputType: ConstSet.MODAL_B_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (2)
   * @param {(*)} value
   */
  getHandleMinuteLeftInput(value) {
    let preData = this.state.inputValue;

    if (!preData) {
      preData = "00:" + value;
    }
    else {
      preData = preData.substring(0, 3) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_B_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (3)
   * @param {(*)} value
   */
  getHandleMinuteInput(value) {
    let preData = this.state.inputValue;
    if (!preData) {
      preData = "00:0" + value;
    }
    else {
      preData = preData.substring(0, 4) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_B_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  // 時間つもりた
  /**
   * 申送
   * @param {*} value
   */
  getHandleReportCaseTyuif(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_REPORTCASETYUIF,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "reportCaseTyuif",
      okSaveButton: false,
    });
  }

  /**
   * メイン
   * @param {*} value
   */
  getHandleMainf(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_MAINF,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "mainf",
      okSaveButton: false,
    });
  }

  /**
   * 体温
   * @param {*} value
   */
  getHandleInputWeight = (value) => {
    this.setState({
      inputType: ConstSet.MODAL_B_WEIGHT,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "weight",
      okSaveButton: false,
    });
  };

  /**
   * 体温
   * @param {*} value
   */
  getHandleInputTemp(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_TEMP,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "temperature",
      okSaveButton: false,
    });
  }

  /**
   * 血圧(高)
   * @param {*} value
   */
  getHandleInputPresureHigh(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_BPRESSURE_HIGHT,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "bpressureHigh",
      okSaveButton: false,
    });
  }

  /**
   * 血圧(低)
   * @param {*} value
   */
  getHandleInputPresureLow(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_BPRESSURE_LOW,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "bpressureLow",
      okSaveButton: false,
    });
  }

  /**
   * 脈拍
   * @param {*} value
   */
  getHandleInputPulsefNo(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_PULSEF_NO,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "pulse",
      okSaveButton: false,
    });
  }

  /**
   * 脈拍 -- f
   * @param {*} value
   */
  getHandleInputPulsefType(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_PULSEF_TYPE,
      inputValue: value,
      inputField: "pulsef",
      okSaveButton: false,
    });
  }

  /**
   * SPOB - before
   * @param {*} value
   */
  getHandleInputSpo2B(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_SPO2_BEFORE,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "spo2",
      okSaveButton: false,
    });
  }

  /**
   * SPOA - after
   * @param {*} value
   */
  getHandleInputSpo2A(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_SPO2_AFTER,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "spo2",
      okSaveButton: false,
    });
  }

  /**
   * 呼吸
   * @param {*} value
   */
  getHandleInputKokyuNo(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_KOKYU_NO,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "kokyu",
      okSaveButton: false,
    });
  }

  /**
   * 呼吸 -- f
   * @param {*} value
   */
  getHandleInputKokyuType(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_KOKYU_TYPE,
      inputValue: value,
      inputField: "kokyuf",
      okSaveButton: false,
    });
  }

  /**
   * ect1
   * @param {*} value
   */
  getHandleInputEtc1(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_B_ETC0,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "etc1",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_B_ETC0,
        inputValue: value,
        inputField: "etc1",
        okSaveButton: false,
      });
    }
  }

  /**
   * ect1
   * @param {*} value
   */
  getHandleInputNumEtc1(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_ETC0,
      inputValue: handleInputNumber(value, this.state.inputValue, true),
      inputField: "etc1",
      okSaveButton: false,
    });
  }

  /**
   * ect2
   * @param {*} value
   */
  getHandleInputEtc2(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_B_ETC1,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "etc2",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_B_ETC1,
        inputValue: value,
        inputField: "etc2",
        okSaveButton: false,
      });
    }
  }

  /**
   * ect2
   * @param {*} value
   */
  getHandleInputNumEtc2(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_ETC1,
      inputValue: handleInputNumber(value, this.state.inputValue, true),
      inputField: "etc2",
      okSaveButton: false,
    });
  }

  /**
   * ect3
   * @param {*} value
   */
  getHandleInputEtc3(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_B_ETC2,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "etc3",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_B_ETC2,
        inputValue: value,
        inputField: "etc3",
        okSaveButton: false,
      });
    }
  }

  /**
   * ect3
   * @param {*} value
   */
  getHandleInputNumEtc3(value) {
    this.setState({
      inputType: ConstSet.MODAL_B_ETC2,
      inputValue: handleInputNumber(value, this.state.inputValue, true),
      inputField: "etc3",
      okSaveButton: false,
    });
  }

  /**
   *
   * @param {*} value
   * @param {*} type
   */
  getHandleInputComment(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_B_COMMENT,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_B_COMMENT,
        inputValue: value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
  }

  /**
   * 担当者
   * @param {*} value
   */
  getHandleTantoMasterKB(value) {
    // let updateCurrentRecord = this.state.record;
    // updateCurrentRecord.tantoId = value;
    this.setState({
      inputType: ConstSet.MODAL_B_TANTOMASTER,
      inputValue: value,
      inputField: "tantoId",
      // record: updateCurrentRecord,
      okSaveButton: false,
    });
  }

  /**
   * Function update data
   */
  handleOkUpdate = (type) => {
    const checkVitailId = this.state.record.vitalId
      ? this.state.record.vitalId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkVitailId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        vitalIds: checkVitailId.toString(),
        index: this.state.record.index,
        date: localStorage.getItem("nyuDate"),
      };

      let updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_B_SPO2_BEFORE) {
          updateField = handleSpo2(this.state.record.spo2, updateField, null);
          params[this.state.inputField] = updateField;
        }
        else if (this.state.inputType === ConstSet.MODAL_B_SPO2_AFTER) {
          updateField = handleSpo2(this.state.record.spo2, null, updateField);
          params[this.state.inputField] = updateField;
        }
        else if (
          (this.state.inputType === ConstSet.MODAL_B_WEIGHT ||
            this.state.inputType === ConstSet.MODAL_B_TEMP ||
            this.state.inputType === ConstSet.MODAL_B_BPRESSURE_HIGHT ||
            this.state.inputType === ConstSet.MODAL_B_BPRESSURE_LOW ||
            this.state.inputType === ConstSet.MODAL_B_PULSEF_NO ||
            this.state.inputType === ConstSet.MODAL_B_SPO2_BEFORE ||
            this.state.inputType === ConstSet.MODAL_B_SPO2_AFTER ||
            this.state.inputType === ConstSet.MODAL_B_KOKYU_NO) &&
          !this.state.inputValue
        ) {
          params[this.state.inputField] = "" + 0;
        }
        else if (
          (this.state.inputType === ConstSet.MODAL_B_PULSEF_TYPE ||
            this.state.inputType === ConstSet.MODAL_B_KOKYU_TYPE) &&
          this.state.inputValue === "無し"
        ) {
          params[this.state.inputField] = "";
        }
        else if (this.state.inputType === ConstSet.MODAL_B_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.updateRecord(params);

        let okSaveButton = true;
        if (
          this.state.inputType === ConstSet.MODAL_B_ETC0 ||
          this.state.inputType === ConstSet.MODAL_B_ETC1 ||
          this.state.inputType === ConstSet.MODAL_B_ETC2 ||
          this.state.inputType === ConstSet.MODAL_B_COMMENT
        ) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,

          // time: this.state.inputValue,
        });
      }
    } // POST
    else {
      // setting time auto if not input
      let preTime = "00:00";
      if (
        getValueIskeep("jikantai") &&
        getValueIskeep("jikantai") !== "全表示"
      ) {
        preTime = getValueIskeep("jikantai").split("~")[0];
      }

      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousya.id.toString(),
        date: localStorage.getItem("nyuDate"),
        time: this.state.record.time ? this.state.record.time : preTime,
        mainf: "0",
        tantoId: getValueLocalstorage("tid"),
      };

      let updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_B_SPO2_BEFORE) {
          updateField = handleSpo2(this.state.record.spo2, updateField, null);
          params[this.state.inputField] = updateField;
        }
        else if (this.state.inputType === ConstSet.MODAL_B_SPO2_AFTER) {
          updateField = handleSpo2(this.state.record.spo2, null, updateField);
          params[this.state.inputField] = updateField;
        }
        else if (
          (this.state.inputType === ConstSet.MODAL_B_WEIGHT ||
            this.state.inputType === ConstSet.MODAL_B_TEMP ||
            this.state.inputType === ConstSet.MODAL_B_BPRESSURE_HIGHT ||
            this.state.inputType === ConstSet.MODAL_B_BPRESSURE_LOW ||
            this.state.inputType === ConstSet.MODAL_B_PULSEF_NO ||
            this.state.inputType === ConstSet.MODAL_B_SPO2_BEFORE ||
            this.state.inputType === ConstSet.MODAL_B_SPO2_AFTER ||
            this.state.inputType === ConstSet.MODAL_B_KOKYU_NO) &&
          !this.state.inputValue
        ) {
          params[this.state.inputField] = "" + 0;
        }
        else if (
          (this.state.inputType === ConstSet.MODAL_B_PULSEF_TYPE ||
            this.state.inputType === ConstSet.MODAL_B_KOKYU_TYPE) &&
          this.state.inputValue === "無し"
        ) {
          params[this.state.inputField] = "";
        }
        else if (this.state.inputType === ConstSet.MODAL_B_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        /**
         * メインは、自動でOnにして下さい（当日1回目はOn,2回目以降はOff）
         *
         */
        let checkFirstRecord = false;
        if (this.state.inputType !== ConstSet.MODAL_B_MAINF) {
          const riyousyaId = this.state.record.riyousya.id;
          const { nyutaisho } = this.props;
          for (let i = 0; i < nyutaisho.length; i++) {
            if (
              nyutaisho[i].riyousya.id === riyousyaId &&
              (!nyutaisho[i].modeRecord || nyutaisho[i].modeRecord !== "copy")
            ) {
              if (nyutaisho[i].vitalId && nyutaisho[i].vitalId > 0) {
                checkFirstRecord = true;
                break;
              }
            }
          }

          if (!checkFirstRecord) {
            params.mainf = "1";
          }
        }
        // メインは、自動でOnにして下さい（当日1回目はOn,2回目以降はOff）

        this.props.insertRecord(params);

        let okSaveButton = true;
        if (
          this.state.inputType === ConstSet.MODAL_B_ETC0 ||
          this.state.inputType === ConstSet.MODAL_B_ETC1 ||
          this.state.inputType === ConstSet.MODAL_B_ETC2 ||
          this.state.inputType === ConstSet.MODAL_B_COMMENT
        ) {
          okSaveButton = false;
        }
        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,

          // time: this.state.inputValue,
        });
      }
    }

    // close popup
    if (type !== true) {
      // save from next, pre, upper, lower not colse popup
      this.setState({
        visibleMInput: false,
      });
    }
  };

  // Edit, Update function end

  /**
   * Handle Edit, Update By Input From from right all Screen
   * ALl fields can be save on this Drawer
   *
   */

  /**
   * Form Inside Drawer
   * Show Form
   */
  showDrawer = (record) => {
    const { jikantai } = this.props;
    /**
     * メインは、自動でOnにして下さい（当日1回目はOn,2回目以降はOff）
     *
     */
    let checkFirstRecord = false;
    const riyousyaId = record.riyousya.id;
    const { nyutaisho } = this.props;
    for (let i = 0; i < nyutaisho.length; i++) {
      if (
        nyutaisho[i].riyousya.id === riyousyaId &&
        (!nyutaisho[i].modeRecord || nyutaisho[i].modeRecord !== "copy")
      ) {
        if (nyutaisho[i].vitalId && nyutaisho[i].vitalId > 0) {
          checkFirstRecord = true;
          break;
        }
      }
    }

    if (!checkFirstRecord) {
      record.mainf = "1";
    }
    // メインは、自動でOnにして下さい（当日1回目はOn,2回目以降はOff）

    if (!record.time && jikantai && jikantai != "全表示") {
      record.time = jikantai.split("~")[0] + ":00";
    }

    this.props.zendkaiLoad(record.riyousya.id, record.time);

    this.setState({
      rowActive: record.index,
      showDrawer: true,
      record,
      isFormUpdate: false,
    });
  };

  /**
   * Close Form
   */
  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  upperForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);
    if (cloneData.spo2) {
      cloneData.spo2B = cloneData.spo2.split("-")[0];

      cloneData.spo2A = cloneData.spo2.split("-")[1]
        ? cloneData.spo2.split("-")[1]
        : "";
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.props.zendkaiLoad(cloneData.riyousya.id, cloneData.time);

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  lowerForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);
    if (cloneData.spo2) {
      cloneData.spo2B = cloneData.spo2.split("-")[0];

      cloneData.spo2A = cloneData.spo2.split("-")[1]
        ? cloneData.spo2.split("-")[1]
        : "";
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.props.zendkaiLoad(cloneData.riyousya.id, cloneData.time);

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value
   * @param {*} type
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.record;
    const objData = {};

    // handle input number
    if (
      type === "weight" ||
      type === "temperature" ||
      type === "bpressureHigh" ||
      type === "bpressureLow" ||
      type === "pulse" ||
      type === "kokyu"
    ) {
      value = value ? value : "0";
    }

    // if (type === 'ect1' || type === 'ect2' || type === 'ect3') {
    //     const { vitalEctType } = this.props;
    //     if (vitalEctType) {
    //         let vitalEctTypeConvert = vitalEctType.toString().split(';');
    //         // ect1 Setting
    //         if (vitalEctTypeConvert[0]) {
    //             if (vitalEctTypeConvert[0] === '1' && type === 'ect1') { // number input
    //                 value = value ? value : '0'
    //             }
    //         }

    //         // ect2 Setting
    //         if (vitalEctTypeConvert[1]) {
    //             if (vitalEctTypeConvert[1] === '1' && type === 'ect2') { // number input
    //                 value = value ? value : '0'
    //             }
    //         }

    //         // ect3 Setting
    //         if (vitalEctTypeConvert[2]) {
    //             if (vitalEctTypeConvert[2] === '1' && type === 'ect3') { // number input
    //                 value = value ? value : '0'
    //             }
    //         }

    //     }
    // }

    // SPO2,SPO2B,SPO2A場合をhandle
    if (type === "spo2B") {
      objData.spo2 = handleSpo2(prepareData.spo2, value, null);
    }

    if (type === "spo2A") {
      objData.spo2 = handleSpo2(prepareData.spo2, null, value);
    }

    objData[type] = value;
    this.setState({
      record: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  /**
   * Save data action
   */
  handleOkUpdateForm = () => {
    const checkVitailId = this.state.record.vitalId
      ? this.state.record.vitalId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    const recordOnState = this.state.record;
    if (checkVitailId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        vitalIds: checkVitailId.toString(),
        index: this.state.record.index,

        riyousyaId: recordOnState.riyousya.id,
        tantoId: recordOnState.tantoId,
        date: localStorage.getItem("nyuDate"), // @TODO check performance when get from record or localstorage
        time: recordOnState.time,
        mainf: recordOnState.mainf,
        weight: recordOnState.weight,
        temperature: recordOnState.temperature,
        bpressureHigh: recordOnState.bpressureHigh,
        bpressureLow: recordOnState.bpressureLow,
        pulse: recordOnState.pulse,
        pulsef: recordOnState.pulsef,
        kokyu: recordOnState.kokyu,
        kokyuf: recordOnState.kokyuf,
        spo2: recordOnState.spo2,
        etc1: recordOnState.etc1,
        etc2: recordOnState.etc2,
        etc3: recordOnState.etc3,
        comment: recordOnState.comment,
        reportCaseTyuif: recordOnState.reportCaseTyuif,
        reportCaseNishif: recordOnState.reportCaseNishif,
        reportCaseNaiyou2: recordOnState.reportCaseNaiyou2, // @TODO
      };

      // 削除場合
      if (params.pulsef && params.pulsef === "無し") {
        params.pulsef = "";
      }

      // 削除場合
      if (params.kokyuf && params.kokyuf === "無し") {
        params.kokyuf = "";
      }

      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.updateRecord(params);
    } // POST
    else {
      // setting time auto if not input
      let preTime = "00:00";
      if (
        getValueIskeep("jikantai") &&
        getValueIskeep("jikantai") !== "全表示"
      ) {
        preTime = getValueIskeep("jikantai").split("~")[0];
      }

      const params = {
        index: this.state.record.index,
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        riyousyaId: recordOnState.riyousya.id,
        date: localStorage.getItem("nyuDate"), // @TODO check performance when get from record or localstorage
        time: recordOnState.time ? recordOnState.time : preTime,
        mainf: recordOnState.mainf,
        reportCaseTyuif: recordOnState.reportCaseTyuif,
        weight: recordOnState.weight,
        temperature: recordOnState.temperature,
        bpressureHigh: recordOnState.bpressureHigh,
        bpressureLow: recordOnState.bpressureLow,
        pulse: recordOnState.pulse,
        pulsef: recordOnState.pulsef,
        kokyu: recordOnState.kokyu,
        kokyuf: recordOnState.kokyuf,
        spo2: recordOnState.spo2,
        etc1: recordOnState.etc1,
        etc2: recordOnState.etc2,
        etc3: recordOnState.etc3,
        comment: recordOnState.comment,
        tantoId: recordOnState.tantoId
          ? recordOnState.tantoId
          : getValueLocalstorage("tid"),

        reportCaseNishif: recordOnState.reportCaseNishif,
        reportCaseNaiyou2: recordOnState.reportCaseNaiyou2, // @TODO
      };

      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.insertRecord(params);
    }

    // clear inputing mode
    this.setState({
      isFormUpdate: false,
    });
  };
  // END Handle Edit, Update By Input Form from right all Screen

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Handle Add, Edit Setting Ect
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEctState(index) {
    this.props.yougoMasterEctState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEctCancel(index) {
    this.props.yougoMasterEctCancel(index);
  }

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEctSave(type, naiyou) {
    this.props.yougoMasterEctSave(type, naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Handle 一括入力
   *
   * handle checkbox: time, comment, tantoMaster
   * handle change time, comment, tantoMaster
   * handle OK to save data
   * handle CANCEL to cancel input mode
   *
   * handle autoCheck when input comment field. Performanceので、onChange has changed by onBlur
   *
   * handle function when user select 利用者
   *
   */

  onClickSelectAll = () => {
    const { arrCheckBox, tableCurrentPage, isClickAll } = this.state;
    const { nyutaisho } = this.props;

    const preArrCheckBox = arrCheckBox;
    let rowsSelect = 0; // count total row selected

    const delIds = [];
    for (let i = 0; i < nyutaisho.length; i++) {
      if (!isClickAll) {
        if (
          i < ConstSet.TABLE_PAGINATION * tableCurrentPage &&
          i >= ConstSet.TABLE_PAGINATION * (tableCurrentPage - 1)
        ) {
          preArrCheckBox[i] = true;

          // handle for del mode, list ids has push into array
          if (nyutaisho[i].vitalId) {
            delIds[i] = nyutaisho[i].vitalId;
          }
          else {
            delIds[i] = 0;
            // preArrCheckBox[i] = false;
          }

          rowsSelect++;
        }
        else {
          preArrCheckBox[i] = false;
        }
      }
      else {
        preArrCheckBox[i] = false;

        // reset delIds from state
        delIds[i] = 0;
      }
    }

    // preMulInputData.vitalIds = vitalIds;

    if (!isClickAll) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      isClickAll: !isClickAll,
      rowsSelect,
      delIds,
    });

    // handle logic
  };

  onClickSelectOne = (recordIndex) => {
    const { arrCheckBox, delIds } = this.state;
    const { nyutaisho } = this.props;

    //
    const preArrCheckBox = arrCheckBox;
    preArrCheckBox[recordIndex] = !arrCheckBox[recordIndex];

    // Check is selected, id have been retrieve from state
    const preDelIds = delIds;
    if (preArrCheckBox[recordIndex]) {
      if (
        nyutaisho &&
        nyutaisho[recordIndex] &&
        nyutaisho[recordIndex].vitalId
      ) {
        preDelIds[recordIndex] = nyutaisho[recordIndex].vitalId;
      }
    }
    else {
      preDelIds[recordIndex] = 0;
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      delIds: preDelIds,
    });

    // display 変更 button
    let mulBtnState = false;

    // count select row
    let rowsSelect = 0;

    preArrCheckBox.forEach((item) => {
      if (item) {
        rowsSelect++;
        mulBtnState = true;
      }
    });

    this.setState({
      rowsSelect,
    });

    if (mulBtnState) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }
  };

  /**
   *
   * @param {*} e
   * @param {*} type
   * @param {*} key
   */
  handleCheckBox = (type, key, e) => {
    const { mulInputData, mulInputCheckbox } = this.state;

    //
    const preDataCheckbox = mulInputCheckbox;
    preDataCheckbox[type] = e.target.checked;

    //
    const preData = ikkatsuCheckboxReset(mulInputData, key);

    this.setState({
      mulInputCheckbox: preDataCheckbox,
      mulInputData: preData,
    });
  };

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulMode(time, timeString) {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulInputComment(value, type) {
    const preData = this.state.mulInputData;
    if (type && type === "select") {
      preData.comment = preData.comment
        ? preData.comment + "、" + value
        : value;
    }
    else {
      preData.comment = value;
    }

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[1] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   */
  getHandleOnChangeMIC = () => {
    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[1] = true;

    this.setState({
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   * @param {int} value ID of tantoMaster
   */
  getHandleMulTantoMasterKB(value) {
    const preData = this.state.mulInputData;
    preData.tantoId = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[2] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   */
  handleMulInputModeOk() {
    //
    this.ikkatsu();

    // close popup
    this.props.handleMulInputModeCancel();

    // open modal
    this.setState({
      visibleMulState: true,
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  handleMulInputModeCancel() {
    this.props.handleMulInputModeCancel();

    // reset
    const reset = {
      vitalIds: null,
      time: null,
      comment: null,
      tantoId: null,
    };

    this.setState({
      mulInputData: reset,
      mulInputCheckbox: [false, false, false],
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  ikkatsu = () => {
    // prepare data
    const { arrCheckBox, mulInputData, mulInputCheckbox, delIds } = this.state;
    const { nyutaisho, mulInputMode, mulDelMode } = this.props;

    if (mulDelMode) {
      // Create count rows that is modify, and index
      let count = 0;
      delIds.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      const preArrCheckBox = arrCheckBox;
      for (let i = 0; i < delIds.length; i++) {
        if (delIds[i]) {
          this.props.deleteRecord(delIds[i]);
          count--;

          const preDelIds = delIds;
          preDelIds[i] = 0;
          preArrCheckBox[i] = false;
          this.setState({
            count,
            delIds: preDelIds,
            arrCheckBox: preArrCheckBox,
          });
          break;
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          vitalIds: null,
          time: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false],
          arrCheckBox: [],
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
    else if (mulInputMode) {
      const preData = {};

      if (!mulInputCheckbox[0]) {
        preData.time = null;
      }
      else {
        preData.time = mulInputData.time;
      }

      if (!mulInputCheckbox[1]) {
        preData.comment = null;
      }
      else if (mulInputCheckbox[1] && !mulInputData.comment) {
        preData.comment = "";
      }
      else {
        preData.comment = mulInputData.comment;
      }

      if (!mulInputCheckbox[2]) {
        preData.tantoId = null;
      }
      else if (mulInputData.tantoId) {
        preData.tantoId = mulInputData.tantoId;
      }
      else {
        preData.tantoId = getValueLocalstorage("tid");
      }

      // if (!preData.time && !mulInputCheckbox[1] && !preData.tantoId) {
      //     return;
      // }

      preData.tantoId = preData.tantoId ? "" + preData.tantoId : null;

      // Create count rows that is modify, and index
      let count = 0;
      arrCheckBox.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      for (let i = 0; i < arrCheckBox.length; i++) {
        if (arrCheckBox[i]) {
          // Check if recordId exist -> call update function
          if (nyutaisho && nyutaisho[i].vitalId) {
            this.props.updateRecordMul(
              Object.assign({ vitalIds: "" + nyutaisho[i].vitalId }, preData)
            );
            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });

            break;
          }
          else if (nyutaisho && !nyutaisho[i].vitalId) {
            // recordId = null -> call crate new function
            // 0. systemControlName
            preData.systemControlName = JSON.parse(localStorage.getItem("menu")).sn;
            preData.systemControlId = JSON.parse(localStorage.getItem("menu")).si;

            // 1. index
            preData.index = nyutaisho[i].index;

            // 2. RiyousyaId
            preData.riyousyaId = "" + nyutaisho[i].riyousya.id;

            // 2. date
            preData.date = localStorage.getItem("nyuDate");

            // 3. time
            if (!preData.time) {
              if (nyutaisho[i].time) {
                preData.time = nyutaisho[i].time;
              }
              else {
                preData.time = "00:00";
                if (
                  getValueIskeep("jikantai") &&
                  getValueIskeep("jikantai") !== "全表示"
                ) {
                  preData.time = getValueIskeep("jikantai").split("~")[0];
                }
              }
            }

            // 4. mainf
            preData.mainf = "0";

            // 7. tantoId
            if (!preData.tantoId) {
              preData.tantoId = getValueLocalstorage("tid");
            }

            this.props.insertRecordMul(Object.assign({}, preData));

            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });
            break;
          }
          else {
            break;
          }
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          vitalIds: null,
          time: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false],
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
  };

  // End Handle 一括入力

  /**
   * handle click cell event
   *
   * in mulInputMode, click do nothing
   * in input mode, base info cell click -> open expander data, edting cell -> display modal
   * in nothing mode -> open expander data
   *
   */
  onClickCellEvent = (recordIndex, type) => {
    const { nyutaisho, inputMode, mulInputMode } = this.props;
    const record = nyutaisho[recordIndex];

    switch (type) {
      case ConstSet.COL_VITAL_ETC1:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_ETC0,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_ETC2:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_ETC1,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_ETC3:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_ETC2,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_SELECT:
        return;

      case ConstSet.COL_COPY:
        return;

      case ConstSet.COL_MODE:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_VITAL_NAME:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ADDRESS:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ROOM:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_VITAL_TIME:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_TIME,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_CASEF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_REPORTCASETYUIF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_MAINF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_MAINF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_WEIGHT:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_WEIGHT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_TEM:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_TEMP,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_PRESSURE:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return;
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_PULSEF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return;
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_SPO2:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return;
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_GULSEF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return;
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_COMENT:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_COMMENT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_TANTO:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_B_TANTOMASTER,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_VITAL_DEL:
        return;

      default:
        return;
    }
  };

  handChangePagination = (pagination) => {
    // reset 一括入力 mode
    const { arrCheckBox } = this.state;
    const preArrCheckBox = [];
    for (let i = 0; i < arrCheckBox.length; i++) {
      preArrCheckBox.push(0);
    }

    this.setState({
      tableCurrentPage: pagination.current,
      arrCheckBox: preArrCheckBox,
      isClickAll: false,
    });
  };

  closeModalMulState = () => {
    this.setState({
      visibleMulState: false,
      isConfirmMulDel: false,
    });
  };

  onChangeConfirmMulDel = (e) => {
    this.setState({
      isConfirmMulDel: e.target.checked,
    });
  };

  onDoubleClick = (key) => {
    alert(key);
  };

  render() {
    const state = this.state;
    const {
      nyutaisho,
      loading,
      bunruiTitle,
      vitalEct,
      vitalEctType,
      vitalCol,
      vitalHighLow,
      inputMode,
      mulInputAllMode,
      mulInputMode,
      mulInputModal,
      mulDelMode,
      tantoMaster,
      yougoMasterNa,
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,
      zenkai,
      isDisLongText,
      jikantai,
    } = this.props;

    /**
     * ECT input type 準備
     */
    let ect1Render = null;
    let ect1Type = null;
    let ect2Render = null;
    let ect2Type = null;
    let ect3Render = null;
    let ect3Type = null;
    // if (inputMode) {
    if (vitalEctType) {
      const vitalEctTypeConvert = vitalEctType.toString().split(";");
      // ect1 Setting
      if (vitalEctTypeConvert[0]) {
        if (vitalEctTypeConvert[0] === "1") {
          ect1Render = <NumberKB handleInputNum={this.getHandleInputNumEtc1} />;
          ect1Type = 1;
        }

        if (vitalEctTypeConvert[0] === "4") {
          ect1Render = (
            <CommentKB
              yougoMasterEditState={this.getYougoMasterEctState}
              yougoMasterEditCancel={this.getYougoMasterEctCancel}
              yougoMasterEditSave={this.getYougoMasterEctSave.bind(this, "1")}
              defaultValue={this.state.inputValue}
              handleTextareaKB={this.getHandleInputEtc1}
              yougoMasterNa={yougoMasterEctSelect1}
              rowNum={2}
            />
          );
          ect1Type = 4;
        }

        if (vitalEctTypeConvert[0] === "5") {
          ect1Render = (
            <InputSelectKB
              value={this.state.inputValue}
              handleInputSelect={this.getHandleInputEtc1}
              data={convertArrayToString(yougoMasterEctSelect1, "naiyou")}
            />
          );
          ect1Type = 5;
        }
      }

      // ect2 Setting
      if (vitalEctTypeConvert[1]) {
        if (vitalEctTypeConvert[1] === "1") {
          ect2Render = <NumberKB handleInputNum={this.getHandleInputNumEtc2} />;
          ect2Type = 1;
        }

        if (vitalEctTypeConvert[1] === "4") {
          ect2Render = (
            <CommentKB
              yougoMasterEditState={this.getYougoMasterEctState}
              yougoMasterEditCancel={this.getYougoMasterEctCancel}
              yougoMasterEditSave={this.getYougoMasterEctSave.bind(this, "2")}
              defaultValue={this.state.inputValue}
              handleTextareaKB={this.getHandleInputEtc2}
              yougoMasterNa={yougoMasterEctSelect2}
              rowNum={2}
            />
          );
          ect2Type = 4;
        }

        if (vitalEctTypeConvert[1] === "5") {
          // Convert yougoMasterEct from array to Sting
          let dataEct = "";
          if (yougoMasterEctSelect2 && yougoMasterEctSelect1.length > 0) {
            for (const i in yougoMasterEctSelect2) {
              dataEct = dataEct + yougoMasterEctSelect2[i].naiyou + ";";
            }
          }

          ect2Render = (
            <InputSelectKB
              value={this.state.inputValue}
              handleInputSelect={this.getHandleInputEtc2}
              data={yougoMasterEctSelect2}
            />
          );
          ect2Type = 5;
        }
      }

      // ect3 Setting
      if (vitalEctTypeConvert[2]) {
        if (vitalEctTypeConvert[2] === "1") {
          ect3Render = <NumberKB handleInputNum={this.getHandleInputNumEtc3} />;
          ect3Type = 1;
        }

        if (vitalEctTypeConvert[2] === "4") {
          ect3Render = (
            <CommentKB
              yougoMasterEditState={this.getYougoMasterEctState}
              yougoMasterEditCancel={this.getYougoMasterEctCancel}
              yougoMasterEditSave={this.getYougoMasterEctSave.bind(this, "3")}
              defaultValue={this.state.inputValue}
              handleTextareaKB={this.getHandleInputEtc3}
              yougoMasterNa={yougoMasterEctSelect3}
              rowNum={2}
            />
          );
          ect3Type = 4;
        }

        if (vitalEctTypeConvert[2] === "5") {
          // Convert yougoMasterEct from array to Sting
          let dataEct = "";
          if (yougoMasterEctSelect3 && yougoMasterEctSelect3.length > 0) {
            for (const i in yougoMasterEctSelect3) {
              dataEct = dataEct + yougoMasterEctSelect3[i] + ";";
            }
          }
          ect3Render = (
            <InputSelectKB
              value={this.state.inputValue}
              handleInputSelect={this.getHandleInputEtc3}
              data={dataEct}
            />
          );
          ect3Type = 5;
        }
      }
    }
    // }

    /**
     * handle date
     */
    const data = [];
    const columnsLong = [];

    if (mulInputAllMode) {
      columnsLong.push({
        title: "全",
        dataIndex: "colSelect",
        key: "colSelect",
        width: 50,
        fixed: "left",
        className: "input-mode-mul",
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.onClickSelectAll();
            }, // click row
          };
        },
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickSelectOne(record.key);
            }, // click row
          };
        },
      });
    }
    else if (inputMode) {
      columnsLong.push({
        title: "追加",
        dataIndex: "colCopy",
        key: "colCopy",
        width: 50,
        fixed: "left",
        className: "input-mode-fix action-col",
      });
    }
    else {
      columnsLong.push({
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        fixed: "left",
        className: `joutaiCol ${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      });
    }
    // colums情報を設定
    columnsLong.push(
      {
        title: "利用者名",
        dataIndex: "col2",
        key: "col2",
        width: 150,
        fixed: "left",
        className: `${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_NAME);
            }, // click row
          };
        },
      },
      {
        title: bunruiTitle,
        dataIndex: "col3",
        key: "col3",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_ADDRESS);
            }, // click row
          };
        },
      },
      {
        title: "部屋名",
        dataIndex: "col4",
        key: "col4",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_ROOM);
            }, // click row
          };
        },
      },
      {
        title: "時間",
        dataIndex: "col5",
        key: "col5",
        width: 70,
        className: `timeCol ${inputMode || mulInputAllMode
          ? " input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_TIME);
            }, // click row
          };
        },
      },
      {
        title: "申送",
        dataIndex: "col6",
        key: "col6",
        width: 70,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_CASEF);
            }, // click row
          };
        },
      },
      {
        title: "メイン",
        dataIndex: "col7",
        key: "col7",
        width: 70,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_MAINF);
            }, // click row
          };
        },
      }
    );

    // check setting display colum
    if (vitalCol && vitalCol[0] === "True") {
      columnsLong.push({
        title: "体重",
        dataIndex: "col_weight",
        key: "col_weight",
        width: 60,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_WEIGHT);
            }, // click row
          };
        },
      });
    }

    // check setting display colum
    if (vitalCol && vitalCol[1] === "True") {
      columnsLong.push({
        title: "体温",
        dataIndex: "col8",
        key: "col8",
        width: 60,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_TEM);
            }, // click row
          };
        },
      });
    }

    // check setting display colum
    if (vitalCol && vitalCol[2] === "True") {
      columnsLong.push({
        title: "血圧(高/低)",
        dataIndex: "col9",
        key: "col9",
        width: 90,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_PRESSURE);
            }, // click row
          };
        },
      });
    }

    // check setting display colum
    if (vitalCol && (vitalCol[3] === "True" || vitalCol[4] === "True")) {
      columnsLong.push({
        title: "脈拍",
        dataIndex: "col10",
        key: "col10",
        width: 90,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_PULSEF);
            }, // click row
          };
        },
      });
    }

    // check setting display colum
    if (vitalCol && (vitalCol[5] === "True" || vitalCol[6] === "True")) {
      columnsLong.push({
        title: "呼吸",
        dataIndex: "col11",
        key: "col11",
        width: 90,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_GULSEF);
            }, // click row
          };
        },
      });
    }

    // check setting display colum
    if (vitalCol && vitalCol[7] === "True") {
      columnsLong.push({
        title: "SPO2",
        dataIndex: "col12",
        key: "col12",
        width: 90,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_SPO2);
            }, // click row
          };
        },
      });
    }

    if (vitalEct) {
      const ectMenu = vitalEct.split(",");
      for (let i = 0; i < ectMenu.length; i++) {
        if (ectMenu[i].split("=").length === 2 && ectMenu[i].split("=")[1]) {
          columnsLong.push({
            title: ectMenu[i].split("=")[1],
            dataIndex: "ect" + i,
            key: "ect" + i,
            width: 90,
            className: `${inputMode || mulInputAllMode
              ? "input-mode dis-text-short"
              : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
            onCell: (record) => {
              return {
                onClick: () => {
                  this.onClickCellEvent(record.key, i + 1);
                }, // click row
              };
            },
          });
        }
      }
    }

    columnsLong.push(
      {
        title: "コメント",
        dataIndex: "col16",
        key: "col16",
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_COMENT);
            }, // click row
            // onDoubleClick: event => { this.onDoubleClick(record.key) }, // click row
          };
        },
      },
      {
        title: "担当者",
        dataIndex: "col17",
        key: "col17",
        width: 120,
        className: `${inputMode || mulInputAllMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_TANTO);
            }, // click row
          };
        },
      }
    );

    if (inputMode) {
      columnsLong.push({
        title: "削除",
        dataIndex: "colDel",
        key: "colDel",
        width: 50,
        // fixed: 'right',
        className: "input-mode-fix action-col",
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_VITAL_DEL);
            }, // click row
          };
        },
      });
    }

    // テーブルデータの準備
    if (nyutaisho) {
      let jikantaiTime = "";
      if (jikantai && jikantai != "全表示") {
        jikantaiTime = jikantai.split("~")[0];
      }

      // eslint-disable-next-line no-unused-vars
      let weight = null;
      let temperature = null;
      let bpressureHigh = null;
      let bpressureLow = null;
      let pulse = null;
      let respiration = null;

      if (vitalHighLow) {
        const extractRange = extractRangeVital(vitalHighLow);
        weight = extractRange.weight;
        temperature = extractRange.temperature;
        bpressureHigh = extractRange.bpressureHigh;
        bpressureLow = extractRange.bpressureLow;
        pulse = extractRange.pulse;
        respiration = extractRange.respiration;
      }

      for (let i = 0; i < nyutaisho.length; i++) {
        // check disable select in multiple input mode
        // let checkDisable = isDisableKiroku(nyutaisho[i], mulYoteiMode, mulDelMode)

        let color = "nuytaisho-yellow";
        if (nyutaisho[i].kubun === "ショート") {
          color = "nuytaisho-blue";
        }

        let gender = "女";
        let colorGender = "nts-gender-red";
        if (nyutaisho[i].riyousya.gender === 1) {
          gender = "男";
          colorGender = "nts-gender-blue";
        }

        let planType = "";
        if (nyutaisho[i].time && nyutaisho[i].planType === true) {
          planType = "bg_green";
        }
        else if (nyutaisho[i].vitalId && nyutaisho[i].time) {
          planType = "bg_pink";
        }

        /**
         * @TODO 体重おすすめ
         */
        const weightDis = nyutaisho[i].weight ? nyutaisho[i].weight : "";

        //
        let temperatureDis = "";
        if (
          nyutaisho[i].temperature &&
          temperature &&
          Number(nyutaisho[i].temperature) <= Number(temperature.split("~")[1])
        ) {
          if (
            Number(nyutaisho[i].temperature) < Number(temperature.split("~")[0])
          ) {
            temperatureDis = (
              <span style={{ color: "blue" }}>{nyutaisho[i].temperature}</span>
            );
          }
          else {
            temperatureDis = <span>{nyutaisho[i].temperature}</span>;
          }
        }
        else if (nyutaisho[i].temperature) {
          temperatureDis = (
            <span style={{ color: "red" }}>{nyutaisho[i].temperature}</span>
          );
        }

        //
        let bpressureHighDis = "";
        if (
          nyutaisho[i].bpressureHigh &&
          bpressureHigh &&
          Number(nyutaisho[i].bpressureHigh) <=
          Number(bpressureHigh.split("~")[1])
        ) {
          if (
            Number(nyutaisho[i].bpressureHigh) <
            Number(bpressureHigh.split("~")[0])
          ) {
            bpressureHighDis = (
              <span style={{ color: "blue" }}>
                {nyutaisho[i].bpressureHigh}
              </span>
            );
          }
          else {
            bpressureHighDis = <span>{nyutaisho[i].bpressureHigh}</span>;
          }
        }
        else if (nyutaisho[i].bpressureHigh) {
          bpressureHighDis = (
            <span style={{ color: "red" }}>{nyutaisho[i].bpressureHigh}</span>
          );
        }

        //
        let bpressureLowDis = "";
        if (
          nyutaisho[i].bpressureLow &&
          bpressureLow &&
          Number(nyutaisho[i].bpressureLow) <=
          Number(bpressureLow.split("~")[1])
        ) {
          if (
            Number(nyutaisho[i].bpressureLow) <
            Number(bpressureLow.split("~")[0])
          ) {
            bpressureLowDis = (
              <span style={{ color: "blue" }}>{nyutaisho[i].bpressureLow}</span>
            );
          }
          else {
            bpressureLowDis = <span>{nyutaisho[i].bpressureLow}</span>;
          }
        }
        else if (nyutaisho[i].bpressureLow) {
          bpressureLowDis = (
            <span style={{ color: "red" }}>{nyutaisho[i].bpressureLow}</span>
          );
        }

        let pulseDis = "";
        let pulseDisInput = "";
        let pulsefInput = "";
        if (
          nyutaisho[i].pulse &&
          pulse &&
          Number(nyutaisho[i].pulse) <= Number(pulse.split("~")[1])
        ) {
          if (Number(nyutaisho[i].pulse) < Number(pulse.split("~")[0])) {
            pulseDis = (
              <span style={{ color: "blue" }}>
                {vitalCol && vitalCol[3] && vitalCol[3] === "True"
                  ? nyutaisho[i].pulse
                  : null}
                {vitalCol &&
                  vitalCol[3] &&
                  vitalCol[3] === "True" &&
                  vitalCol[4] &&
                  vitalCol[4] === "True"
                  ? " "
                  : null}
                {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                  ? nyutaisho[i].pulsef
                  : null}
              </span>
            );
            pulseDisInput = (
              <span style={{ color: "blue" }}>
                {vitalCol && vitalCol[3] && vitalCol[3] === "True"
                  ? nyutaisho[i].pulse
                  : null}
              </span>
            );
            pulsefInput = (
              <span style={{ color: "blue" }}>
                {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                  ? nyutaisho[i].pulsef
                  : null}
              </span>
            );
          }
          else {
            pulseDis = (
              <span>
                {vitalCol && vitalCol[3] && vitalCol[3] === "True"
                  ? nyutaisho[i].pulse
                  : null}
                {vitalCol &&
                  vitalCol[3] &&
                  vitalCol[3] === "True" &&
                  vitalCol[4] &&
                  vitalCol[4] === "True"
                  ? " "
                  : null}
                {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                  ? nyutaisho[i].pulsef
                  : null}
              </span>
            );
            pulseDisInput = (
              <span>
                {vitalCol && vitalCol[3] && vitalCol[3] === "True"
                  ? nyutaisho[i].pulse
                  : null}
              </span>
            );
            pulsefInput = (
              <span>
                {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                  ? nyutaisho[i].pulsef
                  : null}
              </span>
            );
          }
        }
        else if (nyutaisho[i].pulse) {
          pulseDis = (
            <span style={{ color: "red" }}>
              {vitalCol && vitalCol[3] && vitalCol[3] === "True"
                ? nyutaisho[i].pulse
                : null}
              {vitalCol &&
                vitalCol[3] &&
                vitalCol[3] === "True" &&
                vitalCol[4] &&
                vitalCol[4] === "True"
                ? " "
                : null}
              {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                ? nyutaisho[i].pulsef
                : null}
            </span>
          );
          pulseDisInput = (
            <span style={{ color: "red" }}>
              {vitalCol && vitalCol[3] && vitalCol[3] === "True"
                ? nyutaisho[i].pulse
                : null}
            </span>
          );
          pulsefInput = (
            <span style={{ color: "red" }}>
              {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                ? nyutaisho[i].pulsef
                : null}
            </span>
          );
        }
        else if (!nyutaisho[i].pulse) {
          pulseDis = (
            <span>
              {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                ? nyutaisho[i].pulsef
                : null}
            </span>
          );
          pulsefInput = (
            <span>
              {vitalCol && vitalCol[4] && vitalCol[4] === "True"
                ? nyutaisho[i].pulsef
                : null}
            </span>
          );
        }

        let kokyuDis = "";
        let kokyuDisInput = "";
        let kokyufInput = "";
        if (
          nyutaisho[i].kokyu &&
          respiration &&
          Number(nyutaisho[i].kokyu) <= Number(respiration.split("~")[1])
        ) {
          if (Number(nyutaisho[i].kokyu) < Number(respiration.split("~")[0])) {
            kokyuDis = (
              <span style={{ color: "blue" }}>
                {vitalCol && vitalCol[5] && vitalCol[5] === "True"
                  ? nyutaisho[i].kokyu
                  : null}
                {vitalCol &&
                  vitalCol[5] &&
                  vitalCol[5] === "True" &&
                  vitalCol[6] &&
                  vitalCol[6] === "True"
                  ? " "
                  : null}
                {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                  ? nyutaisho[i].kokyuf
                  : null}
              </span>
            );
            kokyuDisInput = (
              <span style={{ color: "blue" }}>
                {vitalCol && vitalCol[5] && vitalCol[5] === "True"
                  ? nyutaisho[i].kokyu
                  : null}
              </span>
            );
            kokyufInput = (
              <span style={{ color: "blue" }}>
                {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                  ? nyutaisho[i].kokyuf
                  : null}
              </span>
            );
          }
          else {
            kokyuDis = (
              <span>
                {vitalCol && vitalCol[5] && vitalCol[5] === "True"
                  ? nyutaisho[i].kokyu
                  : null}
                {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                  ? nyutaisho[i].kokyuf
                  : null}
              </span>
            );
            kokyuDisInput = (
              <span>
                {vitalCol && vitalCol[5] && vitalCol[5] === "True"
                  ? nyutaisho[i].kokyu
                  : null}
              </span>
            );
            kokyufInput = (
              <span>
                {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                  ? nyutaisho[i].kokyuf
                  : null}
              </span>
            );
          }
        }
        else if (nyutaisho[i].kokyu) {
          kokyuDis = (
            <span style={{ color: "red" }}>
              {vitalCol && vitalCol[5] && vitalCol[5] === "True"
                ? nyutaisho[i].kokyu
                : null}
              {vitalCol &&
                vitalCol[5] &&
                vitalCol[5] === "True" &&
                vitalCol[6] &&
                vitalCol[6] === "True"
                ? " "
                : null}
              {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                ? nyutaisho[i].kokyuf
                : null}
            </span>
          );
          kokyuDisInput = (
            <span style={{ color: "red" }}>
              {vitalCol && vitalCol[5] && vitalCol[5] === "True"
                ? nyutaisho[i].kokyu
                : null}
            </span>
          );
          kokyufInput = (
            <span style={{ color: "red" }}>
              {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                ? nyutaisho[i].kokyuf
                : null}
            </span>
          );
        }
        else if (!nyutaisho[i].kokyu) {
          kokyuDis = (
            <span>
              {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                ? nyutaisho[i].kokyuf
                : null}
            </span>
          );
          kokyufInput = (
            <span>
              {vitalCol && vitalCol[6] && vitalCol[6] === "True"
                ? nyutaisho[i].kokyuf
                : null}
            </span>
          );
        }

        data.push({
          key: i,
          colSelect: <Checkbox checked={this.state.arrCheckBox[i]} />,
          colCopy: (
            <span className={"action-kiroku"}>
              <Button
                type={"primary"}
                ghost
                onClick={this.handleCopyRecord.bind(this, nyutaisho[i])}
              >
                <PlusOutlined />
              </Button>
            </span>
          ),
          // nyutaisho type
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType === "1" ? (
                <ArrowRightOutlined />
              ) : (
                nyutaisho[i].nyuType
              )}
            </div>
          ),
          // 利用者名
          col2: (
            <span className={"ntk-riyousya-colum"}>
              {nyutaisho[i].modeRecord && nyutaisho[i].modeRecord === "copy" ? (
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              )}
              <span className={`ntl-riyousya-gender ${colorGender}`}>
                {gender}
              </span>
            </span>
          ),
          col3: <div>{nyutaisho[i].bunrui}</div>, // 住所
          col4: <div>{nyutaisho[i].room}</div>, // 部屋名
          // 時間
          col5: (
            <div className={`icon-table ${planType}`}>
              {nyutaisho[i].time
                ? nyutaisho[i].time.substring(0, 5)
                : jikantai != "全表示"
                  ? jikantaiTime
                  : ""}
            </div>
          ),
          // 申送
          col6: (
            <div className={"icon-table"}>
              {nyutaisho[i].reportCaseTyuif ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          // メイン
          col7: (
            <div className={"icon-table"}>
              {nyutaisho[i].mainf ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          col_weight: weightDis,
          // 体温
          col8: (
            <div className={"icon-table"}>
              {temperatureDis ? temperatureDis : ""}
            </div>
          ),
          // 血圧(高/低)
          col9: inputMode ? (
            <div className={"icon-table"}>
              <Button
                className={"inputMode"}
                onClick={this.handleMInput.bind(
                  this,
                  ConstSet.MODAL_B_BPRESSURE_HIGHT,
                  nyutaisho[i],
                  i
                )}
              >
                {bpressureHighDis}
              </Button>
              <Button
                className={"inputMode"}
                onClick={this.handleMInput.bind(
                  this,
                  ConstSet.MODAL_B_BPRESSURE_LOW,
                  nyutaisho[i],
                  i
                )}
              >
                {bpressureLowDis}
              </Button>
            </div>
          ) : (
            <div className={"icon-table"}>
              {bpressureHighDis ? bpressureHighDis : ""}
              {bpressureLowDis ? "/" : ""}
              {bpressureLowDis ? bpressureLowDis : ""}
            </div>
          ),
          // 脈拍
          col10: inputMode ? (
            <div className={"icon-table"}>
              {vitalCol && vitalCol[3] && vitalCol[3] === "True" ? (
                <Button
                  className={"inputMode"}
                  onClick={this.handleMInput.bind(
                    this,
                    ConstSet.MODAL_B_PULSEF_NO,
                    nyutaisho[i],
                    i
                  )}
                >
                  {pulseDisInput}
                </Button>
              ) : null}

              {vitalCol && vitalCol[4] && vitalCol[4] === "True" ? (
                <Button
                  className={"inputMode"}
                  onClick={this.handleMInput.bind(
                    this,
                    ConstSet.MODAL_B_PULSEF_TYPE,
                    nyutaisho[i],
                    i
                  )}
                >
                  {pulsefInput}
                </Button>
              ) : null}
            </div>
          ) : (
            <div className={"icon-table"}>{pulseDis}</div>
          ),
          // 呼吸
          col11: inputMode ? (
            <div className={"icon-table"}>
              {vitalCol && vitalCol[5] && vitalCol[5] === "True" ? (
                <Button
                  className={"inputMode"}
                  onClick={this.handleMInput.bind(
                    this,
                    ConstSet.MODAL_B_KOKYU_NO,
                    nyutaisho[i],
                    i
                  )}
                >
                  {kokyuDisInput}
                </Button>
              ) : null}
              {vitalCol && vitalCol[6] && vitalCol[6] === "True" ? (
                <Button
                  className={"inputMode"}
                  onClick={this.handleMInput.bind(
                    this,
                    ConstSet.MODAL_B_KOKYU_TYPE,
                    nyutaisho[i],
                    i
                  )}
                >
                  {kokyufInput}
                </Button>
              ) : null}
            </div>
          ) : (
            <div className={"icon-table"}>{kokyuDis}</div>
          ),
          // SPO2
          col12: inputMode ? (
            <div className={"icon-table"}>
              <Button
                className={"inputMode"}
                onClick={this.handleMInput.bind(
                  this,
                  ConstSet.MODAL_B_SPO2_BEFORE,
                  nyutaisho[i],
                  i
                )}
              >
                {nyutaisho[i].spo2 ? nyutaisho[i].spo2.split("-")[0] : ""}
              </Button>
              <Button
                className={"inputMode"}
                onClick={this.handleMInput.bind(
                  this,
                  ConstSet.MODAL_B_SPO2_AFTER,
                  nyutaisho[i],
                  i
                )}
              >
                {nyutaisho[i].spo2 && nyutaisho[i].spo2.split("-")[1]
                  ? nyutaisho[i].spo2.split("-")[1]
                  : ""}
              </Button>
            </div>
          ) : (
            <div className={"icon-table"}>{nyutaisho[i].spo2}</div>
          ),
          ect0: (
            <div>
              {nyutaisho[i].etc1 && nyutaisho[i].etc1.trim()
                ? nyutaisho[i].etc1
                : ""}
            </div>
          ),
          ect1: (
            <div>
              {nyutaisho[i].etc2 && nyutaisho[i].etc2.trim()
                ? nyutaisho[i].etc2
                : ""}
            </div>
          ),
          ect2: (
            <div>
              {nyutaisho[i].etc3 && nyutaisho[i].etc3.trim()
                ? nyutaisho[i].etc3
                : ""}
            </div>
          ),
          col16: (
            <div>{nyutaisho[i].comment}</div>
          ),
          col17:
            nyutaisho[i].tantoName && nyutaisho[i].tantoName.length > 6 ? (
              <Tooltip placement="bottomRight" title={nyutaisho[i].tantoName}>
                {nyutaisho[i].tantoName.substring(0, 4) + "..."}
              </Tooltip>
            ) : (
              <div>{nyutaisho[i].tantoName ? nyutaisho[i].tantoName : ""}</div>
            ),
          colDel: (
            <span className={"action-kiroku"}>
              {nyutaisho[i].modeRecord || nyutaisho[i].vitalId ? (
                !nyutaisho[i].modeRecord && nyutaisho[i].vitalId ? (
                  <Popconfirm
                    overlayStyle={{ width: "210px" }}
                    title="削除してもいいですか？"
                    onConfirm={() =>
                      this.handleServerDelVital(nyutaisho[i].vitalId)
                    }
                  >
                    <span>
                      <Button type={"primary"} ghost>
                        <MinusOutlined />
                      </Button>
                    </span>
                  </Popconfirm>
                ) : (
                  <span>
                    <Button
                      type={"primary"}
                      ghost
                      onClick={this.handleDelVital.bind(this, nyutaisho[i])}
                    >
                      <MinusOutlined />
                    </Button>
                  </span>
                )
              ) : null}
            </span>
          ),
          description: (
            <TableRowButton
              history={this.props.history}
              editRow={this.showDrawer.bind(this, nyutaisho[i])}
              riyousyaId={nyutaisho[i].riyousya.id}
            />
          ),
        });
      }
    }

    return (
      (<div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{
                        position: "bottom",
                        pageSize: ConstSet.TABLE_PAGINATION,
                      }}
                      scroll={{ x: 1400, y: 800 }}
                      className="baitaruTab cusVitalTable table-kiroku-date"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                          index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      onChange={(pagination) => {
                        this.handChangePagination(pagination);
                      }}
                      columns={columnsLong}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            }
            return null;
            /**
               * @TODO develop UI version
               */

          }}
        </MediaQuery>
        <Drawer
          destroyOnClose
          title={this.state.record ? this.state.record.riyousya.name : null}
          width={720}
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
          closable={false}
          rootClassName="kiroku-drawer-form"
        >
          <VitalForm
            tantoMaster={tantoMaster}
            data={this.state.record ? this.state.record : {}}
            handleFormInput={this.getHandleFormInput}
            zenkeiRecord={zenkai}
            yougoMasterEditState={this.getYougoMasterEditState}
            yougoMasterEditCancel={this.getYougoMasterEditCancel}
            yougoMasterEditSave={this.getYougoMasterEditSave}
            yougoMasterEctState={this.getYougoMasterEctState}
            yougoMasterEctCancel={this.getYougoMasterEctCancel}
            yougoMasterEctSave={this.getYougoMasterEctSave}
            yougoMasterNa={yougoMasterNa}
            vitalEct={vitalEct}
            ect1Type={ect1Type}
            ect2Type={ect2Type}
            ect3Type={ect3Type}
            yougoMasterEctSelect1={yougoMasterEctSelect1}
            yougoMasterEctSelect2={yougoMasterEctSelect2}
            yougoMasterEctSelect3={yougoMasterEctSelect3}
            vitalCol={vitalCol}
            vitalHighLow={vitalHighLow}
          />
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left" }}>
              <Button onClick={this.upperForm} style={{ marginRight: 8 }}>
                <ArrowUpOutlined />
              </Button>
              <Button onClick={this.lowerForm}>
                <ArrowDownOutlined />
              </Button>
            </span>

            <span>
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                戻る
              </Button>
              <Button type="primary" danger onClick={this.handleOkUpdateForm}>
                更新
              </Button>
            </span>
          </div>
        </Drawer>
        <Modal
          title={
            <div className="kim-header-title">
              <span>{this.state.riyousya}</span>
              <span>
                {this.state.time ? this.state.time.substring(0, 5) : ""}
              </span>
            </div>
          }
          open={this.state.visibleMInput}
          onOk={this.handleOkUpdate}
          onCancel={this.handleMInputCancel}
          className={"kiroku-input-modal"}
          footer={[
            <Button key="pre" onClick={this.preInput}>
              前項目
            </Button>,
            <Button key="next" onClick={this.nextInput}>
              次項目
            </Button>,
            <Button
              className="kim-btn-ok"
              key="submit"
              type="primary"
              onClick={this.handleOkUpdate}
              disabled={this.state.okSaveButton}
            >
              OK
            </Button>,
            <Button key="up" onClick={this.upperInput}>
              上の行
            </Button>,
            <Button key="dow" onClick={this.lowerInput}>
              下の行
            </Button>,
          ]}
          maskClosable={false}
        >
          <div className="kim-title">
            <span>{this.state.field}</span>
          </div>
          {this.state.isZendkaiDis ? (
            <div className={"model-top-row"}>
              <div className="kim-zenkai">
                {this.state.field !== "時間" ? (
                  <span>
                    前回(
                    {zenkai &&
                      zenkai[0] &&
                      zenkai[0][zenkaiMerge(this.state.modalType)]
                      ? this.state.modalType === ConstSet.MODAL_B_SPO2_BEFORE
                        ? zenkai[0].spo2.split("-")[0]
                        : this.state.modalType === ConstSet.MODAL_B_SPO2_AFTER
                          ? zenkai[0].spo2.split("-")[1]
                            ? zenkai[0].spo2.split("-")[1]
                            : ""
                          : zenkai[0][zenkaiMerge(this.state.modalType)]
                      : ""}
                    )
                  </span>
                ) : null}
              </div>
              <div
                className={`kim-value ${this.state.field === "時間" ? "kim-value-time" : ""}`}
              >
                <div className="kim-value-top">
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
                {this.state.field === "時間" ? (
                  <div className="kim-value-bottom">
                    <Button type="primary" onClick={this.handleSetCurrentTime}>現時刻</Button>
                  </div>
                ) : null}
              </div>

              <div className={"mtr-col1 kim-suggest"}>
                {this.state.suggestValues ? (
                  <span>正常値: {this.state.suggestValues}</span>
                ) : null}
              </div>
            </div>
          ) : null}

          {this.state.ectNumberMode ? (
            <div className={"model-top-row"}>
              <div className="kim-value">
                <div>
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
              </div>
            </div>
          ) : null}

          <div>
            {(() => {
              switch (this.state.modalType) {
                case ConstSet.MODAL_B_TIME:
                  return (
                    <TimeKB
                      handleHourInput={this.getHandleHourInput}
                      handleMinuteLeftInput={this.getHandleMinuteLeftInput}
                      handleMinuteInput={this.getHandleMinuteInput}
                    />
                  );

                case ConstSet.MODAL_B_REPORTCASETYUIF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleReportCaseTyuif}
                    />
                  );

                case ConstSet.MODAL_B_MAINF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleMainf}
                    />
                  );

                case ConstSet.MODAL_B_WEIGHT:
                  return (
                    <NumberKB handleInputNum={this.getHandleInputWeight} />
                  );

                case ConstSet.MODAL_B_TEMP:
                  return <NumberKB handleInputNum={this.getHandleInputTemp} />;

                case ConstSet.MODAL_B_BPRESSURE_HIGHT:
                  return (
                    <NumberKB handleInputNum={this.getHandleInputPresureHigh} />
                  );

                case ConstSet.MODAL_B_BPRESSURE_LOW:
                  return (
                    <NumberKB handleInputNum={this.getHandleInputPresureLow} />
                  );

                case ConstSet.MODAL_B_PULSEF_NO:
                  return (
                    <NumberKB handleInputNum={this.getHandleInputPulsefNo} />
                  );

                case ConstSet.MODAL_B_PULSEF_TYPE:
                  return (
                    <SelectKB
                      defaltValue={this.state.inputValue}
                      handleSelectKB={this.getHandleInputPulsefType}
                    />
                  );

                case ConstSet.MODAL_B_SPO2_BEFORE:
                  return <NumberKB handleInputNum={this.getHandleInputSpo2B} />;

                case ConstSet.MODAL_B_SPO2_AFTER:
                  return <NumberKB handleInputNum={this.getHandleInputSpo2A} />;

                case ConstSet.MODAL_B_KOKYU_NO:
                  return (
                    <NumberKB handleInputNum={this.getHandleInputKokyuNo} />
                  );

                case ConstSet.MODAL_B_KOKYU_TYPE:
                  return (
                    <SelectKB
                      defaltValue={this.state.inputValue}
                      handleSelectKB={this.getHandleInputKokyuType}
                    />
                  );

                case ConstSet.MODAL_B_ETC0:
                  return ect1Render;
                case ConstSet.MODAL_B_ETC1:
                  return ect2Render;
                case ConstSet.MODAL_B_ETC2:
                  return ect3Render;
                case ConstSet.MODAL_B_ETC3:
                  return (
                    <TextareaKB
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputEtc4}
                    />
                  );

                case ConstSet.MODAL_B_COMMENT:
                  return (
                    <CommentKB
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputComment}
                      yougoMasterNa={yougoMasterNa}
                      rowNum={4}
                    />
                  );

                case ConstSet.MODAL_B_TANTOMASTER:
                  return (
                    <TantoMasterKB2
                      tantoMaster={tantoMaster}
                      value={this.state.inputValue}
                      handleTantoMasterKB={this.getHandleTantoMasterKB}
                      widthCode={100}
                      widthText={250}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
        <Modal
          title={[
            <span key={1}>{"一括入力"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={this.props.mulInputModal && mulInputMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              閉じる
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={checkIkkatsuOkBtn(state.mulInputCheckbox)}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 0, "time")}
                    checked={this.state.mulInputCheckbox[0]}
                  >
                    時間
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={12}>
                <TimeKBCus
                  timeString={this.state.mulInputData.time}
                  handleInput={this.onChangeTimeMulModeNew}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 1, "comment")}
                    checked={this.state.mulInputCheckbox[1]}
                  >
                    コメント
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={17}>
                <FormItem>
                  <CommentKB
                    yougoMasterEditState={this.getYougoMasterEditState}
                    yougoMasterEditCancel={this.getYougoMasterEditCancel}
                    yougoMasterEditSave={this.getYougoMasterEditSave}
                    handleTextareaKB={this.getHandleMulInputComment}
                    handleOnchange={this.getHandleOnChangeMIC}
                    yougoMasterNa={yougoMasterNa}
                    defaultValue={this.state.mulInputData.comment}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 2, "tantoId")}
                    checked={this.state.mulInputCheckbox[2]}
                  >
                    担当者
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <TantoMasterKB
                    tantoMaster={tantoMaster}
                    value={this.state.mulInputData.tantoId}
                    handleTantoMasterKB={this.getHandleMulTantoMasterKB}
                    widthCode={100}
                    widthText={250}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          destroyOnClose
          title={[
            <span key={1}>{"一括削除"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={mulInputModal && mulDelMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              キャンセル
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={!this.state.isConfirmMulDel}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <h3>一括削除を実行しますか？</h3>
            <Checkbox
              value={state.isConfirmMulDel}
              onChange={this.onChangeConfirmMulDel}
            >
              削除に同意する
            </Checkbox>
          </div>
        </Modal>
        <IkkatsuModal
          visibleMulState={state.visibleMulState}
          rowsSelect={state.rowsSelect}
          count={state.count}
          closePopup={this.closeModalMulState}
        />
      </div>)
    );
  }
}

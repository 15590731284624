/* eslint-disable no-nested-ternary */
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Form } from "@ant-design/compatible";

// import '@ant-design/compatible/assets/index.css';


import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import MediaQuery from "react-responsive";

import TimeKBCus from "../../../common/component/TimeKBCus";
import { ConstSet } from "../../../common/configs/constset";
import HaisetuForm from "../../../common/form-kiroku/HaisetuForm";
import {
  checkTableField,
  getMenu,
  getValueIskeep,
  getValueLocalstorage,
  handleInputNumber,
} from "../../../common/function_common/functionCommon";
import {
  checkIkkatsuOkBtn,
  ikkatsuCheckboxReset,
} from "../../../common/function_common/functionDisplay";
import IkkatsuModal from "../../../common/modals/ikkatsuModal";
import CheckKB from "../../../common/model-kiroku/CheckKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import HaisetuKubunKB from "../../../common/model-kiroku/HaisetuKubunKB";
import HaisetufKB from "../../../common/model-kiroku/HaisetufKB";
import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";
import NumberDropKB from "../../../common/model-kiroku/NumberDropKB";
import NumberKB from "../../../common/model-kiroku/NumberKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import TantoMasterKB2 from "../../../common/model-kiroku/TantoMasterKB2";
import TimeKB from "../../../common/model-kiroku/TimeKB";
import TableRowButton from "./TableRowButton";

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };
const FormItem = Form.Item;

export default class Haisetu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Table Setting
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      rowActive: null,

      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      // edit add function
      inputMode: this.props.inputMode,
      showDrawer: false,
      visibleMInput: false,
      modalType: null,

      riyousya: null,
      record: null,
      time: null,
      indexRecord: 0,

      field: "",
      isZendkaiDis: false,
      suggestValues: "",

      //
      inputType: null,
      inputValue: null,
      inputField: null,

      count: 0,
      rowsSelect: 0,
      visibleMulState: false,
      mulInputData: {
        haisetuIds: null,
        time: null,
        shurui: null,
        haisetuF: null,
        vol: null,
        joutai: null,
        comment: null,
        tantoId: null,
      },
      mulInputCheckbox: [false, false, false, false, false, false, false],
      mulInputNotification: "",
      arrCheckBox: [], // array index checkbox for multiple add and edit,
      isClickAll: false,
      delIds: [],
      isConfirmMulDel: false,

      formData: null,
      isFormUpdate: false,

      // handle for active OK SAVE Button when user start input value
      okSaveButton: true,

      // this setting is used in multiple input editing
      isShunrui: false,

      tableCurrentPage: 1, // current page of table,
      canUpadateBunrui: false,
    };

    // bind
    this.handleClickRow = this.handleClickRow.bind(this);

    // Copy, Del local, Del Server record
    this.handleCopyHaisetu = this.handleCopyHaisetu.bind(this);
    this.handleDelHaisetu = this.handleDelHaisetu.bind(this);
    this.handleServerDel = this.handleServerDel.bind(this);

    //
    this.getHandleHourInput = this.getHandleHourInput.bind(this);
    this.getHandleMinuteLeftInput = this.getHandleMinuteLeftInput.bind(this);
    this.getHandleMinuteInput = this.getHandleMinuteInput.bind(this);
    this.getHandleReportCaseTyuif = this.getHandleReportCaseTyuif.bind(this);

    this.getHandleHaisetuKubun = this.getHandleHaisetuKubun.bind(this);
    this.getHandleShurui = this.getHandleShurui.bind(this);
    this.getHandleHaisetuf = this.getHandleHaisetuf.bind(this);
    this.getHandleHaisetuVol = this.getHandleHaisetuVol.bind(this);
    this.getHandleJoutai = this.getHandleJoutai.bind(this);

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);

    this.handleOkUpdate = this.handleOkUpdate.bind(this);
    // END

    // add, edit Setting Comment
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    // 一括入力
    this.onChangeTimeMulMode = this.onChangeTimeMulMode.bind(this);
    this.getHandleMulShurui = this.getHandleMulShurui.bind(this);
    this.getHandleMulHaisetuF = this.getHandleMulHaisetuF.bind(this);
    this.getHandleMulVol = this.getHandleMulVol.bind(this);
    this.getHandleMulJoutai = this.getHandleMulJoutai.bind(this);
    this.getHandleMulInputComment = this.getHandleMulInputComment.bind(this);
    this.getHandleMulTantoMasterKB = this.getHandleMulTantoMasterKB.bind(this);

    this.getHandleOnChangeMIC = this.getHandleOnChangeMIC.bind(this); // Active Clickable 一括入力 button
    this.handleMulInputModeOk = this.handleMulInputModeOk.bind(this);
    this.handleMulInputModeCancel = this.handleMulInputModeCancel.bind(this);
  }

  /**
   *
   * @param {*} preProps
   */
  componentDidUpdate(preProps) {
    const { count } = this.state;
    const { nyutaisho, mulInputAllMode, syokichi } = this.props;

    //
    if (nyutaisho !== preProps.nyutaisho) {
      // case update haisetuId when insert value to DOM
      if (this.state.record) {
        this.setState({
          record: nyutaisho[this.state.record.index],
        });
      }

      // alert(count)
      // case multiple input
      if (count > 0) {
        this.ikkatsu();
      }
    }

    // update value if syokichi select
    if (syokichi !== preProps.syokichi && this.state.record) {
      const { showDrawer } = this.state;

      if (showDrawer) {
        if (syokichi.length > 0) {
          this.getHandleFormInput(syokichi[0].syokichi, "shurui");
        }
      }
      else {
        this.setState({
          inputValue:
            syokichi.length > 0 ? syokichi[0].syokichi : this.state.inputValue,
        });
      }
    }

    // reset multiple input mode
    if (mulInputAllMode !== preProps.mulInputAllMode) {
      this.setState({
        count: 0,
        rowsSelect: 0,
        visibleMulState: false,
        mulInputData: {
          haisetuIds: null,
          time: null,
          shurui: null,
          haisetuF: null,
          vol: null,
          joutai: null,
          comment: null,
          tantoId: null,
        },
        mulInputCheckbox: [false, false, false, false, false, false, false],
        mulInputNotification: "",
        arrCheckBox: [], // array index checkbox for multiple add and edit,
        isClickAll: false,
        isShunrui: false,
        expandRowClick: [], // reset expand row on multiple input mode
        rowActive: null,
        activeRowKey: null,
        delIds: [],
        isConfirmMulDel: false,
      });

      this.props.handleIkkatsuBtn(true); // active popup click button
    }
  }

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} event
   */
  onRowClick(key) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    /**
     * disable expander row on ikkaketsu mode
     */
    const { mulInputMode } = this.props;
    if (mulInputMode) {
      return;
    }

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  /**
   *
   * @param {*} key
   */
  handleClickRow(key) {
    if (key === this.state.activeListIndex) {
      this.setState({
        activeListIndex: null,
      });
    }
    else {
      this.setState({
        activeListIndex: key,
      });
    }
  }

  /**
   *
   * @param {*} haisetuRecord
   */
  handleCopyHaisetu(haisetuRecord) {
    // Clone data
    const cloneHaisetuCopy = {
      nyuType: haisetuRecord.nyuType,
      kubun: haisetuRecord.kubun,
      riyousya: haisetuRecord.riyousya,
      bunrui: haisetuRecord.bunrui,
      room: haisetuRecord.room,
      time: haisetuRecord.time,
      index: haisetuRecord.index,
      haisetuKubun: haisetuRecord.haisetuKubun,
      modeRecord: "copy",
    };

    this.props.handleCopyHaisetu(cloneHaisetuCopy);
  }

  /**
   *
   * @param {*} haisetuRecord
   */
  handleDelHaisetu(haisetuRecord) {
    this.props.handleDelHaisetu(haisetuRecord);
  }

  /**
   * Delete record from server
   * @param {*} haisetuId
   */
  handleServerDel(haisetuId) {
    this.props.deleteRecord(haisetuId);
  }

  /**
   * Edit, Update function with single field
   *
   */

  /**
   * function handle when click to edit field
   *
   * @param {int} MType // display modal type corresponding
   * @param {*} record // record for row
   * @param {*} index // index of row
   */
  handleMInput = (MType, record, index) => {
    const { jikantai } = this.props;

    if (!record.time && jikantai && jikantai != "全表示") {
      record.time = jikantai.split("~")[0] + ":00";
    }
    const extractCurrent = checkTableField(MType, record);
    let okSaveButton = true;
    if (
      MType === ConstSet.MODAL_H_COMMENT ||
      MType === ConstSet.MODAL_H_SHURUI ||
      MType === ConstSet.MODAL_H_JOUTAI
    ) {
      okSaveButton = false;
    }

    this.setState({
      rowActive: index,
      expandRowClick: [],

      visibleMInput: true,
      modalType: MType,

      riyousya: record.riyousya.name,
      time: record.time,
      record,
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });

    /**
     * @override
     *
     * overide state with filter haisetu kubun
     */
    const haisetuKubun = getValueLocalstorage("haisetuType");
    if (
      MType === ConstSet.MODAL_H_HAISETUKUBUN &&
      haisetuKubun &&
      haisetuKubun !== "両方" &&
      !record.haisetuKubun
    ) {
      this.setState({
        inputType: ConstSet.MODAL_H_HAISETUKUBUN,
        inputValue: haisetuKubun,
        inputField: "haisetuKubun",
        okSaveButton: false,
      });
    }
    else if (MType === ConstSet.MODAL_H_HAISETUKUBUN && record.settingName) {
      this.setState({
        inputType: ConstSet.MODAL_H_HAISETUKUBUN,
        inputValue:
          record.settingName == "HB提供内容設定"
            ? "排便"
            : record.settingName == "HN提供内容設定"
              ? "排尿"
              : "",
        inputField: "haisetuKubun",
        okSaveButton: false,
      });
    }
  };

  /**
   * (2)
   */
  handleMInputCancel = () => {
    this.setState({
      visibleMInput: false,
      canUpadateBunrui: false,
    });
  };

  /**
   * (3.1) Pre field <--
   */
  preInput = () => {
    let modalType = this.state.modalType;

    //
    const { canUpadateBunrui } = this.state;
    if (
      modalType !== ConstSet.MODAL_H_HAISETUKUBUN ||
      (modalType === ConstSet.MODAL_H_HAISETUKUBUN && canUpadateBunrui)
    ) {
      this.handleOkUpdate(true); // update data before next
    }

    if (
      modalType > ConstSet.MODAL_H_TIME &&
      modalType <= ConstSet.MODAL_H_TANTOMASTER
    ) {
      modalType = modalType - 1;
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_H_COMMENT ||
      modalType === ConstSet.MODAL_H_SHURUI ||
      modalType === ConstSet.MODAL_H_JOUTAI
    ) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    // if value of bunrui is set, display OK button
    let tmpInputType = null;
    let tmpInputField = null;
    if (modalType === ConstSet.MODAL_H_HAISETUKUBUN && extractCurrent.value) {
      okSaveButton = false;
      tmpInputType = ConstSet.MODAL_H_HAISETUKUBUN;
      tmpInputField = "haisetuKubun";
    }

    this.setState({
      //
      modalType,

      inputType: tmpInputType,
      inputValue: extractCurrent.value,
      inputField: tmpInputField,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.2) Pre field ^|
   */
  upperInput = () => {
    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;

    //
    const { canUpadateBunrui } = this.state;
    if (
      modalType !== ConstSet.MODAL_H_HAISETUKUBUN ||
      (modalType === ConstSet.MODAL_H_HAISETUKUBUN && canUpadateBunrui)
    ) {
      this.handleOkUpdate(true); // update data before next
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_H_COMMENT ||
      modalType === ConstSet.MODAL_H_SHURUI ||
      modalType === ConstSet.MODAL_H_JOUTAI
    ) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    // if value of bunrui is set, display OK button
    let tmpInputType = null;
    let tmpInputField = null;
    if (modalType === ConstSet.MODAL_H_HAISETUKUBUN && extractCurrent.value) {
      okSaveButton = false;
      tmpInputType = ConstSet.MODAL_H_HAISETUKUBUN;
      tmpInputField = "haisetuKubun";
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      record: nyutaisho[index],
      time: nyutaisho[index].time,

      inputField: tmpInputField,
      inputValue: extractCurrent.value,
      inputType: tmpInputType,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.3) Pre field _|
   */
  lowerInput = () => {
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;

    //
    const { canUpadateBunrui } = this.state;
    if (
      modalType !== ConstSet.MODAL_H_HAISETUKUBUN ||
      (modalType === ConstSet.MODAL_H_HAISETUKUBUN && canUpadateBunrui)
    ) {
      this.handleOkUpdate(true); // update data before next
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_H_COMMENT ||
      modalType === ConstSet.MODAL_H_SHURUI ||
      modalType === ConstSet.MODAL_H_JOUTAI
    ) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    // if value of bunrui is set, display OK button
    let tmpInputType = null;
    let tmpInputField = null;
    if (modalType === ConstSet.MODAL_H_HAISETUKUBUN && extractCurrent.value) {
      okSaveButton = false;
      tmpInputType = ConstSet.MODAL_H_HAISETUKUBUN;
      tmpInputField = "haisetuKubun";
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      time: nyutaisho[index].time,

      record: nyutaisho[index],

      inputType: tmpInputType,
      inputValue: extractCurrent.value,
      inputField: tmpInputField,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.4) Pre field -->
   */
  nextInput = () => {
    let modalType = this.state.modalType;

    //
    const { canUpadateBunrui } = this.state;
    if (
      modalType !== ConstSet.MODAL_H_HAISETUKUBUN ||
      (modalType === ConstSet.MODAL_H_HAISETUKUBUN && canUpadateBunrui)
    ) {
      this.handleOkUpdate(true); // update data before next
    }

    if (
      modalType >= ConstSet.MODAL_H_TIME &&
      modalType < ConstSet.MODAL_H_TANTOMASTER
    ) {
      modalType = modalType + 1;
    }

    let okSaveButton = true;
    if (
      modalType === ConstSet.MODAL_H_COMMENT ||
      modalType === ConstSet.MODAL_H_SHURUI ||
      modalType === ConstSet.MODAL_H_JOUTAI
    ) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    // if value of bunrui is set, display OK button
    let tmpInputType = null;
    let tmpInputField = null;
    if (modalType === ConstSet.MODAL_H_HAISETUKUBUN && extractCurrent.value) {
      okSaveButton = false;
      tmpInputType = ConstSet.MODAL_H_HAISETUKUBUN;
      tmpInputField = "haisetuKubun";
    }

    this.setState({
      //
      modalType,

      inputType: tmpInputType,
      inputValue: extractCurrent.value,
      inputField: tmpInputField,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * 時間ところ
   */

  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.setState({
      inputType: ConstSet.MODAL_H_TIME,
      inputValue: currentTime,
      inputField: "time",
      okSaveButton: false,
    });
  };

  /**
   * (1)
   * @param {(*)} value
   */
  getHandleHourInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;
    if (!preData) {
      preData = value + ":00";
    }
    else {
      preData = value + preData.substring(2, 5);
    }
    this.setState({
      inputType: ConstSet.MODAL_H_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (2)
   * @param {(*)} value
   */
  getHandleMinuteLeftInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;

    if (!preData) {
      preData = "00:" + value;
    }
    else {
      preData = preData.substring(0, 3) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_H_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (3)
   * @param {(*)} value
   */
  getHandleMinuteInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;
    if (!preData) {
      preData = "00:0" + value;
    }
    else {
      preData = preData.substring(0, 4) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_H_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }
  // 時間end

  /**
   * 申送
   * @param {*} value
   */
  getHandleReportCaseTyuif(value) {
    this.setState({
      inputType: ConstSet.MODAL_H_REPORTCASETYUIF,
      inputValue: value ? "" + 1 : "" + 0,
      inputField: "reportCaseTyuif",
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleHaisetuKubun(value) {
    this.setState({
      inputType: ConstSet.MODAL_H_HAISETUKUBUN,
      inputValue: value,
      inputField: "haisetuKubun",
      okSaveButton: false,
      canUpadateBunrui: true,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleShurui(value) {
    this.setState({
      inputType: ConstSet.MODAL_H_SHURUI,
      inputValue: value,
      inputField: "shurui",
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleHaisetuf(value) {
    this.setState({
      inputType: ConstSet.MODAL_H_HAISETUF,
      inputValue: value,
      inputField: "haisetuF",
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleHaisetuVol(value) {
    this.setState({
      inputType: ConstSet.MODAL_H_VOL,
      inputValue: handleInputNumber(value, this.state.inputValue),
      inputField: "vol",
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleJoutai(value) {
    this.setState({
      inputType: ConstSet.MODAL_H_JOUTAI,
      inputValue: value,
      inputField: "joutai",
      okSaveButton: false,
    });
  }

  /**
   * コメント
   * @param {*} value
   * @param {*} type
   */
  getHandleInputComment(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_H_COMMENT,
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_H_COMMENT,
        inputValue: value,
        inputField: "comment",
        okSaveButton: false,
      });
    }
  }

  /**
   * 担当者
   * @param {*} value
   */
  getHandleTantoMasterKB(value) {
    // let updaterecord = this.state.record;
    // updaterecord.tantoId = value;
    this.setState({
      inputType: ConstSet.MODAL_H_TANTOMASTER,
      inputValue: value,
      inputField: "tantoId",
      // record: updaterecord,
      okSaveButton: false,
    });
  }

  /**
   * OK UPDATE Button Click
   *
   * Save data
   */
  handleOkUpdate(type) {
    const checkHaisetuId = this.state.record.haisetuId
      ? this.state.record.haisetuId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkHaisetuId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        haisetuIds: checkHaisetuId.toString(),
        index: this.state.record.index,
        date: localStorage.getItem("nyuDate"),
      };

      const updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_H_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        if (this.state.inputType === ConstSet.MODAL_H_HAISETUF) {
          params[this.state.inputField] =
            updateField && (updateField === 1 || updateField === "1")
              ? "1"
              : "0";
        }

        if (
          this.state.inputType === ConstSet.MODAL_H_VOL &&
          !this.state.inputValue
        ) {
          params[this.state.inputField] = "0";
        }

        this.props.updateRecord(params);

        let okSaveButton = true;
        if (
          this.state.inputType === ConstSet.MODAL_H_COMMENT ||
          this.state.inputType === ConstSet.MODAL_H_SHURUI ||
          this.state.inputType === ConstSet.MODAL_H_JOUTAI
        ) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    } // POST
    else {
      // setting time auto if not input
      let preTime = "00:00";
      if (
        getValueIskeep("jikantaiH") &&
        getValueIskeep("jikantaiH") !== "全表示"
      ) {
        preTime = getValueIskeep("jikantaiH").split("~")[0];
      }

      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousya.id.toString(),
        date: localStorage.getItem("nyuDate"),
        time: this.state.record.time ? this.state.record.time : preTime,
        tantoId: getValueLocalstorage("tid"),
        haisetuKubun: this.state.record.haisetuKubun
          ? this.state.record.haisetuKubun
          : this.state.record.settingName &&
            this.state.record.settingName == "HB提供内容設定"
            ? "排便"
            : "排尿", // priority: directly INPUT - FILTER (saved in local) - PLAN
        haisetuF: this.state.record.haisetuF ? this.state.record.haisetuF : "0",
      };

      const updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_H_HAISETUF) {
          params[this.state.inputField] =
            updateField && (updateField === 1 || updateField === "1")
              ? "1"
              : "0";
        }
        else if (this.state.inputType === ConstSet.MODAL_H_TANTOMASTER) {
          params.haisetuF = "0";
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params.haisetuF = "0";
          params[this.state.inputField] = updateField;
        }

        if (
          this.state.inputType === ConstSet.MODAL_H_VOL &&
          !this.state.inputValue
        ) {
          params[this.state.inputField] = "0";
        }

        /**
         * if type input is not 区分 field, this field info is get from that
         * selected form -> saved on localstorage -> default value
         *
         */
        if (this.state.inputType !== ConstSet.MODAL_H_HAISETUKUBUN) {
          const tmpHaisetukubun = getValueLocalstorage("haisetuType");
          if (tmpHaisetukubun && tmpHaisetukubun !== "両方")
            params.haisetuKubun = tmpHaisetukubun;
        }
        // end update 区分

        this.props.insertRecord(params);

        let okSaveButton = true;
        if (
          this.state.inputType === ConstSet.MODAL_H_COMMENT ||
          this.state.inputType === ConstSet.MODAL_H_SHURUI ||
          this.state.inputType === ConstSet.MODAL_H_JOUTAI
        ) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,

          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    }

    // close popup
    if (type !== true) {
      // save from next, pre, upper, lower not colse popup
      this.setState({
        visibleMInput: false,
      });
    }
    else {
      this.setState({
        canUpadateBunrui: false,
      });
    }
  }
  // end Edit, Update function

  /**
   * Handle Edit, Update By Input From from right all Screen
   * ALl fields can be save on this Drawer
   *
   */

  /**
   * Form Inside Drawer
   * Show Form
   */
  showDrawer = (record) => {
    const { jikantai } = this.props;
    if (!record.time && jikantai && jikantai != "全表示") {
      record.time = jikantai.split("~")[0] + ":00";
    }
    // Update
    const haisetuType = getValueLocalstorage("haisetuType");
    if (haisetuType && haisetuType !== "両方" && !record.haisetuKubun) {
      record.haisetuKubun = haisetuType;
    }
    else if (record && record.settingName) {
      // get value if youtei is set)
      record.haisetuKubun =
        record.settingName == "HB提供内容設定"
          ? "排便"
          : record.settingName == "HN提供内容設定"
            ? "排尿"
            : "";
    }

    this.setState({
      rowActive: record.index,
      showDrawer: true,
      record,
      isFormUpdate: false,
    });
  };

  /**
   * Close Form
   */
  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  upperForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    // Update
    const haisetuType = getValueLocalstorage("haisetuType");
    if (haisetuType && haisetuType !== "両方" && !cloneData.haisetuKubun) {
      cloneData.haisetuKubun = haisetuType;
    }
    else if (cloneData && cloneData.settingName) {
      // get value if youtei is set)
      cloneData.haisetuKubun =
        cloneData.settingName == "HB提供内容設定"
          ? "排便"
          : cloneData.settingName == "HN提供内容設定"
            ? "排尿"
            : "";
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  lowerForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    // Update
    const haisetuType = getValueLocalstorage("haisetuType");
    if (haisetuType && haisetuType !== "両方" && !cloneData.haisetuKubun) {
      cloneData.haisetuKubun = haisetuType;
    }
    else if (cloneData && cloneData.settingName) {
      // get value if youtei is set)
      cloneData.haisetuKubun =
        cloneData.settingName == "HB提供内容設定"
          ? "排便"
          : cloneData.settingName == "HN提供内容設定"
            ? "排尿"
            : "";
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      rowActive: preRowActive,

      showDrawer: true,
      record: cloneData,
      isFormUpdate: false,
    });
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value
   * @param {*} type
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.record;
    const objData = {};

    // handle input number
    if (type === "vol") {
      value = value ? value : "0";
    }

    objData[type] = value;

    this.setState({
      record: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  /**
   * Save data action
   */
  handleOkUpdateForm = () => {
    const { record } = this.state;

    const checkHaisetuId = record.haisetuId ? record.haisetuId : null;
    const checkCopyType =
      !!(record.modeRecord && record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkHaisetuId && !checkCopyType) {
      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        haisetuIds: checkHaisetuId.toString(),
        index: record.index,
        date: localStorage.getItem("nyuDate"),
      };

      params = Object.assign(params, record);

      params.haisetuF = params.haisetuf;
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.updateRecord(params);
    } // POST
    else {
      // setting time auto if not input
      let preTime = "00:00";
      if (
        getValueIskeep("jikantaiH") &&
        getValueIskeep("jikantaiH") !== "全表示"
      ) {
        preTime = getValueIskeep("jikantaiH").split("~")[0];
      }

      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: record.index,
        riyousyaId: record.riyousya.id.toString(),
        date: localStorage.getItem("nyuDate"),
        tantoId: getValueLocalstorage("tid"),
      };

      // set value following localstorage and youtei
      const haisetuType = getValueLocalstorage("haisetuType");
      if (haisetuType && haisetuType !== "両方") {
        params.haisetuKubun = haisetuType;
      }
      else if (record && record.settingName) {
        // get value if youtei is set)
        params.haisetuKubun =
          record.settingName == "HB提供内容設定"
            ? "排便"
            : record.settingName == "HN提供内容設定"
              ? "排尿"
              : "";
      }

      params = Object.assign(params, record);
      params.time = record.time ? record.time : preTime;

      // setting default for haisetuF is zero
      params.haisetuF = params.haisetuf ? params.haisetuf : "0";

      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.insertRecord(params);
    }

    // clear inputing mode
    this.setState({
      isFormUpdate: false,
    });
  };
  // END Handle Edit, Update By Input Form from right all Screen

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Handle 一括入力
   * 8 functions
   * handle checkbox: time, stapleIntake, sideIntake, shokuNasi, comment, tantoMaster
   * handle change time, stapleIntake, sideIntake, shokuNasi, comment, tantoMaster
   * handle OK to save data
   * handle CANCEL to cancel input mode
   *
   * handle autoCheck when input comment field. Performanceので、onChange has changed by onBlur
   *
   * handle function when user select 利用者
   *
   */
  onClickSelectAll = () => {
    const { arrCheckBox, tableCurrentPage, isClickAll } = this.state;
    const { nyutaisho } = this.props;

    const preArrCheckBox = arrCheckBox;
    let rowsSelect = 0; // count total row selected
    const delIds = [];
    for (let i = 0; i < nyutaisho.length; i++) {
      if (!isClickAll) {
        if (
          i < ConstSet.TABLE_PAGINATION * tableCurrentPage &&
          i >= ConstSet.TABLE_PAGINATION * (tableCurrentPage - 1)
        ) {
          preArrCheckBox[i] = true;

          // handle for del mode, list ids has push into array
          if (nyutaisho[i].haisetuId) {
            delIds[i] = nyutaisho[i].haisetuId;
          }
          else {
            delIds[i] = 0;
            // preArrCheckBox[i] = false;
          }

          rowsSelect++;
        }
        else {
          preArrCheckBox[i] = false;
        }
      }
      else {
        preArrCheckBox[i] = false;

        // reset delIds from state
        delIds[i] = 0;
      }
    }

    // preMulInputData.haisetuIds = haisetuIds;

    if (!isClickAll) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      isClickAll: !isClickAll,
      rowsSelect,
      delIds,
    });

    // handle logic
  };

  onClickSelectOne = (recordIndex) => {
    const { arrCheckBox, delIds } = this.state;
    const { nyutaisho } = this.props;

    //
    const preArrCheckBox = arrCheckBox;
    preArrCheckBox[recordIndex] = !arrCheckBox[recordIndex];

    // Check is selected, id have been retrieve from state
    const preDelIds = delIds;
    if (preArrCheckBox[recordIndex]) {
      if (
        nyutaisho &&
        nyutaisho[recordIndex] &&
        nyutaisho[recordIndex].haisetuId
      ) {
        preDelIds[recordIndex] = nyutaisho[recordIndex].haisetuId;
      }
    }
    else {
      preDelIds[recordIndex] = 0;
    }

    this.setState({
      arrCheckBox: preArrCheckBox,
      delIds: preDelIds,
    });

    // display 変更 button
    let mulBtnState = false;

    // count select row
    let rowsSelect = 0;

    preArrCheckBox.forEach((item) => {
      if (item) {
        rowsSelect++;
        mulBtnState = true;
      }
    });

    this.setState({
      rowsSelect,
    });

    if (mulBtnState) {
      this.props.handleIkkatsuBtn(false);
    }
    else {
      this.props.handleIkkatsuBtn(true);
    }
  };

  /**
   *
   * @param {*} e
   * @param {*} type
   * @param {*} key
   */
  handleCheckBox = (type, key, e) => {
    const { mulInputData, mulInputCheckbox } = this.state;

    //
    const preDataCheckbox = mulInputCheckbox;
    preDataCheckbox[type] = e.target.checked;

    //
    const preData = ikkatsuCheckboxReset(mulInputData, key);

    this.setState({
      mulInputCheckbox: preDataCheckbox,
      mulInputData: preData,
    });
  };

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulMode(time, timeString) {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    // save data to state
    const preData = this.state.mulInputData;
    preData.time = timeString;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[0] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulShurui(value) {
    const preData = this.state.mulInputData;
    preData.shurui = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[1] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulHaisetuF(value) {
    const preData = this.state.mulInputData;
    preData.haisetuF = value ? 1 : 0;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[2] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulVol(value, type) {
    const preData = this.state.mulInputData;

    if (type) {
      if (value === "消去") {
        preData.vol = "";
      }
      else {
        preData.vol = preData.vol ? preData.vol + "" + value : value;
      }
    }
    else {
      preData.vol = "" + value;
    }

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[3] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulJoutai(value) {
    const preData = this.state.mulInputData;
    preData.joutai = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[4] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {string} value
   * @param {string} type. this type to define input from keyboard directly OR select autocomment from database
   */
  getHandleMulInputComment(value, type) {
    const preData = this.state.mulInputData;
    if (type && type === "select") {
      preData.comment = preData.comment
        ? preData.comment + "、" + value
        : value;
    }
    else {
      preData.comment = value;
    }

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[5] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   * @param {int} value ID of tantoMaster
   */
  getHandleMulTantoMasterKB(value) {
    const preData = this.state.mulInputData;
    preData.tantoId = value;

    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[6] = true;

    this.setState({
      mulInputData: preData,
      mulInputCheckbox: preDataCheckbox,
    });
  }

  /**
   *
   */
  getHandleOnChangeMIC = () => {
    // auto update checkbox to checked
    const preDataCheckbox = this.state.mulInputCheckbox;
    preDataCheckbox[5] = true;

    this.setState({
      mulInputCheckbox: preDataCheckbox,
    });
  };

  /**
   *
   */
  handleMulInputModeOk() {
    //
    this.ikkatsu();

    // close popup
    this.props.handleMulInputModeCancel();

    // open modal
    this.setState({
      visibleMulState: true,
      isConfirmMulDel: false,
    });
  }

  /**
   *
   */
  handleMulInputModeCancel() {
    this.props.handleMulInputModeCancel();

    // reset
    const reset = {
      haisetuIds: null,
      time: null,
      shurui: null,
      haisetuF: null,
      vol: null,
      joutai: null,
      comment: null,
      tantoId: null,
    };

    this.setState({
      mulInputData: reset,
      mulInputCheckbox: [false, false, false, false, false, false, false],
      isConfirmMulDel: false,
      isShunrui: false,
    });
  }

  /**
   *
   */
  ikkatsu = () => {
    // prepare data
    const { arrCheckBox, mulInputData, mulInputCheckbox, delIds, isShunrui } =
      this.state;
    const { nyutaisho, mulInputMode, mulDelMode } = this.props;

    if (mulDelMode) {
      // Create count rows that is modify, and index
      let count = 0;
      delIds.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      const preArrCheckBox = arrCheckBox;
      for (let i = 0; i < delIds.length; i++) {
        if (delIds[i]) {
          this.props.deleteRecord(delIds[i]);
          count--;

          const preDelIds = delIds;
          preDelIds[i] = 0;
          preArrCheckBox[i] = false;
          this.setState({
            count,
            delIds: preDelIds,
            arrCheckBox: preArrCheckBox,
          });
          break;
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          haisetuIds: null,
          time: null,
          shurui: null,
          haisetuF: null,
          vol: null,
          joutai: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false, false, false, false, false],
          arrCheckBox: [],
          delIds: [],
          isShunrui: false,
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
    else if (mulInputMode) {
      const preData = {};

      // Time
      if (!mulInputCheckbox[0]) {
        preData.time = null;
      }
      else {
        preData.time = mulInputData.time;
      }

      // shurui
      if (!mulInputCheckbox[1]) {
        preData.shurui = null;
      }
      else {
        preData.shurui = mulInputData.shurui;
      }

      // isShunrui
      if (mulInputCheckbox[1] && !preData.shurui && isShunrui) {
        preData.isShunrui = "1";
      }

      // haisetuF
      if (mulInputCheckbox[2]) {
        preData.haisetuF = mulInputData.haisetuF
          ? "" + mulInputData.haisetuF
          : "0";
      }

      // vol
      if (!mulInputCheckbox[3]) {
        preData.vol = null;
      }
      else {
        preData.vol = mulInputData.vol;
      }

      // joutai
      if (!mulInputCheckbox[4]) {
        preData.joutai = null;
      }
      else {
        preData.joutai = mulInputData.joutai;
      }

      // Comment
      if (!mulInputCheckbox[5]) {
        preData.comment = null;
      }
      else if (mulInputCheckbox[5] && !mulInputData.comment) {
        preData.comment = "";
      }
      else {
        preData.comment = mulInputData.comment;
      }

      // tantoId
      if (!mulInputCheckbox[6]) {
        preData.tantoId = null;
      }
      else if (mulInputData.tantoId) {
        preData.tantoId = mulInputData.tantoId;
      }
      else {
        preData.tantoId = getValueLocalstorage("tid");
      }

      // input nothing
      // if (!preData.time
      //     && !preData.shurui
      //     && !preData.isShunrui
      //     && !preData.haisetuF
      //     && !preData.vol
      //     && !preData.joutai
      //     && !mulInputCheckbox[5]
      //     && !preData.tantoId
      // ) {
      //     return;
      // }

      preData.tantoId = preData.tantoId ? "" + preData.tantoId : null;

      // Create count rows that is modify, and index
      let count = 0;
      arrCheckBox.forEach((item) => {
        if (item) {
          count++;
        }
      });

      // save the first row
      for (let i = 0; i < arrCheckBox.length; i++) {
        if (arrCheckBox[i]) {
          // Check if recordId exist -> call update function
          if (nyutaisho && nyutaisho[i].haisetuId) {
            this.props.updateRecordMul(
              Object.assign(
                { haisetuIds: "" + nyutaisho[i].haisetuId },
                preData
              )
            );
            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });

            break;
          }
          else if (nyutaisho && !nyutaisho[i].haisetuId) {
            // recordId = null -> call crate new function
            // 0. systemControlName
            preData.systemControlName = JSON.parse(localStorage.getItem("menu")).sn;
            preData.systemControlId = JSON.parse(localStorage.getItem("menu")).si;

            // 1. index
            preData.index = nyutaisho[i].index;

            // 2. RiyousyaId
            preData.riyousyaId = "" + nyutaisho[i].riyousya.id;

            // 2. date
            preData.date = localStorage.getItem("nyuDate");

            // Update haisetsuKunun
            const haisetuType = getValueLocalstorage("haisetuType");
            if (haisetuType && haisetuType !== "両方") {
              preData.haisetuKubun = haisetuType;
            }
            else if (nyutaisho[i] && nyutaisho[i].settingName) {
              // get value if youtei is set)
              preData.haisetuKubun =
                nyutaisho[i].settingName == "HB提供内容設定"
                  ? "排便"
                  : nyutaisho[i].settingName == "HN提供内容設定"
                    ? "排尿"
                    : "";
            }
            else {
              preData.haisetuKubun = "排尿";
            }

            // 3. time
            if (!preData.time) {
              if (nyutaisho[i].time) {
                preData.time = nyutaisho[i].time;
              }
              else {
                preData.time = "00:00";
                if (
                  getValueIskeep("jikantaiH") &&
                  getValueIskeep("jikantaiH") !== "全表示"
                ) {
                  preData.time = getValueIskeep("jikantaiH").split("~")[0];
                }
              }
            }

            if (!preData.haisetuF) {
              preData.haisetuF = "0";
            }

            // 7. tantoId
            if (!preData.tantoId) {
              preData.tantoId = getValueLocalstorage("tid");
            }

            this.props.insertRecordMul(Object.assign({}, preData));

            count--;
            const preArrCheckBox = arrCheckBox;
            preArrCheckBox[i] = false;
            this.setState({
              count,
              arrCheckBox: preArrCheckBox,
            });
            break;
          }
          else {
            break;
          }
        }
      }

      // Check if only oneRow have been selected -> reset file
      if (count === 0) {
        // reset
        const reset = {
          haisetuIds: null,
          time: null,
          shurui: null,
          haisetuF: null,
          vol: null,
          joutai: null,
          comment: null,
          tantoId: null,
        };

        this.setState({
          mulInputData: reset,
          mulInputCheckbox: [false, false, false, false, false, false, false],
          isShunrui: false,
          delIds: [],
        });

        this.props.handleIkkatsuBtn(true);
      }
    }
  };

  // End Handle 一括入力

  /**
   *
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = (type) => {
    const { record } = this.state;
    const riyousyaId = record.riyousya.id.toString();
    const kubun = ConstSet.HAISETU_SYOKICHI_NAIYOU;
    this.props.syokichiItem(riyousyaId, kubun);

    if (!type) {
      // case popup
      this.setState({
        inputField: "shurui",
        inputType: ConstSet.MODAL_H_SHURUI,
      });
    }
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = (type) => {
    const { record, inputValue } = this.state;
    const riyousyaId = record.riyousya.id.toString();
    const kubun = ConstSet.HAISETU_SYOKICHI_NAIYOU;
    let syokichi = "";

    if (type) {
      syokichi = record.shurui;
    }
    else {
      syokichi = inputValue;
    }

    this.props.syokichiUpdate(riyousyaId, kubun, syokichi);
  };

  /**
   * handle click cell event
   *
   * in mulInputMode, click do nothing
   * in input mode, base info cell click -> open expander data, edting cell -> display modal
   * in nothing mode -> open expander data
   *
   */
  onClickCellEvent = (recordIndex, type) => {
    const { nyutaisho, inputMode, mulInputMode } = this.props;
    const record = nyutaisho[recordIndex];

    switch (type) {
      case ConstSet.COL_SELECT:
        return;

      case ConstSet.COL_COPY:
        return;

      case ConstSet.COL_MODE:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_NAME:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ADDRESS:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ROOM:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_HAISETU_TIME:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_TIME,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_REPORTCASETYUIF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_REPORTCASETYUIF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_HAISETUKUBUN:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_HAISETUKUBUN,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_SHURUI:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_SHURUI,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_HAISETUF:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_HAISETUF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_VOL:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(ConstSet.MODAL_H_VOL, record, recordIndex);
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_JOUTAI:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_JOUTAI,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_COMMENT:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_COMMENT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      case ConstSet.COL_HAISETU_TANTOMASTER:
        if (mulInputMode) {
          return;
        }
        if (inputMode) {
          return this.handleMInput(
            ConstSet.MODAL_H_TANTOMASTER,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);


      default:
        return;
    }
  };

  // set default value for users
  mulSetDefaultValue = () => {
    // change state
    const { isShunrui, mulInputCheckbox, mulInputData } = this.state;

    const preMulInputCheckbox = mulInputCheckbox;

    // ON -> OFF
    if (isShunrui) {
      // shunrui manual is active
      preMulInputCheckbox[1] = false;

      this.setState({
        isShunrui: !isShunrui,
        mulInputCheckbox: preMulInputCheckbox,
      });
    }
    else {
      // OFF - ON
      // reset shunrui input value
      const preMulInputData = mulInputData;
      preMulInputCheckbox[1] = true;
      preMulInputData.shurui = null;

      this.setState({
        isShunrui: !isShunrui,
        mulInputCheckbox: preMulInputCheckbox,
        mulInputData: preMulInputData,
      });
    }
  };

  handChangePagination = (pagination) => {
    // reset 一括入力 mode
    const { arrCheckBox } = this.state;
    const preArrCheckBox = [];
    for (let i = 0; i < arrCheckBox.length; i++) {
      preArrCheckBox.push(0);
    }

    this.setState({
      tableCurrentPage: pagination.current,
      arrCheckBox: preArrCheckBox,
      isClickAll: false,
    });
  };

  closeModalMulState = () => {
    this.setState({
      visibleMulState: false,
      isConfirmMulDel: false,
    });
  };

  onChangeConfirmMulDel = (e) => {
    this.setState({
      isConfirmMulDel: e.target.checked,
    });
  };

  render() {
    const state = this.state;
    const {
      nyutaisho,
      loading,
      bunruiTitle,
      inputMode,
      mulInputAllMode,
      mulInputMode,
      mulInputModal,
      mulDelMode,
      tantoMaster,
      yougoMasterNa,
      yougoMasterSy,
      yougoMasterJt,
      isDisLongText,
      jikantai,
      settingColor,
    } = this.props;

    const haisetuType = getValueLocalstorage("haisetuType");

    /**
     * handle date
     */
    const data = [];

    // colums情報を設定
    const columnsLong = [];
    if (mulInputAllMode) {
      columnsLong.push({
        title: "全",
        dataIndex: "colSelect",
        key: "colSelect",
        width: 50,
        fixed: "left",
        className: "input-mode-mul",
        onHeaderCell: () => {
          return {
            onClick: () => {
              this.onClickSelectAll();
            }, // click row
          };
        },
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickSelectOne(record.key);
            }, // click row
          };
        },
      });
    }
    else if (inputMode) {
      columnsLong.push({
        title: "追加",
        dataIndex: "colCopy",
        key: "colCopy",
        width: 50,
        fixed: "left",
        className: "input-mode-fix action-col",
      });
    }
    else {
      columnsLong.push({
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        fixed: "left",
        className: `joutaiCol ${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      });
    }

    columnsLong.push(
      {
        title: "利用者名",
        dataIndex: "col2",
        key: "col2",
        width: 150,
        fixed: "left",
        className: `${inputMode || mulInputAllMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: bunruiTitle,
        dataIndex: "col3",
        key: "col3",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "部屋名",
        dataIndex: "col4",
        key: "col4",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "時間",
        dataIndex: "col5",
        key: "col5",
        width: 70,
        className: `timeCol ${inputMode || mulInputAllMode
          ? " input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_HAISETU_TIME);
            }, // click row
          };
        },
      },
      {
        title: "申送",
        dataIndex: "col6",
        key: "col6",
        width: 50,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_HAISETU_REPORTCASETYUIF
              );
            }, // click row
          };
        },
      },
      {
        title: "排泄区分",
        dataIndex: "col7",
        key: "col7",
        width: 100,
        className: `haisetuCol ${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_HAISETU_HAISETUKUBUN
              );
            }, // click row
          };
        },
      },
      {
        title: "種類",
        dataIndex: "col8",
        key: "col8",
        width: 100,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_HAISETU_SHURUI);
            }, // click row
          };
        },
      },
      {
        title: "有無",
        dataIndex: "col9",
        key: "col9",
        width: 100,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_HAISETU_HAISETUF);
            }, // click row
          };
        },
      },
      {
        title: "量",
        dataIndex: "col10",
        key: "col10",
        width: 60,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_HAISETU_VOL);
            }, // click row
          };
        },
      },
      {
        title: "状態",
        dataIndex: "col11",
        key: "col11",
        width: 60,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_HAISETU_JOUTAI);
            }, // click row
          };
        },
      },
      {
        title: "コメント",
        dataIndex: "col12",
        key: "col12",
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_HAISETU_COMMENT);
            }, // click row
          };
        },
      },
      {
        title: "担当者",
        dataIndex: "col13",
        key: "col13",
        width: 120,
        className: `${inputMode || mulInputAllMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_HAISETU_TANTOMASTER
              );
            }, // click row
          };
        },
      }
    );

    if (inputMode) {
      columnsLong.push({
        title: "削除",
        dataIndex: "colDel",
        key: "colDel",
        width: 50,
        // fixed: 'right',
        className: "input-mode-fix action-col",
      });
    }

    // テーブルデータの準備
    if (nyutaisho) {
      let jikantaiTime = "";
      if (jikantai && jikantai != "全表示") {
        jikantaiTime = jikantai.split("~")[0];
      }

      let colorSetHB = "#ffffff";
      if (settingColor) {
        const res = settingColor.split(";");
        if (res[2]) {
          // HB
          const resHB = res[2].split("HB_");
          if (resHB[1]) {
            colorSetHB = "#" + resHB[1];
          }
        }
      }

      for (let i = 0; i < nyutaisho.length; i++) {
        let color = "nuytaisho-yellow";
        if (nyutaisho[i].kubun === "ショート") {
          color = "nuytaisho-blue";
        }

        let gender = "女";
        let colorGender = "nts-gender-red";
        if (nyutaisho[i].riyousya.gender === 1) {
          gender = "男";
          colorGender = "nts-gender-blue";
        }

        let planType = "";
        if (nyutaisho[i].time && nyutaisho[i].planType === true) {
          planType = "bg_green";
        }
        else if (nyutaisho[i].haisetuId && nyutaisho[i].time) {
          planType = "bg_pink";
        }

        let haisetuColor = "";
        if (
          nyutaisho[i].haisetuId &&
          nyutaisho[i].haisetuKubun &&
          nyutaisho[i].haisetuKubun == "排便"
        ) {
          haisetuColor = colorSetHB;
        }

        // let haisetuKubun = null;
        // if (nyutaisho[i].haisetuKubun && nyutaisho[i].haisetuKubun === '排便') {
        //     haisetuKubun = <div><span style={{ background: yellow400, padding: '4px' }}>{nyutaisho[i].haisetuKubun}</span></div>
        // } else {
        //     haisetuKubun = <div>{nyutaisho[i].haisetuKubun}</div>
        // }

        data.push({
          key: i,
          colSelect: <Checkbox checked={this.state.arrCheckBox[i]} />,
          colCopy: (
            <span className={"action-kiroku"}>
              <Button
                type={"primary"}
                ghost
                onClick={this.handleCopyHaisetu.bind(this, nyutaisho[i])}
              >
                <PlusOutlined />
              </Button>
            </span>
          ),
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType === "1" ? (
                <ArrowRightOutlined />
              ) : (
                nyutaisho[i].nyuType
              )}
            </div>
          ),
          col2: (
            <span className={"ntk-riyousya-colum"}>
              {nyutaisho[i].modeRecord && nyutaisho[i].modeRecord === "copy" ? (
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              )}
              <span className={`ntl-riyousya-gender ${colorGender}`}>
                {gender}
              </span>
            </span>
          ),
          col3: <div>{nyutaisho[i].bunrui}</div>, // 住所
          col4: <div>{nyutaisho[i].room}</div>, // 部屋名s
          col5: (
            <div className={`icon-table ${planType}`}>
              {nyutaisho[i].time
                ? nyutaisho[i].time.substring(0, 5)
                : jikantai != "全表示"
                  ? jikantaiTime
                  : ""}
            </div>
          ),
          col6: (
            <div className={"icon-table"}>
              {nyutaisho[i].reportCaseTyuif ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          col7: (
            <div className={"bg_haiben"} style={{ background: haisetuColor }}>
              {haisetuType &&
                haisetuType !== "両方" &&
                !nyutaisho[i].haisetuKubun
                ? haisetuType
                : nyutaisho[i].haisetuKubun
                  ? nyutaisho[i].haisetuKubun
                  : nyutaisho[i].settingName == "HB提供内容設定"
                    ? "排便"
                    : nyutaisho[i].settingName == "HN提供内容設定"
                      ? "排尿"
                      : ""}
            </div>
          ),
          col8: (
            <div>
              {nyutaisho[i].shurui && nyutaisho[i].shurui.trim()
                ? nyutaisho[i].shurui
                : ""}
            </div>
          ),
          col9: (
            <div>
              {nyutaisho[i].haisetuf ? (
                <PlusOutlined />
              ) : nyutaisho[i].haisetuf === 0 ? (
                <MinusOutlined />
              ) : (
                ""
              )}
            </div>
          ),
          col10: inputMode ? (
            <div>{nyutaisho[i].vol ? nyutaisho[i].vol : ""}</div>
          ) : nyutaisho[i].vol ? (
            nyutaisho[i].vol
          ) : (
            ""
          ),
          col11: (
            <div>
              {nyutaisho[i].joutai && nyutaisho[i].joutai.trim()
                ? nyutaisho[i].joutai
                : ""}
            </div>
          ),
          col12: <div>{nyutaisho[i].comment}</div>,
          col13:
            nyutaisho[i].tantoName && nyutaisho[i].tantoName.length > 6 ? (
              <Tooltip placement="bottomRight" title={nyutaisho[i].tantoName}>
                {nyutaisho[i].tantoName.substring(0, 4) + "..."}
              </Tooltip>
            ) : (
              <div className={"icon-table"}>
                {nyutaisho[i].tantoName ? nyutaisho[i].tantoName : ""}
              </div>
            ),
          colDel: (
            <span className={"action-kiroku"}>
              {nyutaisho[i].modeRecord || nyutaisho[i].haisetuId ? (
                !nyutaisho[i].modeRecord && nyutaisho[i].haisetuId ? (
                  <Popconfirm
                    overlayStyle={{ width: "210px" }}
                    title="削除してもいいですか？"
                    onConfirm={() =>
                      this.handleServerDel(nyutaisho[i].haisetuId)
                    }
                  >
                    <span>
                      <Button type={"primary"} ghost>
                        <MinusOutlined />
                      </Button>
                    </span>
                  </Popconfirm>
                ) : (
                  <span>
                    <Button
                      type={"primary"}
                      ghost
                      onClick={this.handleDelHaisetu.bind(this, nyutaisho[i])}
                    >
                      <MinusOutlined />
                    </Button>
                  </span>
                )
              ) : null}
            </span>
          ),
          description: (
            <TableRowButton
              history={this.props.history}
              editRow={this.showDrawer.bind(this, nyutaisho[i])}
              riyousyaId={nyutaisho[i].riyousya.id}
            />
          ),
        });
      }
    }

    return (
      (<div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{
                        position: "bottom",
                        pageSize: ConstSet.TABLE_PAGINATION,
                      }}
                      scroll={{ x: 1400, y: 800 }}
                      className="table-kiroku-date"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                          index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      onChange={(pagination) => {
                        this.handChangePagination(pagination);
                      }}
                      columns={columnsLong}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            }
            return null;

          }}
        </MediaQuery>
        <Drawer
          destroyOnClose
          title={this.state.record ? this.state.record.riyousya.name : null}
          width={720}
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
          closable={false}
          rootClassName="kiroku-drawer-form"
        >
          <HaisetuForm
            tantoMaster={tantoMaster}
            data={this.state.record ? this.state.record : {}}
            handleFormInput={this.getHandleFormInput}
            yougoMasterEditState={this.getYougoMasterEditState}
            yougoMasterEditCancel={this.getYougoMasterEditCancel}
            yougoMasterEditSave={this.getYougoMasterEditSave}
            yougoMasterNa={yougoMasterNa}
            yougoMasterSy={yougoMasterSy}
            yougoMasterJt={yougoMasterJt}
            syokichiItem={this.syokichiItem}
            syokichiUpdate={this.syokichiUpdate}
          />
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <span style={{ float: "left" }}>
              <Button onClick={this.upperForm} style={{ marginRight: 8 }}>
                <ArrowUpOutlined />
              </Button>
              <Button onClick={this.lowerForm}>
                <ArrowDownOutlined />
              </Button>
            </span>

            <span>
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                戻る
              </Button>
              <Button type="primary" danger onClick={this.handleOkUpdateForm}>
                更新
              </Button>
            </span>
          </div>
        </Drawer>
        <Modal
          title={
            <div className="kim-header-title">
              <span>{this.state.riyousya}</span>
              <span>
                {this.state.time ? this.state.time.substring(0, 5) : ""}
              </span>
            </div>
          }
          open={this.state.visibleMInput}
          onOk={this.handleOkUpdate}
          onCancel={this.handleMInputCancel}
          className={"kiroku-input-modal"}
          footer={[
            <Button key="pre" onClick={this.preInput}>
              前項目
            </Button>,
            <Button key="next" onClick={this.nextInput}>
              次項目
            </Button>,
            <Button
              className="kim-btn-ok"
              key="submit"
              type="primary"
              onClick={this.handleOkUpdate}
              disabled={this.state.okSaveButton}
            >
              OK
            </Button>,
            <Button key="up" onClick={this.upperInput}>
              上の行
            </Button>,
            <Button key="dow" onClick={this.lowerInput}>
              下の行
            </Button>,
          ]}
          maskClosable={false}
        >
          <div className="kim-title">
            <span>{this.state.field}</span>
          </div>
          {this.state.isZendkaiDis ? (
            <div className={"model-top-row"}>
              <div className="kim-zenkai"></div>
              <div
                className={`kim-value ${this.state.field === "時間" ? "kim-value-time" : ""}`}
              >
                <div className="kim-value-top">
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
                {this.state.field === "時間" ? (
                  <div className="kim-value-bottom">
                    <Button type="primary" onClick={this.handleSetCurrentTime}>現時刻</Button>
                  </div>
                ) : null}
              </div>
              <div className={"mtr-col1 kim-suggest"}></div>
            </div>
          ) : null}

          <div>
            {(() => {
              switch (this.state.modalType) {
                case ConstSet.MODAL_H_TIME:
                  return (
                    <TimeKB
                      handleHourInput={this.getHandleHourInput}
                      handleMinuteLeftInput={this.getHandleMinuteLeftInput}
                      handleMinuteInput={this.getHandleMinuteInput}
                    />
                  );

                case ConstSet.MODAL_H_REPORTCASETYUIF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleReportCaseTyuif}
                    />
                  );

                case ConstSet.MODAL_H_HAISETUKUBUN:
                  return (
                    <HaisetuKubunKB
                      value={this.state.inputValue}
                      handleHaisetuKubun={this.getHandleHaisetuKubun}
                    />
                  );

                case ConstSet.MODAL_H_SHURUI:
                  return (
                    <InputSelectKB
                      value={this.state.inputValue}
                      handleInputSelect={this.getHandleShurui}
                      data={yougoMasterSy}
                      syokichiItem={this.syokichiItem}
                      syokichiUpdate={this.syokichiUpdate}
                      typeSyokichi={true}
                    />
                  );

                case ConstSet.MODAL_H_HAISETUF:
                  return (
                    <HaisetufKB
                      value={this.state.inputValue}
                      handleHaisetuf={this.getHandleHaisetuf}
                    />
                  );

                case ConstSet.MODAL_H_VOL:
                  return <NumberKB handleInputNum={this.getHandleHaisetuVol} />;

                case ConstSet.MODAL_H_JOUTAI:
                  return (
                    <InputSelectKB
                      value={this.state.inputValue}
                      handleInputSelect={this.getHandleJoutai}
                      data={yougoMasterJt}
                    />
                  );

                case ConstSet.MODAL_H_COMMENT:
                  return (
                    <CommentKB
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputComment}
                      yougoMasterNa={yougoMasterNa}
                      rowNum={4}
                    />
                  );

                case ConstSet.MODAL_H_TANTOMASTER:
                  return (
                    <TantoMasterKB2
                      tantoMaster={tantoMaster}
                      value={this.state.inputValue}
                      handleTantoMasterKB={this.getHandleTantoMasterKB}
                      widthCode={100}
                      widthText={250}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
        <Modal
          title={[
            <span key={1}>{"一括入力"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={this.props.mulInputModal && mulInputMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              閉じる
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={checkIkkatsuOkBtn(state.mulInputCheckbox)}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 0, "time")}
                    checked={this.state.mulInputCheckbox[0]}
                  >
                    時間
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={12}>
                <TimeKBCus
                  timeString={this.state.mulInputData.time}
                  handleInput={this.onChangeTimeMulModeNew}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 1, "shurui")}
                    checked={this.state.mulInputCheckbox[1]}
                  >
                    種類
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <InputSelectKB
                    value={this.state.mulInputData.shurui}
                    handleInputSelect={this.getHandleMulShurui}
                    data={yougoMasterSy}
                    disabled={this.state.isShunrui}
                  />
                  <span>
                    <Checkbox
                      onClick={this.mulSetDefaultValue}
                      checked={this.state.isShunrui}
                    >
                      初期値をセット
                    </Checkbox>
                  </span>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col xs={4}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 2, "haisetuF")}
                    checked={this.state.mulInputCheckbox[2]}
                  >
                    有無
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={6}>
                <FormItem>
                  <HaisetufKB
                    value={this.state.mulInputData.haisetuF}
                    handleHaisetuf={this.getHandleMulHaisetuF}
                  />
                </FormItem>
              </Col>
              <Col xs={4}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 3, "vol")}
                    checked={this.state.mulInputCheckbox[3]}
                  >
                    量
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={10}>
                <FormItem>
                  <NumberDropKB
                    value={this.state.mulInputData.vol}
                    handleNoDropInput={this.getHandleMulVol}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 4, "joutai")}
                    checked={this.state.mulInputCheckbox[4]}
                  >
                    状態
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <InputSelectKB
                    value={this.state.mulInputData.joutai}
                    handleInputSelect={this.getHandleMulJoutai}
                    data={yougoMasterJt}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 5, "comment")}
                    checked={this.state.mulInputCheckbox[5]}
                  >
                    コメント
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={17}>
                <FormItem>
                  <CommentKB
                    yougoMasterEditState={this.getYougoMasterEditState}
                    yougoMasterEditCancel={this.getYougoMasterEditCancel}
                    yougoMasterEditSave={this.getYougoMasterEditSave}
                    handleTextareaKB={this.getHandleMulInputComment}
                    handleOnchange={this.getHandleOnChangeMIC}
                    yougoMasterNa={yougoMasterNa}
                    defaultValue={this.state.mulInputData.comment}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={6}>
                <FormItem>
                  <Checkbox
                    onChange={this.handleCheckBox.bind(this, 6, "tantoId")}
                    checked={this.state.mulInputCheckbox[6]}
                  >
                    担当者
                  </Checkbox>
                </FormItem>
              </Col>
              <Col xs={18}>
                <FormItem>
                  <TantoMasterKB
                    tantoMaster={tantoMaster}
                    value={this.state.mulInputData.tantoId}
                    handleTantoMasterKB={this.getHandleMulTantoMasterKB}
                    widthCode={100}
                    widthText={250}
                  />
                </FormItem>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          destroyOnClose
          title={[
            <span key={1}>{"一括削除"}</span>,
            <span
              key={2}
              style={{ marginLeft: "24px", fontSize: "14px", color: "red" }}
            >
              {this.state.mulInputNotification}
            </span>,
          ]}
          open={mulInputModal && mulDelMode}
          onOk={this.handleMulInputModeOk}
          onCancel={this.handleMulInputModeCancel}
          footer={[
            <Button key="cancel" onClick={this.handleMulInputModeCancel}>
              キャンセル
            </Button>,
            <Button
              key="ok"
              onClick={this.handleMulInputModeOk}
              type="primary"
              disabled={!this.state.isConfirmMulDel}
            >
              OK
            </Button>,
          ]}
        >
          <div>
            <h3>一括削除を実行しますか？</h3>
            <Checkbox
              value={this.state.isConfirmMulDel}
              onChange={this.onChangeConfirmMulDel}
            >
              削除に同意する
            </Checkbox>
          </div>
        </Modal>
        <IkkatsuModal
          visibleMulState={state.visibleMulState}
          rowsSelect={state.rowsSelect}
          count={state.count}
          closePopup={this.closeModalMulState}
        />
      </div>)
    );
  }
}

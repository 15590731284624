import { JirituSokusinActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

function jirituSokusin(state = {}, action) {
  switch (action.type) {
    case JirituSokusinActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case JirituSokusinActionTypes.JIRITU_SOKUSIN_ADL_MASTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        adlMasterMotion: action.data.filter((item) => item.kubun == "基本動作"),
        adlMasterAdl: action.data.filter((item) => item.kubun == "ADL"),
      };
    }
    case JirituSokusinActionTypes.JIRITU_SOKUSIN_ADL_MASTER_ERROR: {
      return {
        ...state,
        loading: false,
        adlMasterMotion: null,
        adlMasterAdl: null,
      };
    }

    case JirituSokusinActionTypes.JIRITU_SOKUSIN_LIFE_YOUGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        jirituSokusinLifeYougo: action.data,
      };
    }
    case JirituSokusinActionTypes.JIRITU_SOKUSIN_LIFE_YOUGO_ERROR: {
      return {
        ...state,
        loading: false,
        jirituSokusinLifeYougo: null,
      };
    }

    case JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case JirituSokusinActionTypes.SAVE_JIRITU_SOKUSIN_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case JirituSokusinActionTypes.SAVE_JIRITU_SOKUSIN_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        jirituSokusinDetails: action.data,
      };
    }

    case JirituSokusinActionTypes.LOAD_JIRITU_SOKUSIN_TO_QUOTE:
      openNotificationWithIcon("success", "前回引用を実施しました");
      return {
        ...state,
        data: action.data,
      };

    case JirituSokusinActionTypes.SET_NEW_DATA:
      return {
        ...state,
        data: null,
      };

    case JirituSokusinActionTypes.GET_LIST_IDS_JIRITU_SOKUSIN: {
      return {
        ...state,
        loading: false,
        jirituSokusinIds: action.ids,
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default jirituSokusin;

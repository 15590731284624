import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import { Avatar, Button, Checkbox, Drawer, Input, List, Modal, Skeleton } from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import FuriganaKeyBoard from "../../../common/component/FuriganaKeyBoard";
import { formatStorage, getMenu, getPath, getValueIskeep, isManagement, setValueIsKeep, } from "../../../common/function_common/functionCommon";
import noImage from "../../../common/images/no-image.png";
import { guid } from "../../../common/timePicker/formatTimePicker";
import Actions from "./Actions";
import GoogleMap from "./GoogleMap";
const FA = require("react-fontawesome");

export default class ListRiyousya extends React.Component {
  constructor(props) {
    super(props);

    const date = new Date();
    const today =
      date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
    const isRiyouf = getValueIskeep("is_hold_riyouf");
    const isNyuusho = getValueIskeep("is_nyuusho_rireki");
    const isMNGT = isManagement();
    this.state = {
      currentDate: today,
      isRiyouf: isRiyouf ? isRiyouf : false,
      isNyuusho: isNyuusho ? isNyuusho : false,
      isMNGT,
      visible: false,
      visibleMap: false,
      address: "",
      titleMap: "",
      addAuth: parseInt(formatStorage(getMenu()).addAuth),
    };

    // bind place
  }

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.trackScrolling);
  };

  onChangeRiyouf = () => {
    const { isRiyouf } = this.state;
    this.props.onChangeRiyouf(!isRiyouf);

    this.setState({
      isRiyouf: !isRiyouf,
      isNyuusho: false,
    });

    setValueIsKeep("is_hold_riyouf", !isRiyouf);
    setValueIsKeep("is_nyuusho_rireki", false);
  };

  onChangeNyuusho = () => {
    const { isNyuusho } = this.state;
    this.props.onChangeNyuusho(!isNyuusho);

    this.setState({
      isNyuusho: !isNyuusho,
      isRiyouf: false,
    });

    setValueIsKeep("is_nyuusho_rireki", !isNyuusho);
    setValueIsKeep("is_hold_riyouf", false);
  };

  showModalFuriKey = () => {
    this.setState({
      visible: true,
    });
  };

  /**
   *
   * @param {*} furi
   */
  getHandleFurigana = (furi) => {
    this.props.updateStateFurigana(furi);
  };

  onChangeFurigana = (value) => {
    this.props.updateStateFurigana(value);
  };

  onChangeFuriganaInput = (e) => {
    this.props.updateStateFurigana(e.target.value);
  };

  handleSearchRiyouOk = () => {
    //
    this.props.searchByFurigana();

    //
    this.setState({
      visible: false,
    });
  };

  handleSearchRiyouCancel = () => {
    this.setState({
      visible: false,
    });

    this.props.updateStateFurigana("");
  };

  resetSearchByFurigana = () => {
    this.props.resetSearchByFurigana();
  };

  /**
   *
   * @param {*} el
   */
  isBottom(el) {
    // @var int totalPageHeight
    const totalPageHeight = el.scrollHeight;
    // @var int scrollPoint
    const scrollPoint = window.scrollY + window.innerHeight;
    // Return new
    return scrollPoint >= totalPageHeight;

    // return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const { page, page_total, isWaitLoadmore } = this.props;
    const wrappedElement = document.getElementById("riyousya-scroll");

    if (this.isBottom(wrappedElement) && !isWaitLoadmore) {
      if (page !== page_total) this.props.loadMore();

      // document.removeEventListener('scroll', this.trackScrolling);
    }

    // this.props.loadMore();
  };

  displayMap = (riyousyaId) => {
    const { riyousya } = this.props;

    const tmp = riyousya.find((item) => item.id === riyousyaId);

    let address = "";
    if (tmp) {
      if (tmp.sonotaAddress) {
        address = tmp.sonotaAddress;
      }
      else if (tmp.address1 || tmp.address2) {
        address = tmp.address1 + tmp.address2;
      }
    }
    this.setState({
      visibleMap: true,
      address,
      titleMap: tmp.name,
    });
  };

  closeDrawer = () => {
    this.setState({
      visibleMap: false,
    });
  };

  riyousyaListIsKeep = () => {
    this.props.riyousyaListIsKeep();
  };

  render() {
    const { riyousya, loading, furigana, currentMenu } = this.props;
    const {
      isRiyouf,
      isNyuusho,
      isMNGT,
      visible,
      visibleMap,
      titleMap,
      address,
      currentDate,
      addAuth,
    } = this.state;
    const path = getPath();

    const header = (
      <div className="ripa-search">
        <Checkbox onChange={this.onChangeRiyouf} checked={isRiyouf}>
          利用中のみ
        </Checkbox>
        {!isMNGT ? (
          <Checkbox onChange={this.onChangeNyuusho} checked={isNyuusho}>
            本日入所中
          </Checkbox>
        ) : (
          ""
        )}
        <div className={"sa-f-item f-by-keyboard"}>
          {addAuth === 1 ? (
            <span>
              <Link
                to={path + "/riyousya-add"}
                onClick={() => this.riyousyaListIsKeep()}
              >
                <Button type="primary">利用者新規</Button>
              </Link>
            </span>
          ) : null}

          <span>
            <Button onClick={this.showModalFuriKey}>
              <FA name="keyboard" key={guid()} />
            </Button>
          </span>
          <span>
            <Input value={furigana} onChange={this.onChangeFuriganaInput} />
          </span>
          <span>
            <Button onClick={this.resetSearchByFurigana}>
              <UndoOutlined />
            </Button>
          </span>
          <span className="ripa-search-btn">
            <Button type="primary" onClick={this.handleSearchRiyouOk}>
              <SearchOutlined /> 検索
            </Button>
          </span>
        </div>
      </div>
    );

    return (
      (<div className="riyousya-list-content">
        <div onScroll={this.trackScrolling} id={"riyousya-scroll"}>
          <List
            header={header}
            dataSource={riyousya && riyousya.length > 0 ? riyousya : []}
            renderItem={(item) => (
              <List.Item
                key={guid()}
                actions={[
                  <Actions
                    key={0}
                    id={item.id}
                    displayMap={this.displayMap}
                    currentMenu={currentMenu}
                    currentDate={currentDate}
                    history={this.props.history}
                    riyousyaListIsKeep={this.riyousyaListIsKeep}
                  />,
                ]}
              >
                <Skeleton avatar title={false} loading={false} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={item.riyousyaImage ? item.riyousyaImage : noImage}
                      />
                    }
                    description={
                      <div>
                        <span className="user_kana">{item.furigana}</span>
                        <span className="user_kanji">{item.name}</span>
                        <span className="user_tell">
                          <FA name="phone" key={guid()} /> {item.tel}
                        </span>
                        <span className="user_ad">
                          <FA name="home" key={guid()} />{" "}
                          {item.address1 + item.address2}
                        </span>
                        <span className="user_extent">
                          <FA name="info-circle" key={guid()} />{" "}
                          {item.extra_info}
                        </span>
                      </div>
                    }
                  />

                  <div className="ripa-item-content">
                    <span className="user_sei men">
                      {item.gender && item.gender === 1 ? (
                        <div className="c-blue">男性</div>
                      ) : (
                        <div className="c-red">女性</div>
                      )}
                    </span>
                    <span className="user_age">
                      {item.birthday && item.birthday !== "0001-01-01"
                        ? dayjs().diff(
                          dayjs(item.birthday, "YYYY-MM-DD"),
                          "years"
                        )
                        : null}
                      歳
                    </span>
                  </div>
                </Skeleton>
              </List.Item>
            )}
          ></List>
          {/* {
                    page && page_total && page !== page_total
                        ?
                        <Row className="riyousya-loadmore">
                            <Button type="primary" onClick={this.loadMore}>もっと見る</Button>
                        </Row>
                        :
                        null
                } */}

          {/* <Row className="riyousya-loadmore">
                    <Button type="primary" onClick={this.loadMore} loading={loading}>もっと見る</Button>
                </Row> */}

          {loading ? (
            <div className="loading">
              <div className="loading-bar"></div>
              <div className="loading-bar"></div>
              <div className="loading-bar"></div>
              <div className="loading-bar"></div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Modal
          title="ふりがな検索"
          open={visible}
          onOk={this.handleSearchRiyouOk}
          onCancel={this.handleSearchRiyouCancel}
          className={"hiragana-model"}
          width={560}
        >
          <FuriganaKeyBoard
            furigana={this.getHandleFurigana}
            resetFurigana={this.onChangeFurigana}
            furiganaState={furigana}
          />
        </Modal>
        <Drawer
          destroyOnClose
          title={
            <div rootClassName="google-map-drawer-title">
              <p>
                <span>{titleMap + "さんの住所"}</span>
                <span style={{float: 'right' }}>
                  <Button onClick={this.closeDrawer}>閉じる</Button>
                </span>
              </p>
              <p>
                <span>{address}</span>
                <a href={`https://www.google.co.jp/maps/place/${address}`} target="_blank" rel="noopener noreferrer">大きい地図で見る</a>
              </p>
            </div>
          }
          width={"100%"}
          onClose={this.closeDrawer}
          open={visibleMap}
          closable={false}
          rootClassName="google-map-drawer"
        >
          <GoogleMap address={address} />
        </Drawer>
      </div>)
    );
  }
}

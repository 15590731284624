import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, HaisetsuActionTypes } from "../action_types";

function getHaisetuLifeYougo() {
  return async (dispatch) => {
    try {
      dispatch({ type: HaisetsuActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.HaisetsuSienPlan.getHaisetuLifeYougo,
      });

      dispatch({ type: HaisetsuActionTypes.HAISETSU_LIFE_YOUGO_SUCCESS, data });
    }
    catch (error) {
      dispatch({ type: HaisetsuActionTypes.HAISETSU_LIFE_YOUGO_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getHaisetsuById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: HaisetsuActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.HaisetsuSienPlan.getHaisetsuById}?id=${id}`,
      });

      dispatch({ type: HaisetsuActionTypes.LOAD_HAISETSU_SUCCESS, data });
    }
    catch (error) {
      dispatch({ type: HaisetsuActionTypes.LOAD_HAISETSU_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteHaisetsu(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: HaisetsuActionTypes.REMOVE_HAISETSU_REQUEST });

      const data = await RequestUtils.callApi({
        method: "delete",
        path: `${ApiPaths.HaisetsuSienPlan.delHaisetsu}?id=${id}`,
      });

      dispatch({ type: HaisetsuActionTypes.REMOVE_HAISETSU_SUCCESS, data });
      return callback(data);
    }
    catch (error) {
      dispatch({ type: HaisetsuActionTypes.REMOVE_HAISETSU_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createUpHaisetsu(body, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: HaisetsuActionTypes.LOAD_SPIN_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.HaisetsuSienPlan.saveHaisetsuSien,
        body,
      });
      dispatch({
        type: HaisetsuActionTypes.SAVE_HAISETSU_SIEN_SUCCESS,
        data,
      });
      callback(data);
    }
    catch (error) {
      dispatch({
        type: HaisetsuActionTypes.SAVE_HAISETSU_SIEN_ERROR,
        error,
      });
    }
  };
}

function getListHaisetuToQuote(riyousyaId, id) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, id, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.HaisetsuSienPlan.getListHaisetuToQuote}`,
        query,
      });
      dispatch({
        type: HaisetsuActionTypes.LOAD_HAISETU_TO_QUOTE_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListIdsHaisetu(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const haisetuSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.haisetu
      ? SWLIFEListRiyousya.sort.haisetu
      : {};
  return async (dispatch) => {
    try {
      dispatch({ type: HaisetsuActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = {
        sid: menu.si || null,
        inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
        searchName: params.searchName,
        fromDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        toDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(haisetuSort)),
        riyousyaId: params.riyousyaId,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.HaisetsuSienPlan.getListIdsHaisetu,
        query,
      });

      dispatch({
        type: HaisetsuActionTypes.GET_LIST_IDS_HAISETU,
        ids: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getHaisetuByIds(ids) {
  return async (dispatch) => {
    try {
      dispatch({
        type: HaisetsuActionTypes.LOAD_HAISETU_BY_IDS_REQUEST,
        jokusoPlanList: [],
      });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.HaisetsuSienPlan.getHaisetuList,
        query: { ids },
      });
      dispatch({
        type: HaisetsuActionTypes.LOAD_HAISETU_BY_IDS_SUCCESS,
        haisetuList: data,
      });
    }
    catch (error) {
      dispatch({
        type: HaisetsuActionTypes.LOAD_HAISETU_BY_IDS_ERROR,
        error,
      });
    }
  };
}

function clearHaisetuByIds() {
  return (dispatch) =>
    dispatch({ type: HaisetsuActionTypes.CLEAR_HAISETU_BY_IDS });
}

export const HaisetsuActions = {
  getHaisetuLifeYougo,
  getHaisetsuById,
  deleteHaisetsu,
  createUpHaisetsu,
  getListHaisetuToQuote,
  getListIdsHaisetu,
  getHaisetuByIds,
  clearHaisetuByIds,
};

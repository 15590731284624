import React from "react";
import { ArrowRightOutlined } from '@ant-design/icons';
import { Table, Spin, Tooltip } from "antd";
import MediaQuery from "react-responsive";
import TableRowButton from "./TableRowButton";
import { ConstSet } from "../../../common/configs/constset";

import { disPlanToString } from "../../../common/function_common/functionCommon";

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };

export default class Riyousya extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,

      // rowSelection: {},
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      rowActive: null,
    };

    //bind
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(key, index, event) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    var keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    } else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  // handleClickRow(key) {
  //     if (key === this.state.activeListIndex) {
  //         this.setState({
  //             activeListIndex: null
  //         })
  //     } else {
  //         this.setState({
  //             activeListIndex: key
  //         })
  //     }

  // }

  render() {
    const state = this.state;
    const {
      TableStyle,
      nyutaisho,
      loading,
      bunruiTitle,
      isDisEventPlan,
      isDisLongText,
      haiben,
      nyutaishoDate,
    } = this.props;

    let date = nyutaishoDate.replace(/-/g, "/");
    let dateTmp = new Date(date);

    const haibenDay =
      haiben && haiben.data && haiben.data.naiyou ? haiben.data.naiyou : 0;
    dateTmp.setDate(dateTmp.getDate() - haibenDay);
    let Y = dateTmp.getFullYear();
    let M = ("00" + (dateTmp.getMonth() + 1)).slice(-2);
    let D = ("00" + dateTmp.getDate()).slice(-2);
    let dateTmp2 = Y + "-" + M + "-" + D;

    let tmp = [];

    const columnsShort = [
      {
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        render: (text) => text,
        fixed: "left",
        className: "joutaiCol",
      },
      {
        title: "利用者名",
        dataIndex: "col2",
        key: "col2",
        width: 150,
        fixed: "left",
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
      },
      {
        title: bunruiTitle,
        dataIndex: "col3",
        key: "col3",
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        width: 100,
      },
      {
        title: "部屋名",
        dataIndex: "col4",
        key: "col4",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
      },
      {
        title: "入所日",
        dataIndex: "col5",
        key: "col5",
        width: 100,
      },
      {
        title: "退所日",
        dataIndex: "col6",
        key: "col6",
        width: 100,
      },
      {
        title: "最終排便日",
        dataIndex: "col16",
        key: "col16",
        width: 100,
      },
      {
        title: "本日の予定",
        dataIndex: "col7",
        key: "col7",
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        // width: 200,
      },
    ];

    const columnsLong = [
      {
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        render: (text) => text,
        fixed: "left",
        className: "joutaiCol",
      },
      {
        title: "利用者名",
        dataIndex: "col2",
        key: "col2",
        width: 150,
        fixed: "left",
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
      },
      {
        title: bunruiTitle,
        dataIndex: "col3",
        key: "col3",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
      },
      {
        title: "部屋名",
        dataIndex: "col4",
        key: "col4",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
      },
      {
        title: "入所日",
        dataIndex: "col5",
        key: "col5",
        width: 100,
      },
      {
        title: "退所日",
        dataIndex: "col6",
        key: "col6",
        width: 100,
      },
      {
        title: "便回数",
        dataIndex: "col8",
        key: "col8",
        width: 100,
      },
      {
        title: "尿回数",
        dataIndex: "col9",
        key: "col9",
        width: 100,
      },
      {
        title: "尿測",
        dataIndex: "col10",
        key: "col10",
        width: 100,
      },
      {
        title: "水分摂取量",
        dataIndex: "col11",
        key: "col11",
        width: 110,
      },
      {
        title: "朝",
        dataIndex: "col12",
        key: "col12",
        width: 60,
      },
      {
        title: "昼",
        dataIndex: "col13",
        key: "col13",
        width: 60,
      },
      {
        title: "夕",
        dataIndex: "col14",
        key: "col14",
        width: 60,
      },
      {
        title: "間",
        dataIndex: "col15",
        key: "col15",
        // width: 80,
      },
    ];

    if (TableStyle) {
      tmp = columnsLong;
    } else {
      tmp = columnsShort;
    }

    /**
     * handle date
     */
    let data = [];
    if (nyutaisho) {
      for (let i = 0; i < nyutaisho.length; i++) {
        let eventPlan = "";
        if (isDisEventPlan) {
          eventPlan = disPlanToString(
            nyutaisho[i].event_plan,
            nyutaisho[i].riyousya_plan_yotei,
            nyutaisho[i].riyousya_plan_sonota
          );
        } else {
          eventPlan = disPlanToString(
            nyutaisho[i].riyousya_plan_yotei,
            nyutaisho[i].riyousya_plan_sonota
          );
        }

        let color = "nuytaisho-yellow";
        if (nyutaisho[i].kubun === "ショート") {
          color = "nuytaisho-blue";
        }

        let gender = "女";
        let colorGender = "nts-gender-red";
        if (nyutaisho[i].riyousya.gender === 1) {
          gender = "男";
          colorGender = "nts-gender-blue";
        }

        let colorHaibenbi = ""; //最終排便日の色
        if (nyutaisho[i].haiben <= dateTmp2) {
          colorHaibenbi = "nts-haiben-red";
        }

        data.push({
          key: i,
          col1: (
            <div className={`ntk-riyousya-status ${color}`}>
              {nyutaisho[i].nyuType === "1" ? (
                <ArrowRightOutlined />
              ) : (
                nyutaisho[i].nyuType
              )}
            </div>
          ),
          col2: (
            <span className={"ntk-riyousya-colum"}>
              <span style={{ fontWeight: "bold" }}>
                {nyutaisho[i].riyousya.name}
              </span>
              <span className={`ntl-riyousya-gender ${colorGender}`}>
                {gender}
              </span>
            </span>
          ),
          col3: (
            <div>
              {nyutaisho[i] &&
              nyutaisho[i].bunrui &&
              nyutaisho[i].bunrui.length > 7 ? (
                <Tooltip placement="bottomRight" title={nyutaisho[i].bunrui}>
                  {nyutaisho[i].bunrui.substring(0, 6) + "..."}
                </Tooltip>
              ) : (
                nyutaisho[i].bunrui
              )}
            </div>
          ),

          col4: (
            <div>
              {nyutaisho[i] &&
              nyutaisho[i].room &&
              nyutaisho[i].room.length > 9 ? (
                <Tooltip placement="bottomRight" title={nyutaisho[i].room}>
                  {nyutaisho[i].room.substring(0, 7) + "..."}
                </Tooltip>
              ) : (
                nyutaisho[i].room
              )}
            </div>
          ),
          col5: nyutaisho[i].nyusyoDateString,
          col6:
            nyutaisho[i].taishoDateString &&
            nyutaisho[i].taishoDateString == "0001-01-01"
              ? null
              : nyutaisho[i].taishoDateString,
          col7: <div>{eventPlan}</div>,
          col8: nyutaisho[i].countHaiben,
          col9: nyutaisho[i].countHainyo,
          col10: nyutaisho[i].totalVol,
          col11: nyutaisho[i].totalSuibun,
          col12: nyutaisho[i].asaMeal,
          col13: nyutaisho[i].hiruMeal,
          col14: nyutaisho[i].yuuMeal,
          col15: nyutaisho[i].kanMeal,
          col16: (
            <span className={"ntk-riyousya-colum"}>
              <span className={`${colorHaibenbi}`}>
                {nyutaisho[i] && nyutaisho[i].haiben ? nyutaisho[i].haiben : ""}
              </span>
            </span>
          ),
          description: (
            <TableRowButton
              history={this.props.history}
              riyousyaId={nyutaisho[i].riyousya.id}
            />
          ),
          expandable: true,
        });
      }
    }

    return (
      <div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{
                        position: "bottom",
                        pageSize: ConstSet.TABLE_PAGINATION_RIYOUSYA,
                      }}
                      scroll={{ x: 1400, y: 800 }}
                      className="table-kiroku-date"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                        index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            this.onRowClick(rowIndex);
                          },
                        };
                      }}
                      columns={tmp}
                      dataSource={state.hasData ? data : []}
                    />
                  </Spin>
                </div>
              );
            } else {
              return null;
            }
          }}
        </MediaQuery>
      </div>
    );
  }
}

import moment from "moment";
import jwtDecode from "jwt-decode";
import { OPTIONS_ACTUAL_SORT, OPTIONS_PLAN_SORT, OPTIONS_SUPPORT_ADDITION_LIST_SORT, STATE_USAGE_TICKET_SAVE_TO_LOCAL } from "../../pages/UsageTicketManagement/constants";
import { usageTicketManagementActionTypes } from "../action_types";
import { StorageUtils } from "../../utils";

const handleGetInitialState = () => {
  const usageTicketManagementState = StorageUtils.getValue(STATE_USAGE_TICKET_SAVE_TO_LOCAL) || {};
  const tokens = StorageUtils.getValue("tokens") && StorageUtils.getValue("tokens").token;
  let decoded = null;
  if (tokens) {
    decoded = jwtDecode(tokens);
  }
  const tantoId = !usageTicketManagementState?.tantoId ? usageTicketManagementState?.tantoId : decoded?.userInfo?.tidCopy + "";
  const handleGetSortDataInit = (optionsSort) => {
    const resultObject = {};

    optionsSort.forEach((item, index) => {
      resultObject[`key${index + 1}`] = "";
      resultObject[`value${index + 1}`] = "asc";
    });

    return resultObject;
  };
  return {
    filterConditionPlan: {
      sort: usageTicketManagementState?.valueSortTabPlan
        ? JSON.parse(usageTicketManagementState?.valueSortTabPlan)
        : handleGetSortDataInit(OPTIONS_PLAN_SORT),
      serviceKikanMasterId: null,
      serviceKikanMasterName: "",
      serviceType_kaigoCode: "",
      searchName: "",
      createDate: moment(new Date(), "YYYY-MM"),
      tantoId: tantoId || null,
      serviceSyuruiCd: "",
      sid: parseInt(StorageUtils.getValue("menuMNGT")?.si),
    },
    filterConditionActual: {
      sort: usageTicketManagementState?.valueSortTabActual
        ? JSON.parse(usageTicketManagementState?.valueSortTabActual)
        : handleGetSortDataInit(OPTIONS_ACTUAL_SORT),
      serviceKikanMasterId: null,
      serviceKikanMasterName: "",
      serviceType_kaigoCode: "",
      searchName: "",
      createDate: moment(new Date(), "YYYY-MM"),
      tantoId: tantoId || null,
      serviceSyuruiCd: "",
      sid: parseInt(StorageUtils.getValue("menuMNGT")?.si),
    },
    filterConditionSupportAdditionList: {
      sort: usageTicketManagementState?.valueSortTabSupportAddition
        ? JSON.parse(usageTicketManagementState?.valueSortTabSupportAddition)
        : handleGetSortDataInit(OPTIONS_SUPPORT_ADDITION_LIST_SORT),
      serviceKikanMasterId: null,
      serviceKikanMasterName: "",
      serviceType_kaigoCode: "",
      searchName: "",
      createDate: moment(new Date(), "YYYY-MM"),
      tantoId: tantoId || null,
      serviceSyuruiCd: "",
      sid: parseInt(StorageUtils.getValue("menuMNGT")?.si),
    },
    numberItem: 0,
    listSyuruiMaster: [],
    loadingGetListSyuruiMaster: false,
    mode: { plan: "", actual: "", supportAddition: "" },
    loadingScreen: false,
    loadingGetListUserActive: false,
    listUserActive: [],
    listOfficeByRiyouhyouId: [],
    listOfficeByFilterCondition: [],
    loadingListOfficeByRiyouhyouId: false,
    loadingListOfficeByFilterCondition: false,
    rowSelectedPlanMode: {},
    rowSelectedActualMode: {},
    rowSelectedSupportAdditionListMode: {}
  };
};

export default function usageTicketManagementReducers(state = handleGetInitialState(), action) {
  switch (action.type) {
    case usageTicketManagementActionTypes.SAVE_FILTER_CONDIITON_PLAN:
      return {
        ...state,
        filterConditionPlan: {...state.filterConditionPlan, ...action.params},
      };
    case usageTicketManagementActionTypes.SAVE_FILTER_CONDIITON_ACTUAL:
      return {
        ...state,
        filterConditionActual: {...state.filterConditionActual, ...action.params},
      };
    case usageTicketManagementActionTypes.SAVE_FILTER_CONDIITON_SUPPORT_ADDITION_LIST:
      return {
        ...state,
        filterConditionSupportAdditionList: {...state.filterConditionSupportAdditionList, ...action.params},
      };
    case usageTicketManagementActionTypes.SAVE_NUMBER_ITEM:
      return {
        ...state,
        numberItem: action.params,
      };

    case usageTicketManagementActionTypes.GET_LIST_SYURUI_MASTER_REQUEST: {
      return {
        ...state,
        loadingGetListSyuruiMaster: true,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_SYURUI_MASTER_SUCCESS: {
      return {
        ...state,
        listSyuruiMaster: action.data,
        loadingGetListSyuruiMaster: false,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_SYURUI_MASTER_FAILURE: {
      return {
        ...state,
        loadingGetListSyuruiMaster: false,
      };
    }

    case usageTicketManagementActionTypes.SAVE_MODE: {
      return {
        ...state,
        mode: action.mode,
      };
    }

    case usageTicketManagementActionTypes.LOADING_SCREEN: {
      return {
        ...state,
        loadingScreen: action.loadingScreen,
      };
    }

    case usageTicketManagementActionTypes.GET_LIST_USER_ACTIVE: {
      return {
        ...state,
        loadingGetListUserActive: true,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_USER_ACTIVE_SUCCESS: {
      return {
        ...state,
        listUserActive: action.data,
        loadingGetListUserActive: false,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_USER_ACTIVE_FAILURE: {
      return {
        ...state,
        loadingGetListUserActive: false,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_OFFICE_BY_RIYOUHYOU_ID_REQUEST: {
      return {
        ...state,
        loadingListOfficeByRiyouhyouId: true,
      };
    }

    case usageTicketManagementActionTypes.GET_LIST_OFFICE_BY_RIYOUHYOU_ID_SUCCESS: {
      return {
        ...state,
        listOfficeByRiyouhyouId: action.data,
        loadingListOfficeByRiyouhyouId: false,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_OFFICE_BY_RIYOUHYOU_ID_FAILURE: {
      return {
        ...state,
        loadingListOfficeByRiyouhyouId: false,
      };
    }

    case usageTicketManagementActionTypes.GET_LIST_OFFICE_BY_FILTER_CONDITION_REQUEST: {
      return {
        ...state,
        loadingListOfficeByFilterCondition: true,
      };
    }

    case usageTicketManagementActionTypes.GET_LIST_OFFICE_BY_FILTER_CONDITION_SUCCESS: {
      return {
        ...state,
        listOfficeByFilterCondition: action.data,
        loadingListOfficeByFilterCondition: false,
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_OFFICE_BY_FILTER_CONDITION_FAILURE: {
      return {
        ...state,
        loadingListOfficeByFilterCondition: false,
      };
    }

    case usageTicketManagementActionTypes.SAVE_RECORD_SELECTED_PLAN_MODE: {
      return {
        ...state,
        rowSelectedPlanMode: action.selectedRow
      };
    }

    case usageTicketManagementActionTypes.SAVE_RECORD_SELECTED_ACTUAL_MODE: {
      return {
        ...state,
        rowSelectedActualMode: action.selectedRow
      };
    }

    case usageTicketManagementActionTypes.SAVE_RECORD_SELECTED_SUPPORT_ADDITION_LIST_MODE: {
      return {
        ...state,
        rowSelectedSupportAdditionListMode: action.selectedRow
      };
    }
    case usageTicketManagementActionTypes.GET_LIST_USER_ACTIVE_WITH_NOT_TANTO_ID: {
      return {
        ...state,
        listUserWithNotTantoId: action.data
      };
    }

    default:
      return state;
  }
}

import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { middleware } from '../middleware/middleware'
import { rootReducer } from '../reducers/rootReducer'

const initState = {};

// logger apply to development mode
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

export const store = createStore(
  rootReducer,
  initState,
  applyMiddleware(middleware, thunk, loggerMiddleware)
);

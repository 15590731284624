/* eslint-disable no-unused-vars */
import { Calendar, Col, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

function SelectYearMonthDialog(props) {
  const [selectValue, setSelectValue] = useState(dayjs().startOf("month"));

  useEffect(() => {
    if (props.visible) {
      setSelectValue(dayjs().startOf("month"));
    }
  }, [props.visible]);

  function onPanelChange(value) {
    setSelectValue(value);
  }

  return (
    (<Modal
      open={props.visible}
      title={"作成年月ダイアログ"}
      onOk={() => props.onRedirect(selectValue)}
      onCancel={props.onHideModal}
    >
      <Calendar
        value={selectValue}
        fullscreen={false}
        onPanelChange={onPanelChange}
        mode={"year"}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const year = value.year();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i}>
                {i}
              </Select.Option>
            );
          }
          return (
            <div style={{ padding: 10 }}>
              <Row type="flex" justify="end">
                <Col>
                  <Select
                    dropdownMatchSelectWidth={false}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                    value={String(year)}
                  >
                    {options}
                  </Select>
                </Col>
              </Row>
            </div>
          );
        }}
      />
    </Modal>)
  );
}

export { SelectYearMonthDialog };

import { Form } from "@ant-design/compatible";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import React, { Component } from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Checkbox, Col, Input, Row, Select } from "antd";
import _ from "lodash";
import { isKanji, toKatakana } from "wanakana";

const CheckboxGroup = Checkbox.Group;
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class DengonForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shown: false,
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      socket: this.props.socket,
      userSelect: false,
      messageContent: false,
      pageNo: 0,
    };

    this.toggle = this.toggle.bind(this);
    this.groupFilter = this.groupFilter.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sendTo.length > 0) {
      this.setState({
        checkedList: nextProps.sendTo,
      });
    }
  }

  groupFilter = (value) => {
    // console.log(`selected ${value}`);
    this.props.groupFilter(value);

    // save value to sate
  };

  search = () => {
    const text = document.getElementById("txtSearch").value;
    this.props.search(text);
    document.getElementById("txtSearch").value = "";
  };

  onChangeGroup = (checkedList) => {
    // console.log(checkedList);
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < this.props.users.length,
      checkAll: checkedList.length === this.props.users.length,
    });

    this.props.sendToIds(checkedList);
  };

  onCheckAllChange = (e) => {
    const listItems = [];

    const { groupName, users } = this.props;

    if (e.target.checked) {
      users.map((user) => {
        if (groupName && groupName !== "全表示") {
          if (user.sendGroups && user.sendGroups.length > 0) {
            for (let i = 0; i < user.sendGroups.length; i++) {
              if (user.sendGroups[i].groupName === groupName) {
                listItems.push(user.value);
              }
            }
          }
        }
        else {
          listItems.push(user.value);
        }
      });
    }

    this.setState({
      checkedList: e.target.checked ? listItems : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });

    this.props.sendToIds(listItems);
  };

  selectUser = () => {
    this.setState({
      shown: !this.state.shown,
    });
  };

  toggle = () => {
    const storage = sessionStorage;

    if (this.state.shown) {
      const cList = storage.getItem("cList").split(",").map(Number);
      const indeterminate = storage.getItem("indeterminate");
      const checkAll = storage.getItem("checkAll");

      this.setState({
        checkedList: cList == 0 ? [] : cList,
        indeterminate: indeterminate == "true",
        checkAll: checkAll == "true",
      });

      cList == 0 ? this.props.sendToIds([]) : this.props.sendToIds(cList);
      storage.removeItem("cList");
      storage.removeItem("indeterminate");
      storage.removeItem("checkAll");
    }
    else {
      storage.setItem("cList", this.props.sendTo);
      storage.setItem("indeterminate", this.state.indeterminate);
      storage.setItem("checkAll", this.state.checkAll);
    }

    this.setState({
      shown: !this.state.shown,
    });
  };

  sendMessage = () => {
    const text = document.getElementById("message-text").value;
    const sentToIds = this.state.checkedList;

    this.props.sendMessage(text, sentToIds);

    // Reset Value
    this.setState({
      checkedList: [],
    });
    this.props.sendToIds([]);
    document.getElementById("message-text").value = "";
  };

  rely = (tantoId, readf, id) => {
    // If not read yet, update
    if (readf === 0) {
      this.props.mitayo(id.toString());
    }

    const listItem = [];
    const { users } = this.props;

    Object.keys(users).forEach(function (user) {
      if (users[user].tidCopy === tantoId) {
        listItem.push(users[user].id);
      }
    });

    this.setState({
      checkedList: listItem,
    });

    window.scrollTo(0, 0);
    document.getElementById("message-text").focus();
  };

  render() {
    //
    const shown = {
      display: this.state.shown ? "block" : "none",
    };
    const hidden = {
      display: this.state.shown ? "none" : "block",
    };

    const { users, groups, groupName, name, sendTo } = this.props;

    // Display group
    const group = [];
    if (groups) {
      for (let i = 0; i < groups.length; i++) {
        group.push(<Option key={groups[i]}>{groups[i]}</Option>);
      }
    }

    // lodash filter object for searching and filter group
    const userOptions =
      groupName !== "全表示"
        ? _.filter(users, (obj) => {
          return _.some(obj.sendGroups, { groupName });
        })
        : _.filter(users, (obj) => {
          if (name === "") {
            // case get all
            return _.includes(obj.name, "");
          } // case search by name
          if (isKanji(name)) {
            // kanji input
            return _.startsWith(obj.name, name);
          } // hiragana or katakana input
          return _.startsWith(obj.furigana, toKatakana(name));
        });

    // display selected users
    const selectedUsers = (id, users) => {
      const user = _.filter(users, (obj) => {
        // console.log(id + " / " + obj.id);
        return obj.id === id;
      });

      return user[0].name;
    };

    return (
      <div className={"dengon-form"}>
        <Row className={"dg-bd-popup"} style={shown}>
          <div className={"maxw-960"}>
            <Col span={24}>
              <Form layout="inline">
                <FormItem label="グループ"></FormItem>
                <FormItem>
                  <Select
                    defaultValue="全表示"
                    style={{ width: 140 }}
                    onChange={this.groupFilter}
                  >
                    <Option key="全表示">全表示</Option>
                    {group}
                  </Select>
                </FormItem>
                <FormItem>
                  <Input
                    style={{ width: 160 }}
                    placeholder="お名前"
                    id={"txtSearch"}
                  />
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={this.search}
                  >
                    検索
                  </Button>
                </FormItem>
              </Form>
            </Col>
            <Col span={24} className="dg-pu-c-all">
              <span>
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={this.state.checkAll}
                >
                  全ON/OFFにする
                </Checkbox>
              </span>
            </Col>
            <Col span={24} className="dg-list-user">
              <CheckboxGroup
                options={userOptions}
                value={this.state.checkedList}
                onChange={this.onChangeGroup}
              />
            </Col>
            <Col span={24} className="dg-pu-btn-end">
              <Button
                type="primary"
                className="dg-pu-btn-e-r"
                onClick={this.selectUser}
              >
                <CheckOutlined />
                決定
              </Button>
              <Button
                type="primary"
                className="dg-pu-btn-e-l"
                onClick={this.toggle}
              >
                <CloseOutlined />
                閉じる
              </Button>
            </Col>
          </div>
        </Row>
        <Row className="dg-bg-header" style={hidden}>
          <div className="maxw-960">
            <Col span={24}>
              <Button type="primary" onClick={this.toggle}>
                宛先を選択してください
              </Button>
              <br></br>
              {sendTo.map((listUser) => {
                return (
                  <a key={listUser} className="tag-user">
                    {selectedUsers(listUser, users)},
                  </a>
                );
              })}
            </Col>
            <Col span={24}>
              <TextArea id={"message-text"} rows={4} />
            </Col>
            <Col span={24}>
              <Button type="primary" onClick={this.sendMessage}>
                伝言送信
              </Button>
            </Col>
          </div>
        </Row>
      </div>
    );
  }
}

export default DengonForm;

import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { LifeYougoMasterActionTypes } from "../action_types";

function lifeYougoMaster(state = {}, action) {
  switch (action.type) {
    case LifeYougoMasterActionTypes.GET_SIENNAIYO_MASTER_SUCESS: {
      return {
        ...state,
        sienNaiyoMaster: action.data,
      };
    }

    case LifeYougoMasterActionTypes.GET_ICF_MASTER_SUCESS: {
      return {
        ...state,
        ifcMaster: action.data,
      };
    }

    case LifeYougoMasterActionTypes.GET_ICF_MASTER_KOUI: {
      return {
        ...state,
        koui: action.data,
      };
    }

    case LifeYougoMasterActionTypes.GET_SIENNAIYO_MASTER_SAIKOMOKU: {
      return {
        ...state,
        saiKomoku: action.data,
      };
    }

    case LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_BUNRUI1: {
      return {
        ...state,
        bunrui1: action.naiyou,
        loading: false,
      };
    }

    case LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_SUCCESS: {
      const { naiyou } = action;
      const yougoNaiyou = naiyou
        ? naiyou.split(";").map((v, i) => {
            return {
              key: i,
              naiyou: v,
              editable: false,
            };
          })
        : [];
      return {
        ...state,
        yougoNaiyou,
      };
    }

    case LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoNaiyou: [],
      };

    case LifeYougoMasterActionTypes.SAVE_LIFE_YOUGO_MASTER_SUCCESS:
      return {
        ...state,
        yougoNaiyou: action.naiyou
          ? action.naiyou.split(";").map((v, i) => {
              return {
                key: i,
                naiyou: v,
                editable: false,
              };
            })
          : [],
      };

    case LifeYougoMasterActionTypes.SAVE_LIFE_YOUGO_MASTER_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        yougoNaiyou: [],
      };

    case LifeYougoMasterActionTypes.LIFE_YOUGO_EDIT_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = true;
          } else {
            item.editable = false;
          }
          return item;
        }),
      };

    case LifeYougoMasterActionTypes.LIFE_YOUGO_CANCEL_STATE:
      return {
        ...state,
        yougoNaiyou: state.yougoNaiyou.map((item, index) => {
          if (index === action.index) {
            item.editable = false;
          }
          return item;
        }),
      };

    case LifeYougoMasterActionTypes.LOAD_SEIKATU_ADL_MASTER_REQUEST:
      return { ...state };
    case LifeYougoMasterActionTypes.LOAD_SEIKATU_ADL_MASTER_SUCCESS:
      return {
        ...state,
        seikatuKinouAdlMasters: action.seikatuKinouAdlMasters
          ? action.seikatuKinouAdlMasters
          : [],
      };
    case LifeYougoMasterActionTypes.LOAD_SEIKATU_ADL_MASTER_ERROR:
      return {
        ...state,
        seikatuKinouAdlMasters: [],
      };
    default: {
      return {
        ...state,
      };
    }
  }
}

export default lifeYougoMaster;

import React, { Fragment } from "react";
import { InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, Checkbox, Upload, Button } from "antd";

// import { checkFileStyle } from '../../../common/function_common/functionCommon'

import TantoMasterKB from "../model-kiroku/TantoMasterKB";
import CommentKB from "../model-kiroku/CommentKB";
import SelectGazouKubun from "../model-kiroku/SelectGazouKubun";
import TimeKBCus from "../component/TimeKBCus";
import ViewFile from "./ViewFile";

const Dragger = Upload.Dragger;

class NormalGazoForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,

      fileList: [],
      uploading: false,
      imageUrl: "",
    };

    this.onChangeTime = this.onChangeTime.bind(this);
    this.onClickRctf = this.onClickRctf.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);
    this.onChangeKubun = this.onChangeKubun.bind(this);
    this.onPriorityf = this.onPriorityf.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.data !== newProps.data) {
      this.setState({
        reportCaseTyuif: newProps.data.reportCaseTyuif ? true : false,
        fileList: [],
      });

      this.props.form.resetFields();
    }
  }

  onChangeTime(time, timeString) {
    this.props.handleFormInput(timeString, "time");
  }

  onClickRctf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "reportCaseTyuif");
    this.setState({
      reportCaseTyuif: e.target.checked,
    });
  }

  onPriorityf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "priorityf");
    // this.setState({
    //     reportCaseTyuif: e.target.checked
    // })
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "comment");
  }

  onChangeKubun(value) {
    this.props.handleFormInput(value, "kubun");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  handleUpload = () => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    this.setState({
      uploading: true,
    });
  };

  onRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  beforeUpload = (file) => {
    this.props.handleFormInput(file, "file");
  };

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    this.props.handleFormInput(timeString, "time");
  };

  /**
   * getCurrentTime
   *
   */
  handleSetCurrentTime = () => {
    let currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.props.handleFormInput(currentTime, "time");
  };

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      tantoMaster,
      data,
      yougoMasterNa,
      gazouKubun,
      file,
      imageUrl,
      filePreview,
    } = this.props;

    return (
      <Fragment>
        <Form layout="vertical" hideRequiredMark>
          <Row>
            <Col className="kdf-label" span={4}>
              <span>時間</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item className="form-item-300">
                <TimeKBCus
                  timeString={data && data.time ? data.time : null}
                  handleInput={this.onChangeTimeMulModeNew}
                />
                <Button
                  className="kdf-number-k"
                  type="primary"
                  onClick={this.handleSetCurrentTime}
                >
                  現時刻
                </Button>
              </Form.Item>
              <Form.Item className="form-item-100">
                {getFieldDecorator("reportCaseTyuif", {
                  rules: [{ required: false }],
                })(
                  <Checkbox
                    className="kdf-f-extent"
                    checked={data && data.reportCaseTyuif ? true : false}
                    onChange={this.onClickRctf}
                  >
                    申送
                  </Checkbox>
                )}
              </Form.Item>
              <Form.Item className="form-item-100">
                {getFieldDecorator("priorityf", {
                  rules: [{ required: false }],
                })(
                  <Checkbox
                    className="kdf-f-extent"
                    checked={data && data.priorityf ? true : false}
                    onChange={this.onPriorityf}
                  >
                    優先
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="kdf-label" span={4}>
              <span>区分</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item>
                <SelectGazouKubun
                  value={data.kubun}
                  data={gazouKubun}
                  handleChangeSelect={this.onChangeKubun}
                  styleCus="left"
                />
              </Form.Item>
            </Col>
          </Row>
          {!data.fileManagementId ? (
            <Row>
              <Col className="kdf-label" span={4}>
                <span>ファイル</span>
              </Col>
              <Col className="kdf-f-item" span={20}>
                <Row>
                  <Col span={12} style={{ padding: "0 8px 8px 0px" }}>
                    <Dragger
                      onRemove={this.onRemove}
                      beforeUpload={this.beforeUpload}
                      fileList={[]}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        このエリアにファイルをクリックし、ドラッグして、アップロードしてください
                      </p>
                      {/* <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                band files</p> */}
                    </Dragger>
                  </Col>

                  {file && file.name ? (
                    <Col span={12}>
                      <img
                        alt="ファイル"
                        src={imageUrl}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      ></img>
                      <p>{file.name}</p>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col className="kdf-label" span={4}>
              <span>コメント</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item>
                <CommentKB
                  yougoMasterEditState={this.getYougoMasterEditState}
                  yougoMasterEditCancel={this.getYougoMasterEditCancel}
                  yougoMasterEditSave={this.getYougoMasterEditSave}
                  yougoMasterNa={yougoMasterNa}
                  rowNum={4}
                  pstion="topRight"
                  handleTextareaKB={this.onChangeCommentSelect}
                  defaultValue={data.comment}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col className="kdf-label" span={4}>
              <span>担当者</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item>
                <TantoMasterKB
                  tantoMaster={tantoMaster}
                  widthCode={100}
                  widthText={200}
                  value={data}
                  handleTantoMasterKB={this.getHandleTantoMasterKB}
                  styleCus="left"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <ViewFile record={data} filePreview={filePreview} />
        </Row>
      </Fragment>
    );
  }
}

const GazoForm = Form.create()(NormalGazoForm);
export default GazoForm;

/* eslint-disable react/prop-types */
import { Form } from "@ant-design/compatible";
import { EnterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Input, InputNumber, Modal, Popover, Row } from "antd";
import dayjs from "dayjs";
import React, { Component, Fragment } from "react";
import MediaQuery from "react-responsive";
import { handleInputNumber } from "../../../common/function_common/functionCommon";
import JPDateSelectPicker from "./JPDateSelectPicker";
import KohifutanMasterModal from "./KohifutanMasterModal";
import SelectOptionsForm from "./SelectOptionsForm";
import TextAreaSelectNewService from "./TextAreaSelectNewService";
import TextInput from "./TextInput";
import YougoMasterDialog from "./YougoMasterDialog";
import moment from "moment";

class HokenTableForm2Normal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
      visibleNoKeyboard: false,
      keyType: "",
      value: "",
      visibleKohi: false,
      updateVisible: false,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouConcern",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  // 公費モーダル
  handleModalKohi = () => {
    this.setState({
      visibleKohi: true,
    });
  };
  onSelectKohifutan = (record) => {
    const { form } = this.props;
    // マスタ選択時、setFieldで値を変更
    if (record) {
        this.props.getKohiMaster(record.kohiNo, (data) => {
        if (data) {
          let dataK = data;
          if (data && data.length > 1) {
            dataK = data.filter(data => data.kaigof === 1);
          }
          if (dataK && dataK.length > 0) {
            if (dataK[0].jititaif == 0) {
              form.setFieldsValue({
                jititaif1: false,
                jititaif2: false,
              });
            }
            else if (dataK[0].jititaif == 1) {
              form.setFieldsValue({
                jititaif1: true,
                jititaif2: false,
              });
            }
            else if (dataK[0].jititaif == 2) {
              form.setFieldsValue({
                jititaif1: false,
                jititaif2: true,
              });
            }
            form.setFieldsValue({
              serviceSyurui: dataK[0] && dataK[0]?.id ? dataK[0].serviceSyurui : "",
              kyufu: dataK[0] && dataK[0]?.id ? dataK[0].kyufu : null,
              futansyaName: record.futanName,
              futansyaNo: record.futanNo,
              kohiNo: record?.kohiNo ? record.kohiNo : "",
            });
          }
        }
      });
    }

    this.setState({
      visibleKohi: false,
    });
  };
  onHideKohifutan = () => {
    this.setState({
      visibleKohi: false,
    });
  };

  /**
   * Update yougoMaster
   *
   */
  yougoMasterUpdate = (key, naiyou) => {
    this.props.yougoMasterUpdate(key, naiyou);
  };

  // inputクリック時(テンキー呼び出し)
  keyInput = (type) => {
    this.setState({
      value: null, // 値
      visibleNoKeyboard: true,
      keyType: type,
    });
  };
  // テンキー入力
  handleInput(value) {
    const { keyType } = this.state;
    const { form } = this.props;

    value = value ? value : "0";
    const checkValue = handleInputNumber(value, this.state.value, true);
    // (旧)ここで親に値を渡して、Object.assignでDataを複製してここに渡している
    // this.props.handleFormInput(checkValue, this.state.keyType)
    // 直接fieldを変更
    form.setFieldsValue({
      [keyType]: checkValue,
    });

    this.setState({
      value: checkValue, // ステートに保存して数字を重ねる
    });
  }
  // テンキーvisible
  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };
  // テンキーエンター
  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  // OKクリック時
  formHandleInput = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      // 警告 コメントアウト
      // if(!values.futansyaNo || values.futansyaNo == "0"
      //     || !values.jukyusyaNo || values.jukyusyaNo == "0"
      // ) {
      //     this.setState({
      //         updateVisible: true
      //     })
      // } else {
      this.props.update(values);
      // }
    });
  };
  // ok
  formCloseInput = () => {
    this.setState({
      updateVisible: false,
    });
    this.props.closeInput();
  };
  // modal閉じるok
  formUpdateCancel = () => {
    this.setState({
      updateVisible: false,
    });
  };
  // 強行保存ok
  formUpdateOk = () => {
    this.props.updateOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  render() {
    const { visible, keyType, visibleNoKeyboard } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayoutCus = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            {/* <Button onClick={() => this.handleInput('.')}>.</Button> */}
            <Button disabled>-</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        // {...formItemLayout}
        labelAlign="left"
      >
        <Row className="title-inline">
          {/* hidden field place */}
          <Col span={24}>
            <Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("id", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            {/*<Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("kohiNo", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>*/}
            <Form.Item
              label={"利用者"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("riyousyaId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            {/* <Form.Item label={"mainSID"} colon={false} {...formItemLayoutCus} style={{ display: "none" }}>
                            {getFieldDecorator('serviceKikanMasterId', {
                                rules: [{ required: false, message: "必須項目をセットして下さい" }],
                            })(<Input />)}
                        </Form.Item> */}
          </Col>
        </Row>
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">公費負担者番号</span><span style={{ fontSize: 12, color: "red" }}>（8桁）</span>
          </Col>
          <Col span={3}>
            {getFieldDecorator("futansyaNo", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(<Input style={{ width: 88 }} />)}
          </Col>
          <Col span={13} style={{ display: "flex", marginLeft: -25 }}>
            <span className="text-area-select-btn">
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={this.handleModalKohi}
              >
                M
              </Button>
            </span>
            {getFieldDecorator("kohiNo", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <TextInput
                rows={1}
                className="text-area-select-text"
                readOnly
                style={{ width: 60 }}
              />
            )}
            {getFieldDecorator("futansyaName", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <TextInput
                rows={1}
                className="text-area-select-text"
                readOnly
                style={{ width: 370 }}
              />
            )}
          </Col>
          <Col span={4} style={{ marginLeft: 20 }}>
            {getFieldDecorator("tukiKohitanf", {
              rules: [{ required: false }],
              valuePropName: "checked",
            })(
              <Checkbox style={{ marginTop: 5 }}>
                <span className="title">月途中単独</span>
              </Checkbox>
            )}
          </Col>
        </Row>
        <Row className="title-inline" style={{ marginTop: 10 }}>
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">受給者番号</span><span style={{ fontSize: 12, color: "red" }}>（7桁）</span>
          </Col>
          <MediaQuery query="(min-device-width: 850px)">
            {(matches) =>
              matches ? (
                <Col span={5}>
                  {getFieldDecorator("jukyusyaNo", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(<Input style={{ width: 88 }} />)}
                </Col>
              ) : (
                <Col span={5}>
                  {getFieldDecorator("jukyusyaNo", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(<Input style={{ width: 88 }} />)}
                  <Popover
                    open={
                      !!(this.state.visibleNoKeyboard && keyType == "jukyusyaNo")
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onOpenChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.keyInput("jukyusyaNo")}
                    >
                      入力
                    </Button>
                  </Popover>
                </Col>
              )
            }
          </MediaQuery>

          <MediaQuery query="(min-device-width: 850px)">
            {(matches) =>
              matches ? (
                <Fragment>
                  <Col span={2} style={{ marginTop: 5 }}>
                    <span className="title-red">給付率</span>
                  </Col>
                  <Col span={4} style={{ marginLeft: -20 }}>
                    {getFieldDecorator("kyufu", {
                      rules: [
                        {
                          required: false,
                          message: "必須項目をセットして下さい",
                        },
                      ],
                    })(
                      <InputNumber
                        min={0}
                        max={100}
                      // formatter={value => `${value}%`}
                      // parser={value => value.replace('%', '')}
                      />
                    )}
                    %
                  </Col>
                </Fragment>
              ) : (
                <Fragment>
                  <Col span={2} style={{ marginTop: 5 }}>
                    <span className="title-red">給付率</span>
                  </Col>
                  <Col span={4} style={{ marginLeft: -20 }}>
                    {getFieldDecorator("kyufu", {
                      rules: [
                        {
                          required: false,
                          message: "必須項目をセットして下さい",
                        },
                      ],
                    })(
                      <InputNumber
                        min={0}
                        max={100}
                        // formatter={value => `${value}%`}
                        // parser={value => value.replace('%', '')}
                        readOnly
                        onClick={() => this.keyInput("kyufu")}
                      />
                    )}
                    <Popover
                      open={
                        !!(visibleNoKeyboard && keyType == "kyufu")
                      }
                      placement="bottomRight"
                      content={numberKB}
                      trigger="click"
                      onOpenChange={this.onVisibleChangePopover}
                    >
                      <span>%</span>
                    </Popover>
                  </Col>
                </Fragment>
              )
            }
          </MediaQuery>
          <Col span={2} style={{ marginTop: 5 }}>
            <span className="title">負担率</span>
          </Col>
          <Col span={4} style={{ marginLeft: -20 }}>
            {getFieldDecorator("iryouFutanwari", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(<SelectOptionsForm data={[1, 2, 3]} wari={true} style={{ width: '80px' }}/>)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">取得日</span>
          </Col>
          <Col span={10}>
            {getFieldDecorator("syutokuDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<JPDateSelectPicker />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">有効期限</span>
          </Col>
          <Col span={12}>
            {getFieldDecorator("yukoDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<JPDateSelectPicker />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 36 }}>
            <span className="title">本人負担額</span>
          </Col>
          <Col span={15}>
            <MediaQuery query="(min-device-width: 850px)">
              {(matches) =>
                matches ? (
                  <Row>
                    <Col span={3} style={{ marginTop: 5 }}>
                      <span className="title">介護保険</span>
                    </Col>
                    <Col span={10}>
                      {getFieldDecorator("futangaku", {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: 120 }}
                          className="input-number"
                        />
                      )}{" "}
                      円
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={3} style={{ marginTop: 5 }}>
                      <span className="title">介護保険</span>
                    </Col>
                    <Col span={10}>
                      {getFieldDecorator("futangaku", {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <Input
                          style={{ width: 120, textAlign: "right" }}
                          readOnly
                          onClick={() => this.keyInput("futangaku")}
                        />
                      )}
                      <Popover
                        open={
                          !!(visibleNoKeyboard && keyType == "futangaku")
                        }
                        placement="bottomRight"
                        content={numberKB}
                        trigger="click"
                        onOpenChange={this.onVisibleChangePopover}
                      >
                        <span>円</span>
                      </Popover>
                    </Col>
                  </Row>
                )
              }
            </MediaQuery>
            <MediaQuery query="(min-device-width: 850px)">
              {(matches) =>
                matches ? (
                  <Row>
                    <Col span={3} style={{ marginTop: 5 }}>
                      <span className="title">医療保険</span>
                    </Col>
                    <Col span={10}>
                      {getFieldDecorator("iryouFutangaku", {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: 120, textAlign: "right" }}
                          className="input-number"
                        />
                      )}{" "}
                      円
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={3} style={{ marginTop: 5 }}>
                      <span className="title">医療保険</span>
                    </Col>
                    <Col span={10}>
                      {getFieldDecorator("iryouFutangaku", {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <Input
                          style={{ width: 120, textAlign: "right" }}
                          readOnly
                          onClick={() => this.keyInput("iryouFutangaku")}
                        />
                      )}
                      <Popover
                        open={
                          !!(visibleNoKeyboard && keyType == "iryouFutangaku")
                        }
                        placement="bottomRight"
                        content={numberKB}
                        trigger="click"
                        onOpenChange={this.onVisibleChangePopover}
                      >
                        <span>円</span>
                      </Popover>
                    </Col>
                  </Row>
                )
              }
            </MediaQuery>
            <MediaQuery query="(min-device-width: 850px)">
              {(matches) =>
                matches ? (
                  <Row>
                    <Col span={3} style={{ marginTop: 5 }}>
                      <span className="title">食費</span>
                    </Col>
                    <Col span={10}>
                      {getFieldDecorator("syokuhiFutangaku", {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <InputNumber
                          style={{ width: 120 }}
                          className="input-number"
                        />
                      )}{" "}
                      円
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={3} style={{ marginTop: 5 }}>
                      <span className="title">食費</span>
                    </Col>
                    <Col span={10}>
                      {getFieldDecorator("syokuhiFutangaku", {
                        rules: [
                          {
                            required: false,
                            message: "必須項目をセットして下さい",
                          },
                        ],
                      })(
                        <Input
                          style={{ width: 120, textAlign: "right" }}
                          readOnly
                          onClick={() => this.keyInput("syokuhiFutangaku")}
                        />
                      )}
                      <Popover
                        open={
                          !!(visibleNoKeyboard && keyType == "syokuhiFutangaku")
                        }
                        placement="bottomRight"
                        content={numberKB}
                        trigger="click"
                        onOpenChange={this.onVisibleChangePopover}
                      >
                        <span>円</span>
                      </Popover>
                    </Col>
                  </Row>
                )
              }
            </MediaQuery>
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 8 }}>
            <span className="title">該当サービス</span>
            <span style={{ fontSize: 12 }}>(介護保険)</span>
          </Col>
          <Col span={20}>
            {getFieldDecorator("serviceSyurui", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(<TextAreaSelectNewService />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 3 }}>
            <span className="title">自治体公費</span>
          </Col>
          <Col span={4} style={{ marginLeft: -5 }}>
            {getFieldDecorator("jititaif1", {
              rules: [{ required: false }],
              valuePropName: "checked",
            })(<Checkbox style={{ marginTop: 2 }}>自治体1</Checkbox>)}
          </Col>
          <Col span={5}>
            {getFieldDecorator("jititaif2", {
              rules: [{ required: false }],
              valuePropName: "checked",
            })(<Checkbox style={{ marginTop: 2 }}>自治体2</Checkbox>)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4}>
            <div style={{ marginTop: 5 }}>
              <span className="title">障害名称</span>
            </div>
            <div style={{ marginTop: 10 }}>
              <span className="title">種別</span>
            </div>
            <div style={{ marginTop: 10 }}>
              <span className="title">等級</span>
            </div>
          </Col>
          <Col span={10}>
            <div>
              {getFieldDecorator("syogaimei", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input style={{ width: 150 }} />)}
            </div>
            <div>
              {getFieldDecorator("syubetu", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input style={{ width: 150 }} />)}
            </div>
            <div>
              {getFieldDecorator("tokyu", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input style={{ width: 150 }} />)}
            </div>
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">備考</span>
          </Col>
          <Col span={20}>
            {getFieldDecorator("bikou", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(<YougoMasterDialog />)}
          </Col>
        </Row>
        <Row style={{ justifyContent: "flex-end", marginTop: 20 }}>
          <Popover
            open={this.state.updateVisible}
            title={false}
            content={
              <div style={{ overflow: "hidden" }}>
                <p>必須項目が未入力ですがそのまま登録しますか？</p>
                <div style={{ float: "right" }}>
                  <Button
                    key="update"
                    type={"primary"}
                    onClick={() => this.formUpdateOk()}
                    style={{ marginRight: 5, marginTop: 20 }}
                  >
                    OK
                  </Button>
                  <Button
                    key="cancelUpdate"
                    onClick={() => this.formUpdateCancel()}
                  >
                    キャンセル
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              key="input"
              type={"primary"}
              onClick={() => this.formHandleInput()}
            >
              OK
            </Button>
          </Popover>
          <Button
            key="cancelInput"
            style={{ marginLeft: 8 }}
            onClick={() => this.formCloseInput()}
          >
            キャンセル
          </Button>
        </Row>
        <KohifutanMasterModal
          visible={this.state.visibleKohi}
          onSelect={this.onSelectKohifutan}
          onHide={this.onHideKohifutan}
        />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const HokenTableForm2 = Form.create({
  name: "input-riyou-syokujisen",
  onValuesChange(props, changedFields) {
    const { form } = props;

    if (changedFields && changedFields.jititaif1) {
      props.form.setFieldsValue({
        jititaif2: false,
      });
    }
    else if (changedFields && changedFields.jititaif2) {
      props.form.setFieldsValue({
        jititaif1: false,
      });
    }

    // 手入力の自動変更（公費負担番号）
    if (changedFields.futansyaNo && !changedFields.kohiNo) {
      if (String(changedFields.futansyaNo).length >= 2) {
        // 上2桁をkohiNoにセット
        const tmp = String(changedFields.futansyaNo).slice(0, 2);
        form.setFieldsValue({
          kohiNo: tmp ? tmp : "",
        });
      }
      // 番号で公費負担マスタ検索し、公費負担者名をセット
      props.getKohifutanMaster(changedFields.futansyaNo, (data) => {
        // ヒットすればマスタの情報を送る
        if (data) {
          form.setFieldsValue({
            futansyaName: data?.futanName ? data.futanName : "",
            kohiNo: data?.kohiNo ? data.kohiNo : "", // 空欄がいやなら下のやつ使う
            // kohiNo: data?.kohiNo ? data.kohiNo : String(changedFields.futansyaNo).slice(0, 2),
          });
        }
        else {
          form.setFieldsValue({
            futansyaName: "",
          });
        }
      });
    }
    else if (changedFields.futansyaNo == "") {
      form.setFieldsValue({
        kohiNo: "",
      });
    }

    // 負担者名が変更&&マスタ選択時、公費負担マスタにヒットさせる
    if (changedFields?.futansyaName && changedFields?.kohiNo) {
      // セット後に公費マスタを検索し、該当サービス,自治体公費をセット
      props.getKohiMaster(changedFields.kohiNo, (data) => {
        if (data) {
          let dataK = data;
          if (data && data.length > 1) {
            dataK = data.filter(data => data.kaigof === 1);
          }
          if (dataK && dataK.length > 0) {
            if (dataK[0].jititaif == 0) {
              form.setFieldsValue({
                jititaif1: false,
                jititaif2: false,
              });
            }
            else if (dataK[0].jititaif == 1) {
              form.setFieldsValue({
                jititaif1: true,
                jititaif2: false,
              });
            }
            else if (dataK[0].jititaif == 2) {
              form.setFieldsValue({
                jititaif1: false,
                jititaif2: true,
              });
            }
            form.setFieldsValue({
              serviceSyurui: dataK[0] && dataK[0]?.id ? dataK[0].serviceSyurui : "",
              kyufu: dataK[0] && dataK[0]?.id ? dataK[0].kyufu : null,
            });
          }
        }
      });
    }
  },
  mapPropsToFields(props) {
    // let date = new Date();
    // if (props.data && props.data.date) {
    //   let propsDate = new Date(props.data.date);
    //   date = propsDate;
    // }

    // let serviceKikanMasterId = StorageUtils.getValue(getMenu()).si;

    return {
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : props.riyousyaId,
      }),
      // serviceKikanMasterId: Form.createFormField({
      //     value: props.data && props.data.serviceKikanMasterId ? props.data.serviceKikanMasterId : serviceKikanMasterId
      // }),
      kohiNo: Form.createFormField({
        value: props.data && props.data.kohiNo ? props.data.kohiNo : "",
      }),
      futansyaNo: Form.createFormField({
        value: props.data && props.data.futansyaNo ? props.data.futansyaNo : "",
      }),

      futansyaName: Form.createFormField({
        value: props?.futanMasterData ? props.futanMasterData : "",
      }),
      jukyusyaNo: Form.createFormField({
        value: props.data && props.data.jukyusyaNo ? props.data.jukyusyaNo : "",
      }),

      syutokuDate: Form.createFormField({
        value:
          props.data && props.data.syutokuDate
            ? moment(props.data.syutokuDate)
            : // : moment(new Date())
            moment("0001-01-01"),
      }),

      yukoDate: Form.createFormField({
        value:
          props.data && props.data.yukoDate
            ? moment(props.data.yukoDate)
            : // : moment(new Date())
            moment("0001-01-01"),
      }),

      kyufu: Form.createFormField({
        value: props.data && props.data.kyufu ? props.data.kyufu : 0,
      }),

      futangaku: Form.createFormField({
        value: props.data && props.data.futangaku ? props.data.futangaku : 0,
      }),

      iryouFutangaku: Form.createFormField({
        value: props.data && props.data.iryouFutangaku ? props.data.iryouFutangaku : 0,
      }),

      syokuhiFutangaku: Form.createFormField({
        value: props.data && props.data.syokuhiFutangaku ? props.data.syokuhiFutangaku : 0,
      }),

      serviceSyurui: Form.createFormField({
        value: props.data && props.data.serviceSyurui ? props.data.serviceSyurui : "",
      }),

      jititaif1: Form.createFormField({
        value: !!(props.data && props.data.jititaif === 1),
      }),

      jititaif2: Form.createFormField({
        value: !!(props.data && props.data.jititaif === 2),
      }),

      tukiKohitanf: Form.createFormField({
        value: props.data ? props.data.tukiKohitanf : "",
      }),

      iryouFutanwari: Form.createFormField({
        value:
          props.data && props.data.iryouFutanwari != 0
            ? props.data.iryouFutanwari
            : "",
      }),

      syogaimei: Form.createFormField({
        value: props.data && props.data.syogaimei ? props.data.syogaimei : "",
      }),

      syubetu: Form.createFormField({
        value: props.data && props.data.syubetu ? props.data.syubetu : "",
      }),

      tokyu: Form.createFormField({
        value: props.data && props.data.tokyu ? props.data.tokyu : "",
      }),

      bikou: Form.createFormField({
        value: props.data && props.data.bikou ? props.data.bikou : "",
      }),
    };
  },
})(HokenTableForm2Normal);

/* eslint-disable indent */
import {
  EditFilled, EnvironmentOutlined, FolderOutlined, HeartOutlined, UndoOutlined,
} from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import { getPath } from "../../common/function_common/functionCommon";
import "./RiyousyaDetail.css";
import DetailRiyousya from "./UI/DetailRiyousya";
import GoogleMap from "./UI/GoogleMap";

class RiyousyaDetail extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;
    const query = new URLSearchParams(this.props.location.search);

    this.state = {
      riyousyaId: match.params.riyousyaId,
      url: match.url,
      backLink: query.get("backLink"),
      visibleMap: false,
      address: "",
      titleMap: "",
      facesheetId: 0,
    };
  }

  /**
   *
   */
  componentDidMount() {
    const { riyousyaId } = this.state;
    this.props.getRiyousya(riyousyaId);

    // get TantoMaster
    this.props.tantoMasterList2();

    // loading setting
    this.props.getSettingALL("", "");

    // get kihon_adl_master
    this.props.getKihonAdlAll();
  }

  /**
   *
   */
  componentDidUpdate() { }

  displayMap = () => {
    const { riyousyaDetail } = this.props;

    let address = "";
    if (riyousyaDetail) {
      if (riyousyaDetail.sonotaAddress) {
        address = riyousyaDetail.sonotaAddress;
      }
      else if (riyousyaDetail.address1 || riyousyaDetail.address2) {
        address = riyousyaDetail.address1 + riyousyaDetail.address2;
      }
    }
    this.setState({
      visibleMap: true,
      address,
      titleMap: riyousyaDetail.name,
    });
  };

  closeDrawer = () => {
    this.setState({
      visibleMap: false,
    });
  };

  handleChangeFacesheetId = (id) => {
    this.setState({
      facesheetId: id,
    });
  };

  handleCopyFacesheet = (id) => {
    const { riyousyaId } = this.state;
    const path = getPath();
    this.setState({
      facesheetId: id,
    });

    this.props.history.push(
      `${path}/riyousya-update/${riyousyaId}?faceId=${id}&type=copy`
    );
  };

  handleGoBack = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  /**
   *
   */
  render() {
    const {
      sessionTimeout,
      riyousyaDetail,
      loading,
      bunrui_is_dis,
      bunrui_title,
      systemBunrui_is_dis,
      systemBunrui_title,
      tekiyou_is_dis,
      tekiyou_title,
      fax_title,
      setFSOtherTitle,
      setFSSonotaTitle,
      fsSonotaTitleDis,
      system_setFSSonotaTitle,
      system_fsSonotaTitleDis,
      kihonAdl,
      tantoMastersList,
      changeFacesheetState,
      saveFacesheetState,
      openFacesheetState,
    } = this.props;
    const { riyousyaId, facesheetId, visibleMap, address, titleMap } =
      this.state;
    const path = getPath();
    let menuInfo = null;

    if (path == "/management") {
      menuInfo = JSON.parse(localStorage.getItem("menuMNGT"));
    }
    else if (path == "/life") {
      menuInfo = JSON.parse(localStorage.getItem("SWLIFE_menu"));
    }
    else {
      menuInfo = JSON.parse(localStorage.getItem("menu"));
    }

    return (
      (<CommonPageContainer
        id="riyousya-detail-page"
        sessionTimeout={sessionTimeout}
      >
        <div className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={`${riyousyaDetail ? riyousyaDetail.name : ""}さん利用者情報`}
                        iconType="user"
                      />
                    }
                    TopRight={
                      <div className="submenu-t-r">
                        {/* <Spin spinning={loadingAll}>//右上アイコン待機 */}
                        <Link
                          to={"/"}
                          onClick={this.handleGoBack}
                          className="sub-top-right-item"
                        >
                          <span>
                            <UndoOutlined />
                          </span>
                          <span> 戻る</span>
                        </Link>

                        <Link
                          to={`${path}/riyousya-shiryou/${riyousyaId}`}
                          className="sub-top-right-item"
                        >
                          <span>
                            <FolderOutlined />
                          </span>
                          <span> 資料</span>
                        </Link>

                        <Link
                          to={`${path}/riyousya-update/${riyousyaId}?faceId=${facesheetId}`}
                          className="sub-top-right-item"
                        >
                          <span>
                            <EditFilled />
                          </span>
                          <span> 編集</span>
                        </Link>

                        {menuInfo &&
                          menuInfo.usageAuth &&
                          menuInfo.usageAuth.includes("hoken") ? (
                          <Link
                            to={`${path}/riyousya-hoken/${riyousyaId}`}
                            className="sub-top-right-item"
                          >
                            <span>
                              <HeartOutlined />
                            </span>
                            <span> 保険</span>
                          </Link>
                        ) : null}

                        <a
                          onClick={() => this.displayMap()}
                          className="sub-top-right-item"
                        >
                          <span>
                            <EnvironmentOutlined />
                          </span>
                          <span>地図</span>
                        </a>
                        {/* </Spin> */}
                      </div>
                    }
                  />
                );
              }
              /**
                 * @TODO design UI for mobile
                 *
                 * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                 */
              return null;

            }}
          </MediaQuery>
        </div>
        <div>
          <DetailRiyousya
            riyousya={riyousyaDetail}
            loading={loading}
            tantoMastersList={tantoMastersList}
            bunruiIsDis={bunrui_is_dis}
            bunruiTitle={bunrui_title}
            systemBunruiIsDis={systemBunrui_is_dis}
            systemBunruiTitle={systemBunrui_title}
            tekiyouIsDis={tekiyou_is_dis}
            tekiyouTitle={tekiyou_title}
            faxTitle={fax_title}
            setFSOtherTitle={setFSOtherTitle}
            setFSSonotaTitle={setFSSonotaTitle}
            fsSonotaTitleDis={fsSonotaTitleDis}
            system_setFSSonotaTitle={system_setFSSonotaTitle}
            system_fsSonotaTitleDis={system_fsSonotaTitleDis}
            facesheetId={facesheetId}
            handleChangeFacesheetId={this.handleChangeFacesheetId}
            handleCopyFacesheet={this.handleCopyFacesheet}
            kihonAdl={kihonAdl}
            changeFacesheetState={changeFacesheetState}
            saveFacesheetState={saveFacesheetState}
            openFacesheetState={openFacesheetState}
            changeFacesheet={this.props.changefacesheet}
          />
        </div>
        <Drawer
          destroyOnClose
          title={
            <div rootClassName="google-map-drawer-title">
              <p>
                <span>{titleMap + "さんの住所"}</span>
                <span style={{float: 'right' }}>
                  <Button onClick={this.closeDrawer}>閉じる</Button>
                </span>
              </p>
              <p>
                <span>{address}</span>
                <a
                  href={`https://www.google.co.jp/maps/place/${address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  大きい地図で見る
                </a>
              </p>
            </div>
          }
          width={"100%"}
          onClose={this.closeDrawer}
          open={visibleMap}
          closable={false}
          rootClassName="google-map-drawer"
        >
          <GoogleMap address={address} />
        </Drawer>
      </CommonPageContainer>)
    );
  }
}

export default RiyousyaDetail;

/* eslint-disable indent */

import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";


// function getFocus(name) {
//   const pathname = window.location.pathname;
//   if (pathname.indexOf(name) !== -1) {
//     return "active";
//   }
//   return null;
// }

function MenuTop(props) {
  // const menuKiroku = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
  // const isButton = !!(
  //   props.history && (props.history.location.pathname == "/life/list" || props.history.location.pathname == "/life/rehabili")
  // );

  const isButton = true;

  function handleRedirect() {
    props.history.push(props.redirectTo);
  }

  // const menu = useMemo(() => {
  //   const documents = (
  //     <Fragment>
  //       <Link to={"/life/list"}>
  //         <div className={`${getFocus("documents")} link-item`}>
  //           <FileTextOutlined />
  //           <p>LIFE</p>
  //         </div>
  //       </Link>
  //       <Link to={"/life/rehabili"}>
  //         <div className={`${getFocus("documents")} link-item`}>
  //           <FileTextOutlined />
  //           <p>リハ</p>
  //         </div>
  //       </Link>
  //     </Fragment>);
  //   return documents;
  // }, [props.config]);

  return (
    <div
      className={`${props.isDengon ? "sub-menu-top-isdengon" : "sub-menu-top"}`}
    >
      <div className="m-left" style={{ display: "flex", alignItems: "center" }}>
        <div className="text-tool-bar">
          {/* <span style={{ fontSize: "20px", color: "white" }}>
            <Icon type={props.iconType} theme="outlined" /> {props.title}
          </span>
          <span style={{ fontSize: "25px", color: "white" }}>
            {props.content}
          </span> */}
          <div style={{ display: "flex" }}>
            {
              props.title
                ?
                <span style={{ display: "block", fontWeight: "bold", marginBottom: "8px" }}>
                  <h3 style={{ color: "white" }}>{"【" + props.title + "】"}</h3>
                </span>
                :
                null
            }

            {
              props.count && props.title != "科学的介護一覧" ?
                <span style={{ display: "block", fontSize: "15px", color: "white", marginLeft: 20 }} >{`${props.countNum}件表示中`}</span>
                :
                null
            }
          </div>
          <span style={{ display: "block", color: "white" }}>
            {props.riyousya ? "利用者 " : ""}
            <span
              style={{
                fontSize: "1.2em",
                fontWeight: "bold",
                paddingLeft: "10px",
              }}
            >
              {props.riyousya ? props.riyousya.name : ""}
            </span>
            {
              props.riyouAndKeikabtn
                ?
                props.riyouAndKeikabtn
                :
                null
            }
          </span>
        </div>
        {/* <div style={{ marginLeft: "20px" }}>
          {isButton ? (
            <Button
              type="primary"
              onClick={handleRedirect}
              className="button-link-monitoring"
              style={{backgroundColor: "#e46c0a"}}
            >
              モニタリング管理
            </Button>
          ) : null}
        </div> */}
      </div>
      <div className="m-right">
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={"#"}>
            <div className={`${getFocus("dengon")} link-item`}>
              <Icon type="notification" theme="outlined" />
              <p>伝言</p>
            </div>
          </Link>
          <Link to={"#"}>
            <div className={`${getFocus("progress")} link-item`}>
              <Icon type="message" theme={"outlined"} twoToneColor="red" />
              <p>申送</p>
            </div>
          </Link>
          <Link to={"#"}>
            <div className={`${getFocus("riyousya")} link-item`}>
              <Icon type="user" theme="outlined" />
              <p>一覧</p>
            </div>
          </Link>
          {menu}
        </div> */}
        <div style={{ marginLeft: "20px" }}>
          {isButton && props.redirectText ? (
            <Button
              type="primary"
              onClick={handleRedirect}
              className="button-link-monitoring"
              style={{ backgroundColor: "#e46c0a", border: "none", padding: "0 4px" }}
            >
              {props.redirectText}
            </Button>
          ) : null}
        </div>
      </div>
      {
        props.leftMenu
          ?
          props.leftMenu
          :
          null
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export default connect(mapStateToProps, null)(MenuTop);

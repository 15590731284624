import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Row, Col, Button, InputNumber, Popover } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'
import { handleInputNumber } from "../../../common/function_common/functionCommon";
import { ConstSet } from "../../../common/configs/constset";

import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
// import HaisetuKubunKB from '../../../common/model-kiroku/HaisetuKubunKB'
import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";
import HaisetufKB from "../../../common/model-kiroku/HaisetufKB";

// import moment from 'moment'

class NormalFormHainyou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      type: null,
      extendValue: false,
    };

    this.onChangeHaisetuKubun = this.onChangeHaisetuKubun.bind(this);
    this.onChangeHaisetuF = this.onChangeHaisetuF.bind(this);
    this.onChangeShurui = this.onChangeShurui.bind(this);
    this.onChangeJoutai = this.onChangeJoutai.bind(this);
    this.onChangeVol = this.onChangeVol.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    this.handleType = this.handleType.bind(this);
  }

  componentDidUpdate(newProps) {
    // const oldProps = this.props

    //@TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeHaisetuKubun(value) {
    this.props.handleFormInput(value, "hainyou", "haisetuKubun");
  }

  onChangeHaisetuF(value) {
    this.props.handleFormInput(value, "hainyou", "haisetuF");
  }

  onChangeShurui(value) {
    this.props.handleFormInput(value, "hainyou", "shurui");
  }

  onChangeJoutai(value) {
    this.props.handleFormInput(value, "hainyou", "joutai");
  }

  onChangeVol(value) {
    this.props.handleFormInput(value, "hainyou", "vol");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "hainyou", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "hainyou", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "hainyou", "tantoId");
  }

  handleInput(value) {
    let checkValue = handleInputNumber(value, this.state.value);

    this.props.handleFormInput(checkValue, "hainyou", this.state.type);
    this.setState({
      value: checkValue,
    });
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 4);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 4);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 4);
  }

  handleType(type) {
    this.setState({
      type: type,
      value: null,
    });
  }

  /**
   *
   * get syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   */
  syokichiItem = () => {
    let kubun = ConstSet.HAISETU_SYOKICHI_NAIYOU;
    this.props.syokichiItem(kubun);
  };

  /**
   * update syokichi item
   *
   * @param {*} riyousyaId
   * @param {*} kubun
   * @param {*} syokichi
   */
  syokichiUpdate = () => {
    let kubun = ConstSet.HAISETU_SYOKICHI_NAIYOU;
    this.props.syokichiUpdate(kubun);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      tantoMaster,
      data,
      yougoMasterNa,
      haisetuType,
      haisetuState,
      disabled,
    } = this.props;

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button onClick={() => this.handleInput(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
        </Col>
      </Row>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    const formItemLayoutSmall = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        // onSubmit={this.handleSubmit}
      >
        {/* <Form.Item label="排泄: ">
                    <HaisetuKubunKB
                        value={data.haisetuKubun}
                        handleHaisetuKubun={this.onChangeHaisetuKubun}
                        styleCus="left"
                    />
                </Form.Item> */}

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>有無 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <HaisetufKB
                value={data.haisetuF}
                handleHaisetuf={this.onChangeHaisetuF}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>種類 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <InputSelectKB
                value={data.shurui}
                handleInputSelect={this.onChangeShurui}
                data={haisetuType}
                disabled={disabled}
                syokichiItem={this.syokichiItem}
                syokichiUpdate={this.syokichiUpdate}
                typeSyokichi={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>量 :</div>
          </Col>

          <Col sm={18} md={11}>
            <Form.Item {...formItemLayoutSmall}>
              {getFieldDecorator("vol", {
                rules: [{ required: false }],
                initialValue: data.vol,
              })(
                <InputNumber
                  disabled={disabled}
                  min={0}
                  onChange={this.onChangeVol}
                />
              )}
              {disabled ? null : (
                <Popover
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                >
                  <Button onClick={() => this.handleType("vol")} type="primary">
                    入力
                  </Button>
                </Popover>
              )}
            </Form.Item>
          </Col>
          <Col sm={24} md={3} className="zenkai-value"></Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>状態 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <InputSelectKB
                value={data.joutai}
                handleInputSelect={this.onChangeJoutai}
                data={haisetuState}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormHainyou = Form.create()(NormalFormHainyou);
export default FormHainyou;

/* eslint-disable no-nested-ternary */
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  CheckCircleTwoTone,
  MinusOutlined,
  PlusOutlined,
  StarFilled,
} from "@ant-design/icons";

import { Button, Drawer, Modal, Popconfirm, Spin, Table, Tooltip } from "antd";
import React from "react";
import { findDOMNode } from "react-dom";

import MediaQuery from "react-responsive";
// import PDFViewer from '../../../common/PDFViewer/PDFViewer'
// import PDFJSBackend from '../../../backends/pdfjs'
import { ConstSet } from "../../../common/configs/constset";
import GazoForm from "../../../common/form-kiroku/GazoForm";
import PopupUploadFile from "../../../common/form-kiroku/PopupUploadFile";
import {
  checkFileStyle,
  checkIconUrl,
  checkTableField,
  gazouKubunExtract,
  getBase64,
  getMenu,
  getValueIskeep,
  getValueLocalstorage,
  openNotificationWithIcon,
  urlTobase64,
} from "../../../common/function_common/functionCommon";
import CheckKB from "../../../common/model-kiroku/CheckKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";
import SelectGazouKubun from "../../../common/model-kiroku/SelectGazouKubun";
import TantoMasterKB2 from "../../../common/model-kiroku/TantoMasterKB2";
import TimeKB from "../../../common/model-kiroku/TimeKB";
import TableRowButton from "./TableRowButton";

const FileSaver = require("file-saver");

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };

export default class Gazou extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Table Setting
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      rowActive: null,

      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      // edit add function
      inputMode: this.props.inputMode,
      showDrawer: false,
      visibleMInput: false,
      modalType: null,

      riyousya: null,
      record: null,
      time: null,
      indexRecord: 0,

      field: "",
      isZendkaiDis: false,
      suggestValues: "",

      inputType: null,
      inputValue: null,
      inputField: null,

      mulInputData: {
        fileManagementIds: null,
        time: null,
        stapleIntake: null,
        sideIntake: null,
        shokuNasi: null,
        comment: null,
        tantoId: null,
      },
      mulInputCheckbox: [false, false, false, false, false, false],
      mulInputNotification: "",
      delIds: [],
      isConfirmMulDel: false,

      formData: null,
      isFormUpdate: false,

      file: null,
      imageUrl: null,

      // handle for active OK SAVE Button when user start input value
      okSaveButton: true,

      showPopupUploadFile: false,
    };

    // bind
    this.handleClickRow = this.handleClickRow.bind(this);
    this.myViewer = React.createRef();

    // bind
    this.handleClickRow = this.handleClickRow.bind(this);

    // Copy, Del local, Del Server record
    this.handleCopyGazo = this.handleCopyGazo.bind(this);
    this.handleDelGazo = this.handleDelGazo.bind(this);
    this.handleServerDel = this.handleServerDel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.handleMInput = this.handleMInput.bind(this);
    this.handleMInputCancel = this.handleMInputCancel.bind(this);

    /**
     * Add, Edit by Modal
     */
    this.preInput = this.preInput.bind(this);
    this.upperInput = this.upperInput.bind(this);
    this.lowerInput = this.lowerInput.bind(this);
    this.nextInput = this.nextInput.bind(this);

    this.getHandleHourInput = this.getHandleHourInput.bind(this);
    this.getHandleMinuteLeftInput = this.getHandleMinuteLeftInput.bind(this);
    this.getHandleMinuteInput = this.getHandleMinuteInput.bind(this);
    this.getHandleReportCaseTyuif = this.getHandleReportCaseTyuif.bind(this);
    this.getHandlePriority = this.getHandlePriority.bind(this);
    this.getHandleKubun = this.getHandleKubun.bind(this);

    this.getHandleInputComment = this.getHandleInputComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);

    this.handleOkUpdate = this.handleOkUpdate.bind(this);
    // END

    // add, edit Setting Comment
    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    //
    this.OpenIsDisplayName = this.OpenIsDisplayName.bind(this);
    this.CloseIsDisplayName = this.CloseIsDisplayName.bind(this);

    this.openPopupUploadFile = this.openPopupUploadFile.bind(this);
    this.loadFilePreview = this.loadFilePreview.bind(this);
  }

  /**
   *
   * @param {*} preProps
   */
  componentDidUpdate(preProps) {
    const { nyutaisho } = this.props;

    // case update vitalId when insert value to DOM
    if (nyutaisho !== preProps.nyutaisho && this.state.currentRecord) {
      this.setState({
        currentRecord: nyutaisho[this.state.currentRecord.index],
      });
    }
  }

  /**
   *
   * @param {*} key
   * @param {*} index
   * @param {*} event
   */
  onRowClick(key) {
    // Setting active row key
    this.setState({
      rowActive: key,
    });

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  /**
   *
   * @param {*} key
   */
  handleClickRow(key) {
    if (key === this.state.activeListIndex) {
      this.setState({
        activeListIndex: null,
      });
    }
    else {
      this.setState({
        activeListIndex: key,
      });
    }
  }

  /**
   *
   * @param {*} gazoRecord
   */
  handleCopyGazo(gazoRecord) {
    // Clone data
    const cloneGazoCopy = {
      nyuType: gazoRecord.nyuType,
      kubun: gazoRecord.kubun,
      riyousya: gazoRecord.riyousya,
      bunrui: gazoRecord.bunrui,
      room: gazoRecord.room,
      time: gazoRecord.time,
      index: gazoRecord.index,
      modeRecord: "copy",
    };

    this.props.handleCopyGazo(cloneGazoCopy);
  }

  /**
   *
   * @param {*} gazoRecord
   */
  handleDelGazo(gazoRecord) {
    this.props.handleDelGazo(gazoRecord);
  }

  /**
   * Delete record from server
   * @param {*} fileManagementId
   */
  handleServerDel(fileManagementId) {
    this.props.gazouDelete(fileManagementId);
  }

  /**
   * Edit, Update function with single field
   *
   */

  /**
   * function handle when click to edit field
   *
   * @param {int} MType // display modal type corresponding
   * @param {*} report // record for row
   * @param {*} index // index of row
   */
  handleMInput(MType, record, index) {
    const extractCurrent = checkTableField(MType, record);

    let okSaveButton = true;
    if (MType === ConstSet.MODAL_G_COMMENT) {
      okSaveButton = false;
    }

    this.setState({
      rowActive: index,
      expandRowClick: [],

      visibleMInput: true,
      modalType: MType,

      riyousya: record.riyousya.name,
      time: record.time,
      record,
      indexRecord: index,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  }

  /**
   * (2)
   */
  handleMInputCancel() {
    this.setState({
      visibleMInput: false,
    });
  }

  /**
   * (3.1) Pre field <--
   */
  preInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType > ConstSet.MODAL_G_TIME &&
      modalType <= ConstSet.MODAL_G_TANTOMASTER
    ) {
      modalType = modalType - 1;
    }

    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_G_COMMENT) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.2) Pre field ^|
   */
  upperInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_G_COMMENT) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      record: nyutaisho[index],
      time: nyutaisho[index].time,

      inputField: extractCurrent.type,
      inputValue: extractCurrent.value,
      inputType: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.3) Pre field _|
   */
  lowerInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    const { nyutaisho } = this.props;

    const modalType = this.state.modalType;
    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_G_COMMENT) {
      okSaveButton = false;
    }

    let index = this.state.indexRecord;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const extractCurrent = checkTableField(
      this.state.modalType,
      nyutaisho[index]
    );

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      //
      rowActive: preRowActive,

      indexRecord: index,
      riyousya: nyutaisho[index].riyousya.name,
      time: nyutaisho[index].time,

      record: nyutaisho[index],

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * (3.4) Pre field -->
   */
  nextInput = () => {
    this.handleOkUpdate(true); // update data before next

    // function
    let modalType = this.state.modalType;

    if (
      modalType >= ConstSet.MODAL_G_TIME &&
      modalType < ConstSet.MODAL_G_TANTOMASTER
    ) {
      modalType = modalType + 1;
    }

    let okSaveButton = true;
    if (modalType === ConstSet.MODAL_G_COMMENT) {
      okSaveButton = false;
    }

    const extractCurrent = checkTableField(modalType, this.state.record);

    this.setState({
      //
      modalType,

      inputType: null,
      inputValue: extractCurrent.value,
      inputField: null,

      field: extractCurrent.type,
      isZendkaiDis: extractCurrent.zenkai,
      suggestValues: extractCurrent.suggestValues,

      okSaveButton,
    });
  };

  /**
   * 時間ところ
   */

  handleSetCurrentTime = () => {
    const currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.setState({
      inputType: ConstSet.MODAL_G_TIME,
      inputValue: currentTime,
      inputField: "time",
      okSaveButton: false,
    });
  };

  /**
   * (1)
   * @param {(*)} value
   */
  getHandleHourInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;
    if (!preData) {
      preData = value + ":00";
    }
    else {
      preData = value + preData.substring(2, 5);
    }
    this.setState({
      inputType: ConstSet.MODAL_G_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (2)
   * @param {(*)} value
   */
  getHandleMinuteLeftInput(value) {
    let preData = this.state.inputValue;

    if (!preData) {
      preData = "00:" + value;
    }
    else {
      preData = preData.substring(0, 3) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_G_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }

  /**
   * (3)
   * @param {(*)} value
   */
  getHandleMinuteInput(value) {
    let preData = this.state.inputValue
      ? this.state.inputValue
      : this.state.inputValue;
    if (!preData) {
      preData = "00:0" + value;
    }
    else {
      preData = preData.substring(0, 4) + value;
    }
    this.setState({
      inputType: ConstSet.MODAL_G_TIME,
      inputValue: preData,
      inputField: "time",
      okSaveButton: false,
    });
  }
  // 時間end

  /**
   * 申送
   * @param {*} value
   */
  getHandleReportCaseTyuif(value) {
    this.setState({
      inputType: ConstSet.MODAL_G_REPORTCASETYUIF,
      inputField: "reportCaseTyuif",
      inputValue: value ? "" + 1 : "" + 0,
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandlePriority(value) {
    this.setState({
      inputType: ConstSet.MODAL_G_PRIORITYF,
      inputField: "priorityf",
      inputValue: value ? "" + 1 : "" + 0,
      okSaveButton: false,
    });
  }

  /**
   * 申送
   * @param {*} value
   */
  getHandleKubun(value) {
    this.setState({
      inputType: ConstSet.MODAL_G_KUBUN,
      inputValue: value,
      inputField: "kubun",
      okSaveButton: false,
    });
  }

  /**
   * コメント
   * @param {*} value
   * @param {*} type
   */
  getHandleInputComment(value, type) {
    if (type && type === "select") {
      this.setState({
        inputType: ConstSet.MODAL_G_COMMENT,
        inputField: "comment",
        inputValue: this.state.inputValue
          ? this.state.inputValue + "、" + value
          : value,
        okSaveButton: false,
      });
    }
    else {
      this.setState({
        inputType: ConstSet.MODAL_G_COMMENT,
        inputField: "comment",
        inputValue: value,
        okSaveButton: false,
      });
    }
  }

  /**
   * 担当者
   * @param {*} value
   */
  getHandleTantoMasterKB(value) {
    // let updateCurrentRecord = this.state.record;
    // updateCurrentRecord.tantoId = value;
    this.setState({
      inputType: ConstSet.MODAL_G_TANTOMASTER,
      inputValue: value,
      inputField: "tantoId",
      // record: updateCurrentRecord,
      okSaveButton: false,
    });
  }

  /**
   * OK UPDATE Button Click
   *
   * Save data
   */
  handleOkUpdate(type) {
    const checkGazoId = this.state.record.fileManagementId
      ? this.state.record.fileManagementId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkGazoId && !checkCopyType) {
      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        fileManagementId: checkGazoId.toString(),
        index: this.state.record.index,
        date: localStorage.getItem("nyuDate"),
      };

      const updateField = this.state.inputValue;

      if (this.state.inputField && this.state.inputType) {
        if (this.state.inputType === ConstSet.MODAL_G_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.gazouUpdate(params, (data) => {
          this.setState({
            record: Object.assign(this.state.record, data),
          });

          this.loadFilePreview(data);
        });

        let okSaveButton = true;
        if (this.state.inputType === ConstSet.MODAL_G_COMMENT) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    } // POST
    else {
      let preTime = "00:00";
      if (
        getValueIskeep("jikantaiG") &&
        getValueIskeep("jikantaiG") !== "全表示"
      ) {
        preTime = getValueIskeep("jikantaiG").split("~")[0];
      }

      const params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousya.id.toString(),
        date: localStorage.getItem("nyuDate"),
        time: this.state.record.time ? this.state.record.time : preTime,
        tantoId: getValueLocalstorage("tid"),
        kubunGazo: this.state.record.kubunGazo,
      };

      const updateField = this.state.inputValue;

      if (
        this.state.inputField &&
        this.state.inputValue &&
        this.state.inputType
      ) {
        if (this.state.inputType === ConstSet.MODAL_G_TANTOMASTER) {
          params[this.state.inputField] = "" + updateField;
        }
        else {
          params[this.state.inputField] = updateField;
        }

        this.props.gazouInsert(params, (data) => {
          this.setState({
            record: Object.assign(this.state.record, data),
          });

          this.loadFilePreview(data);
        });

        let okSaveButton = true;
        if (this.state.inputType === ConstSet.MODAL_G_COMMENT) {
          okSaveButton = false;
        }

        this.setState({
          inputType: null,
          inputField: null,
          inputValue: updateField,
          okSaveButton,
        });
      }
    }

    // close popup
    if (type !== true) {
      // save from next, pre, upper, lower not colse popup
      this.setState({
        visibleMInput: false,
      });
    }
  }
  // end Edit, Update function

  /**
   * Handle Edit, Update By Input From from right all Screen
   * ALl fields can be save on this Drawer
   *
   */

  /**
   * Form Inside Drawer
   * Show Form
   */
  showDrawer = (record) => {
    const { jikantai } = this.props;
    if (!record.time && jikantai && jikantai != "全表示") {
      record.time = jikantai.split("~")[0] + ":00";
    }

    this.setState({
      rowActive: record.index,
      showDrawer: true,
      record: Object.assign(record, { kubun: record.kubunFile }),
      file: null,
      isFormUpdate: false,
      filePreview: null,
    });

    if (record.fileManagementId) {
      this.loadFilePreview(record);
    }
  };

  /**
   * Close Form
   */
  closeDrawer = () => {
    this.setState({
      showDrawer: false,
      file: null,
      isFormUpdate: false,
      filePreview: null,
    });
  };

  /**
   *
   */
  upperForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index > 0 && index <= nyutaisho.length) {
      index = index - 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    if (cloneData.fileManagementId) {
      this.loadFilePreview(cloneData);
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive > 0) {
      preRowActive = preRowActive - 1;
    }

    this.setState({
      filePreview: null,
      rowActive: preRowActive,
      showDrawer: true,
      record: Object.assign(cloneData, { kubun: cloneData.kubunFile }),
      file: null,
      isFormUpdate: false,
    });
  };

  /**
   *
   */
  lowerForm = () => {
    /**
     * automcatically update data if form has been modify
     */
    const { isFormUpdate } = this.state;
    if (isFormUpdate) {
      this.handleOkUpdateForm();
    }

    const { nyutaisho } = this.props;

    let index = this.state.record.index;
    if (index >= 0 && index < nyutaisho.length - 1) {
      index = index + 1;
    }

    const cloneData = Object.assign({}, nyutaisho[index]);

    if (cloneData.fileManagementId) {
      this.loadFilePreview(cloneData);
    }

    let preRowActive = this.state.rowActive;
    if (preRowActive < nyutaisho.length - 1) {
      preRowActive = preRowActive + 1;
    }

    this.setState({
      filePreview: null,
      rowActive: preRowActive,
      showDrawer: true,
      record: Object.assign(cloneData, { kubun: cloneData.kubunFile }),
      file: null,
      isFormUpdate: false,
    });
  };

  /**
   * Update file to State, Prepare data to save when click on 更新 button
   * @param {*} value
   * @param {*} type
   */
  getHandleFormInput = (value, type) => {
    const prepareData = this.state.record;
    const objData = {};
    objData[type] = value;

    const mfs =
      !isNaN(parseInt(getValueLocalstorage("mfs"), 10)) &&
        parseInt(getValueLocalstorage("mfs"), 10) > 0
        ? parseInt(getValueLocalstorage("mfs"), 10)
        : 20;
    if (type === "file") {
      const isLt2M = value.size / 1024 / 1024 < mfs;
      if (!isLt2M) {
        openNotificationWithIcon(
          "error",
          `ファイルサイズが${mfs}MBを超えています`
        );
        return;
      }

      const isJPG1 = value.type === "image/jpeg";
      const isJPG2 = value.type === "image/jpg";
      const isJPG3 = value.type === "image/png";
      const isJPG4 = value.type === "image/git";
      if (isJPG1 || isJPG2 || isJPG3 || isJPG4) {
        getBase64(value, (imageUrl) =>
          this.setState({
            imageUrl,
          })
        );
      }
      else {
        urlTobase64(checkIconUrl(value.name.split(".").pop()), (imgBase64) => {
          this.setState({
            imageUrl: imgBase64,
          });
        });
      }

      this.setState({
        file: value,
      });
    }

    this.setState({
      record: Object.assign({}, prepareData, objData),
      isFormUpdate: true,
    });
  };

  /**
   * Save data action
   */
  handleOkUpdateForm = () => {
    const checkGazoId = this.state.record.fileManagementId
      ? this.state.record.fileManagementId
      : null;
    const checkCopyType =
      !!(this.state.record.modeRecord && this.state.record.modeRecord === "copy");

    // PUT
    const key = getMenu();
    if (checkGazoId && !checkCopyType) {
      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        fileManagementId: checkGazoId.toString(),
        index: this.state.record.index,
        date: localStorage.getItem("nyuDate"),
      };

      params = Object.assign(params, this.state.record);

      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.gazouUpdate(params, (data) => {
        this.setState({
          record: Object.assign(this.state.record, data),
          isFormUpdate: false,
        });

        this.loadFilePreview(params);
      });
    } // POST
    else {
      let preTime = "00:00";
      if (
        getValueIskeep("jikantaiG") &&
        getValueIskeep("jikantaiG") !== "全表示"
      ) {
        preTime = getValueIskeep("jikantaiG").split("~")[0];
      }

      let params = {
        systemControlName: JSON.parse(localStorage.getItem(key)).sn,
        systemControlId: JSON.parse(localStorage.getItem(key)).si,
        index: this.state.record.index,
        riyousyaId: this.state.record.riyousya.id.toString(),
        date: localStorage.getItem("nyuDate"),
        tantoId: getValueLocalstorage("tid"),
        file: this.state.file,
        thumbnail: this.state.imageUrl ? this.state.imageUrl : "",
      };

      params = Object.assign(params, this.state.record);
      params.time = this.state.record.time ? this.state.record.time : preTime;
      // Convert all fields to string
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
          if (typeof params[key] === "number") {
            params[key] = params[key].toString();
          }
        }
      }

      this.props.gazouInsert(params, (data) => {
        this.setState({
          record: Object.assign(this.state.record, data, {
            kubun: data.kubunFile,
          }),
          isFormUpdate: false,
        });

        this.loadFilePreview(data);
      });
    }
  };
  // END Handle Edit, Update By Input Form from right all Screen

  /**
   * Handle Add, Edit Setting Comment
   */
  /**
   * move to input state of table
   * @param {*} index
   */
  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  /**
   * Cancel Save
   * @param {*} index
   */
  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  /**
   * Save function
   * @param {*} naiyou
   */
  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }
  // END Handle Add, Edit Setting Comment

  /**
   * Display and download file
   */
  OpenIsDisplayName = (record) => {
    this.setState({
      showPopupUploadFile: true,
      record,
    });
  };

  /**
   * Close Form
   */
  CloseIsDisplayName = () => {
    this.setState({
      showPopupUploadFile: false,
      // record: null
    });
  };

  /**
   *
   */
  download() {
    this.props.download(
      this.state.record.fileManagementId,
      this.state.record.fileName
    );
  }

  /**
   * handle click cell event
   *
   * in mulInputAllMode, click do nothing
   * in input mode, base info cell click -> open expander data, edting cell -> display modal
   * in nothing mode -> open expander data
   *
   */
  onClickCellEvent = (recordIndex, type) => {
    const { nyutaisho, inputMode, mulInputAllMode } = this.props;
    const record = nyutaisho[recordIndex];

    switch (type) {
      case ConstSet.COL_SELECT:
        return;

      case ConstSet.COL_COPY:
        return;

      case ConstSet.COL_MODE:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_NAME:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ADDRESS:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_ROOM:
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_TIME:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          if (!record.fileManagementId) return this.showDrawer(record);
          return this.handleMInput(
            ConstSet.MODAL_G_TIME,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_REPORTCASETYUIF:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          if (!record.fileManagementId) return this.showDrawer(record);
          return this.handleMInput(
            ConstSet.MODAL_G_REPORTCASETYUIF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_PRIORITYF:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          if (!record.fileManagementId) return this.showDrawer(record);
          return this.handleMInput(
            ConstSet.MODAL_G_PRIORITYF,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_KUBUN:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          if (!record.fileManagementId) return this.showDrawer(record);
          return this.handleMInput(
            ConstSet.MODAL_G_KUBUN,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_COMMENT:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          if (!record.fileManagementId) return this.showDrawer(record);
          return this.handleMInput(
            ConstSet.MODAL_G_COMMENT,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_TANTOMASTER:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          if (!record.fileManagementId) return this.showDrawer(record);
          return this.handleMInput(
            ConstSet.MODAL_G_TANTOMASTER,
            record,
            recordIndex
          );
        }
        return this.onRowClick(recordIndex);

      case ConstSet.COL_GAZO_THUMBNAIL:
        if (mulInputAllMode) {
          return;
        }
        if (inputMode) {
          return this.showDrawer(record);
        }
        if (record && record.fileManagementId) {
          return this.openPopupUploadFile(record);
        }
        return this.onRowClick(recordIndex);

      default:
        return;
    }
  };

  async openPopupUploadFile(record) {
    this.loadFilePreview(record);

    this.setState({
      showPopupUploadFile: true,
      record,
      expandRowClick: [],
      activeRowKey: null,
    });
  }

  loadFilePreview(record) {
    this.props.loadFilePreview(record, (data) =>
      this.setState({ filePreview: data })
    );
  }

  download = (record) => {
    this.props.download(record, (data) => {
      FileSaver(data, record.fileName);
    });
  };

  closePopupUploadFile = () => {
    this.setState({
      showPopupUploadFile: false,
      filePreview: null,
    });
  };

  getContainer = () => {
    // eslint-disable-next-line react/no-find-dom-node
    return findDOMNode(this);
  };

  render() {
    const state = this.state;
    const {
      nyutaisho,
      loading,
      bunruiTitle,

      inputMode,
      mulInputAllMode,

      tantoMaster,
      yougoMasterNa,
      gazouKubun,
      isDisLongText,
      jikantai,
      loadingFileManagement,
      update,
      created,
      deleteFile,
    } = this.props;

    /**
     * handle date
     */
    const data = [];

    // colums情報を設定
    const columnsLong = [];

    if (inputMode) {
      columnsLong.push({
        title: "追加",
        dataIndex: "colCopy",
        key: "colCopy",
        width: 50,
        fixed: "left",
        className: "input-mode-fix action-col",
      });
    }
    else {
      columnsLong.push({
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        fixed: "left",
        className: `joutaiCol ${inputMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      });
    }

    columnsLong.push(
      {
        title: "利用者名",
        dataIndex: "col2",
        key: "col2",
        width: 150,
        fixed: "left",
        className: `${inputMode ? "input-mode-fix" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: bunruiTitle,
        dataIndex: "col3",
        key: "col3",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "部屋名",
        dataIndex: "col4",
        key: "col4",
        width: 100,
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_MODE);
            }, // click row
          };
        },
      },
      {
        title: "時間",
        dataIndex: "col5",
        key: "col5",
        width: 70,
        className: `timeCol ${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_GAZO_TIME);
            }, // click row
          };
        },
      },
      {
        title: "申送",
        dataIndex: "col6",
        key: "col6",
        width: 50,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(
                record.key,
                ConstSet.COL_GAZO_REPORTCASETYUIF
              );
            }, // click row
          };
        },
      },
      {
        title: "画像等",
        dataIndex: "col7",
        key: "col7",
        width: 70,
        className: `timeCol ${inputMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_GAZO_THUMBNAIL);
            }, // click row
          };
        },
      },
      {
        title: "優先",
        dataIndex: "col8",
        key: "col8",
        width: 70,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_GAZO_PRIORITYF);
            }, // click row
          };
        },
      },
      {
        title: "区分",
        dataIndex: "col9",
        key: "col9",
        width: 80,
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_GAZO_KUBUN);
            }, // click row
          };
        },
      },
      {
        title: "コメント",
        dataIndex: "col10",
        key: "col10",
        className: `${inputMode || mulInputAllMode
          ? "input-mode dis-text-short"
          : `${isDisLongText ? "dis-text-long" : "dis-text-short"}`}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_GAZO_COMMENT);
            }, // click row
          };
        },
      },
      {
        title: "担当者",
        dataIndex: "col11",
        key: "col11",
        width: 120,
        className: `${inputMode ? "input-mode" : ""}`,
        onCell: (record) => {
          return {
            onClick: () => {
              this.onClickCellEvent(record.key, ConstSet.COL_GAZO_TANTOMASTER);
            }, // click row
          };
        },
      }
    );

    if (inputMode) {
      columnsLong.push({
        title: "削除",
        dataIndex: "colDel",
        key: "colDel",
        width: 50,
        // fixed: 'right',
        className: "input-mode-fix action-col",
      });
    }

    // テーブルデータの準備
    if (nyutaisho) {
      let jikantaiTime = "";
      if (jikantai && jikantai != "全表示") {
        jikantaiTime = jikantai.split("~")[0];
      }

      for (let i = 0; i < nyutaisho.length; i++) {
        let color = "nuytaisho-yellow";
        if (nyutaisho[i].kubunRiyou === "ショート") {
          color = "nuytaisho-blue";
        }

        let gender = "女";
        let colorGender = "nts-gender-red";
        if (nyutaisho[i].riyousya.gender === 1) {
          gender = "男";
          colorGender = "nts-gender-blue";
        }

        let planType = "";
        if (nyutaisho[i].time && nyutaisho[i].planType === true) {
          planType = "bg_green";
        }
        else if (nyutaisho[i].fileManagementId && nyutaisho[i].time) {
          planType = "bg_pink";
        }

        let fileIcon = null;

        if (nyutaisho[i].type) {
          fileIcon = checkFileStyle(nyutaisho[i].type).icon;
        }

        //
        let imageMaxPixel = "45px";
        if (nyutaisho[i].imageMaxPixel && nyutaisho[i].imageMaxPixel > 0) {
          imageMaxPixel = `${nyutaisho[i].imageMaxPixel}px`;
        }

        data.push({
          key: i,
          colCopy: (
            <span className={"action-kiroku"}>
              <Button
                type={"primary"}
                ghost
                onClick={this.handleCopyGazo.bind(this, nyutaisho[i])}
              >
                <PlusOutlined />
              </Button>
            </span>
          ),
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType === "1" ? (
                <ArrowRightOutlined />
              ) : (
                nyutaisho[i].nyuType
              )}
            </div>
          ),
          col2: (
            <span className={"ntk-riyousya-colum"}>
              {nyutaisho[i].modeRecord && nyutaisho[i].modeRecord === "copy" ? (
                <span style={{ color: "blue", fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              ) : (
                <span style={{ fontWeight: "bold" }}>
                  {nyutaisho[i].riyousya.name}
                </span>
              )}
              <span className={`ntl-riyousya-gender ${colorGender}`}>
                {gender}
              </span>
            </span>
          ),
          col3: <div>{nyutaisho[i].bunrui}</div>, // 住所
          col4: <div>{nyutaisho[i].room}</div>, // 部屋名
          col5: (
            <div className={`icon-table ${planType}`}>
              {nyutaisho[i].time
                ? nyutaisho[i].time.substring(0, 5)
                : jikantai != "全表示"
                  ? jikantaiTime
                  : ""}
            </div>
          ),
          col6: (
            <div className={"icon-table"}>
              {nyutaisho[i].reportCaseTyuif ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : (
                ""
              )}
            </div>
          ),
          col7: (
            <div className={"icon-table"}>
              {nyutaisho[i].imageMaxPixel === 0 ? (
                fileIcon ? (
                  <img
                    src={fileIcon}
                    alt={"thubnail"}
                    style={{ width: imageMaxPixel, height: imageMaxPixel }}
                  />
                ) : (
                  ""
                )
              ) : nyutaisho[i].thumbnail ? (
                <img
                  src={nyutaisho[i].thumbnail}
                  alt={"thubnail"}
                  style={{ width: imageMaxPixel, height: imageMaxPixel }}
                />
              ) : fileIcon ? (
                <img
                  src={fileIcon}
                  alt={"thubnail"}
                  style={{ width: imageMaxPixel, height: imageMaxPixel }}
                />
              ) : (
                ""
              )}
            </div>
          ),
          // nyutaisho[i].thumbnail ? <img src={nyutaisho[i].thumbnail} alt={"thubnail"} /> : ''
          col8: (
            <div className={"icon-table"}>
              {nyutaisho[i].priorityf && nyutaisho[i].priorityf === 1 ? (
                <StarFilled />
              ) : (
                ""
              )}
            </div>
          ),
          col9: (
            <div className={"icon-table"}>
              {nyutaisho[i].kubunFile
                ? gazouKubunExtract(gazouKubun, nyutaisho[i].kubunFile)
                : ""}
            </div>
          ),
          col10: <div>{nyutaisho[i].comment}</div>,
          col11:
            nyutaisho[i].tantoName && nyutaisho[i].tantoName.length > 6 ? (
              <Tooltip placement="bottomRight" title={nyutaisho[i].tantoName}>
                {nyutaisho[i].tantoName.substring(0, 4) + "..."}
              </Tooltip>
            ) : (
              <div>{nyutaisho[i].tantoName ? nyutaisho[i].tantoName : ""}</div>
            ),
          colDel: (
            <span className={"action-kiroku"}>
              {nyutaisho[i].modeRecord || nyutaisho[i].fileManagementId ? (
                !nyutaisho[i].modeRecord && nyutaisho[i].fileManagementId ? (
                  <Popconfirm
                    overlayStyle={{ width: "210px" }}
                    title="削除してもいいですか？"
                    onConfirm={() =>
                      this.handleServerDel(nyutaisho[i].fileManagementId)
                    }
                  >
                    <span>
                      <Button type={"primary"} ghost>
                        <MinusOutlined />
                      </Button>
                    </span>
                  </Popconfirm>
                ) : (
                  <span>
                    <Button
                      type={"primary"}
                      ghost
                      onClick={this.handleDelGazo.bind(this, nyutaisho[i])}
                    >
                      <MinusOutlined />
                    </Button>
                  </span>
                )
              ) : null}
            </span>
          ),
          description: (
            <TableRowButton
              history={this.props.history}
              editRow={this.showDrawer.bind(this, nyutaisho[i])}
              riyousyaId={nyutaisho[i].riyousya.id}
            />
          ),
        });
      }
    }

    return (
      (<div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      expandedRowKeys={this.state.expandRowClick}
                      pagination={{
                        position: "bottom",
                        pageSize: ConstSet.TABLE_PAGINATION,
                      }}
                      scroll={{ x: 1400, y: 800 }}
                      className="table-kiroku-date"
                      rowClassName={(record, index) =>
                        this.state.rowActive != null &&
                          index === this.state.rowActive
                          ? "pljp-table-row-hover"
                          : ""
                      }
                      // onRow={(record, index) => {
                      //     return {
                      //         onClick: (event) => {
                      //             this.onRowClick(record, index, event)
                      //         }
                      //     }
                      // }}

                      columns={columnsLong}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            }
            return null;

          }}
        </MediaQuery>
        <Modal
          title={
            <div className="kim-header-title">
              <span>{this.state.riyousya}</span>
              <span>
                {this.state.time ? this.state.time.substring(0, 5) : ""}
              </span>
            </div>
          }
          open={this.state.visibleMInput}
          onOk={this.handleOkUpdate}
          onCancel={this.handleMInputCancel}
          className={"kiroku-input-modal"}
          footer={[
            <Button key="pre" onClick={this.preInput}>
              前項目
            </Button>,
            <Button key="next" onClick={this.nextInput}>
              次項目
            </Button>,
            <Button
              className="kim-btn-ok"
              key="submit"
              type="primary"
              onClick={this.handleOkUpdate}
              disabled={this.state.okSaveButton}
            >
              OK
            </Button>,
            <Button key="up" onClick={this.upperInput}>
              上の行
            </Button>,
            <Button key="dow" onClick={this.lowerInput}>
              下の行
            </Button>,
          ]}
          maskClosable={false}
        >
          <div className="kim-title">
            <span>{this.state.field}</span>
          </div>
          {this.state.isZendkaiDis ? (
            <div className={"model-top-row"}>
              <div className="kim-zenkai"></div>
              <div
                className={`kim-value ${this.state.field === "時間" ? "kim-value-time" : ""}`}
              >
                <div className="kim-value-top">
                  {this.state.inputValue ? this.state.inputValue : "\u00A0"}
                </div>
                {this.state.field === "時間" ? (
                  <div className="kim-value-bottom">
                    <Button type="primary" onClick={this.handleSetCurrentTime}>現時刻</Button>
                  </div>
                ) : null}
              </div>
              <div className={"mtr-col1 kim-suggest"}></div>
            </div>
          ) : null}

          <div>
            {(() => {
              switch (this.state.modalType) {
                case ConstSet.MODAL_G_TIME:
                  return (
                    <TimeKB
                      handleHourInput={this.getHandleHourInput}
                      handleMinuteLeftInput={this.getHandleMinuteLeftInput}
                      handleMinuteInput={this.getHandleMinuteInput}
                    />
                  );

                case ConstSet.MODAL_G_REPORTCASETYUIF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandleReportCaseTyuif}
                    />
                  );

                case ConstSet.MODAL_G_PRIORITYF:
                  return (
                    <CheckKB
                      defaltValue={this.state.inputValue}
                      handleOnchangeCheck={this.getHandlePriority}
                    />
                  );

                case ConstSet.MODAL_G_KUBUN:
                  return (
                    <SelectGazouKubun
                      value={this.state.inputValue}
                      data={gazouKubun}
                      handleChangeSelect={this.getHandleKubun}
                    />
                  );

                case ConstSet.MODAL_G_COMMENT:
                  return (
                    <CommentKB
                      yougoMasterEditState={this.getYougoMasterEditState}
                      yougoMasterEditCancel={this.getYougoMasterEditCancel}
                      yougoMasterEditSave={this.getYougoMasterEditSave}
                      defaultValue={this.state.inputValue}
                      handleTextareaKB={this.getHandleInputComment}
                      yougoMasterNa={yougoMasterNa}
                      rowNum={4}
                    />
                  );

                case ConstSet.MODAL_G_TANTOMASTER:
                  return (
                    <TantoMasterKB2
                      tantoMaster={tantoMaster}
                      value={this.state.inputValue}
                      handleTantoMasterKB={this.getHandleTantoMasterKB}
                      widthCode={100}
                      widthText={250}
                    />
                  );

                default:
                  return null;
              }
            })()}
          </div>
        </Modal>
        <Drawer
          destroyOnClose
          title={
            this.state.record ? this.state.record.riyousya.name + " さん" : null
          }
          width={800}
          onClose={this.closeDrawer}
          open={this.state.showDrawer}
          closable={false}
          getContainer={this.getContainer}
          rootClassName="kiroku-drawer kiroku-drawer-form kiroku-drawer-form-file"
          placement="right"
        >
          <Spin
            spinning={loadingFileManagement || update || created || deleteFile}
          >
            <GazoForm
              tantoMaster={tantoMaster}
              data={this.state.record ? this.state.record : {}}
              handleFormInput={this.getHandleFormInput}
              yougoMasterEditState={this.getYougoMasterEditState}
              yougoMasterEditCancel={this.getYougoMasterEditCancel}
              yougoMasterEditSave={this.getYougoMasterEditSave}
              yougoMasterNa={yougoMasterNa}
              gazouKubun={gazouKubun}
              file={this.state.file}
              imageUrl={this.state.imageUrl}
              filePreview={this.state.filePreview}
              opepPopupTyuiF={this.opepPopupTyuiF}
            />
          </Spin>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e8e8e8",
              padding: "10px 16px",
              textAlign: "right",
              left: 0,
              background: "#fff",
              borderRadius: "0 0 4px 4px",
            }}
          >
            <span style={{ float: "left" }}>
              <Button onClick={this.upperForm} style={{ marginRight: 8 }}>
                <ArrowUpOutlined />
              </Button>
              <Button onClick={this.lowerForm}>
                <ArrowDownOutlined />
              </Button>
            </span>

            <span>
              <Button onClick={this.closeDrawer} style={{ marginRight: 8 }}>
                戻る
              </Button>
              <Button type="primary" danger onClick={this.handleOkUpdateForm}>
                更新
              </Button>
            </span>
          </div>
        </Drawer>
        <Drawer
          closable
          onClose={this.closePopupUploadFile}
          open={this.state.showPopupUploadFile}
          destroyOnClose
          rootClassName="kiroku-drawer"
          placement="left"
        >
          <PopupUploadFile
            // isDownloadable={true}
            record={this.state.record}
            onCancel={this.closePopupUploadFile}
            download={this.download}
            filePreview={this.state.filePreview}
          />
        </Drawer>
      </div>)
    );
  }
}

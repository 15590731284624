import { ApiPaths } from "../../constants/api_paths";
import { FacesheetActionTypes, ErrorActionTypes } from "../action_types/index";
import { RequestUtils, StorageUtils, CommonUtils } from "../../utils/index";

function getAll(riyousyaId, sort, page) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: FacesheetActionTypes.GET_ALL_FACESHEET_REQUEST });
      const query = {
        riyousyaId: Number(riyousyaId),
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Facesheet.GetList,
        query,
      });
      dispatch({ type: FacesheetActionTypes.GET_ALL_FACESHEET_SUCCESS, data });
    } catch (error) {
      dispatch({ type: FacesheetActionTypes.GET_ALL_FACESHEET_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function getById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: FacesheetActionTypes.GET_FACESHEET_REQUEST });
      const query = {
        id: Number(id),
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Facesheet.GetById,
        query,
      });
      dispatch({
        type: FacesheetActionTypes.GET_FACESHEET_SUCCESS,
        data,
      });
      // return callback(data)
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: FacesheetActionTypes.GET_FACESHEET_FAILURE,
        error,
      });
    }
  };
}
function create(value, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: FacesheetActionTypes.CREATE_FACESHEET_REQUEST });
      const data = await RequestUtils.callApi({
        method: "post",
        path: ApiPaths.Facesheet.Create,
        body: {
          sid: Number(sid),
          ...value,
        },
      });
      dispatch({
        type: FacesheetActionTypes.CREATE_FACESHEET_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: FacesheetActionTypes.CREATE_FACESHEET_FAILURE,
        error,
      });
    }
  };
}

function update(value, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: FacesheetActionTypes.UPDATE_FACESHEET_REQUEST });
      const data = await RequestUtils.callApi({
        method: "put",
        path: ApiPaths.Facesheet.Update,
        body: {
          sid: sid ? parseInt(sid) : null,
          ...value,
        },
      });
      dispatch({
        type: FacesheetActionTypes.UPDATE_FACESHEET_SUCCESS,
        data,
      });
      return callback(data);
    } catch (error) {
      // dispatch({ type: RIHABILI_ERROR });
      // console.log(error);
      dispatch({
        type: FacesheetActionTypes.UPDATE_FACESHEET_FAILURE,
        error,
      });
    }
  };
}

function deleteId(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: FacesheetActionTypes.REMOVE_FACESHEET_REQUEST });
      const query = {
        id,
      };
      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.Facesheet.Delete,
        query,
      });
      dispatch({ type: FacesheetActionTypes.REMOVE_FACESHEET_SUCCESS, data });
    } catch (error) {
      dispatch({ type: FacesheetActionTypes.REMOVE_FACESHEET_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getQuoteSoudanDate(riyousyaId, callback) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId: Number(riyousyaId),
        sid: Number(sid),
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Facesheet.GetSoudanDate,
        query,
      });
      return callback(data);
    } catch (error) {
      dispatch({
        type: FacesheetActionTypes.GET_FACESHEET_FAILURE,
        error,
      });
    }
  };
}

function getByDate(riyousyaId, sort, page, nowId) {
  const menu = StorageUtils.getValue("menuMNGT");
  const sid = menu.si || null;
  return async (dispatch) => {
    try {
      dispatch({ type: FacesheetActionTypes.GET_FACESHEET_BY_DATA_REQUEST });
      const query = {
        riyousyaId,
        sid,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(sort)),
        page,
        nowId,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Facesheet.GetList,
        query,
      });
      dispatch({
        type: FacesheetActionTypes.GET_FACESHEET_BY_DATA_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({ type: FacesheetActionTypes.GET_FACESHEET_BY_DATA_FAILURE });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function newPage() {
  return {
    type: FacesheetActionTypes.NEW_PAGE,
  };
}

export const FacesheetActions = {
  getAll,
  getById,
  create,
  update,
  deleteId,
  getQuoteSoudanDate,
  getByDate,
  newPage,
};

import React from "react";
import { Provider } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { PrivateRoute } from "../common/component/PrivateRoute";
import { AccountContainer } from "../components/account/accountContainer";
import DengonContainer from "../components/dengon/dengonContainer";
import { HomeContainer } from "../components/home/homeContainer";
import {
  KanriContainer,
  KanriDengonDataContainer,
  KanriDengonDataSaveContainer,
  KanriFileContainer,
  KanriFileSaveContainer,
  KanriKaigoYougoMasterContainer,
  KanriKaigoYougoMasterSaveContainer,
  KanriKangoKirokuContainer,
  KanriKangoNaiyouContainer,
  KanriLifeYougoMasterContainer,
  KanriLifeYougoMasterSaveContainer,
  KanriMoshiokuriLinkContainer,
  KanriMoshiokuriLinkSaveContainer,
  KanriOpenScreenLogContainer,
  KanriOpenScreenLogSaveContainer,
  KanriSendGroupContainer,
  KanriSettingMasterContainer,
  KanriSettingMasterSaveContainer,
  KanriTantoMasterContainer,
  KanriTantoMasterSaveContainer,
  KanriUserContainer,
  KanriUserRiyousyaLinkContainer,
  KanriUserRiyousyaLinkSaveContainer,
  KanriUserSaveContainer,
  KanriYougoMasterContainer,
  KanriYougoMasterSaveContainer,
} from "../components/kanri/KanriContainer";
import { LogoutContainer } from "../components/logout/logoutContainer";
import {
  PlanContainer,
  RiyousyaPlanContainer,
} from "../components/plan/PlanContainer";
import {
  InputReportCaseContainer,
  ProgressRecordContainer,
  ProgressRecordRiyousyaContainer,
} from "../components/progress/ProgressRecordContainer";
import { ReportContainer } from "../components/report/ReportContainer";
import { ReportUserContainer } from "../components/reportUser/ReportUserContainer";
import { ReportWeeklyContainer } from "../components/reportWeekly/ReportWeeklyContainer";
import { ReportWeeklyAddContainer } from "../components/reportWeeklyAdd/ReportWeeklyAddContainer";
import {
  RiyousyaAddContainer,
  RiyousyaContainer,
  RiyousyaDetailContainer,
  RiyousyaHokenContainer,
  RiyousyaShokujiContainer,
  RiyousyaUpdateContainer,
} from "../components/riyousya/riyousyaContainer";
import { store } from "../store/store";
// adl
import {
  AdlDetailContainer,
  AdlIndexContainer,
  AdlInputContainer,
} from "../components/adl/AdlContainer";
import {
  InputRiyousyaShiryouContainer,
  RiyousyaShiryouContainer,
} from "../components/riyousyaShiryou/riyousyaShiryouContainer";
// 3S UPDATE
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import jaJP from "antd/es/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import SisetuDailyPlanContainer from "../components/documentList/DailyPlan";
import DocumentListRecord from "../components/documentList/DocumentListRecord";
import DocumentListRiyousya from "../components/documentList/DocumentListRiyousya";
import KaigiContainer from "../components/documentList/inputKaigi/index";
import KeikaKirokuContainer from "../components/documentList/inputKeikaKiroku/index";
import MonitoringContainer from "../components/documentList/inputMonitoring/index";
import KyotakuPlan1Container from "../components/documentList/inputPlan1/InputKyotakuPlan1Container";
import KyotakuPlan2Container from "../components/documentList/inputPlan2";
import InfoDocumentList from "../components/documentList/viewDocumentList/InfoDocumentList";
import ViewDocumentList from "../components/documentList/viewDocumentList/ViewDocumentList";
import { DutyReportContainer } from "../components/dutyReport/DutyReportContainer";
import InOutStatus from "../components/inOutStatus/InOutStatus";
import KanriTable from "../components/kanri/KanriTable";
import KanriTableDetail from "../components/kanri/KanriTableDetail";
import KanriTableRecord from "../components/kanri/KanriTableRecord";
import { SendGroupContainer } from "../components/sendGroup/SendGroupContainer";
import {
  InputUserManagement,
  UserManagementContainer,
} from "../components/userManagement/UserManagementContainer";
import "../styles/font-awesome.min.css";
import "../styles/fontawesome.min.css";
import "../styles/normalize.css";
import "../styles/style.css";
dayjs.locale("ja");

function User() {
  // Filter to redirect Router not permission on ui
  const menuInfo = JSON.parse(localStorage.getItem("menu"));
  const sisetukiroku =
    !(menuInfo &&
      menuInfo.usageAuth &&
      menuInfo.usageAuth.includes("sisetukiroku"));
  const sisetuyotei =
    !(menuInfo && menuInfo.usageAuth && menuInfo.usageAuth.includes("sisetuyotei"));
  const nissi =
    !(menuInfo && menuInfo.usageAuth && menuInfo.usageAuth.includes("nissi"));
  const sisetureport =
    !(menuInfo &&
      menuInfo.usageAuth &&
      menuInfo.usageAuth.includes("sisetureport"));
  const nyutaisho =
    !(menuInfo && menuInfo.usageAuth && menuInfo.usageAuth.includes("nyutaisho"));

  const [primary] = React.useState("#00838F");

  return (
    <ConfigProvider
      prefixCls="pljp"
      locale={jaJP}
      theme={{
        hashed: false,
        token: {
          colorPrimary: primary,
          colorLink: primary,
          fontFamily: "KozGo !important",
          colorBgContainerDisabled: "#f5f5f5"
        },
        components: {
          Modal: {
            wireframe: true // here
          },
          Table: {
            borderColor: "#cccccc",
            rowHoverBg: "#bacfcd",
            rowSelectedBg: "#bacfcd",
            rowSelectedHoverBg: "#bacfcd"
          }
        }
      }}
    >
      <Provider store={store}>
        <Router>
          <Switch>
            {sisetukiroku ? <Redirect from="/report" to="/dengon" /> : null}
            {sisetuyotei ? <Redirect from="/plan" to="/dengon" /> : null}
            {nissi ? <Redirect from="/duty-report" to="/dengon" /> : null}
            {sisetureport ? (
              <Redirect from="/document-list" to="/dengon" />
            ) : null}
            {nyutaisho ? <Redirect from="/in-out-status" to="/dengon" /> : null}
            <Route exact path="/" component={HomeContainer} />
            <Route path="/login" component={AccountContainer} />
            <Route path="/logout" component={LogoutContainer} />
            <PrivateRoute exact path="/kanri/dashboard" component={KanriContainer} />
            <PrivateRoute
              exact
              path="/kanri/table"
              component={KanriTable}
            />
            <PrivateRoute
              exact
              path="/kanri/table/detail"
              component={KanriTableDetail}
            />
            <PrivateRoute
              exact
              path="/kanri/table/record"
              component={KanriTableRecord}
            />
            <PrivateRoute
              exact
              path="/kanri/user"
              component={KanriUserContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/user/:userId"
              component={KanriUserSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/settingmaster"
              component={KanriSettingMasterContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/settingmaster/:smId"
              component={KanriSettingMasterSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/yougomaster"
              component={KanriYougoMasterContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/yougomaster/:ymId"
              component={KanriYougoMasterSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/lifeyougomaster"
              component={KanriLifeYougoMasterContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/lifeyougomaster/:lymId"
              component={KanriLifeYougoMasterSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/kaigoyougomaster"
              component={KanriKaigoYougoMasterContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/kaigoyougomaster/:kymId"
              component={KanriKaigoYougoMasterSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/tantomaster"
              component={KanriTantoMasterContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/tantomaster/:tmId"
              component={KanriTantoMasterSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/userriyousyalink"
              component={KanriUserRiyousyaLinkContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/userriyousyalink/:urlId"
              component={KanriUserRiyousyaLinkSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/file"
              component={KanriFileContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/file/:fileId"
              component={KanriFileSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/sendgroup"
              component={KanriSendGroupContainer}
            />

            <PrivateRoute
              exact
              path="/kanri/kango-naiyou"
              component={KanriKangoNaiyouContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/kango-kiroku"
              component={KanriKangoKirokuContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/dengondata"
              component={KanriDengonDataContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/dengondata/:dengonId"
              component={KanriDengonDataSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/moshiokurilink"
              component={KanriMoshiokuriLinkContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/moshiokurilink/:moshiokuriId"
              component={KanriMoshiokuriLinkSaveContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/openscreenlog"
              component={KanriOpenScreenLogContainer}
            />
            <PrivateRoute
              exact
              path="/kanri/openscreenlog/:openId"
              component={KanriOpenScreenLogSaveContainer}
            />

            <PrivateRoute path="/dengon" component={DengonContainer} />
            {/* ADL */}
            <PrivateRoute
              exact
              path="/report-adl/:riyousyaId"
              component={AdlIndexContainer}
            />
            <PrivateRoute
              exact
              path="/report-adl/:riyousyaId/:adlId"
              component={AdlInputContainer}
            />
            <PrivateRoute
              exact
              path="/report-adl-view/:riyousyaId/:adlId"
              component={AdlDetailContainer}
            />
            <PrivateRoute
              exact
              path="/progress"
              component={ProgressRecordContainer}
            />
            <PrivateRoute
              path="/progress/:riyousyaId"
              component={ProgressRecordRiyousyaContainer}
            />
            <PrivateRoute
              path="/input-report-case/:riyousyaId/:reportCaseId"
              component={InputReportCaseContainer}
            />
            <PrivateRoute path="/riyousya" component={RiyousyaContainer} />
            <PrivateRoute
              path="/riyousya-detail/:riyousyaId"
              component={RiyousyaDetailContainer}
            />
            <PrivateRoute
              path="/riyousya-update/:riyousyaId"
              component={RiyousyaUpdateContainer}
            />
            <PrivateRoute
              path="/riyousya-shokuji/:riyousyaId"
              component={RiyousyaShokujiContainer}
            />
            <PrivateRoute
              path="/riyousya-shiryou/:riyousyaId"
              component={RiyousyaShiryouContainer}
            />
            <PrivateRoute
              path="/riyousya-shiryou-input/:riyousyaId/:fileManagementId"
              component={InputRiyousyaShiryouContainer}
            />
            <PrivateRoute
              path="/riyousya-add"
              component={RiyousyaAddContainer}
            />
            <PrivateRoute
              path="/riyousya-hoken/:riyousyaId"
              component={RiyousyaHokenContainer}
            />

            <PrivateRoute
              exact
              path="/user-management"
              component={UserManagementContainer}
            />
            <PrivateRoute
              path="/user-management/:userId"
              component={InputUserManagement}
            />
            <PrivateRoute
              exact
              path="/send-group"
              component={SendGroupContainer}
            />

            {/* menu record */}
            <PrivateRoute
              menu="record"
              exact
              path="/report"
              component={ReportContainer}
            />
            <PrivateRoute
              menu="record"
              path="/report/:userId"
              component={ReportUserContainer}
            />
            <PrivateRoute
              menu="record"
              path="/report-weekly/:userId"
              component={ReportWeeklyContainer}
            />
            <PrivateRoute
              menu="record"
              path="/report-weekly-save/:userId"
              component={ReportWeeklyAddContainer}
            />
            <PrivateRoute
              menu="record"
              exact
              path="/plan"
              component={PlanContainer}
            />
            <PrivateRoute
              menu="record"
              path="/plan/:riyousyaId"
              component={RiyousyaPlanContainer}
            />
            <PrivateRoute
              menu="record"
              path="/view-document-list/:riyousyaId"
              component={ViewDocumentList}
            />
            <PrivateRoute
              menu="record"
              exact
              path="/duty-report"
              component={DutyReportContainer}
            />

            {/* menu business */}
            <PrivateRoute
              menu="business"
              exact
              path="/document-list"
              component={DocumentListRecord}
            />
            <PrivateRoute
              menu="business"
              path="/document-list/:riyousyaId"
              component={DocumentListRiyousya}
            />
            <PrivateRoute
              menu="business"
              path="/input-kyotaku-plan1/:riyousyaId/:kyotakuPlan1Id"
              component={KyotakuPlan1Container}
            />
            <PrivateRoute
              menu="business"
              path="/input-kyotaku-plan2/:riyousyaId/:kyotakuPlan2Id"
              component={KyotakuPlan2Container}
            />
            <PrivateRoute
              menu="business"
              path="/input-sisetu-daily-plan/:riyousyaId/:sisetuDailyPlanId"
              component={SisetuDailyPlanContainer}
            />

            <PrivateRoute
              menu="business"
              path="/input-monitoring/:riyousyaId/:monitoringId"
              component={MonitoringContainer}
            />
            <PrivateRoute
              menu="business"
              path="/input-tanto-kaigi/:riyousyaId/:tantoKaigiId"
              component={KaigiContainer}
            />
            <PrivateRoute
              menu="business"
              path="/input-keika-kiroku/:riyousyaId/:keikaKirokuId"
              component={KeikaKirokuContainer}
            />

            <PrivateRoute
              menu="business"
              path="/info-document-list/:index"
              component={InfoDocumentList}
            />
            <PrivateRoute
              menu="business"
              path="/info-document-list-riyousya/:riyousyaId/:index"
              component={InfoDocumentList}
            />
            <PrivateRoute
              menu="business"
              path="/check-info-document-list/:riyousyaId/:recordId"
              component={InfoDocumentList}
            />
            <PrivateRoute
              menu="business"
              path="/in-out-status"
              component={InOutStatus}
            />
            <Route
              component={() => (
                <div className="wrapper text-center">
                  <img
                    src={require("../styles/img/not_found_page.jpg")}
                    alt="Page Not Found 404"
                    height="100%"
                    width="100%"
                  />
                </div>
              )}
            />
          </Switch>
        </Router>
      </Provider>
    </ConfigProvider >
  );
}

export default User;

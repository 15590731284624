import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import { ApiPaths } from "../../constants";
import { KobetsuApi } from "../../reuse/api/kobetsu";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, KobetsuActionTypes } from "../action_types";

export function getKobetsuByRiyousyaId(params, callback) {
  return (dispatch) => {
    dispatch({
      type: KobetsuActionTypes.LOAD_SPIN_REQUEST,
    });

    KobetsuApi.loadKobetsuDetail(params).then(
      (data) => {
        if (typeof callback === "function") {
          callback(data);
        }
        return dispatch({
          type: KobetsuActionTypes.GET_KOBETSU_DETAIL_SUCESS,
          kobetsu: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: KobetsuActionTypes.GET_KOBETSU_DETAIL_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

// function postKobetsuCopy(params, callback) {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: KobetsuActionTypes.LOAD_SPIN_REQUEST });

//       const data = await RequestUtils.callApi({
//         method: "post",
//         path: ApiPaths.LifeList.postCopyKebetsu,
//         body: { ...params },
//       });

//       dispatch({ type: KobetsuActionTypes.CREATE_KOBETSU_SUCCESS, data });

//       return callback(data);

//     } catch (error) {
//       // dispatch({ type: KobetsuActionTypes.CREATE_KOBETSU_ERROR });
//       dispatch({
//         type: ErrorActionTypes.SET_ERROR,
//         error,
//       });
//     }
//   };
// }

export function createKobetsu(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: KobetsuActionTypes.LOAD_SPIN_REQUEST,
    });

    KobetsuApi.createKobetsu(params, riyousyaId).then(
      (data) => {
        callback(data);
        return dispatch({
          type: KobetsuActionTypes.CREATE_KOBETSU_SUCCESS,
          kobetsu: data,
        });
      },
      (err) => {
        checkError(err);
        dispatch({
          type: KobetsuActionTypes.CREATE_KOBETSU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

export function updateKobetsu(params, riyousyaId, callback) {
  return (dispatch) => {
    dispatch({
      type: KobetsuActionTypes.LOAD_SPIN_REQUEST,
    });

    KobetsuApi.updateKobetsu(params, riyousyaId).then(
      (data) =>
        dispatch({
          type: KobetsuActionTypes.EDIT_KOBETSU_SUCCESS,
          kobetsu: data,
          callback,
        }),
      (err) => {
        checkError(err);
        dispatch({
          type: KobetsuActionTypes.EDIT_KOBETSU_ERROR,
          error:
            err.response.status === 400
              ? err.response.data.error
              : { message: err.response.statusText },
        });
      }
    );
  };
}

function deleteKobetsu(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KobetsuActionTypes.REMOVE_KOBETSU_REQUEST });

      const data = await RequestUtils.callApi({
        method: "delete",
        path: ApiPaths.LifeList.delKobetsu + `/${id}`,
      });

      dispatch({ type: KobetsuActionTypes.REMOVE_KOBETSU_SUCCESS, data });
      return callback(data);
    }
    catch (error) {
      dispatch({ type: KobetsuActionTypes.REMOVE_KOBETSU_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getFirstLoadData(riyousyaId, fdayMonth, ldayMonth, callback) {
  return async (dispatch) => {
    try {
      const query = {
        riyousyaId,
        fdayMonth,
        ldayMonth,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getFirstLoadData,
        query,
      });

      dispatch({ type: KobetsuActionTypes.GET_FIRST_LOAD_DATA, data });
      callback(data);
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getZenkaiRecord(riyousyaId, date) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const sid = menu.si || null;

      const query = { sid, riyousyaId, date };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getZenkaiRecord,
        query,
      });

      dispatch({ type: KobetsuActionTypes.GET_ZENKAI_RECORD, kobetsu: data });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListKobetuKinouToQuote(riyousyaId, kobetuId) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, kobetuId, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.LifeList.GetListKobetuKinouToQuote}`,
        query,
      });
      dispatch({
        type: KobetsuActionTypes.LOAD_KOBETU_KINOU_TO_QUOTE_SUCCESS,
        kobetsuDetails: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function goToRecord(riyousyaId, id, type, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KobetsuActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const sid = menu.si || null;

      const query = { sid, riyousyaId, id, type };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getGoToRecord,
        query,
      });

      dispatch({
        type: KobetsuActionTypes.GET_GOTORECORD_RECORD,
        kobetsu: data,
      });

      callback(data);
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListIdsKobetu(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const kobetuSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.kobetu
      ? SWLIFEListRiyousya.sort.kobetu
      : {};
  return async (dispatch) => {
    try {
      dispatch({ type: KobetsuActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = {
        sid: menu.si || null,
        inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
        searchName: params.searchName,
        fromDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        toDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(kobetuSort)),
        riyousyaId: params.riyousyaId,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.GetListIdsKobetu,
        query,
      });

      dispatch({
        type: KobetsuActionTypes.GET_LIST_IDS_KOBETU,
        ids: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getKobetsuByIds(ids) {
  return async (dispatch) => {
    try {
      dispatch({
        type: KobetsuActionTypes.LOAD_KOBETSU_BY_IDS_REQUEST,
        jokusoPlanList: [],
      });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.KobetsuKinou.getKobetsuList,
        query: { ids },
      });
      dispatch({
        type: KobetsuActionTypes.LOAD_KOBETSU_BY_IDS_SUCCESS,
        KobetsuList: data,
      });
    }
    catch (error) {
      dispatch({
        type: KobetsuActionTypes.LOAD_KOBETSU_BY_IDS_ERROR,
        error,
      });
    }
  };
}

function clearKobetsuByIds() {
  return (dispatch) =>
    dispatch({ type: KobetsuActionTypes.CLEAR_KOBETSU_BY_IDS });
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/life/login");
  }
}

export const KobetsuActions = {
  deleteKobetsu,
  getFirstLoadData,
  getZenkaiRecord,
  getListKobetuKinouToQuote,
  goToRecord,
  getListIdsKobetu,
  getKobetsuByIds,
  clearKobetsuByIds,
};

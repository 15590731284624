import { UserOutlined } from '@ant-design/icons';
import { Avatar, Row } from "antd";
import React from "react";
import { Redirect } from "react-router-dom";
import { getValueLocalstorage } from "../function_common/functionCommon";
import { guid } from "../timePicker/formatTimePicker";
import "./Header.css";
import "./LogOutModal.css";
import Left from "./UI/Left";
import Right from "./UI/Right";

class Header extends React.Component {
  constructor(props) {
    super(props);
    const menu = JSON.parse(localStorage.getItem("menu"));
    let current = "record0";
    if (menu && menu.officeAuth === "1") {
      current = localStorage.getItem("currentmenu")
        ? localStorage.getItem("currentmenu")
        : "record0";
    }

    this.state = {
      current,
      ti1: menu.ti1,
      ti2: menu.ti2,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.sessionTimeout !== nextProps.sessionTimeout) {
      return true;
    }
    if (this.props.getAvatarResponseData !== nextProps.getAvatarResponseData) {
      return true;
    }
    return false;
  }

  render() {
    const { ti1, ti2 } = this.state;
    const { getAvatarResponse, getAvatarResponseData } = this.props;
    const avatar = [];
    if (getAvatarResponse || getAvatarResponse === true) {
      if (getAvatarResponseData.substring(0, 4) === "data") {
        avatar.push(
          // <img src={getAvatarResponseData} key={guid()} alt="" />
          <Avatar
            shape="square"
            size={40}
            icon={<UserOutlined />}
            src={getAvatarResponseData}
            key={guid()}
          />
        );
      }
      else {
        avatar.push(
          <Avatar shape="square" size={40} icon={<UserOutlined />} key={guid()} />
        );
      }
    }
    else if (!getAvatarResponse || getAvatarResponse === false) {
      avatar.push(<Avatar shape="square" size={40} icon={<UserOutlined />} key={guid()} />);
    }

    const token = localStorage.getItem("jwtToken");
    if (token && getValueLocalstorage("tid")) {
      const jwtDecode = require("jwt-decode");
      let userName = jwtDecode(token).userInfo.name;
      // if log from kanri mode, fix display name
      const loginMode = getValueLocalstorage("mode");
      if (loginMode) {
        userName = loginMode + "_" + userName;
      }

      const { sessionTimeout } = this.props;
      return (
        <Row className={"main-header"}>
          <Left
            title={this.state.current.indexOf("record") >= 0 ? ti1 : ti2}
            sessionTimeout={sessionTimeout}
          />
          <Right
            current={
              this.state.current.indexOf("record0") >= 0
                ? "record0"
                : this.state.current
            }
            avatar={avatar}
            userName={userName}
          />
        </Row>
      );
    }
    else if (token && !getValueLocalstorage("tid")) {
      return <Redirect to={{ pathname: "/kanri/dashboard" }} />;
    }
    return <Redirect to={{ pathname: "/login" }} />;

  }
}

export default Header;

import { Form } from "@ant-design/compatible";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";

// import '@ant-design/compatible/assets/index.css';


import { Button, Input } from "antd";

import { convertMessageByCode } from "../../../common/function_common/functionCommon";

const FormItem = Form.Item;

class NormalLoginForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit(values.username, values.password);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { errorLogin } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form" autocomplete="off">
        <FormItem>
          {getFieldDecorator("username", {
            rules: [
              { required: true, message: "ログインIDを入力してください" },
            ],
          })(
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="ログインIDを入力してください"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "パスワードを入力してください" },
            ],
          })(
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="パスワードを入力してください"
            />
          )}
        </FormItem>
        <div className={"lg-f-error"}>
          {errorLogin === "LOGIN_FAIL" ? (
            <span>{convertMessageByCode("LOGIN_FAIL")}</span>
          ) : null}
        </div>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            <h3>ログイン</h3>
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const LoginForm = Form.create()(NormalLoginForm);
export default LoginForm;

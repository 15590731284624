import { checkTemplate } from "@pdfme/generator";
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_JokusoPlan_JokusoPlanInputFormPDF = (data) => {
  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];
  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          let temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };
  const generateOption2 = (options, field, firstPosition, endPosition) => {
    if (options && options[field]) {
      let optionsArr = options[field].split(";").filter((item) => !!item);
      const startIndex = firstPosition || 0;
      const endIndex = endPosition || optionsArr.length;
      let resultOptions = [];
      for (let i = startIndex; i < endIndex; i++) {
        let temp = optionsArr[i].includes(":") ? optionsArr[i].split(":")[0] : optionsArr[i];
        resultOptions.push({ label: optionsArr[i] + "\n", value: temp });
      }
      return resultOptions;
    }
    return [];
  };
  const generateOption3 = (options, field) => {
    if (options && options[field]) {
      let optionsArr = options[field].split(";").filter((item) => !!item);
      let resultOptions = [];
      optionsArr.forEach((item) => {
        resultOptions.push({ label: item, value: item.split(":")[1] });
      });

      return resultOptions;
    }
    return [];
  };

  //{ label: item, value: temp }
  //データ
  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options?.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + " ";
      } else {
        result += "□" + item.label + " ";
      }
    });
    return result;
  };
  const generateCheckBoxString2 = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (optionSelected.indexOf(item.value) > -1) {
        result += "■" + item.label;
      } else {
        result += "▢" + item.label;
      }
    });
    return result;
  };

  const generateCheckBoxArray = (options, optionSelected) => {
    const joinarray = options.map((item) => {
      if (item.value === optionSelected) return " ■" + item.label;
      else return " □" + item.label;
    });
    return joinarray.join("");
  };

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result = result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result = result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

    const { jokusoPlan, lifeYougoList } = data;

    if (typeof jokusoPlan === 'object' && Array.isArray(jokusoPlan)) {
      let sampledata = [];
      jokusoPlan?.map((jokusoPlan) => {
        sampledata.push({
          氏名: jokusoPlan.riyousyaName ?? "",
          評価日: formatSeireki(jokusoPlan.hyoukaDate) ?? "",
          計画作成日: formatSeireki(jokusoPlan.makeDate) ?? "",
          生年月日: formatBirthday(jokusoPlan.riyousyaBirthDay) ?? "",
          記入担当者: jokusoPlan.tantoName ?? "",
          殿: `${generateCheckBoxString(GENDER_OPTIONS, jokusoPlan.riyousyaGender)}`,
          現在: `${generateCheckBoxString(generateOption(lifeYougoList, "jokusoumu"), jokusoPlan.isBedsore)}(${generateCheckBoxString(
            generateOption(lifeYougoList, "jokusobui"),
            jokusoPlan.bedsorePartOther
          )}(${jokusoPlan.bedsorePartFreeDescription ? jokusoPlan.bedsorePartFreeDescription : "    "})`,
          過去: `${generateCheckBoxString(generateOption(lifeYougoList, "jokusoumu"), jokusoPlan.isPastBedsore)}(${generateCheckBoxString2(
            generateOption3(lifeYougoList, "jokusobui"),
            jokusoPlan.pastBedsorePart
          )} (${jokusoPlan.pastBedsorePartFreeDescription ? jokusoPlan.pastBedsorePartFreeDescription : "    "})`,
          障害高年者の日常生活自立度: `${generateCheckBoxString(generateOption(lifeYougoList, "ziritsudo"), jokusoPlan.dailyDegree)}`,
          入浴: `${generateCheckBoxString(generateOption(lifeYougoList, "adlnyuyoku"), jokusoPlan.adlBathe)}`,
          食事摂取: `${generateCheckBoxString(generateOption(lifeYougoList, "shokuzisesshu"), jokusoPlan.adlDietaryIntake)}（※1）`,
          上衣: `${generateCheckBoxString(generateOption(lifeYougoList, "koui"), jokusoPlan.adlDressingUpper)}`,
          下衣: `${generateCheckBoxString(generateOption(lifeYougoList, "koui"), jokusoPlan.adlDressingLower)}`,
          寝返り: `${generateCheckBoxString(generateOption(lifeYougoList, "negaeri"), jokusoPlan.kihonTurningOver)}`,
          座位の保持: `${generateCheckBoxString(generateOption(lifeYougoList, "zai"), jokusoPlan.kihonSittingContinuous)}`,
          座位での乗り移り: `${generateCheckBoxString(generateOption(lifeYougoList, "zai"), jokusoPlan.kihonTransfer)}`,
          立位の保持: `${generateCheckBoxString(generateOption(lifeYougoList, "ritsui"), jokusoPlan.kihonKeepStanding)}`,
          尿失禁: `${generateCheckBoxString(generateOption(lifeYougoList, "sikkin"), jokusoPlan.haisetuUrinationIncontinence)}（※2）`,
          便失禁: `${generateCheckBoxString(generateOption(lifeYougoList, "sikkin"), jokusoPlan.haisetuDefecationIncontinence)}（※3）`,
          バルーンカテーテル使用: `${generateCheckBoxString(generateOption(lifeYougoList, "catheter"), jokusoPlan.haisetuBalloonCatheter)}`,
          過去３か月以内に褥瘡の既往があるか: `${generateCheckBoxString(
            generateOption(lifeYougoList, "kioreki"),
            jokusoPlan.isBedsoreWithinThreeMonths
          )}`,
          深さ1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusohukasa", null, 3), jokusoPlan.depthEvaluation)}`,
          浸出液1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "sinshutueki", null, 3), jokusoPlan.leachateEvaluation)}`,
          大きさ1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusoookisa", null, 6), jokusoPlan.sizeEvaluation)}`,
          炎症感染1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "enshou", null, 2), jokusoPlan.infectionEvaluation)}`,
          肉芽組織1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "nikuga", null, 2), jokusoPlan.granulationEvaluation)}`,
          壊死組織1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "esi", null, 1), jokusoPlan.necroticTissueEvaluation)}`,
          ポケット1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusopocket", null, 1), jokusoPlan.pocketSizeEvaluation)}`,
          深さ2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusohukasa", 3, null), jokusoPlan.depthEvaluation)}`,
          浸出液2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "sinshutueki", 3, null), jokusoPlan.leachateEvaluation)}`,
          大きさ2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusoookisa", 6, null), jokusoPlan.sizeEvaluation)}`,
          炎症感染2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "enshou", 2, null), jokusoPlan.infectionEvaluation)}`,
          肉芽組織2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "nikuga", 2, null), jokusoPlan.granulationEvaluation)}`,
          壊死組織2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "esi", 1, null), jokusoPlan.necroticTissueEvaluation)}`,
          ポケット2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusopocket", 1, null), jokusoPlan.pocketSizeEvaluation)}`,
          関連職種が共同して取り組むべき事項: `${formatString10Line(jokusoPlan.mattersRelatedJobs) ?? ""}`,
          評価を行う間隔: `${formatString10Line(jokusoPlan.evaluationInterval) ?? ""}`,
          ベット上: `${formatString10Line(jokusoPlan.removedPressureOnBed) ?? ""}`,
          イス上: `${formatString10Line(jokusoPlan.removedPressureOnChair) ?? ""}`,
          スキンケア: `${formatString10Line(jokusoPlan.skinCare) ?? ""}`,
          栄養状態改善: `${formatString10Line(jokusoPlan.improvedNutrition) ?? ""}`,
          リハビリテーション: `${formatString10Line(jokusoPlan.rehabilitation) ?? ""}`,
          その他: `${formatString10Line(jokusoPlan.bedsoreOther) ?? ""}`,
        })
      })

      return sampledata;
    } else if (typeof jokusoPlan === 'object') {
      let sampledata = [
        {
          氏名: jokusoPlan.riyousyaName ?? "",
          評価日: formatSeireki(jokusoPlan.hyoukaDate) ?? "",
          計画作成日: formatSeireki(jokusoPlan.makeDate) ?? "",
          生年月日: formatBirthday(jokusoPlan.riyousyaBirthDay) ?? "",
          記入担当者: jokusoPlan.tantoName ?? "",
          殿: `${generateCheckBoxString(GENDER_OPTIONS, jokusoPlan.riyousyaGender)}`,
          現在: `${generateCheckBoxString(generateOption(lifeYougoList, "jokusoumu"), jokusoPlan.isBedsore)}(${generateCheckBoxString(
            generateOption(lifeYougoList, "jokusobui"),
            jokusoPlan.bedsorePartOther
          )}(${jokusoPlan.bedsorePartFreeDescription ? jokusoPlan.bedsorePartFreeDescription : "    "})`,
          過去: `${generateCheckBoxString(generateOption(lifeYougoList, "jokusoumu"), jokusoPlan.isPastBedsore)}(${generateCheckBoxString2(
            generateOption3(lifeYougoList, "jokusobui"),
            jokusoPlan.pastBedsorePart
          )} (${jokusoPlan.pastBedsorePartFreeDescription ? jokusoPlan.pastBedsorePartFreeDescription : "    "})`,
          障害高年者の日常生活自立度: `${generateCheckBoxString(generateOption(lifeYougoList, "ziritsudo"), jokusoPlan.dailyDegree)}`,
          入浴: `${generateCheckBoxString(generateOption(lifeYougoList, "adlnyuyoku"), jokusoPlan.adlBathe)}`,
          食事摂取: `${generateCheckBoxString(generateOption(lifeYougoList, "shokuzisesshu"), jokusoPlan.adlDietaryIntake)}（※1）`,
          上衣: `${generateCheckBoxString(generateOption(lifeYougoList, "koui"), jokusoPlan.adlDressingUpper)}`,
          下衣: `${generateCheckBoxString(generateOption(lifeYougoList, "koui"), jokusoPlan.adlDressingLower)}`,
          寝返り: `${generateCheckBoxString(generateOption(lifeYougoList, "negaeri"), jokusoPlan.kihonTurningOver)}`,
          座位の保持: `${generateCheckBoxString(generateOption(lifeYougoList, "zai"), jokusoPlan.kihonSittingContinuous)}`,
          座位での乗り移り: `${generateCheckBoxString(generateOption(lifeYougoList, "zai"), jokusoPlan.kihonTransfer)}`,
          立位の保持: `${generateCheckBoxString(generateOption(lifeYougoList, "ritsui"), jokusoPlan.kihonKeepStanding)}`,
          尿失禁: `${generateCheckBoxString(generateOption(lifeYougoList, "sikkin"), jokusoPlan.haisetuUrinationIncontinence)}（※2）`,
          便失禁: `${generateCheckBoxString(generateOption(lifeYougoList, "sikkin"), jokusoPlan.haisetuDefecationIncontinence)}（※3）`,
          バルーンカテーテル使用: `${generateCheckBoxString(generateOption(lifeYougoList, "catheter"), jokusoPlan.haisetuBalloonCatheter)}`,
          過去３か月以内に褥瘡の既往があるか: `${generateCheckBoxString(
            generateOption(lifeYougoList, "kioreki"),
            jokusoPlan.isBedsoreWithinThreeMonths
          )}`,
          深さ1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusohukasa", null, 3), jokusoPlan.depthEvaluation)}`,
          浸出液1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "sinshutueki", null, 3), jokusoPlan.leachateEvaluation)}`,
          大きさ1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusoookisa", null, 6), jokusoPlan.sizeEvaluation)}`,
          炎症感染1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "enshou", null, 2), jokusoPlan.infectionEvaluation)}`,
          肉芽組織1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "nikuga", null, 2), jokusoPlan.granulationEvaluation)}`,
          壊死組織1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "esi", null, 1), jokusoPlan.necroticTissueEvaluation)}`,
          ポケット1: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusopocket", null, 1), jokusoPlan.pocketSizeEvaluation)}`,
          深さ2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusohukasa", 3, null), jokusoPlan.depthEvaluation)}`,
          浸出液2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "sinshutueki", 3, null), jokusoPlan.leachateEvaluation)}`,
          大きさ2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusoookisa", 6, null), jokusoPlan.sizeEvaluation)}`,
          炎症感染2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "enshou", 2, null), jokusoPlan.infectionEvaluation)}`,
          肉芽組織2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "nikuga", 2, null), jokusoPlan.granulationEvaluation)}`,
          壊死組織2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "esi", 1, null), jokusoPlan.necroticTissueEvaluation)}`,
          ポケット2: `${generateCheckBoxArray(generateOption2(lifeYougoList, "jokusopocket", 1, null), jokusoPlan.pocketSizeEvaluation)}`,
          関連職種が共同して取り組むべき事項: `${formatString10Line(jokusoPlan.mattersRelatedJobs) ?? ""}`,
          評価を行う間隔: `${formatString10Line(jokusoPlan.evaluationInterval) ?? ""}`,
          ベット上: `${formatString10Line(jokusoPlan.removedPressureOnBed) ?? ""}`,
          イス上: `${formatString10Line(jokusoPlan.removedPressureOnChair) ?? ""}`,
          スキンケア: `${formatString10Line(jokusoPlan.skinCare) ?? ""}`,
          栄養状態改善: `${formatString10Line(jokusoPlan.improvedNutrition) ?? ""}`,
          リハビリテーション: `${formatString10Line(jokusoPlan.rehabilitation) ?? ""}`,
          その他: `${formatString10Line(jokusoPlan.bedsoreOther) ?? ""}`,
        },
      ];

      return sampledata;
    } else {
      return [{}];
    }
    
    
};

import _ from "lodash";
import {
  changeSortTab,
  reportSortOptions,
} from "../../common/function_common/functionCommon";

/**
 *
 * @param {*} key
 */
function sortKeyCheck(key) {
  const tabActive = localStorage.getItem("tabActive");
  var num = Number(key);

  if (num == 1) {
    return "riyousya.furigana";
  } else if (num == 2) {
    return "room";
  } else if (num == 3) {
    return "nyuType";
  } else if (num == 4) {
    return "nyusyoDateString";
  } else if (num == 5) {
    return "taishoDateString";
  } else if (num == 6) {
    return tabActive && tabActive === "画像等" ? "kubunRiyou" : "kubun";
  } else if (num == 7) {
    return "bunrui";
  } else if (num > 7) {
    const sortOptions = reportSortOptions(tabActive);
    const option = sortOptions.find((item) => item.value == key);
    return option.key.toString();
  }
}

/**
 *
 * @param {*} arraySort
 * @param {*} key
 * @param {*} type
 */
function sortAlgorithm(arraySort) {
  const tabActive = localStorage.getItem("tabActive");
  const sortTabName = changeSortTab(tabActive);
  const sortData = JSON.parse(localStorage.getItem("is_keep"));

  if (arraySort && sortData) {
    const fieldArray = [],
      orderArray = [];
    if (sortData[sortTabName].key1 && sortData[sortTabName].key1 !== "0") {
      fieldArray.push(sortKeyCheck(sortData[sortTabName].key1));
      if (sortData[sortTabName].value1 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData[sortTabName].key2 && sortData[sortTabName].key2 !== "0") {
      fieldArray.push(sortKeyCheck(sortData[sortTabName].key2));
      if (sortData[sortTabName].value2 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData[sortTabName].key3 && sortData[sortTabName].key3 !== "0") {
      fieldArray.push(sortKeyCheck(sortData[sortTabName].key3));
      if (sortData[sortTabName].value3 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData[sortTabName].key4 && sortData[sortTabName].key4 !== "0") {
      fieldArray.push(sortKeyCheck(sortData[sortTabName].key4));
      if (sortData[sortTabName].value4 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (sortData[sortTabName].key5 && sortData[sortTabName].key5 !== "0") {
      fieldArray.push(sortKeyCheck(sortData[sortTabName].key5));
      if (sortData[sortTabName].value5 === "1") {
        // ASC
        orderArray.push("asc");
      } else {
        orderArray.push("desc");
      }
    }
    if (fieldArray.length > 0) {
      arraySort = _.orderBy(arraySort.slice(), fieldArray, orderArray);
    }
  }

  return arraySort;
}

export default sortAlgorithm;

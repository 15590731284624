import { combineReducers } from "redux";
import error from "./error";
import config from "./config";
import auth from "./auth";
import document from "./document";
import plan1 from "./plan1";
import plan2 from "./plan2";
import inquiryRequest from "./inquiryRequest";
import tantoMaster from "./tantoMaster";
import weeklyPlan from "./weeklyPlan";
import monitoringMNGT from "./monitoring";
import meeting from "./meeting";
import progressMNGT from "./progress";
import monitoringManagement from "./monitoringManagement";
import serviceKikanMaster from "./serviceKikanMaster";
import itakuTantoMaster from "./itakuTantoMaster";
import task from "./task";
import useslip from "./useslip";
import facesheet from "./facesheet";
import kihonCheck from "./kihonCheck";
import yobousienPlan from "./yobousienPlan";
import hyoukahyou from "./hyoukahyou";
import usageTicketManagement from "./usageTicketManagement";

//
import accountReducers from "../../reducers/accountReducers/accountReducers";
import userListReducers from "../../reducers/userListReducers/userListReducers";
import sendGroupReducers from "../../reducers/sendGroupReducers/sendGroupReducers";
import messageListReducers from "../../reducers/messageListReducers/messageListReducers";
import dengonSendToReducers from "../../reducers/dengonSendToReducers/dengonSendToReducers";
import kokyakuReducers from "../../reducers/kokyakuReducers/kokyakuReducers";
import testingReducers from "../../reducers/testingReducers/testingReducers";
import getAvatarReducers from "../../reducers/getAvatarReducers/getAvatarReducers";
import reportReducers from "../../reducers/reportReducers/reportReducers";
import reportUserReducers from "../../reducers/reportUserReducers/reportUserReducers";
import reportWeeklyReducers from "../../reducers/reportWeeklyReducers/reportWeeklyReducers";
import reportWeeklyAddReducers from "../../reducers/reportWeeklyAddReducers/reportWeeklyAddReducers";
import tantoMasterListReducers from "../../reducers/tantoMasterListReducers/tantoMasterListReducers";
import yougoMasterNaiyouReducers from "../../reducers/yougoMasterNaiyouReducers/yougoMasterNaiyouReducers";
import drugMasterReducers from "../../reducers/drugMasterReducers/drugMasterReducers";
import allMasterReducers from "../../reducers/allMasterReducers/allMasterReducers";
import syokichiReducers from "../../reducers/syokichiReducers/syokichiReducers";
import planReducers from "../../reducers/planReducers/planReducers";
import progressReducers from "../../reducers/progressReducers/progressReducers";
import systemNameListReducers from "../../reducers/systemControlReducers/systemControlReducers";
import eventPlanReducers from "../../reducers/eventPlanReducers/eventPlanReducers";
import riyousyaReducers from "../../reducers/riyousyaReducers/riyousyaReducers";
import SettingMasterReducers from "../../reducers/SettingMasterReducers/SettingMasterReducers";
import ZipMasterReducers from "../../reducers/zipMasterReducers/zipMasterReducers";
import dutyReportReducers from "../../reducers/dutyReportReducers/dutyReportReducers";
import documentListReducers from "../../reducers/documentListReducers/DocumentListReducers";
import kyotakuPlan1Reducers from "../../reducers/kyotakuPlan1Reducers/kyotakuPlan1Reducers";
import kyotakuPlan2Reducers from "../../reducers/kyotakuPlan2Reducers/kyotakuPlan2Reducers";
import sisetuDailyPlanReducers from "../../reducers/sisetuDailyPlanReducers/sisetuDailyPlanReducers";
import monitoringReducers from "../../reducers/monitoringReducers/monitoringReducer";
import ioStatusReducers from "../../reducers/ioStatusReducers/ioStatusReducers";
import riyouKaigohokenReducers from "../../reducers/riyouKaigohokenReducers/riyouKaigohokenReducers";
import kaigoYougoMasterReducers from "../../reducers/kaigoYougoMasterReducers/kaigoYougoMasterReducers";
import sisetuKaigoNaiyouMasterReducers from "../../reducers/sisetuKaigoNaiyouMasterReducers/sisetuKaigoNaiyouMasterReducers";
import roomMasterReducers from "../../reducers/roomMasterReducers/roomMasterReducers";
import kanriReducers from "../../reducers/kanriReducers/kanriReducers";
import doctorMasterReducers from "../../reducers/doctorMasterReducers/doctorMasterReducers";
import mediSyoubyoumeiMasterReducers from "../../reducers/mediSyoubyoumeiMasterReducers/mediSyoubyoumeiMasterReducers";
import referenceNumberReducers from "../../reducers/referenceNumberReducers/referenceNumberReducers";
import kaigoHokenMasterReducers from "../../reducers/kaigoHokenMasterReducers/kaigoHokenMasterReducers";
import kohifutanMasterReducers from "../../reducers/kohifutanMasterReducers/kohifutanMasterReducers";
// keikaKirokuReducer management
import keikaKirukuReducers from "../../reducers/keikaKirokuReducers/keikaKirokuReducer";
import tantoKaigiReducers from "../../reducers/tantoKaigiReducers/tantoKaigiReducer";
import fileManagementReducers from "../../reducers/fileManagementReducers/fileManagementReducers";
//共有
import reportAdl from "../../../reducers/reportAdlReducers/reportAdlReducer";
import riyousyaHokenReducers from "../../../reducers/riyousyaHokenReducers/riyousyaHokenReducers";
import iryoKougakuFutanMasterReducers from "../../../reducers/iryoKougakuFutanMasterReducers/iryoKougakuFutanMasterReducers";
import iryouhokenMasterReducers from "../../../reducers/iryouhokenMasterReducers/iryouhokenMasterReducers";

const rootReducer = combineReducers({
  error,
  config,
  auth,
  document,
  plan1,
  plan2,
  inquiryRequest,
  tantoMaster,
  monitoringManagement,
  // clone code
  dataLogin: accountReducers,
  userList: userListReducers,
  sendGroup: sendGroupReducers,
  messageList: messageListReducers,
  dengonSendTo: dengonSendToReducers,
  kokyaku: kokyakuReducers,
  testing: testingReducers,
  getAvatar: getAvatarReducers,
  report: reportReducers,
  reportUser: reportUserReducers,
  reportWeekly: reportWeeklyReducers,
  reportWeeklyAdd: reportWeeklyAddReducers,
  tantoMasterList: tantoMasterListReducers,
  yougoMasterNaiyou: yougoMasterNaiyouReducers,
  drugMaster: drugMasterReducers,
  allMaster: allMasterReducers,
  syokichi: syokichiReducers,
  plan: planReducers,
  progress: progressReducers,
  system: systemNameListReducers,
  eventPlan: eventPlanReducers,
  settingMaster: SettingMasterReducers,
  zipMaster: ZipMasterReducers,
  dutyReport: dutyReportReducers,
  ioStatusReducers,
  documentList: documentListReducers,
  kyotakuPlan1: kyotakuPlan1Reducers,
  kyotakuPlan2: kyotakuPlan2Reducers,
  sisetuDailyPlan: sisetuDailyPlanReducers,
  riyouKaigohokens: riyouKaigohokenReducers,
  riyousya: riyousyaReducers,
  riyousyaHoken: riyousyaHokenReducers,
  kaigoYougoMaster: kaigoYougoMasterReducers,
  sisetuKaigoNaiyouMaster: sisetuKaigoNaiyouMasterReducers,
  roomMaster: roomMasterReducers,
  kanri: kanriReducers,
  doctorMaster: doctorMasterReducers,
  mediSyoubyoumeiMaster: mediSyoubyoumeiMasterReducers,
  referenceNumber: referenceNumberReducers,
  monitoring: monitoringReducers,
  keikaKiroku: keikaKirukuReducers,
  tantoKaigi: tantoKaigiReducers,
  fileManagement: fileManagementReducers,
  kaigoHokenMaster: kaigoHokenMasterReducers,
  kohifutanMaster: kohifutanMasterReducers,
  reportAdl: reportAdl,
  weeklyPlan,
  monitoringMNGT,
  meeting,
  progressMNGT,
  serviceKikanMaster,
  itakuTantoMaster,
  task,
  useslip,
  facesheet,
  kihonCheck,
  yobousienPlan,
  hyoukahyou,

  iryouhokenMaster: iryouhokenMasterReducers,
  iryoKougakuFutanMaster: iryoKougakuFutanMasterReducers,
  usageTicketManagement: usageTicketManagement
});

export default rootReducer;

/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Form } from "@ant-design/compatible";
import { EnterOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, InputNumber, Modal, Popover, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { handleInputNumber } from "../../../common/function_common/functionCommon";
import IryouhokenMasterModal from "./IryouhokenMasterModal";
import JPDateSelectPicker from "./JPDateSelectPicker";
import SelectOptionsForm from "./SelectOptionsForm";
import moment from "moment";

class HokenTableForm3Normal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
      visibleNoKeyboard: false,
      keyType: "",
      value: "",
      visibleIryou: false,
      updateVisible: false,
      hokenName: "",
      errorNameVisible: false,
    };
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouConcern",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  // モーダル
  handleModalIryou = () => {
    this.setState({
      visibleIryou: true,
    });
  };
  onSelectIryou = (record) => {
    const { form } = this.props;
    if (record) {
      const kubun = form.getFieldValue("honnin");
      form.setFieldsValue({
        hokenName: record.hokenName ?? "",
        hokensyaNo: record.hokenNo ?? "",
        hokensyurui: record.bunrui ?? "",
        kyufu:
          kubun === "本人"
            ? record.honKyufu
            : kubun === "家族"
              ? record.kazKyufu
              : 0,
      });
    }

    this.setState({
      visibleIryou: false,
    });
  };
  onHideIryou = () => {
    this.setState({
      visibleIryou: false,
    });
  };

  // inputクリック時(テンキー呼び出し)
  keyInput = (type) => {
    this.setState({
      value: null, // 値
      visibleNoKeyboard: true,
      keyType: type,
    });
  };
  // テンキー入力
  handleInput(value) {
    const { keyType } = this.state;
    const { form } = this.props;

    value = value ? value : "0";
    const checkValue = handleInputNumber(value, this.state.value, true);
    // 直接fieldを変更
    form.setFieldsValue({
      [keyType]: checkValue,
    });

    this.setState({
      value: checkValue, // ステートに保存して数字を重ねる
    });
  }
  // テンキーvisible
  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };
  // テンキーエンター
  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  checkRequired(values) {
    let isError = false;
    const kubun = values.hokenkubun;
    const hokenNo = values.hokensyaNo;
    const kyufu = values.kyufu;
    if (!kubun || !hokenNo || (!kyufu && !kyufu == 0) || kyufu == null) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    }
    else {
      this.setState({
        errorNameVisible: false,
      });
    }
    return isError;
  }

  // OKクリック時
  formHandleInput = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (this.checkRequired(values)) return;
      // 警告 コメントアウト
      // if(!values.futansyaNo || values.futansyaNo == "0"
      //     || !values.jukyusyaNo || values.jukyusyaNo == "0"
      // ) {
      //     this.setState({
      //         updateVisible: true
      //     })
      // } else {
      this.props.update(values);
      // }
    });
  };
  // ok
  formCloseInput = () => {
    this.setState({
      updateVisible: false,
    });
    this.props.closeInput();
  };
  // modal閉じるok
  formUpdateCancel = () => {
    this.setState({
      updateVisible: false,
    });
  };
  // 強行保存ok
  formUpdateOk = () => {
    this.props.updateOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  checkNo = (value) => {
    const { form } = this.props;
    if (value) {
      this.props.getIryouhokenMaster(value, (data) => {
        // 検索した情報を引用
        if (data) {
          const kubun = form.getFieldValue("honnin");
          form.setFieldsValue({
            hokenName: data.hokenName ?? "",
            hokensyurui: data.bunrui ?? "",
            kyufu:
              kubun === "本人"
                ? data.honKyufu
                : kubun === "家族"
                  ? data.kazKyufu
                  : 0,
          });
        }
        else {
          form.setFieldsValue({
            hokenName: "",
          });
        }
      });
    }
  };

  render() {
    const { visible, keyType, errorNameVisible } = this.state;
    const { getFieldDecorator, data } = this.props.form;

    const formItemLayoutCus = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 10 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            {/* <Button onClick={() => this.handleInput('.')}>.</Button> */}
            <Button disabled>-</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        // {...formItemLayout}
        labelAlign="left"
      >
        <Row className="title-inline">
          {/* hidden field place */}
          <Col span={24}>
            <Form.Item
              label={"無し"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("id", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label={"利用者"}
              colon={false}
              {...formItemLayoutCus}
              style={{ display: "none" }}
            >
              {getFieldDecorator("riyousyaId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">保険区分</span>
          </Col>
          <Col span={4}>
            {getFieldDecorator("hokenkubun", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <SelectOptionsForm
                data={["医療", "その他"]}
                style={
                  errorNameVisible
                    ? { border: "1px solid red", borderRadius: 5, width: 120 }
                    : { width: 120 }
                }
              />
            )}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title-red">保険者番号</span>
          </Col>
          <Col span={4}>
            {getFieldDecorator("hokensyaNo", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(
              <Input
                style={
                  errorNameVisible
                    ? { border: "1px solid red", width: 110 }
                    : { width: 110 }
                }
                // style={{ width: 110 }}
                onChange={(value) => this.checkNo(value.target.value)}
              />
            )}
          </Col>
          <Col span={13} style={{ marginLeft: -30 }}>
            <Button type="primary" onClick={this.handleModalIryou}>
              M
            </Button>
            {getFieldDecorator("hokenName")(
              <Input
                readOnly
                // value={this.state.hokenName}
                style={{ width: 330 }}
              />
            )}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">保険種類</span>
          </Col>
          <Col span={5}>
            {getFieldDecorator("hokensyurui", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <SelectOptionsForm
                data={["国保", "社保"]}
                style={{ width: 120 }}
              />
            )}
          </Col>
          <Col span={3} style={{ marginTop: 5 }}>
            <span className="title">本人家族区分</span>
          </Col>
          <Col span={6}>
            {getFieldDecorator("honnin", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <SelectOptionsForm
                data={["本人", "家族"]}
                style={{ width: 120 }}
              />
            )}
          </Col>
          <Col span={2} style={{ marginTop: 5 }}>
            <span className="title-red">給付率</span>
          </Col>
          <MediaQuery query="(min-device-width: 850px)">
            {(matches) =>
              matches ? (
                <Col span={3}>
                  {getFieldDecorator("kyufu", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <InputNumber
                      min={0}
                      max={100}
                      style={
                        errorNameVisible
                          ? { border: "1px solid red", borderRadius: 5 }
                          : {}
                      }
                    />
                  )}
                  %
                </Col>
              ) : (
                <Col span={3}>
                  {getFieldDecorator("kyufu", {
                    rules: [
                      {
                        required: false,
                        message: "必須項目をセットして下さい",
                      },
                    ],
                  })(
                    <InputNumber
                      min={0}
                      max={100}
                      onClick={() => this.keyInput("kyufu")}
                      readOnly
                      style={
                        errorNameVisible
                          ? { border: "1px solid red", borderRadius: 5 }
                          : {}
                      }
                    />
                  )}
                  <Popover
                    visible={
                      !!(this.state.visibleNoKeyboard && keyType == "kyufu")
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <span>%</span>
                  </Popover>
                </Col>
              )
            }
          </MediaQuery>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }} className="title">
            <span>記号</span>
          </Col>
          <Col span={5}>
            <div>
              {getFieldDecorator("kigou", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input style={{ width: 150 }} />)}
            </div>
          </Col>
          <Col span={2} style={{ marginTop: 5 }}>
            <span className="title">番号</span>
          </Col>
          <Col span={5}>
            <div>
              {getFieldDecorator("bango", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input style={{ width: 150 }} />)}
            </div>
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">取得日</span>
          </Col>
          <Col span={9}>
            {getFieldDecorator("syutokuDate", {
              rules: [
                {
                  required: false,
                  message: "必須項目をセットして下さい",
                },
              ],
            })(<JPDateSelectPicker keyId={data && data?.id ? data.id : -1} placement={"right"} />)}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 28 }}>
            <span className="title">有効期間</span>
          </Col>
          <Col span={15}>
            <div style={{ marginBottom: 8 }}>
              {getFieldDecorator("yukoDateFrom", {
                rules: [
                  {
                    required: false,
                    message: "必須項目をセットして下さい",
                  },
                ],
              })(
                <JPDateSelectPicker
                  keyId={data ? data.id : -1}
                  style={{ marginLeft: 0 }}
                  placement={"right"}
                />
              )}
            </div>
            <div>
              <span style={{ display: "flex" }}>
                {getFieldDecorator("yukoDateTo", {
                  rules: [
                    {
                      required: false,
                      message: "必須項目をセットして下さい",
                    },
                  ],
                })(
                  <JPDateSelectPicker
                    keyId={data ? data.id : -1}
                    style={{ marginLeft: 0 }}
                  />
                )}
              </span>
            </div>
          </Col>
        </Row>
        <Row style={{ justifyContent: "flex-end", marginTop: 20 }}>
          <Popover
            visible={this.state.updateVisible}
            title={false}
            content={
              <div style={{ overflow: "hidden" }}>
                <p>必須項目が未入力ですがそのまま登録しますか？</p>
                <div style={{ float: "right" }}>
                  <Button
                    key="update"
                    type={"primary"}
                    onClick={() => this.formUpdateOk()}
                    style={{ marginRight: 5, marginTop: 20 }}
                  >
                    OK
                  </Button>
                  <Button
                    key="cancelUpdate"
                    onClick={() => this.formUpdateCancel()}
                  >
                    キャンセル
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              key="input"
              type={"primary"}
              onClick={() => this.formHandleInput()}
            >
              OK
            </Button>
          </Popover>
          <Button
            key="cancelInput"
            style={{ marginLeft: 8 }}
            onClick={() => this.formCloseInput()}
          >
            キャンセル
          </Button>
        </Row>
        <IryouhokenMasterModal
          visible={this.state.visibleIryou}
          onSelect={this.onSelectIryou}
          onHide={this.onHideIryou}
        />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const HokenTableForm3 = Form.create({
  name: "input-riyou-syokujisen",
  mapPropsToFields(props) {
    // let serviceKikanMasterId = StorageUtils.getValue(getMenu()).si;

    return {
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : props.riyousyaId,
      }),
      hokenkubun: Form.createFormField({
        value: props?.data?.hokenkubun ?? "",
      }),
      hokensyaNo: Form.createFormField({
        value: props?.data?.hokennsyaNo ?? "",
      }),
      hokensyurui: Form.createFormField({
        value: props?.data?.hokensyurui ?? "",
      }),
      honnin: Form.createFormField({
        value: props?.data?.honnin ?? "",
      }),
      kigou: Form.createFormField({
        value: props?.data?.kigou ?? "",
      }),
      bango: Form.createFormField({
        value: props.data.bango ?? "",
      }),
      syutokuDate: Form.createFormField({
        value:
          props.data && props.data.syutokuDate
            ? dayjs(props.data.syutokuDate)
            : dayjs("0001-01-01"),
      }),
      yukoDateFrom: Form.createFormField({
        value:
          props.data && props.data.yukoDateFrom
            ? dayjs(props.data.yukoDateFrom)
            : dayjs("0001-01-01"),
      }),
      yukoDateTo: Form.createFormField({
        value:
          props.data && props.data.yukoDateTo
            ? dayjs(props.data.yukoDateTo)
            : dayjs("0001-01-01"),
        // : dayjs(new Date())
      }),
      kyufu: Form.createFormField({
        value: props?.data?.kyufu ?? 0,
      }),
      hokenName: Form.createFormField({
        value: props?.hokenNameData ?? "",
      }),
    };
  },
})(HokenTableForm3Normal);

/* eslint-disable no-nested-ternary */
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Alert, Button, Col, Input, Row } from "antd";
import React from "react";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'

import CommentKB from "../../../common/model-kiroku/CommentKB";
import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import { PopoverKaigoNaiyou } from "../../documentList/DailyPlan/PopoverKaigoNaiyou";

// import moment from 'moment'

class NormalFormKaigo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: !!this.props.data.reportCaseTyuif,
      type: null,
      extendValue: false,
      visiblePopover: false,
      kaigoNaiyou: null,
    };

    this.onChangeKaigoNaiyou = this.onChangeKaigoNaiyou.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate() {
    // const oldProps = this.props

    // @TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeKaigoNaiyou(value) {
    this.props.handleFormInput(value, "kaigo", "kaigo_naiyou_code");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "kaigo", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "kaigo", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "kaigo", "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 7);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 7);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 7);
  }

  disPopoverKaigo = () => {
    this.setState({
      visiblePopover: true
    });
  }

  hanleVisible = (visible) => {
    this.setState({
      visiblePopover: visible,
    });
  };
  handleClose = () => {
    // this.search();

    this.setState({
      visiblePopover: false,
    });
  }

  selectRecordKaigo = (rc) => {
    // const { rowIndex, dataDaily } = this.state;
    // const dataClone = [...dataDaily];

    // dataClone[rowIndex].name = rc.kaigoNaiyou;
    // dataClone[rowIndex].code = rc.kaigoNaiyouCode;

    this.props.handleFormInput(rc.kaigoNaiyouCode, "kaigo", "kaigo_naiyou_code");

    this.setState({
      // dataDaily: dataClone,
      kaigoNaiyou: rc.kaigoNaiyou,
      visiblePopover: false,
    });
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { kaigoNaiyou } = this.state;
    const { tantoMaster, data, yougoMasterNa, disabled } =
      this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
      // onSubmit={this.handleSubmit}
      >
        {data && data.error && data.errorMasseage ? (
          <Alert message="エラーが発生しました。" type="error" />
        ) : null}

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} style={{ marginBottom: 8 }}>
          <Col sm={6} md={3} className="rwf-title">
            <div>提供内容 :</div>
          </Col>
          <Col className="kdf-label" sm={12} md={6}>
            <Input value={kaigoNaiyou ? kaigoNaiyou : (data.kaigo_naiyou ? data.kaigo_naiyou : null)} style={{ borderRadius: 4 }} />
          </Col>
          <Col className="kdf-label" sm={6} md={3}>
            <Button type="primary" style={{ margin: "0 10px" }} onClick={this.disPopoverKaigo}>選択</Button>
            <PopoverKaigoNaiyou
              visiblePopover={this.state.visiblePopover}
              handleVisible={(visible) => this.hanleVisible(visible)}
              selectRecordKaigo={(rc) => this.selectRecordKaigo(rc)}
              handleClose={() => this.handleClose()}
            />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormKaigo = Form.create()(NormalFormKaigo);
export default FormKaigo;

import React from "react";
import { Icon as LegacyIcon } from "@ant-design/compatible";

const TopLeft = (props) => {
  return (
    <div>
      <span style={{ fontSize: "15px", color: "white" }}>
        <LegacyIcon type={props.iconType} theme="outlined" /> {props.title}
      </span>
      {props.count ? (
        <span
          style={{ fontSize: "15px", color: "white", marginLeft: 20 }}
        >{`${props.countNum}件表示中`}</span>
      ) : null}
      <span style={{ fontSize: "25px", color: "white" }}>{props.content}</span>
    </div>
  );
};

export default TopLeft;

import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, KokuActionTypes } from "../action_types";

function getKokuLifeYougo() {
  return async (dispatch) => {
    try {
      dispatch({ type: KokuActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.Koku.getKokuLifeYougo,
      });

      dispatch({ type: KokuActionTypes.KOKU_LIFE_YOUGO_SUCCESS, data });
    }
    catch (error) {
      dispatch({ type: KokuActionTypes.KOKU_LIFE_YOUGO_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getKokuById(id, sid, riyousyaId, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KokuActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.Koku.getKokuById}/${id}?sid=${sid}&riyousyaId=${riyousyaId}`,
      });

      // sort Ks and KI on Local. by date asc >> 0001-01-001 >> null
      if (data && data.kokuScreening && data.kokuScreening.length > 0) {
        data.kokuScreening.sort(compareDate);
      }

      if (
        data &&
        data.kokuImplementationRecord &&
        data.kokuImplementationRecord.length > 0
      ) {
        data.kokuImplementationRecord.sort(compareDate);
      }

      dispatch({ type: KokuActionTypes.LOAD_KOKU_SUCCESS, data });
      return callback(data);
    }
    catch (error) {
      dispatch({ type: KokuActionTypes.LOAD_KOKU_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteKoku(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KokuActionTypes.REMOVE_KOKU_REQUEST });

      const data = await RequestUtils.callApi({
        method: "delete",
        path: `${ApiPaths.Koku.delKoku}/${id}`,
      });

      dispatch({ type: KokuActionTypes.REMOVE_KOKU_SUCCESS, data });
      return callback(data);
    }
    catch (error) {
      dispatch({ type: KokuActionTypes.REMOVE_KOKU_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createUpKoku(body, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: KokuActionTypes.LOAD_SPIN_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.Koku.saveKoku,
        body,
      });
      dispatch({
        type: KokuActionTypes.SAVE_KOKU_SIEN_SUCCESS,
        data,
      });
      callback(data);
    }
    catch (error) {
      dispatch({
        type: KokuActionTypes.SAVE_KOKU_SIEN_ERROR,
        error,
      });
    }
  };
}

function getListKokuToQuote(riyousyaId, id) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, id, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.Koku.getListKokuToQuote}`,
        query,
      });
      dispatch({
        type: KokuActionTypes.LOAD_KOKU_TO_QUOTE_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListIdsKoku(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const kokuSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.koku
      ? SWLIFEListRiyousya.sort.koku
      : {};
  return async (dispatch) => {
    try {
      dispatch({ type: KokuActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = {
        sid: menu.si || null,
        inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
        searchName: params.searchName,
        fromDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        toDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(kokuSort)),
        riyousyaId: params.riyousyaId,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.Koku.getListIdsKoku,
        query,
      });

      dispatch({
        type: KokuActionTypes.GET_LIST_IDS_KOKU,
        ids: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function compareDate(a, b) {
  if (a.date === "0001-01-01" || b.date === "0001-01-01" || a.date < b.date) {
    return -1;
  }
  if (a.date > b.date) {
    return 1;
  }
  return 0;
}

export const KokuActions = {
  getKokuLifeYougo,
  getKokuById,
  deleteKoku,
  createUpKoku,
  getListKokuToQuote,
  getListIdsKoku,
};

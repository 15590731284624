import React from "react";
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Row, Col } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'

import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";

// import moment from 'moment'

class NormalFormTokki extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      type: null,
      extendValue: false,
    };

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);
  }

  componentDidUpdate(newProps) {
    // const oldProps = this.props

    //@TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "tokki", "tokki");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.tokki) {
      value = this.props.data.tokki + "、" + value;
    }

    this.props.handleFormInput(value, "tokki", "tokki");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "tokki", "tantoId");
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 8);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 8);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 8);
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa, disabled } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    // const formItemLayoutSmall = {
    //     labelCol: {
    //         xs: { span: 24 },
    //         sm: { span: 4 },
    //     },
    //     wrapperCol: {
    //         xs: { span: 24 },
    //         sm: { span: 18 },
    //     },
    // };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        // onSubmit={this.handleSubmit}
      >
        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.tokki}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormTokki = Form.create()(NormalFormTokki);
export default FormTokki;

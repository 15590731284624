import React from "react";
import { EnterOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, InputNumber, Button, Popover } from "antd";

// import { convertMessageByCode } from '../../../common/function_common/functionCommon'
import { handleInputNumber } from "../../../common/function_common/functionCommon";

import TantoMasterKB from "../../../common/model-kiroku/TantoMasterKB";
import CommentKB from "../../../common/model-kiroku/CommentKB";

import InputSelectKB from "../../../common/model-kiroku/InputSelectKB";

// import moment from 'moment'

class NormalFormSuibun extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif ? true : false,
      shokuNasi: this.props.data.shokuNasi ? true : false,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
    };

    this.onChangeSyurui = this.onChangeSyurui.bind(this);
    this.onChangeIntake = this.onChangeIntake.bind(this);

    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    this.handleType = this.handleType.bind(this);
  }

  componentDidUpdate(newProps) {
    // const oldProps = this.props

    //@TODO CHECK
    this.props.form.resetFields();
    // if (oldProps.data !== newProps.data) {
    //     this.props.form.resetFields();
    // }
  }

  onChangeSyurui(value) {
    this.props.handleFormInput(value, "suibun", "syurui");
  }

  onChangeIntake(value) {
    this.props.handleFormInput(value, "suibun", "intake");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "suibun", "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "suibun", "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "suibun", "tantoId");
  }

  handleInput(value) {
    let checkValue = handleInputNumber(value, this.state.value);

    this.props.handleFormInput(checkValue, "suibun", this.state.type);

    this.setState({
      value: checkValue,
    });
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index, 3);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index, 3);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou, 3);
  }

  handleType(type) {
    this.setState({
      type: type,
      value: null,
      visibleNoKeyboard: true,
    });
  }

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { tantoMaster, data, yougoMasterNa, yougoMasterSy, disabled } =
      this.props;

    const { visibleNoKeyboard, type } = this.state;

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button onClick={() => this.handleInput(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 21 },
      },
    };

    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        // onSubmit={this.handleSubmit}
      >
        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>担当者 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item></Form.Item>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>種類 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <InputSelectKB
                value={data.syurui}
                handleInputSelect={this.onChangeSyurui}
                data={yougoMasterSy}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>量 :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              {getFieldDecorator("intake", {
                rules: [{ required: false }],
                initialValue: data.intake,
              })(
                <InputNumber
                  disabled={disabled}
                  min={0}
                  max={100000}
                  onChange={this.onChangeIntake}
                />
              )}
              {disabled ? null : (
                <Popover
                  visible={
                    visibleNoKeyboard && type === "intake" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  type="temperature"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    onClick={() => this.handleType("intake")}
                    type="primary"
                  >
                    入力
                  </Button>
                </Popover>
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col sm={6} md={3} className="rwf-title">
            <div>コメント :</div>
          </Col>
          <Col sm={18} md={21}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={2}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const FormSuibun = Form.create()(NormalFormSuibun);
export default FormSuibun;

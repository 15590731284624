export const jokusoPlanActionTypes = {
  GET_ALL_JOKUSO_PLAN_REQUEST: "GET_ALL_JOKUSO_PLAN_REQUEST",
  GET_ALL_JOKUSO_PLAN_SUCCESS: "GET_ALL_JOKUSO_PLAN_SUCCESS",
  GET_ALL_JOKUSO_PLAN_FAILURE: "GET_ALL_JOKUSO_PLAN_FAILURE",

  REMOVE_JOKUSO_PLAN_REQUEST: "REMOVE_JOKUSO_PLAN_REQUEST",
  REMOVE_JOKUSO_PLAN_SUCCESS: "REMOVE_JOKUSO_PLAN_SUCCESS",
  REMOVE_JOKUSO_PLAN_FAILURE: "REMOVE_JOKUSO_PLAN_FAILURE",

  SAVE_CREATE_JOKUSO_PLAN_REQUEST: "SAVE_CREATE_JOKUSO_PLAN_REQUEST",
  SAVE_CREATE_JOKUSO_PLAN_SUCCESS: "SAVE_CREATE_JOKUSO_PLAN_SUCCESS",
  SAVE_CREATE_JOKUSO_PLAN_ERROR: "SAVE_CREATE_JOKUSO_PLAN_ERROR",

  SAVE_UPDATE_JOKUSO_PLAN_REQUEST: "SAVE_UPDATE_JOKUSO_PLAN_REQUEST",
  SAVE_UPDATE_JOKUSO_PLAN_SUCCESS: "SAVE_UPDATE_JOKUSO_PLAN_SUCCESS",
  SAVE_UPDATE_JOKUSO_PLAN_ERROR: "SAVE_UPDATE_JOKUSO_PLAN_ERROR",

  SET_NEW_DATA: "SET_NEW_DATA",

  GET_LIFE_YOUGO_MASTER: "GET_LIFE_YOUGO_MASTER",
  GET_LIFE_YOUGO_MASTER_SUCCESS: "GET_LIFE_YOUGO_MASTER_SUCCESS",
  GET_LIFE_YOUGO_MASTER_ERROR: "GET_LIFE_YOUGO_MASTER_ERROR",

  GET_YOUGO_MASTER: "GET_YOUGO_MASTER",
  GET_YOUGO_MASTER_SUCCESS: "GET_YOUGO_MASTER_SUCCESS",
  GET_YOUGO_MASTER_ERROR: "GET_YOUGO_MASTER_ERROR",

  SAVE_YOUGO_MASTER: "SAVE_YOUGO_MASTER",
  SAVE_YOUGO_MASTER_SUCCESS: "SAVE_YOUGO_MASTER_SUCCESS",
  SAVE_YOUGO_MASTER_ERROR: "SAVE_YOUGO_MASTER_ERROR",

  HOME_YOUGO_EDIT_STATE: "HOME_YOUGO_EDIT_STATE",
  HOME_YOUGO_CANCEL_STATE: "HOME_YOUGO_CANCEL_STATE",

  LOAD_JOKUSOPLAN_BY_ID_REQUEST: "LOAD_JOKUSOPLAN_BY_ID_REQUEST",
  LOAD_JOKUSOPLAN_BY_ID_SUCCESS: "LOAD_JOKUSOPLAN_BY_ID_SUCCESS",
  LOAD_JOKUSOPLAN_BY_ID_ERROR: "LOAD_JOKUSOPLAN_BY_ID_ERROR",

  LOAD_JOKUSOPLAN_BY_IDS_REQUEST: "LOAD_JOKUSOPLAN_BY_IDS_REQUEST",
  LOAD_JOKUSOPLAN_BY_IDS_SUCCESS: "LOAD_JOKUSOPLAN_BY_IDS_SUCCESS",
  LOAD_JOKUSOPLAN_BY_IDS_ERROR: "LOAD_JOKUSOPLAN_BY_IDS_ERROR",
  CLEAR_JOKUSOPLAN_BY_IDS: "CLEAR_JOKUSOPLAN_BY_IDS",

  CLONE_JOKUSOPLAN_BY_ID_REQUEST: "CLONE_JOKUSOPLAN_BY_ID_REQUEST",
  CLONE_JOKUSOPLAN_BY_ID_SUCCESS: "CLONE_JOKUSOPLAN_BY_ID_SUCCESS",
  CLONE_JOKUSOPLAN_BY_ID_ERROR: "CLONE_JOKUSOPLAN_BY_ID_ERROR",

  GET_LIST_IDS_REQUEST: "GET_LIST_IDS_REQUEST",
  GET_LIST_IDS_SUCCESS: "GET_LIST_IDS_SUCCESS",
  GET_LIST_IDS_ERROR: "GET_LIST_IDS_ERROR",

  GET_ADD_ZIRITUDO: "GET_ADD_ZIRITUDO",
  GET_ADD_ZIRITUDO_SUCCESS: "GET_ADD_ZIRITUDO_SUCCESS",
  GET_ADD_ZIRITUDO_ERROR: "GET_ADD_ZIRITUDO_ERROR",

  LOAD_JOKUSO_TO_QUOTE_REQUEST: "LOAD_JOKUSO_TO_QUOTE_REQUEST",
  LOAD_JOKUSO_TO_QUOTE_SUCCESS: "LOAD_JOKUSO_TO_QUOTE_SUCCESS",
  LOAD_JOKUSO_TO_QUOTE_FAILURE: "LOAD_JOKUSO_TO_QUOTE_FAILURE",

  LOAD_JOKUSO_TO_QUOTE: "LOAD_JOKUSO_TO_QUOTE",
};

/* eslint-disable no-irregular-whitespace */
/* eslint-disable indent */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-constant-condition */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import XlsxPopulate from 'xlsx-populate';

import Holidays from "date-holidays";
import dayjs from "dayjs";
import JapaneseHolidays from "japanese-holidays";
import { ConstSet } from "../configs/constset";

import _, { cloneDeep } from "lodash";

import docIcon from "../images/document/doc.png";
import fileIcon from "../images/document/file.png";
import movieIcon from "../images/document/movie.png";
import pdfIcon from "../images/document/pdf.png";
import pptIcon from "../images/document/ppt.png";
import voiceIcon from "../images/document/voice.png";
import xlsIcon from "../images/document/xls.png";

import { notification } from "antd/lib/index";
import { ConfigName } from "../../config-name";
import { StorageUtils } from "../../management/utils";
import { ListNameSet } from "../configs/listnameset";

import jsonData from "../../data/jsonLocal";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { getTemplate } from "../PDFme/helper";
import { ListTanisikibetu, objectDefaultListDataAttachedPDF } from "../../life/constants";
import { CODE3, handleDataKyufu, KAIGO_CODE_SHORT_TYPE, SPECIAL_TANISIKIBETU } from "../../management/pages/UseSlip/function";
import { fontColorSchedule, ListDaysOfWeek, OfficeDialogPDF10, OfficeDialogPDF6, OfficeDialogPDF8 } from "../../management/constants/useslip";



const defaultPrintOffice = ["提供票","提供票/別表（選択）","提供票/別表（選択）","提供票/別表（全）","利用票/別表・提供票/別表（選択）","利用票/別表・提供票/別表（全）"];
// ユーザ画像取り込み時のリサイズ
const userImageResizeWidth = 150;
const userImageResizeHeight = 150;

/**
 * Convert param to query for kanri search function
 *
 * @param {*} objParam
 */
function kanriQuerySearch(objParam) {
  let result = "";

  if (objParam.value1 && objParam.value2 && objParam.value3 && objParam.type) {
    // objParam.value2.match(/^[0-9]+$/) != null => validate string
    if (objParam.type === 1 || objParam.type === 3 || objParam.type === 4) {
      // query col is char or text
      switch (objParam.value3) {
        case 1: // =
          result = `${objParam.value1} = '${objParam.value2}'`;
          break;

        case 5: // !=
          result = `${objParam.value1} != '${objParam.value2}'`;
          break;

        case 6: // >
          result = `${objParam.value1} > '${objParam.value2}'`;
          break;

        case 7: // <
          result = `${objParam.value1} < '${objParam.value2}'`;
          break;

        case 8: // >=
          result = `${objParam.value1} >= '${objParam.value2}'`;
          break;

        case 9: // <=
          result = `${objParam.value1} <= '${objParam.value2}'`;
          break;

        default:
          break;
      }
    }
    else if (objParam.type === 2) {
      switch (objParam.value3) {
        case 1: // =
          result = `${objParam.value1} LIKE '${objParam.value2}'`;
          break;

        case 2: // %LIKE%
          result = `${objParam.value1} LIKE '%${objParam.value2}%'`;
          break;

        case 3: // LIKE%
          result = `${objParam.value1} LIKE '${objParam.value2}%'`;
          break;

        case 4: // %LIKE
          result = `${objParam.value1} LIKE '%${objParam.value2}'`;
          break;

        case 5: // !=
          result = `${objParam.value1} != '${objParam.value2}'`;
          break;

        default:
          break;
      }
    }
  }

  return result;
}

function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

function getDateInMonth(year, month, date) {
  const newDate = new Date(year, month - 1, date);
  let dayName = "";
  switch (newDate.getDay()) {
    case 0:
      dayName = "日";
      break;
    case 1:
      dayName = "月";
      break;
    case 2:
      dayName = "火";
      break;
    case 3:
      dayName = "水";
      break;
    case 4:
      dayName = "木";
      break;
    case 5:
      dayName = "金";
      break;
    case 6:
      dayName = "土";
      break;
    default:
      dayName = "日";
      break;
  }
  return dayName;
}

function getHolidayInMonth(monthDisplay, yearDisplay) {
  const hd = new Holidays("JP");
  const holidaysList = hd.getHolidays(yearDisplay);
  const holidaysInMonth = [];
  for (let j = 0; j < holidaysList.length; j++) {
    if (
      new Date(holidaysList[j].date).getMonth() + 1 ===
      parseInt(monthDisplay, 0)
    ) {
      holidaysInMonth.push(holidaysList[j]);
    }
  }
  const holidayDate = [];
  for (let h = 0; h < holidaysInMonth.length; h++) {
    if (
      new Date(holidaysInMonth[h].end).getDate() >
      new Date(holidaysInMonth[h].start).getDate() + 1
    ) {
      for (
        let k = new Date(holidaysInMonth[h].start).getDate();
        k <= new Date(holidaysInMonth[h].end).getDate();
        k++
      ) {
        holidayDate.push(k);
      }
    }
    else {
      holidayDate.push(new Date(holidaysInMonth[h].date).getDate());
    }
  }
  return holidayDate;
}

function checkHoliday(date) {
  if (!date) {
    return "";
  }

  const newDate = new Date(`${date}`);
  if (newDate.getDay() === 6) {
    return " c-blue";
  }

  // let hd = new Holidays('JP')
  if (newDate.getDay() === 0 || JapaneseHolidays.isHoliday(newDate)) {
    return " c-red";
  }

  return "";
}

function getDistance(startDate, endDate, startDateSelected, endDateSelected) {
  let distance = "";
  if (startDateSelected && endDateSelected) {
    const startDateNumber = new Date(startDate).getDate();
    const endDateNumber = new Date(endDate).getDate();
    const startDateSelectedNumber = new Date(startDateSelected).getDate();
    const endDateSelectedNumber = new Date(endDateSelected).getDate();
    if (
      endDateSelectedNumber > endDateNumber &&
      startDateSelectedNumber <= startDateNumber
    ) {
      distance = endDateNumber - startDateNumber;
    }
    else if (
      endDateSelectedNumber > endDateNumber &&
      startDateSelectedNumber > startDateNumber
    ) {
      distance = endDateNumber - startDateSelectedNumber;
    }
    else if (
      endDateSelectedNumber <= endDateNumber &&
      startDateSelectedNumber <= startDateNumber
    ) {
      distance = endDateSelectedNumber - startDateNumber;
    }
    else {
      distance = endDateSelectedNumber - startDateSelectedNumber;
    }
  }
  else if (new Date(endDate).getFullYear() === new Date(startDate).getFullYear()) {
    if (new Date(endDate).getMonth() > new Date(startDate).getMonth()) {
      distance = new Date(endDate).getDate();
    }
    else {
      distance = new Date(endDate).getDate() - new Date(startDate).getDate();
    }
  }
  else {
    distance = daysInMonth(
      new Date(endDate).getMonth() + 1,
      new Date(endDate).getFullYear()
    );
  }
  return distance;
}

function convertMessageByCode(messageCode) {
  if (messageCode === "LOGIN_FAIL") {
    return "あなたは登録されていないか、パスワードが違っています。";
  }
  else if (messageCode === "LOGIN_CODE_FAIL") {
    return "あなたは登録されていないか、認証コードが違っています。";
  }
  else if (messageCode === "PARAMS_MISSING") {
    return "必要なパラメータが足りない、又は間違っています。";
  }
  else if (messageCode === "INVALID_TOKEN") {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("doubleLogin");
    localStorage.removeItem("getAvatarResponse");
    localStorage.removeItem("getAvatarResponseData");
    window.location.href = "/login";
  }
  else if (messageCode === "DATE_INVALID_FORMAT") {
    return "日付の形式が正しくないです。";
  }
  else if (messageCode === "START_DATE_MUST_BE_SMALLER_END_DATE") {
    return "開始時間が終了時間より前でなければなりません。";
  }
  else if (messageCode === "NYUSHO_DATE_MUST_BE_SMALLER_TAISHO_DATE") {
    return "入所時間が退所時間より前でなければなりません。";
  }
  else if (messageCode === "END_DATE_MUST_SMALLER_TAISHO_DATE") {
    return "日付範囲に誤りがあります。";
  }
  else if (messageCode === "GAIHAKU_NYUIN_JOKYO_EXISTED") {
    return "既に予定がセットされている為変更できませんでした。";
  }
  else if (messageCode === "NYUTAISHO_JOKYO_EXISTED") {
    return "この利用者のその期間は、既に予定がセットされています。";
  }
  else if (messageCode === "CREATE_ERROR") {
    return "作成できません。";
  }
  else if (messageCode === "RECORD_NOT_FOUND") {
    return "レコードが見つけませんでした。";
  }
  else if (messageCode === "ROOM_BED_HAS_BEEN_USED") {
    return "この部屋には空きがありません。";
  }
  else if (messageCode === "DELETE_ERROR") {
    return "削除できません。";
  }
  else if (messageCode === "RIYOUSYA_NOT_FOUND") {
    return "利用者が存在しません。";
  }
  else if (messageCode === "THIS_FIELD_IS_REQUIRED") {
    return "このフィールドが入力必須です。";
  }
  else if (messageCode === "LOGOUT") {
    return "ログアウト";
  }
  else if (messageCode === "ARE_YOU_SURE") {
    return "ログアウトしてもよろしいですか。";
  }
  else if (messageCode === "UPDATE_ERROR") {
    return "修正できません。";
  }
  else if (messageCode === "ROOM_INVALID") {
    return "該当の部屋が見つかりませんでした。";
  }
  else if (messageCode === "BED_INVALID") {
    return "該当のベッドが見つかりませんでした。";
  }
  else if (messageCode === "NYUTAISHO_NOT_FOUND") {
    return "該当の入所状況が見つかりませんでした。";
  }
  else if (messageCode === "GAIHAKU_NOT_FOUND") {
    return "該当の外泊又は入院の予定が見つかりませんでした。";
  }
  else if (messageCode === "SEARCHING_MISSING_PARAMS") {
    return "検索文字列を入力してください。";
  }
  else if (messageCode === "END_DATE_INVALID") {
    return "日付範囲に誤りがあります。";
  }
  else if (messageCode === "PLAN_EXISTED") {
    return "既に予定がセットされている為変更できませんでした。";
  }
  else if (messageCode === "NYUSHO_DATE_MUST_BE_SMALLER_START_DATE") {
    return "入所日が開始日より前でなければなりません。";
  }
}

/**
 *
 * @param {*} shortFlag
 * @param {*} tempBookFlag
 * @param {*} nyushoKubunFlag
 * @param {*} gaihakuNyuinFlag
 */
function genderStatus(
  shortFlag,
  tempBookFlag,
  nyushoKubunFlag,
  gaihakuNyuinFlag
) {
  if (shortFlag) {
    return "短期利用";
  }
  else if (tempBookFlag) {
    return "仮予約のみ";
  }
  else if (gaihakuNyuinFlag) {
    if (gaihakuNyuinFlag === 1) {
      return "外泊";
    }
    else if (gaihakuNyuinFlag === 2) {
      return "入院";
    }
    return "入所";

  }
  return "入所";

}

/**
 *
 * @param {*} type
 * @param {*} record
 * @param {*} vitalHighLow
 * @param {*} vitalEct
 */
function checkTableField(type, record, vitalHighLow, vitalEct) {
  const range = extractRangeVital(vitalHighLow);
  // let ectTypeCheck = {};
  switch (type) {
    case ConstSet.MODAL_B_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_MAINF:
      return {
        type: "メイン",
        value: record && record.mainf ? record.mainf : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_WEIGHT:
      return {
        type: "体重",
        value: record && record.weight ? record.weight : null,
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_TEMP:
      return {
        type: "体温",
        value: record && record.temperature ? record.temperature : null,
        zenkai: true,
        suggestValues: range.temperature,
      };

    case ConstSet.MODAL_B_BPRESSURE_HIGHT:
      return {
        type: "血圧高",
        value: record && record.bpressureHigh ? record.bpressureHigh : null,
        zenkai: true,
        suggestValues: range.bpressureHigh,
      };

    case ConstSet.MODAL_B_BPRESSURE_LOW:
      return {
        type: "血圧低",
        value: record && record.bpressureLow ? record.bpressureLow : null,
        zenkai: true,
        suggestValues: range.bpressureLow,
      };

    case ConstSet.MODAL_B_PULSEF_NO:
      return {
        type: "脈拍",
        value: record && record.pulse ? record.pulse : null,
        zenkai: true,
        suggestValues: range.pulse,
      };

    case ConstSet.MODAL_B_PULSEF_TYPE:
      return {
        type: "脈拍",
        value: record && record.pulsef ? record.pulsef : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_SPO2_BEFORE:
      return {
        type: "SPO2前",
        value:
          record && record.spo2 && record.spo2.toString().split("-")[0]
            ? record.spo2.toString().split("-")[0]
            : null,
        zenkai: true,
        suggestValues: range.spo2B,
      };

    case ConstSet.MODAL_B_SPO2_AFTER:
      return {
        type: "SPO2後",
        value:
          record && record.spo2 && record.spo2.toString().split("-")[1]
            ? record.spo2.toString().split("-")[1]
            : null,
        zenkai: true,
        suggestValues: range.spo2A,
      };

    case ConstSet.MODAL_B_KOKYU_NO:
      return {
        type: "呼吸",
        value: record && record.kokyu ? record.kokyu : null,
        zenkai: true,
        suggestValues: range.respiration,
      };

    case ConstSet.MODAL_B_KOKYU_TYPE:
      return {
        type: "呼吸",
        value: record && record.kokyuf ? record.kokyuf : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC0:
      // ectTypeCheck = ectCheckInput(etcType);
      return {
        type: vitalEct.split(",")[0].split("=")[1],
        value: record && record.etc1 ? record.etc1 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC1:
      // ectTypeCheck = ectCheckInput(etcType);
      return {
        type: vitalEct.split(",")[1].split("=")[1],
        value: record && record.etc2 ? record.etc2 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC2:
      // ectTypeCheck = ectCheckInput(etcType);
      return {
        type: vitalEct.split(",")[2].split("=")[1],
        value: record && record.etc3 ? record.etc3 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC3:
      return {
        type: "その他4",
        value: record && record.etc4 ? record.etc4 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC4:
      return {
        type: "その他5",
        value: record && record.etc5 ? record.etc5 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC5:
      return {
        type: "その他6",
        value: record && record.etc6 ? record.etc6 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC6:
      return {
        type: "その他7",
        value: record && record.etc7 ? record.etc7 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC7:
      return {
        type: "その他8",
        value: record && record.etc8 ? record.etc8 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC8:
      return {
        type: "その他9",
        value: record && record.etc9 ? record.etc9 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_ETC9:
      return {
        type: "その他10",
        value: record && record.etc10 ? record.etc10 : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_B_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    // Meal
    case ConstSet.MODAL_M_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_SHUBETU:
      return {
        type: "種類",
        value: record && record.kubunMeal ? record.kubunMeal : null,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_STAPLEINTAKE:
      return {
        type: "主食摂取量",
        value: record && record.stapleIntake ? record.stapleIntake : null,
        zenkai:
          !!((record.kubunMeal && record.kubunMeal === "経管栄養") ||
            getValueIskeep("mealType") === "経管栄養"),
        suggestValues: null,
      };

    case ConstSet.MODAL_M_SIDEINTAKE:
      return {
        type: "副食摂取量",
        value: record && record.sideIntake ? record.sideIntake : null,
        zenkai:
          !!((record.kubunMeal && record.kubunMeal === "経管栄養") ||
            getValueIskeep("mealType") === "経管栄養"),
        suggestValues: null,
      };

    case ConstSet.MODAL_M_SHOKUNASI:
      return {
        type: "食無",
        value: record && record.shokuNasi ? record.shokuNasi : null,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : null,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_M_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_S_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_S_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_S_SYURUI:
      return {
        type: "種類",
        value: record && record.syurui ? record.syurui : null,
        zenkai: false,
      };

    case ConstSet.MODAL_S_INTAKE:
      return {
        type: "量",
        value: record && record.intake ? record.intake : null,
        zenkai: true,
      };

    case ConstSet.MODAL_S_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_S_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_H_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_H_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_H_HAISETUKUBUN:
      /**
       * Logic for 排泄分類と提供記録
       * if modalType = ...., the default value(from record -> plan -> select on UI) is taken
       *
       * auto save function have been check if OK save button is true (state) - have been modify by user
       */
      let haisetuKubun =
        record && record.haisetuKubun ? record.haisetuKubun : null;
      if (!haisetuKubun && record && record.settingName == "HB提供内容設定") {
        haisetuKubun = "排便";
      }
      else if (
        !haisetuKubun &&
        record &&
        record.settingName == "HN提供内容設定"
      ) {
        haisetuKubun = "排尿";
      }
      const tmpHaisetuKubun = getValueLocalstorage("haisetuType");
      if (tmpHaisetuKubun && tmpHaisetuKubun !== "両方") {
        haisetuKubun = tmpHaisetuKubun;
      } // end

      return { type: "排泄区分", value: haisetuKubun, zenkai: false };

    case ConstSet.MODAL_H_SHURUI:
      return {
        type: "種類",
        value: record && record.shurui ? record.shurui : null,
        zenkai: false,
      };

    case ConstSet.MODAL_H_HAISETUF:
      return {
        type: "有無",
        value:
          record && record.haisetuf
            ? record.haisetuf
            : record.haisetuf === 0
              ? 0
              : "",
        zenkai: false,
      };

    case ConstSet.MODAL_H_VOL:
      return {
        type: "量",
        value: record && record.vol ? record.vol : null,
        zenkai: true,
      };

    case ConstSet.MODAL_H_JOUTAI:
      return {
        type: "状態",
        value: record && record.joutai ? record.joutai : null,
        zenkai: false,
      };

    case ConstSet.MODAL_H_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_H_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_N_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_N_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_N_NYUYOKUNAIYOU:
      return {
        type: "種類",
        value: record && record.nyuyokuNaiyou ? record.nyuyokuNaiyou : null,
        zenkai: false,
      };

    case ConstSet.MODAL_N_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_N_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_F_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_F_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_F_YAKUMEI:
      return {
        type: "薬名",
        value: record && record.yakumei ? record.yakumei : null,
        zenkai: false,
      };

    case ConstSet.MODAL_F_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_F_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_K_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };
    case ConstSet.MODAL_K_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_K_KAIGONAIYOUCODE:
      /**
       * Logic for 排泄分類と提供記録
       * if modalType = ...., the default value(from record -> plan -> select on UI) is taken
       *
       * auto save function have been check if OK save button is true (state) - have been modify by user
       */
      let kaigoNaiyouCode =
        record && record.kaigo_naiyou_code
          ? record.kaigo_naiyou_code
          : record.kaigoNaiyouCode
            ? record.kaigoNaiyouCode
            : null;
      const tmpKaigoNaiyouCode = getValueIskeep("kiroku_kaigo_naiyou");
      if (tmpKaigoNaiyouCode && tmpKaigoNaiyouCode !== "全表示") {
        kaigoNaiyouCode = tmpKaigoNaiyouCode;
      } // end

      return { type: "提供内容", value: kaigoNaiyouCode, zenkai: false };

    case ConstSet.MODAL_K_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_K_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_T_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_T_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_T_COMMENT:
      return {
        type: "コメント",
        value: record && record.tokki ? record.tokki : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_T_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    //
    case ConstSet.MODAL_G_TIME:
      return {
        type: "時間",
        value: record && record.time ? record.time.substring(0, 5) : "00:00",
        zenkai: true,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_REPORTCASETYUIF:
      return {
        type: "申送",
        value: record && record.reportCaseTyuif ? record.reportCaseTyuif : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_PRIORITYF:
      return {
        type: "優先",
        value: record && record.priorityf ? record.priorityf : 0,
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_KUBUN:
      return {
        type: "区分",
        value: record && record.kubun ? record.kubun : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_COMMENT:
      return {
        type: "コメント",
        value: record && record.comment ? record.comment : "",
        zenkai: false,
        suggestValues: null,
      };

    case ConstSet.MODAL_G_TANTOMASTER:
      return {
        type: "担当者",
        value: record && record.tantoId ? record.tantoId : null,
        zenkai: false,
        suggestValues: null,
      };

    default:
      return { type: null, value: null };
  }
}

/**
 *
 * @param {string} inputChar
 * @param {float} value
 */
function handleInputNumber(inputChar, value) {
  if (inputChar === "消去") {
    value = "";
  }
  else if (inputChar === ".") {
    if (!value) {
      value = "0.";
    }
    else if (!value.includes(".")) {
      value = value + ".";
    }
  }
  else if (!value) {
    // if (inputChar !== '0')
    value = inputChar;
  }
  else {
    value = value + "" + inputChar;
  }

  // let pointNum = parseFloat(value);

  // @TODO: not check much min input. check requirement for this and fix
  // if (!checkMaxMin) {
  //   if (pointNum > 200 || pointNum < 0) {
  //     return 0;
  //   }
  // }

  return value;
}

/**
 *
 * @param {*} url
 */
function getUrlVars(url) {
  const vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

/**
 *
 * @param {array, obj or int, string} Obj
 */
function clone(Obj) {
  let buf;
  if (Obj instanceof Array) {
    buf = []; // create an empty array
    let i = Obj.length;
    while (i--) {
      buf[i] = clone(Obj[i]); // recursively clone the elements
    }
    return buf;
  }
  else if (Obj instanceof Object) {
    buf = {}; // create an empty object
    for (const k in Obj) {
      buf[k] = clone(Obj[k]); // recursively clone the value
    }
    return buf;
  }
  return Obj;

}

function extractRangeVital(vitalHighLow) {
  let temperature = null;
  let bpressureHigh = null;
  let bpressureLow = null;
  let pulse = null;
  let respiration = null;

  if (vitalHighLow) {
    const preVitalHighLow = vitalHighLow.split(";");
    for (let tmpVhl = 0; tmpVhl < preVitalHighLow.length; tmpVhl++) {
      if (preVitalHighLow[tmpVhl].split("_")[0] === "体温") {
        temperature = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "血圧高") {
        bpressureHigh = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "血圧低") {
        bpressureLow = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "脈拍") {
        pulse = preVitalHighLow[tmpVhl].split("_")[1];
      }

      if (preVitalHighLow[tmpVhl].split("_")[0] === "呼吸") {
        respiration = preVitalHighLow[tmpVhl].split("_")[1];
      }
    }

    return {
      temperature,
      bpressureHigh,
      bpressureLow,
      pulse,
      respiration,
      spo2B: null,
      spo2A: null,
    };
  }
}

/**
 * SPO2 の format: spo2B-spo2A, spo2B, -spo2A,
 * @param {sting} spo2
 * @param {number} spo2B
 * @param {number} spo2A
 *
 * Return String
 */
function handleSpo2(spo2, spo2B, spo2A) {
  // handle Spo2B and Spo2A
  let spo2BPre = spo2 ? spo2.toString().split("-")[0] : "";
  let spo2APre =
    spo2 && spo2.toString().split("-")[1] ? spo2.split("-")[1] : "";

  if (spo2B !== null) {
    spo2BPre = spo2B;
  }
  if (spo2A !== null) {
    spo2APre = spo2A;
  }

  if (spo2BPre && spo2APre) {
    spo2 = spo2BPre + "-" + spo2APre;
  }
  else if (spo2BPre && !spo2APre) {
    spo2 = spo2BPre;
  }
  else if (!spo2BPre && spo2APre) {
    spo2 = "-" + spo2APre;
  }
  else {
    spo2 = "";
  }

  return spo2;
}

/**
 * zenkai merge
 */
function zenkaiMerge(type) {
  switch (type) {
    case ConstSet.MODAL_B_WEIGHT:
      return "weight";
    case ConstSet.MODAL_B_TEMP:
      return "temperature";
    case ConstSet.MODAL_B_BPRESSURE_HIGHT:
      return "bpressureHigh";
    case ConstSet.MODAL_B_BPRESSURE_LOW:
      return "bpressureLow";
    case ConstSet.MODAL_B_PULSEF_NO:
      return "pulse";
    case ConstSet.MODAL_B_KOKYU_NO:
      return "kokyu";
    case ConstSet.MODAL_B_SPO2_AFTER:
      return "spo2";
    case ConstSet.MODAL_B_SPO2_BEFORE:
      return "spo2";
    default:
      return null;
  }
}

/**
 * Convert MODAL TYPE TO PARAMETER
 */
function convertToParameter(type) {
  switch (type) {
    case ConstSet.MODAL_B_TIME:
      return "time";

    case ConstSet.MODAL_B_REPORTCASETYUIF:
      return "reportCaseTyuif";

    case ConstSet.MODAL_B_MAINF:
      return "mainf";

    case ConstSet.MODAL_B_TEMP:
      return "temperature";

    case ConstSet.MODAL_B_BPRESSURE_HIGHT:
      return "bpressureHigh";

    case ConstSet.MODAL_B_BPRESSURE_LOW:
      return "bpressureLow";

    case ConstSet.MODAL_B_PULSEF_NO:
      return "pulse";

    case ConstSet.MODAL_B_PULSEF_TYPE:
      return "pulsef";

    case ConstSet.MODAL_B_SPO2_BEFORE:
      return "spo2";

    case ConstSet.MODAL_B_SPO2_AFTER:
      return "spo2";

    case ConstSet.MODAL_B_KOKYU_NO:
      return "kokyu";

    case ConstSet.MODAL_B_KOKYU_TYPE:
      return "kokyuf";

    case ConstSet.MODAL_B_ETC0:
      return "etc1";

    case ConstSet.MODAL_B_ETC1:
      return "etc2";

    case ConstSet.MODAL_B_ETC2:
      return "etc3";

    case ConstSet.MODAL_B_COMMENT:
      return "comment";

    case ConstSet.MODAL_B_TANTOMASTER:
      return "tantoId";

    default:
      return "";
  }
}

/**
 *
 * @param {sting} fieldName
 * @param {sting} value
 */
function setValueLocalstorage(fieldName, value) {
  const key = isManagement()
    ? "swpspsMNGT"
    : isLife()
      ? ConfigName.SWLIFE_SWPSPS
      : "swpsps";

  let preParedata = isManagement()
    ? StorageUtils.getValue(key)
    : isLife()
      ? StorageUtils.getValue(key)
      : JSON.parse(localStorage.getItem(key));

  if (preParedata) {
    preParedata[fieldName] = value;
  }
  else {
    preParedata = {};
    preParedata.started = true;
    preParedata[fieldName] = value;
  }

  if (isManagement()) {
    StorageUtils.setValue(key, preParedata);
  }
  else if (isLife()) {
    StorageUtils.setValue(key, preParedata);
  }
  else {
    localStorage.setItem(key, JSON.stringify(preParedata));
  }
}

/**
 *
 * @param {sting} fieldName
 * @param {sting} value
 *
 * return value
 * if fieldName is null return all
 */
function getValueLocalstorage(fieldName) {
  const key = isManagement()
    ? "swpspsMNGT"
    : isLife()
      ? ConfigName.SWLIFE_SWPSPS
      : "swpsps";
  const preParedata = isManagement()
    ? StorageUtils.getValue(key)
    : isLife()
      ? StorageUtils.getValue(key)
      : JSON.parse(localStorage.getItem(key));

  // return all if field name is  empty
  if (!fieldName) {
    return preParedata;
  }

  // return value
  if (preParedata && preParedata[fieldName]) {
    return preParedata[fieldName].toString();
  }

  // exception
  return null;
}

/**
 *
 * @param {sting} fieldName
 * @param {sting} value
 */
function setValueIsKeep(fieldName, value, fileChild) {
  let preParedata = JSON.parse(localStorage.getItem("is_keep"));

  if (preParedata) {
    // check fileChild
    if (fileChild) {
      preParedata[fieldName][fileChild] = value;
    }
    else {
      preParedata[fieldName] = value;
    }
  }
  else {
    // setStart
    preParedata = jsonData;

    // check fileChild
    if (fileChild) {
      preParedata[fieldName][fileChild] = value;
    }
    else {
      preParedata[fieldName] = value;
    }
  }

  localStorage.setItem("is_keep", JSON.stringify(preParedata));
}

/**
 *
 * @param {sting} fieldName
 * @param {sting} value
 *
 * return value
 * if fieldName is null return all
 */
function getValueIskeep(fieldName) {
  const preParedata = JSON.parse(localStorage.getItem("is_keep"));

  // return all if field name is  empty
  if (!fieldName) {
    return preParedata;
  }

  // return value
  if (preParedata && preParedata[fieldName]) {
    return preParedata[fieldName];
  }

  // exception
  return null;
}

/**
 *
 * @param {*} array
 * @param {*} key
 */
function convertArrayToString(array, key) {
  let data = "";
  if (array && array.length > 0) {
    for (const i in array) {
      data = data + array[i][key] + ";";
    }
  }

  return data;
}

/**
 *
 * @param {file} img
 * @param {*} callback
 */
function getBase64(file, callback) {
  const reader = new FileReader();
  // image turned to base64-encoded Data URI.
  reader.readAsDataURL(file);
  reader.name = file.name; // get the image's name
  reader.size = file.size; // get the image's size
  reader.onload = function (file) {
    const image = new Image(); // create a image
    const dataUrl = file.target.result;
    image.src = dataUrl;
    // img.name = event.target.name;//set name (optional)
    // img.size = event.target.size;//set size (optional)
    image.onload = function (el) {
      const canvas = document.createElement("canvas"); // create a canvas
      canvas.width = userImageResizeWidth;
      canvas.height = userImageResizeHeight;
      const context = canvas.getContext("2d");

      // 画像のサムネイル位置の判定
      let imageCutSize = 0;
      let startX = 0;
      let startY = 0;
      if (image.width < image.height) {
        imageCutSize = image.width;
        startY = (image.height - image.width) / 2;
      }
      else {
        imageCutSize = image.height;
        startX = (image.width - image.height) / 2;
      }

      // 画像の撮影向きに従って、表示を修正
      if (getOrientation(dataUrl)) {
        const orientation = getOrientation(dataUrl);
        if (orientation != 1) {
          context.translate(
            userImageResizeWidth / 2,
            userImageResizeHeight / 2
          );
          if (orientation == 6) context.rotate((90 * Math.PI) / 180);
          if (orientation == 3) context.rotate((180 * Math.PI) / 180);
          if (orientation == 8) context.rotate((270 * Math.PI) / 180);
          context.translate(
            -userImageResizeWidth / 2,
            -userImageResizeHeight / 2
          );
        }
      }

      // draw in canvas
      context.drawImage(
        el.target,
        startX,
        startY,
        imageCutSize,
        imageCutSize,
        0,
        0,
        userImageResizeWidth,
        userImageResizeHeight
      );

      // get the base64-encoded Data URI from the resize image
      const srcEncoded = context.canvas.toDataURL(el.target, "image/jpeg", 0);

      callback(srcEncoded);
    };
  };
}

/**
 * DataURL形式の画像データからOrientation情報(回転情報)を取得します
 * @param imgDataURL
 * @returns
 */
function getOrientation(imgDataURL) {
  const byteString = atob(imgDataURL.split(",")[1]);
  const orientaion = byteStringToOrientation(byteString);
  return orientaion;

  function byteStringToOrientation(img) {
    let head = 0;
    let orientation;
    while (1) {
      if ((img.charCodeAt(head) == 255) & (img.charCodeAt(head + 1) == 218)) {
        break;
      }
      if ((img.charCodeAt(head) == 255) & (img.charCodeAt(head + 1) == 216)) {
        head += 2;
      }
      else {
        const length = img.charCodeAt(head + 2) * 256 + img.charCodeAt(head + 3);
        const endPoint = head + length + 2;
        if ((img.charCodeAt(head) == 255) & (img.charCodeAt(head + 1) == 225)) {
          const segment = img.slice(head, endPoint);
          const bigEndian = segment.charCodeAt(10) == 77;
          if (bigEndian) {
            var count = segment.charCodeAt(18) * 256 + segment.charCodeAt(19);
          }
          else {
            var count = segment.charCodeAt(18) + segment.charCodeAt(19) * 256;
          }
          for (let i = 0; i < count; i++) {
            const field = segment.slice(20 + 12 * i, 32 + 12 * i);
            if (
              (bigEndian && field.charCodeAt(1) == 18) ||
              (!bigEndian && field.charCodeAt(0) == 18)
            ) {
              orientation = bigEndian
                ? field.charCodeAt(9)
                : field.charCodeAt(8);
            }
          }
          break;
        }
        head = endPoint;
      }
      if (head > img.length) {
        break;
      }
    }
    return orientation;
  }
}

/**
 * Check filestyle to render icon thumbnail
 *
 * @param {*} fileType
 */
function checkFileStyle(fileType) {
  switch (fileType) {
    case "doc":
      return {
        isIcon: true,
        icon: docIcon,
        type: fileType,
      };

    case "docx":
      return {
        isIcon: true,
        icon: docIcon,
        type: fileType,
      };

    case "xls":
      return {
        isIcon: true,
        icon: xlsIcon,
        type: fileType,
      };

    case "xlsx":
      return {
        isIcon: true,
        icon: xlsIcon,
        type: fileType,
      };

    case "ppt":
      return {
        isIcon: true,
        icon: pptIcon,
      };

    case "pdf":
      return {
        isIcon: true,
        icon: pdfIcon,
        type: fileType,
      };

    case "mp3":
      return {
        isIcon: true,
        icon: voiceIcon,
        type: fileType,
      };

    case "m4a":
      return {
        isIcon: true,
        icon: voiceIcon,
        type: fileType,
      };

    case "mov":
      return {
        isIcon: true,
        icon: movieIcon,
        type: fileType,
      };

    case "MOV":
      return {
        isIcon: true,
        icon: movieIcon,
        type: fileType,
      };

    case "mp4":
      return {
        isIcon: true,
        icon: movieIcon,
        type: fileType,
      };

    case "jpg":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    case "jpeg":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    case "png":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    case "gif":
      return {
        isIcon: true,
        icon: imgIcon,
        type: fileType,
      };

    default:
      return {
        isIcon: true,
        icon: fileIcon,
        type: fileType,
      };
  }
}

/**
 *
 * @param {*} fileType
 *
 * return icon url for convert to base64
 */
function checkIconUrl(fileType) {
  switch (fileType) {
    case "doc":
      return docIcon;

    case "docx":
      return docIcon;

    case "xls":
      return xlsIcon;

    case "xlsx":
      return xlsIcon;

    case "ppt":
      return pptIcon;

    case "pdf":
      return pdfIcon;

    case "mp3":
      return voiceIcon;

    case "m4a":
      return voiceIcon;

    case "mov":
      return movieIcon;

    case "MOV":
      return movieIcon;

    case "mp4":
      return movieIcon;

    default:
      return fileIcon;
  }
}

/**
 *
 * @param {*} url
 * @param {*} callback
 */
function urlTobase64(src, callback) {
  const canvas = document.createElement("canvas"),
    ctx = canvas.getContext("2d"),
    img = new Image();

  img.onload = function () {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);
    callback(canvas.toDataURL());
  };
  img.src = src;
}

/**
 *
 * @param {*} gazouKubun
 * @param {*} kubun
 */
function gazouKubunExtract(gazouKubun, kubun) {
  if (gazouKubun) {
    gazouKubun
      .toString()
      .split(";")
      .map((item) => {
        if (item) {
          if (
            item.toString().split("_")[1] &&
            item.toString().split("_")[1] === kubun
          ) {
            kubun = [item.toString().split("_")[0]];
            return false;
          }
        }

        // return false;
      });
  }

  return kubun;
}

/**
 *
 * @param {array} vitalCol. setting display vital col by [true, True, False]
 * 体重 - [0], 体温 - [1], 血圧　[2],  脈拍/type - [3][4], 呼吸/type　ー[5][6], SPO2 - [7]
 * @param {int} modalType define type modal display
 * @param {string} type. next function or back function
 */
function vitalColSetting(vitalCol, modalType, type) {
  if (type === "next") {
    // 体重
    if (vitalCol[0] === "False" && modalType === ConstSet.MODAL_B_WEIGHT) {
      modalType = modalType + 1;
    }

    // 体温
    if (vitalCol[1] === "False" && modalType === ConstSet.MODAL_B_TEMP) {
      modalType = modalType + 1;
    }

    // 血圧
    if (
      vitalCol[2] === "False" &&
      modalType === ConstSet.MODAL_B_BPRESSURE_HIGHT
    ) {
      modalType = modalType + 1;
    }

    // 血圧
    if (
      vitalCol[2] === "False" &&
      modalType === ConstSet.MODAL_B_BPRESSURE_LOW
    ) {
      modalType = modalType + 1;
    }

    // 脈拍
    if (vitalCol[3] === "False" && modalType === ConstSet.MODAL_B_PULSEF_NO) {
      modalType = modalType + 1;
    }

    // 脈拍
    if (vitalCol[4] === "False" && modalType === ConstSet.MODAL_B_PULSEF_TYPE) {
      modalType = modalType + 1;
    }

    // 呼吸
    if (vitalCol[5] === "False" && modalType === ConstSet.MODAL_B_KOKYU_NO) {
      modalType = modalType + 1;
    }

    // 呼吸
    if (vitalCol[6] === "False" && modalType === ConstSet.MODAL_B_KOKYU_TYPE) {
      modalType = modalType + 1;
    }

    // SPO2
    if (vitalCol[7] === "False" && modalType === ConstSet.MODAL_B_SPO2_BEFORE) {
      modalType = modalType + 1;
    }

    // SPO2
    if (vitalCol[7] === "False" && modalType === ConstSet.MODAL_B_SPO2_AFTER) {
      modalType = modalType + 1;
    }
  }

  if (type === "pre") {
    // SPO2
    if (vitalCol[7] === "False" && modalType === ConstSet.MODAL_B_SPO2_AFTER) {
      modalType = modalType - 1;
    }

    // SPO2
    if (vitalCol[7] === "False" && modalType === ConstSet.MODAL_B_SPO2_BEFORE) {
      modalType = modalType - 1;
    }

    // 呼吸
    if (vitalCol[6] === "False" && modalType === ConstSet.MODAL_B_KOKYU_TYPE) {
      modalType = modalType - 1;
    }

    // 呼吸
    if (vitalCol[5] === "False" && modalType === ConstSet.MODAL_B_KOKYU_NO) {
      modalType = modalType - 1;
    }

    // 脈拍
    if (vitalCol[4] === "False" && modalType === ConstSet.MODAL_B_PULSEF_TYPE) {
      modalType = modalType - 1;
    }

    // 脈拍
    if (vitalCol[3] === "False" && modalType === ConstSet.MODAL_B_PULSEF_NO) {
      modalType = modalType - 1;
    }

    // 血圧
    if (
      vitalCol[2] === "False" &&
      modalType === ConstSet.MODAL_B_BPRESSURE_LOW
    ) {
      modalType = modalType - 1;
    }

    // 血圧
    if (
      vitalCol[2] === "False" &&
      modalType === ConstSet.MODAL_B_BPRESSURE_HIGHT
    ) {
      modalType = modalType - 1;
    }

    // 体温
    if (vitalCol[1] === "False" && modalType === ConstSet.MODAL_B_TEMP) {
      modalType = modalType - 1;
    }

    // 体重
    if (vitalCol[0] === "False" && modalType === ConstSet.MODAL_B_WEIGHT) {
      modalType = modalType - 1;
    }
  }

  return modalType;
}

/**
 *
 * @param {sting} data. setting_mater etc [1-number, 5-combobox, 4-text]
 *
 * @return object for each type
 */
// function ectCheckInput(data) {
//   let obj = {
//     etc1: {
//       number: false,
//       combobox: false,
//       text: false
//     },
//     etc2: {
//       number: false,
//       combobox: false,
//       text: false
//     },
//     etc3: {
//       number: false,
//       combobox: false,
//       text: false
//     },

//   }

//   if (data) {
//     let arr = data.toString().split(';')

//     // etc1
//     if (arr[0] && arr[0] === '1') {
//       obj.etc1.number = true;
//     }

//     else if (arr[0] && arr[0] === '5') {
//       obj.etc1.combobox = true;
//     }

//     else if (arr[0] && arr[0] === '4') {
//       obj.etc1.text = true;
//     }

//     // etc2
//     if (arr[1] && arr[1] === '1') {
//       obj.etc2.number = true;
//     }

//     else if (arr[1] && arr[1] === '5') {
//       obj.etc2.combobox = true;
//     }

//     else if (arr[1] && arr[1] === '4') {
//       obj.etc2.text = true;
//     }

//     // etc3
//     if (arr[2] && arr[2] === '1') {
//       obj.etc3.number = true;
//     }

//     else if (arr[2] && arr[2] === '5') {
//       obj.etc3.combobox = true;
//     }

//     else if (arr[2] && arr[2] === '4') {
//       obj.etc3.text = true;
//     }
//   }

//   return obj;

// }

/**
 * CheckSetting and turn display type of all vital colum
 *
 * @param {array} arr
 *
 * @returns {object} obj
 */
function colSetting(arr) {
  const obj = {
    taion: false,
    ketsuatsu: false,
    myakuhakuNo: false,
    myakuhakuType: false,
    spo2: false,
    kokyuNo: false,
    kokyuType: false,
  };

  if (arr.length > 0) {
    if (arr[0] && arr[0] === "True") {
      obj.taion = true;
    }

    if (arr[1] && arr[1] === "True") {
      obj.ketsuatsu = true;
    }

    if (arr[2] && arr[2] === "True") {
      obj.myakuhakuNo = true;
    }

    if (arr[3] && arr[3] === "True") {
      obj.myakuhakuType = true;
    }

    if (arr[4] && arr[4] === "True") {
      obj.spo2 = true;
    }

    if (arr[5] && arr[5] === "True") {
      obj.kokyuNo = true;
    }

    if (arr[6] && arr[6] === "True") {
      obj.kokyuType = true;
    }
  }

  return obj;
}

/**
 * extract riyousya base data from localstorage
 *
 * @param {*} riyousyaId
 * @param {*} string
 */
function extractRiyousyaInfo(riyousyaId, riyousyaInfo) {
  const data = [];

  if (riyousyaInfo) {
    const riyoysyaList = JSON.parse(riyousyaInfo);

    if (riyoysyaList.length > 0) {
      for (const i in riyoysyaList) {
        if (riyoysyaList[i].riyousya.id.toString() === riyousyaId) {
          data.push(riyoysyaList[i]);
          break;
        }
      }
    }
  }

  return data;
}

/**
 * extract riyousya base data from localstorage
 *
 * @param {*} riyousyaId
 * @param {*} string
 */
function exRiyousya(riyousyaId) {
  const data = {
    riyousyaName: "",
    riyousyaId: 0,
    nyuType: 0,
    kubun: "",
  };

  const riyoysyaList = JSON.parse(localStorage.getItem("kirokuBasic"));

  if (riyoysyaList.length > 0) {
    for (const i in riyoysyaList) {
      if (riyoysyaList[i].riyousya.id.toString() === riyousyaId.toString()) {
        data.riyousyaName = riyoysyaList[i].riyousya.name;
        data.riyousyaId = riyoysyaList[i].riyousya.id;
        data.nyuType = riyoysyaList[i].nyuType;
        data.kubun = riyoysyaList[i].kubun;
        break;
      }
    }
  }

  return data;
}

/**
 *
 *
 * @param {*} fromDate
 * @param {*} todate
 * @param {*} records
 * @param {*} riyousya
 */
function reportByRiyousyaFormat(parameterObj, records, gaihaku) {
  const fDate = dayjs(parameterObj.startDate);
  const tDate = dayjs(parameterObj.endDate);

  const data = [];
  let index = 0;

  // hardcode dubplicate key
  const tabActive = localStorage.getItem("tabActive");
  for (
    let m = dayjs(fDate);
    m.isBefore(tDate) || m.isSame(tDate);
    m = m.add(1, "day")
  ) {
    let obj = {};
    if (tabActive && tabActive === "画像等") {
      obj = {
        index,
        riyousyaId: parameterObj.riyousyaId.toString(),
        riyousyaName: gaihaku ? gaihaku[0].riyousyaName : "",
        nyuType: "",
        kubunRiyou: gaihaku ? gaihaku[0].kubun : "",
        date: m.format("YYYY-MM-DD"),
        holidayClass: checkHoliday(m.format("YYYY-MM-DD")),
      };
    }
    else {
      obj = {
        index,
        riyousyaId: parameterObj.riyousyaId.toString(),
        riyousyaName: gaihaku ? gaihaku[0].riyousyaName : "",
        nyuType: "",
        kubun: gaihaku ? gaihaku[0].kubun : "",
        date: m.format("YYYY-MM-DD"),
        holidayClass: checkHoliday(m.format("YYYY-MM-DD")),
      };
    }

    // update gaihaku resiki to record
    let nyuType = "空";
    if (gaihaku.length > 0) {
      for (const j in gaihaku) {
        if (gaihaku[j].date === m.format("YYYY-MM-DD")) {
          if (gaihaku[j].gaihakuKubun == "入院") {
            nyuType = "院";
          }
          else if (gaihaku[j].gaihakuKubun == "外泊") {
            nyuType = "外";
          }
          else if (gaihaku[j].gaihakuKubun == "外出") {
            nyuType = "出";
          }
        }
        else if (
          gaihaku[j].nyusyoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].gei == "0"
        ) {
          nyuType = "入";
        }
        else if (
          gaihaku[j].nyusyoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].gei == "1"
        ) {
          nyuType = "迎";
        }
        else if (
          gaihaku[j].taishoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].sou == "0"
        ) {
          nyuType = "退";
        }
        else if (
          gaihaku[j].taishoDate === m.format("YYYY-MM-DD") &&
          gaihaku[j].sou == "1"
        ) {
          nyuType = "送";
        }
        else if (
          nyuType == "空" &&
          gaihaku[j].nyusyoDate < m.format("YYYY-MM-DD") &&
          (gaihaku[j].taishoDate === "0001-01-01" ||
            gaihaku[j].taishoDate > m.format("YYYY-MM-DD"))
        ) {
          nyuType = "";
        }
      }
    }

    obj.nyuType = nyuType;

    let checkRecord = false;
    if (records.length > 0) {
      for (const i in records) {
        if (records[i].date === m.format("YYYY-MM-DD")) {
          checkRecord = true;
          obj.index = index;
          data.push(Object.assign({}, obj, records[i]));
          index++;
        }
      }
    }

    if (!checkRecord) {
      data.push(obj);
      index++;
    }
  }

  return sortAlgorithm(data);
}

/**
 *
 *
 * @param {*} fromDate
 * @param {*} todate
 * @param {*} records
 * @param {*} riyousya
 */
function countRecordByDate(records, recordId, field) {
  let res = 0;

  let date = "";
  records.forEach(function (v) {
    if (v[field] && v[field] === recordId) {
      date = v.date;
    }
  });

  records.forEach(function (v) {
    if (v.date === date) {
      res = (res || 0) + 1;
    }
  });

  return res;
}

/**
 *
 *
 * @param {*} fromDate
 * @param {*} todate
 * @param {*} records
 * @param {*} riyousya
 */
function formatRecords(records, recordId, field) {
  const count = countRecordByDate(records, recordId, field);

  const data = [];

  if (count > 1) {
    let reIndex = 0;
    records.forEach((item) => {
      if (!item[field] || item[field] !== recordId) {
        item.index = reIndex;
        data.push(item);
        reIndex = reIndex + 1;
      }
    });
  }

  if (count === 1) {
    records.forEach((item, index) => {
      if (item[field] && item[field] === recordId) {
        item = {
          index,
          riyousyaId: item.riyousyaId,
          riyousyaName: item.riyousyaName,
          kubun: item.kubun,
          nyuType: item.nyuType,
          date: item.date,
        };
        return data.push(item);
      }
      item.index = index;
      data.push(item);

    });
  }

  return data;
}

/**
 * Covert int item in object to sting. server required all params is string
 *
 * @param {obj} params
 */
function convertStringInArr(params) {
  if (params) {
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (typeof params[key] === "number") {
          params[key] = params[key].toString();
        }
      }
    }
  }

  return params;
}

/**
 *
 * Sort ASC time for report follow riyousya
 *
 * @param {*} arraySort
 * @param {*} key
 * @param {*} type
 */
function sortAlgorithm(arraySort) {
  arraySort = arraySort.slice().sort(function (a, b) {
    // sortData.sort.key1
    if (a.date === b.date && a.time < b.time) return -1;
    return 1;
  });

  return arraySort;
}

/**
 *
 *
isEmpty(""), // true
isEmpty(33), // true (arguably could be a TypeError)
isEmpty([]), // true
isEmpty({}), // true
isEmpty({length: 0, custom_property: []}), // true

isEmpty("Hello"), // false
isEmpty([1,2,3]), // false
isEmpty({test: 1}), // false
isEmpty({length: 3, custom_property: [1,2,3]}) // false
 *
 * @param {*} obj
 */
// Speed up calls to hasOwnProperty
const hasOwnProperty = Object.prototype.hasOwnProperty;
function isEmpty(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

/**
 * Convert YYYY-M-D to YYYY-MM-DD
 *
 * @param {sting} date
 */
function convertDateToX(date) {
  const pad = function (n) {
    return function (str) {
      while (str.length < n) {
        str = "0" + str;
      }
      return str;
    };
  };

  date = date.split(/-/g).map(pad(2)).join("-"); // => 2013-01-01

  return date;
}

function getUrlVarsNew() {
  const vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );

  return vars;
}

/**
 *
 * @param {*} dateString
 */
function addZeroToDateString(dateString) {
  let y = "0000";
  let m = "00";
  let d = "00";

  if (dateString) {
    const arr = dateString.split("-");

    if (arr[0]) {
      y = arr[0];
    }

    if (arr[1]) {
      if (arr[1] < 10) {
        m = "0" + arr[1].replace(/^0+/, "");
      }
      else {
        m = arr[1];
      }
    }

    if (arr[2]) {
      if (arr[2] < 10) {
        d = "0" + arr[2].replace(/^0+/, "");
      }
      else {
        d = arr[2];
      }
    }
  }

  return y + "-" + m + "-" + d;
}

// update parameter on url
function updateUrlParameter(param, value) {
  const regExp = new RegExp(param + "(.+?)(&|$)", "g");
  const newUrl = window.location.href.replace(
    regExp,
    param + "=" + value + "$2"
  );
  window.history.pushState("", "", newUrl);
}

/**
 * Convert from time to active row
 *
 * @param {String: 00:00 or 00:00:00} activeTime
 */
function convertTimeToIndex(timeActive) {
  if (!timeActive) return;

  const timeString = timeActive.substring(0, 5).split(":");

  if (!timeString[1]) return;

  const hr = parseInt(timeString[0], 10);
  const mt = parseInt(timeString[1], 10);

  switch (hr) {
    case 0:
      if (mt < 30) return 0;
      return 1;

    case 1:
      if (mt < 30) return 2;
      return 3;

    case 2:
      if (mt < 30) return 4;
      return 5;

    case 3:
      if (mt < 30) return 6;
      return 7;

    case 4:
      if (mt < 30) return 8;
      return 9;

    case 5:
      if (mt < 30) return 10;
      return 11;

    case 6:
      if (mt < 30) return 12;
      return 13;

    case 7:
      if (mt < 30) return 14;
      return 15;

    case 8:
      if (mt < 30) return 16;
      return 17;

    case 9:
      if (mt < 30) return 18;
      return 19;

    case 10:
      if (mt < 30) return 20;
      return 21;

    case 11:
      if (mt < 30) return 22;
      return 23;

    case 12:
      if (mt < 30) return 24;
      return 25;

    case 13:
      if (mt < 30) return 26;
      return 27;

    case 14:
      if (mt < 30) return 28;
      return 29;

    case 15:
      if (mt < 30) return 30;
      return 31;

    case 16:
      if (mt < 30) return 32;
      return 33;

    case 17:
      if (mt < 30) return 34;
      return 35;

    case 18:
      if (mt < 30) return 36;
      return 37;

    case 19:
      if (mt < 30) return 38;
      return 39;

    case 20:
      if (mt < 30) return 40;
      return 41;

    case 21:
      if (mt < 30) return 42;
      return 43;

    case 22:
      if (mt < 30) return 44;
      return 45;

    case 23:
      if (mt < 30) return 46;
      return 47;

    default:
      return;
  }
}

/**
 * This function clear all localStorage without filter and sort history
 *
 */
function localStorageClearCus() {
  // Keep Filter and Sort history
  // let filter = localStorage.getItem('is_keep');
  // let iosFilter = localStorage.getItem('showFilter');
  // Keep Menu, Filter and Sort history
  // let menu = localStorage.getItem("menu");
  // let currentmenu = localStorage.getItem("currentmenu");
  // if (!currentmenu) {
  //   currentmenu = "record0";
  // }
  // let showreportlist = localStorage.getItem("showreportlist");
  // let officeAuth_roombunrui = localStorage.getItem("officeAuth_roombunrui");
  // let showReportList2 = localStorage.getItem("showreportlist2");
  // let dayKeikaFilter = JSON.parse(localStorage.getItem('dayKeikaFilter'));
  // let riyouKeikaFilter = JSON.parse(localStorage.getItem('riyouKeikaFilter'));

  // Clear Other
  // localStorage.clear();
  // localStorage.removeItem("nyuDate");
  // localStorage.removeItem("jwtToken");

  // Save filter again
  // localStorage.setItem('is_keep', filter);
  // localStorage.setItem('showFilter', iosFilter);
  // Save menu, filter again
  // localStorage.setItem("menu", menu);
  // localStorage.setItem("filter", filter);
  // localStorage.setItem("currentmenu", currentmenu);
  // localStorage.setItem("showreportlist", showreportlist);
  // localStorage.setItem("showreportlist2", showReportList2);

  // if (dayKeikaFilter !== null) {
  //   localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));
  // }
  // if (riyouKeikaFilter !== null) {
  //   localStorage.setItem("riyouKeikaFilter", JSON.stringify(riyouKeikaFilter));
  // }
  // if (officeAuth_roombunrui !== null) {
  //   localStorage.setItem("officeAuth_roombunrui", officeAuth_roombunrui);
  // }

  // partial reset
  let currentmenu = localStorage.getItem("currentmenu");
  if (!currentmenu) {
    currentmenu = "record0";
  }
  let filter = JSON.parse(localStorage.getItem("is_keep"));
  let iosFilter = JSON.parse(localStorage.getItem("showFilter"));
  let showreportlist = JSON.parse(localStorage.getItem("showreportlist"));

  filter = filter ? { ...filter, searchName: "" } : null;
  iosFilter = iosFilter ? { ...iosFilter, searchName: "" } : null;
  showreportlist = showreportlist
    ? { ...showreportlist, searchName: "" }
    : null;

  // Clear Other
  localStorage.removeItem("nyuDate");
  localStorage.removeItem("jwtToken");

  localStorage.setItem("currentmenu", currentmenu);
  localStorage.setItem("is_keep", JSON.stringify(filter));
  localStorage.setItem("showFilter", JSON.stringify(iosFilter));
  localStorage.setItem("showreportlist", JSON.stringify(showreportlist));
}

function localStorageClearCusLogout() {
  // Keep Filter and Sort history
  // let filter = localStorage.getItem('is_keep');
  // let iosFilter = localStorage.getItem('showFilter');
  // Keep Menu, Filter and Sort history
  // let menu = localStorage.getItem("menu");
  // let currentmenu = localStorage.getItem("currentmenu");
  // if (!currentmenu) {
  //   currentmenu = "record0";
  // }
  // let showreportlist = localStorage.getItem("showreportlist");
  // let officeAuth_roombunrui = localStorage.getItem("officeAuth_roombunrui");
  // let showReportList2 = localStorage.getItem("showreportlist2");
  // let dayKeikaFilter = JSON.parse(localStorage.getItem('dayKeikaFilter'));
  // let riyouKeikaFilter = JSON.parse(localStorage.getItem('riyouKeikaFilter'));

  // // Clear Other
  // localStorage.removeItem("jwtToken");
  // localStorage.removeItem("swpsps");
  // localStorage.removeItem("menu");

  // Save filter again
  // localStorage.setItem('is_keep', filter);
  // localStorage.setItem('showFilter', iosFilter);
  // Save menu, filter again
  // localStorage.setItem("menu", menu);
  // localStorage.setItem("filter", filter);
  // localStorage.setItem("currentmenu", currentmenu);
  // localStorage.setItem("showreportlist", showreportlist);
  // localStorage.setItem("showreportlist2", showReportList2);

  // if (dayKeikaFilter !== null) {
  //   localStorage.setItem("dayKeikaFilter", JSON.stringify(dayKeikaFilter));
  // }
  // if (riyouKeikaFilter !== null) {
  //   localStorage.setItem("riyouKeikaFilter", JSON.stringify(riyouKeikaFilter));
  // }
  // if (officeAuth_roombunrui !== null) {
  //   localStorage.setItem("officeAuth_roombunrui", officeAuth_roombunrui);
  // }

  // partial reset
  let currentmenu = localStorage.getItem("currentmenu");
  if (!currentmenu) {
    currentmenu = "record0";
  }
  let filter = JSON.parse(localStorage.getItem("is_keep"));
  let iosFilter = JSON.parse(localStorage.getItem("showFilter"));
  let showreportlist = JSON.parse(localStorage.getItem("showreportlist"));

  filter = filter ? { ...filter, searchName: "" } : null;
  iosFilter = iosFilter ? { ...iosFilter, searchName: "" } : null;
  showreportlist = showreportlist
    ? { ...showreportlist, searchName: "" }
    : null;

  // Clear Other
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("swpsps");

  localStorage.setItem("currentmenu", currentmenu);
  localStorage.setItem("is_keep", JSON.stringify(filter));
  localStorage.setItem("showFilter", JSON.stringify(iosFilter));
  localStorage.setItem("showreportlist", JSON.stringify(showreportlist));
}

function openNotificationWithIcon(type, notifyContent, callback) {
  notification[type]({
    message: notifyContent,
    placement: "topRight",
    duration: type === "success" ? 1 : 3,
    style: { whiteSpace: "pre-wrap" },
    prefixCls: "pljp-notification",
  });
  if (callback) {
    callback();
  }
}

function disPlanToString(eventPlan, riyousyaYoutei, riyousyaSonota) {
  let result = "";

  if (eventPlan) {
    result = eventPlan;
  }

  if (riyousyaYoutei) {
    if (result) {
      result = result + "、" + riyousyaYoutei;
    }
    else {
      result = riyousyaYoutei;
    }
  }

  if (riyousyaSonota) {
    if (result) {
      result = result + "、" + riyousyaSonota;
    }
    else {
      result = riyousyaSonota;
    }
  }

  return result;
}

/**
 * Fuction prepare parameters for loading kiroku data
 *
 * Using for search, sort...function
 * When user search or sort,
 * all data have been reload
 * and then the sort and the search algorithm have been apply on that data
 *
 */
function reportParams(objState, type) {
  switch (objState.tabName) {
    case ConstSet.KIROKU_RIOYUSYA:
      return objState;

    case ConstSet.KIROKU_VITAL:
      let startTime = "0:00";
      let endTime = "23:59";
      if (
        getValueIskeep("jikantai") &&
        getValueIskeep("jikantai") !== "全表示"
      ) {
        startTime = getValueIskeep("jikantai").split("~")[0];
        endTime = getValueIskeep("jikantai").split("~")[1];
      }
      return {
        isLoadBasic: objState.isLoadBasic,
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.startDate,
        endDate: objState.endDate,
        startTime, // vital
        endTime,
        shubetu: objState.shubetu,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_MEAL:
      return objState;

    case ConstSet.KIROKU_SUIBUN:
      let startTimeSb = "0:00";
      let endTimeSb = "23:59";
      if (
        getValueIskeep("jikantaiSb") &&
        getValueIskeep("jikantaiSb") !== "全表示"
      ) {
        startTimeSb = getValueIskeep("jikantaiSb").split("~")[0];
        endTimeSb = getValueIskeep("jikantaiSb").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeSb, // suibun
        endTimeSb,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_HAISETU:
      let startTimeH = "0:00";
      let endTimeH = "23:59";
      if (
        getValueIskeep("jikantaiH") &&
        getValueIskeep("jikantaiH") !== "全表示"
      ) {
        startTimeH = getValueIskeep("jikantaiH").split("~")[0];
        endTimeH = getValueIskeep("jikantaiH").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeH, // haisetu
        endTimeH,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_NYUYOKU:
      let startTimeN = "0:00";
      let endTimeN = "23:59";
      if (
        getValueIskeep("jikantaiN") &&
        getValueIskeep("jikantaiN") !== "全表示"
      ) {
        startTimeN = getValueIskeep("jikantaiN").split("~")[0];
        endTimeN = getValueIskeep("jikantaiN").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeN, // nyushoku
        endTimeN,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_FUKUYAKU:
      let startTimeF = "0:00";
      let endTimeF = "23:59";
      if (
        getValueIskeep("jikantaiF") &&
        getValueIskeep("jikantaiF") !== "全表示"
      ) {
        startTimeF = getValueIskeep("jikantaiF").split("~")[0];
        endTimeF = getValueIskeep("jikantaiF").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeF, // fukuyaku
        endTimeF,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_KAIGO:
      let startTimeK = "0:00";
      let endTimeK = "23:59";
      if (
        getValueIskeep("jikantaiK") &&
        getValueIskeep("jikantaiK") !== "全表示"
      ) {
        startTimeK = getValueIskeep("jikantaiK").split("~")[0];
        endTimeK = getValueIskeep("jikantaiK").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeK, // kaigonaigo
        endTimeK,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_TOKKI:
      let startTimeT = "0:00";
      let endTimeT = "23:59";
      if (
        getValueIskeep("jikantaiT") &&
        getValueIskeep("jikantaiT") !== "全表示"
      ) {
        startTimeT = getValueIskeep("jikantaiT").split("~")[0];
        endTimeT = getValueIskeep("jikantaiT").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeT, // tokki
        endTimeT,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    case ConstSet.KIROKU_FILE:
      let startTimeG = "0:00";
      let endTimeG = "23:59";
      if (
        getValueIskeep("jikantaiG") &&
        getValueIskeep("jikantaiG") !== "全表示"
      ) {
        startTimeG = getValueIskeep("jikantaiG").split("~")[0];
        endTimeG = getValueIskeep("jikantaiG").split("~")[1];
      }
      return {
        isLoadBasic: type === "date",
        nyutaishoDate: objState.nyutaishoDate,
        tabName: objState.tabName,
        startDate: objState.nyutaishoDate,
        endDate: objState.nyutaishoDate,
        startTimeG, // gazou
        endTimeG,
        shubetu: objState.mealType,
        haisetuKubun: objState.haisetuKubun,
        gender: objState.gender,
        kaigoNaiyouCode: objState.kaigoNaiyouCode,
      };

    default:
      return objState;
  }
}

/**
 * Function add element to data that return after insert kiroku
 *
 * DOM is updated directly, not reload data of page agian
 *
 */
function modifyAfterInsert(payload, item) {
  // update index key to return value
  payload.key = item.index;
  payload.index = item.index;

  // update riyousya information
  payload.riyousya = item.riyousya;

  //
  payload.kubun = item.kubun;

  //
  payload.bunrui = item.bunrui;

  //
  payload.room = item.room;

  //
  payload.nyuType = item.nyuType;

  // with gazo tab(image), kubun of riyou has been convert to kubunRiyou
  payload.kubunRiyou = item.kubunRiyou;

  return payload;
}

/**
 * extract value from setting by index and position
 *
 * @param {ABC_XYZ;BCD_BBD;JPJ_VNV} setting
 * @param {0->n of Arr} index
 * @param {0/1} pos
 *
 * @returns string
 */
function extractKubunSetting(setting, index, pos) {
  let result = "";

  if (setting) {
    const tmpArrParent = setting.toString().split(";");

    if (tmpArrParent[index]) {
      const tmpArrChild = tmpArrParent[index].toString().split("_");

      if (tmpArrChild[pos]) {
        result = tmpArrChild[pos];
      }
    }
  }

  return result;
}

/**
 *
 * @param {*} data // riyousya detail
 * @param {*} field // riyou tabs: riyouConcern,...など
 * @param {*} type // add, up/down, del
 * @param {*} index // handling index
 */
function riyouAddSortDel(data, field, type, index) {
  const result = _.cloneDeep(data);

  switch (field) {
    case "riyouConcern": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouConcern = handleRiyouAdd(result.riyouConcern);
          return result;
        case "up":
          result.riyouConcern = handleSort(result.riyouConcern, type, index);
          return result;

        case "down":
          result.riyouConcern = handleSort(result.riyouConcern, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouByoureki": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouByoureki = handleRiyouAdd(result.riyouByoureki);
          return result;
        case "up":
          result.riyouByoureki = handleSort(result.riyouByoureki, type, index);
          return result;

        case "down":
          result.riyouByoureki = handleSort(result.riyouByoureki, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouKusuri": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouKusuri = handleRiyouAdd(result.riyouKusuri);
          return result;
        case "up":
          result.riyouKusuri = handleSort(result.riyouKusuri, type, index);
          return result;

        case "down":
          result.riyouKusuri = handleSort(result.riyouKusuri, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouKiki": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouKiki = handleRiyouAdd(result.riyouKiki);
          return result;
        case "up":
          result.riyouKiki = handleSort(result.riyouKiki, type, index);
          return result;

        case "down":
          result.riyouKiki = handleSort(result.riyouKiki, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouService": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouService = handleRiyouAdd(result.riyouService);
          return result;
        // case ('up'):
        //   result.riyouService = handleSort(result.riyouService, type, index)
        //   return result;

        // case ('down'):
        //   result.riyouService = handleSort(result.riyouService, type, index)
        //   return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "riyouNyuin": // data.riyouConcern
      switch (type) {
        case "add":
          result.riyouNyuin = handleRiyouAdd(result.riyouNyuin, "riyouNyuin");
          return result;
        case "up":
          result.riyouNyuin = handleSort(result.riyouNyuin, type, index);
          return result;

        case "down":
          result.riyouNyuin = handleSort(result.riyouNyuin, type, index);
          return result;

        case "del":
          return handleRiyouDel(result, field, index);

        default:
          return result;
      }

    case "test":
      return result;

    default:
  }
}

/**
 * mark add + reindex
 *
 * @param {*} data
 */
function handleRiyouAdd(data, type) {
  let tmp = [];
  if (data.length > 0) {
    const tmp = [];
    for (let i = 0; i <= data.length; i++) {
      if (type && type === "riyouNyuin") {
        // if riyouNyuin tabs, the record will be put to front of
        if (!i) {
          // case i = 0
          tmp.push({ sortNum: 1, inputType: "add" });

          tmp.push(Object.assign(data[i], { sortNum: i + 2 }));
        }

        if (i && i < data.length) {
          // case i !== 0
          tmp.push(Object.assign(data[i], { sortNum: i + 2 }));
        }
      }
      else {
        if (i < data.length) {
          tmp.push(Object.assign(data[i], { sortNum: i + 1 }));
        }

        if (i === data.length) {
          tmp.push({ sortNum: i + 1, inputType: "add" });
        }
      }
    }

    return tmp;
  }
  tmp = [{ sortNum: 1, inputType: "add" }];

  return tmp;

}

/**
 * handle del, mark recordId that have been delete if saving button is click
 * rewrite sortNum field
 * return riyousyaDetail
 * @param {*} data
 * @param {*} field
 * @param {*} index
 */
function handleRiyouDel(data, field, index) {
  const tmp = [];
  if (data && data[field] && data[field].length > 0) {
    let count = 1;
    for (let i = 0; i < data[field].length; i++) {
      if (i !== index) {
        tmp.push(Object.assign(data[field][i], { sortNum: count }));
        count++;
      }

      if (i === index) {
        if (data[`${field}DelIds`]) {
          data[`${field}DelIds`] =
            data[`${field}DelIds`] + "," + data[field][i].id;
        }
        else {
          data[`${field}DelIds`] = "" + data[field][i].id;
        }
      }
    }

    data[field] = tmp;
  }

  return data;
}

/**
 *
 * @param {*} data
 * @param {*} sortType
 * @param {*} index
 */
function handleSort(data, sortType, index) {
  if (sortType === "up") {
    if (index !== 0) {
      data[index - 1].sortNum = data[index - 1].sortNum + 1;
      data[index].sortNum = data[index].sortNum - 1;

      return _.sortBy(data, ["sortNum"]);
    }
  }

  if (sortType === "down") {
    if (index !== data.length - 1) {
      data[index + 1].sortNum = data[index + 1].sortNum - 1;
      data[index].sortNum = data[index].sortNum + 1;

      return _.sortBy(data, ["sortNum"]);
    }
  }

  return data;
}

/**
 *
 * @param {*} data
 * @param {*} forms:
 * forms.formRefBasic - object,
 * forms.formRefKankei - arrayObject,
 * forms.formRefByoki - arrayObject,
 * forms.formRefKusuri - arrayObject,
 * forms.formRefKiki - arrayObject,
 * forms.formRefService - arrayObject,
 * forms.formRefNyuin - arrayObject,
 * forms.formRefFacesheet - object,
 * forms.formRefAdl - object,
 * forms.formRefJokyo - object,
 * forms.formRefHoka - object,
 *
 */
function riyouMergeFormToState(data, forms, facesheetId, facesheetType) {
  const mainSID = formatStorage(getMenu()).si;
  const riyousyaId = data.id;

  let result = _.cloneDeep(data);
  // formRefBasic
  if (forms && forms.formRefBasic) {
    result = Object.assign({}, result, forms.formRefBasic);
    result.facesheet = {};
  }

  // formRefKankei
  if (
    forms &&
    !_.isEmpty(forms.formRefKankei) &&
    forms.formRefKankei.length > 0
  ) {
    const tmpConcert = [];
    for (let i = 0; i < forms.formRefKankei.length; i++) {
      const cusCheckbox = {
        doukyof: forms.formRefKankei[i].doukyof
          ? forms.formRefKankei[i].doukyof.length
          : 0,
        kaigof:
          forms.formRefKankei[i].kaigof1 &&
            forms.formRefKankei[i].kaigof1.length
            ? 1
            : forms.formRefKankei[i].kaigof2 &&
              forms.formRefKankei[i].kaigof2.length
              ? 2
              : 0,
        seikyuf: forms.formRefKankei[i].seikyuf
          ? forms.formRefKankei[i].seikyuf.length
          : 0,
        keypersonf: forms.formRefKankei[i].keypersonf
          ? forms.formRefKankei[i].keypersonf.length
          : 0,
        moshikomif: forms.formRefKankei[i].moshikomif
          ? forms.formRefKankei[i].moshikomif.length
          : 0,
        hoshoninf: forms.formRefKankei[i].hoshoninf
          ? forms.formRefKankei[i].hoshoninf.length
          : 0,
        urgentf: forms.formRefKankei[i].urgentf
          ? forms.formRefKankei[i].urgentf.length
          : 0,
        furigana: forms.formRefKankei[i].furigana
          ? forms.formRefKankei[i].furigana
          : "",
        name: forms.formRefKankei[i].name ? forms.formRefKankei[i].name : "",
        kankei: forms.formRefKankei[i].kankei
          ? forms.formRefKankei[i].kankei
          : "",
      };

      if (data.riyouConcern && data.riyouConcern[i]) {
        tmpConcert.push(
          Object.assign(
            {},
            data.riyouConcern[i],
            forms.formRefKankei[i],
            { riyousyaId },
            cusCheckbox
          )
        );
      }
    }

    result.riyouConcern = tmpConcert;
  }

  // formRefByoki
  if (
    forms &&
    !_.isEmpty(forms.formRefByoki) &&
    forms.formRefByoki.length > 0
  ) {
    const tmpByoki = [];
    for (let i = 0; i < forms.formRefByoki.length; i++) {
      const cusCheckbox = {
        mainf: forms.formRefByoki[i].mainf
          ? forms.formRefByoki[i].mainf.length
          : 0,
        kansenf: forms.formRefByoki[i].kansenf
          ? forms.formRefByoki[i].kansenf.length
          : 0,
        tokuteif: forms.formRefByoki[i].tokuteif
          ? forms.formRefByoki[i].tokuteif.length
          : 0,
        ryoyoSyokuf: forms.formRefByoki[i].ryoyoSyokuf
          ? forms.formRefByoki[i].ryoyoSyokuf.length
          : 0,
        kangoSijif: forms.formRefByoki[i].kangoSijif
          ? forms.formRefByoki[i].kangoSijif.length
          : 0,
      };
      if (data.riyouConcern && data.riyouByoureki[i]) {
        tmpByoki.push(
          Object.assign(
            {},
            data.riyouByoureki[i],
            forms.formRefByoki[i],
            { riyousyaId },
            cusCheckbox
          )
        );
      }
    }

    result.riyouByoureki = tmpByoki;
  }

  // formRefKusuri
  if (
    forms &&
    !_.isEmpty(forms.formRefKusuri) &&
    forms.formRefKusuri.length > 0
  ) {
    const tmpKusuri = [];
    for (let i = 0; i < forms.formRefKusuri.length; i++) {
      if (data.riyouKusuri && data.riyouKusuri[i]) {
        const tmpUpdateField = {
          riyousyaId,
          stop: 0,
          bikou: forms.formRefKusuri[i].bikou
            ? forms.formRefKusuri[i].bikou
            : "",
        };

        tmpKusuri.push(
          Object.assign(
            {},
            data.riyouKusuri[i],
            forms.formRefKusuri[i],
            tmpUpdateField
          )
        );
      }
    }

    result.riyouKusuri = tmpKusuri;
  }

  // formRefKiki
  if (forms && !_.isEmpty(forms.formRefKiki) && forms.formRefKiki.length > 0) {
    const tmpKiki = [];

    for (let i = 0; i < forms.formRefKiki.length; i++) {
      if (data.riyouKiki && data.riyouKiki[i]) {
        const tmpUpdateField = {
          riyousyaId,
          bikou: forms.formRefKiki[i].bikou ? forms.formRefKiki[i].bikou : "",
        };

        tmpKiki.push(
          Object.assign(
            {},
            data.riyouKiki[i],
            forms.formRefKiki[i],
            tmpUpdateField
          )
        );

      }
    }

    result.riyouKiki = tmpKiki;
  }

  // formRefService
  if (
    forms &&
    !_.isEmpty(forms.formRefService) &&
    forms.formRefService.length > 0
  ) {
    const tmpService = [];

    for (let i = 0; i < forms.formRefService.length; i++) {
      if (forms.formRefService[i].kaishiDate) {
        forms.formRefService[i].kaishiDate = forms.formRefService[
          i
        ].kaishiDate.format(ConstSet.DATE_FORMAT);
      }

      if (data.riyouService && data.riyouService[i]) {
        tmpService.push(
          Object.assign({}, data.riyouService[i], forms.formRefService[i], {
            riyousyaId,
          })
        );
      }
    }

    result.riyouService = tmpService;
  }

  // formRefNyuin
  if (
    forms &&
    !_.isEmpty(forms.formRefNyuin) &&
    forms.formRefNyuin.length > 0
  ) {
    const tmpNyuin = [];

    for (let i = 0; i < forms.formRefNyuin.length; i++) {
      // if (forms.formRefNyuin[i].nyuinDate) {
      //   forms.formRefNyuin[i].nyuinDate = forms.formRefNyuin[i].nyuinDate.format(ConstSet.DATE_FORMAT);
      // }

      // if (forms.formRefNyuin[i].tainDate) {
      //   forms.formRefNyuin[i].tainDate = forms.formRefNyuin[i].tainDate.format(ConstSet.DATE_FORMAT);
      // }

      if (data.riyouNyuin && data.riyouNyuin[i]) {
        tmpNyuin.push(
          Object.assign({}, data.riyouNyuin[i], forms.formRefNyuin[i], {
            riyousyaId,
          })
        );
      }
    }

    result.riyouNyuin = tmpNyuin;
  }

  // formRefFacesheet
  let facesheetOri = {};
  if (data && data.facesheet && data.facesheet.length > 0) {
    if (facesheetId > 0) {
      facesheetOri = data.facesheet.find(
        (item) => item.id.toString() === facesheetId.toString()
      );

      if (!facesheetOri) {
        facesheetOri = data.facesheet[0];
      }
    }
    else {
      facesheetOri = data.facesheet[0];
    }
  }

  if (forms && !_.isEmpty(forms.formRefFacesheet)) {
    // if (forms.formRefFacesheet.date) {
    //   forms.formRefFacesheet.date = forms.formRefFacesheet.date.format(ConstSet.DATE_FORMAT);
    // }

    result.facesheet = Object.assign({}, facesheetOri, forms.formRefFacesheet, {
      riyousyaId,
    });

    if (facesheetId && facesheetType === "copy") {
      // remove facesheetId to add new record
      result.facesheet.id = 0;
    }
  }
  else {
    result.facesheet = facesheetOri;
  }

  // merge formRefAdl
  const tmpAdl = [];
  if (forms && !_.isEmpty(forms.formRefAdl) && forms.formRefAdl.length > 0) {
    for (let i = 0; i < forms.formRefAdl.length; i++) {
      tmpAdl.push({
        riyousyaId,
        id: forms.formRefAdl[i].id,
        kihonAdlMasterId: forms.formRefAdl[i].kihonAdlMasterId,
        answer: forms.formRefAdl[i].answer,
        // facesheetId: facesheetId > 0 ? facesheetId : null
        facesheetId:
          facesheetId && facesheetType === "copy"
            ? 0
            : facesheetId > 0
              ? facesheetId
              : 0,
      });
    }
  }
  result.riyouAdl = tmpAdl;

  // merge formRefJokyo
  if (forms && !_.isEmpty(forms.formRefJokyo)) {
    if (!_.isEmpty(result.facesheet)) {
      result.facesheet = Object.assign(
        {},
        result.facesheet,
        forms.formRefJokyo
      );
    }
    else {
      result.facesheet = Object.assign({}, facesheetOri, forms.formRefJokyo, {
        riyousyaId,
      });
    }

    if (facesheetId && facesheetType === "copy") {
      // remove facesheetId to add new record
      result.facesheet.id = 0;
    }
  }

  // merge formRefHoka
  if (forms && !_.isEmpty(forms.formRefHoka)) {
    if (!_.isEmpty(result.facesheet)) {
      result.facesheet = Object.assign({}, result.facesheet, forms.formRefHoka);
    }
    else {
      result.facesheet = Object.assign({}, facesheetOri, forms.formRefHoka, {
        riyousyaId,
      });
    }

    if (facesheetId && facesheetType === "copy") {
      // remove facesheetId to add new record
      result.facesheet.id = 0;
      result.facesheet.faceAdl = [];
      result.facesheet.jigyosyoFacesheet = [];
      result.facesheet.riyouChart = [];
    }
  }

  // merge formRefHoka2
  if (forms && !_.isEmpty(forms.formRefHoka2)) {
    let jigyosyofacesheet = {};
    if (result.facesheet && result.facesheet.jigyosyoFacesheet) {
      const jf = result.facesheet.jigyosyoFacesheet.find(
        (item) => item.serviceKikanMasterId.toString() === mainSID.toString()
      );
      if (jf !== undefined) {
        jigyosyofacesheet = jf;
      }
    }

    if (!_.isEmpty(jigyosyofacesheet)) {
      result.jigyosyofacesheet = Object.assign(
        {},
        jigyosyofacesheet,
        forms.formRefHoka2
      );
    }
    else {
      result.jigyosyofacesheet = forms.formRefHoka2;
    }

    if (facesheetId && facesheetType === "copy") {
      result.jigyosyofacesheet.id = 0;
      result.jigyosyofacesheet.facesheetId = 0;
      result.jigyosyofacesheet.riyousyaId = riyousyaId;
    }
  }
  else {
    result.jigyosyofacesheet = {};
  }

  // marge riyou_chart
  if (forms && !_.isEmpty(forms.formRefChart)) {
    if (forms.formRefChart) {
      result.riyouChart = forms.formRefChart;
    }

    if (facesheetId && facesheetType === "copy") {
      // 複製なので新規追加
      result.riyouChart.id = 0;
      result.riyouChart.facesheetId = 0;
      result.riyouChart.riyousyaId = riyousyaId;
    }
    // 更新、アコーディオンが無ければ元dataの値を取得
  }
  else if (facesheetId > 0) {
    facesheetOri = data.facesheet.find(
      (item) => item.id.toString() === facesheetId.toString()
    );

    if (!facesheetOri) {
      result.riyouChart = data.facesheet[0]?.riyouChart[0] ?? {};
    }
    else {
      result.riyouChart = facesheetOri.riyouChart[0] ?? {};
    }
  }
  else {
    result.riyouChart = data.facesheet[0]?.riyouChart[0] ?? {};
  }


  return result;
}

function stateYougoMasterName(listName) {
  const menu = formatStorage(getMenu());
  const systemName = menu.sn;
  const sid = menu.si;
  switch (listName) {
    case ListNameSet.SYOKUJI_KUBUN:
      return "shokujisenbunrui";

    case ListNameSet.SYOKUJI_TOKKI:
      return "shokujisenbikou";

    case ListNameSet.SHOKUJI_JOKYO:
      return "shokujiRirekiSituation";

    case ListNameSet.SHOKUJI_COMMENT:
      return "shokujiRirekiComent";

    // riyousya update page
    // @TODO update to const
    // Basic tab1
    case "利用者分類1":
      return "riyousyaBunrui1";

    case "利用者分類2":
      return "riyousyaBunrui2";

    case "利用者分類3":
      return "riyousyaBunrui3";

    case "利用者分類4":
      return "riyousyaBunrui4";

    case "利用者分類5":
      return "riyousyaBunrui5";

    case systemName + "分類1":
      return "systemBunrui1";

    case systemName + "分類2":
      return "systemBunrui2";

    case systemName + "分類3":
      return "systemBunrui3";

    case systemName + "分類4":
      return "systemBunrui4";

    case systemName + "分類5":
      return "systemBunrui5";

    case "入金分類":
      return "nyukinBunrui";

    case "入金摘要":
      return "nyukinTekiyo";

    // kankei tab2
    case "利用者関係":
      return "kankeList";

    // service tab6
    case "利用機関名":
      return "kikanNameList";

    case "機関利用頻度":
      return "hindoList";

    case "機関利用状況":
      return "stateList";

    // nyuin tab7
    case "入院理由":
      return "nyuinRiyuList";

    case "退院理由":
      return "tainRiyuList";

    //
    case "宛先":
      return "attnList";

    case "現況":
      return "genkyouList";

    case "経済状況":
      return "keizaiJoukyouList";

    case "家族状況":
      return "kazokuJoukyouList";

    case "身体状況":
      return "shintaiJoukyouList";

    case "療養状況":
      return "ryoyoJokyoList";

    case "介護状況":
      return "kaigoJokyoList";

    case "住居環境":
      return "jukyoKankyouList";

    case "生活歴":
      return "seikatuRekiList";

    case "既往歴":
      return "kiorekiList";

    case "職業歴":
      return "shokuRekiList";

    case "趣味":
      return "syumiEtcList";

    case "対人関係":
      return "taijinKankeiList";

    case "本人主訴":
      return "honninSyusoList";

    case "家族主訴":
      return "kazokuSyusoList";

    case "本人家族の意向":
      return "honninKazokuIkoList";

    case "関係者要望":
      return "kankeishaYoboList";

    case "公的サービス":
      return "riyouServiceKoutekiList";

    case "非公的サービス":
      return "riyouServiceHikoutekiList";

    //
    case "FSその他1":
      return "sonotaList1";

    case "FSその他2":
      return "sonotaList2";

    case "FSその他3":
      return "sonotaList3";

    case "FSその他4":
      return "sonotaList4";

    case "FSその他5":
      return "sonotaList5";

    case "FSその他6":
      return "sonotaList6";

    case "FSその他7":
      return "sonotaList7";

    case "FSその他8":
      return "sonotaList8";

    case "FSその他9":
      return "sonotaList9";

    case "FSその他10":
      return "sonotaList10";

    case "FSSその他1S" + sid:
      return "systemSonotaList1";

    case "FSSその他2S" + sid:
      return "systemSonotaList2";

    case "FSSその他3S" + sid:
      return "systemSonotaList3";

    case "FSSその他4S" + sid:
      return "systemSonotaList4";

    case "FSSその他5S" + sid:
      return "systemSonotaList5";

    case "FSSその他6S" + sid:
      return "systemSonotaList6";

    case "FSSその他7S" + sid:
      return "systemSonotaList7";

    case "FSSその他8S" + sid:
      return "systemSonotaList8";

    case "FSSその他9S" + sid:
      return "systemSonotaList9";

    case "FSSその他10S" + sid:
      return "systemSonotaList10";

    case "病名分類":
      return "ymByoumeiBunrui";

    case "薬名分類":
      return "ymKusurimeiBunrui";

    case "介護担当者":
      return "yougoMasterTanto";

    case "施設介護内容分類":
      return "yougoMasterNaiyouC";

    case "施設介護内容分類2":
      return "yougoMasterNaiyou2C";

    case "部屋分類":
      return "yougoMasterRoomNaiyou";

    default:
      return "error";
  }
}

/**
 *
 * @param {*} records sendgroups records
 * @param {*} checkListUsers checklist user add
 * @param {*} selectedGroup group add TO
 */
function addSendGroupRecords(records, checkListUsers, selectedGroup) {
  const data = JSON.parse(JSON.stringify(records));
  let userExisted = "";

  if (checkListUsers && checkListUsers.length > 0) {
    for (let i = 0; i < checkListUsers.length; i++) {
      const extractTantoId = checkListUsers[i].split("_")[1];
      const extractTantoName = checkListUsers[i].split("_")[2];

      if (isSendGroupExisted(records, extractTantoId, selectedGroup)) {
        userExisted = userExisted
          ? userExisted + "," + extractTantoName + "さん"
          : extractTantoName + "さん";
      }
      else {
        const preObj = {
          type: "new",
          id: 0,
          tantoId: parseInt(extractTantoId),
          groupName: selectedGroup,
          tantoMasterOri: {
            id: parseInt(extractTantoId),
            name: extractTantoName,
          },
        };

        data.push(preObj);
      }
    }
  }

  return { data, userExisted };
}

/**
 *
 * @param {*} records
 * @param {*} tantoId
 * @param {*} selectedGroup
 */
function isSendGroupExisted(records, tantoId, selectedGroup) {
  let isExsited = false;

  if (records && records.length > 0) {
    for (let i = 0; i < records.length; i++) {
      if (
        records[i].tantoId.toString() === tantoId.toString() &&
        records[i].groupName === selectedGroup
      ) {
        isExsited = true;
      }
    }
  }

  return isExsited;
}

/**
 *
 * @param {*} records sendgroups records
 * @param {*} checkListGroups checklist user add
 * @param {*} selectedGroup group add TO
 */
function delSendGroupRecords(records, checkListGroups, selectedGroup, delIds) {
  const data = JSON.parse(JSON.stringify(records));

  if (checkListGroups && checkListGroups.length > 0) {
    for (let i = 0; i < checkListGroups.length; i++) {
      const tmpExtractData = checkListGroups[i].split("_");

      const index = data.findIndex(
        (item) =>
          item.tantoId.toString() === tmpExtractData[1] &&
          item.groupName === selectedGroup
      );

      if (index > -1) {
        // alert(index)
        if (tmpExtractData[0] && tmpExtractData[0] !== "0") {
          delIds = delIds
            ? delIds + "," + tmpExtractData[0]
            : tmpExtractData[0];
        }

        data.splice(index, 1);
      }
    }
  }

  return { data, delIds };
}

/**
 *
 * @param {*} users
 * @param {*} group
 */
function filterUserByGroupLocal(users, group) {
  let data = [];

  if (group && group === "全表示") {
    data = users;
  }
  else if (users && users.length > 0) {
    for (let i = 0; i < users.length; i++) {
      if (users[i].sendGroups && users[i].sendGroups.length > 0) {
        for (let j = 0; j < users[i].sendGroups.length; j++) {
          if (users[i].sendGroups[j].groupName === group) {
            data.push(users[i]);
          }
        }
      }
    }
  }

  return data;
}

function validateNumberAndLetter(str) {
  return str.match("^[A-Za-z0-9]+$");
}

function sortByDate({
  list,
  key,
  format = ConstSet.DATE_FORMAT,
  orderBy = "asc",
}) {
  if (!Array.isArray(list)) return list;
  return _.orderBy(list, (item) => dayjs(item[key]).format(format), [orderBy]);
}

function changeSortTab(value) {
  switch (value) {
    case "利用者":
      return "sort";

    case "バイタル":
      return "sortVaital";

    case "食事摂取":
      return "sortMeal";

    case "水分摂取":
      return "sortSuibun";

    case "排泄":
      return "sortHaisetu";

    case "入浴":
      return "sortNyuyoku";

    case "服薬":
      return "sortFukuyaku";

    case "提供記録":
      return "sortKaigo";

    case "特記":
      return "sortTokki";

    case "画像等":
      return "sortGazou";

    default:
      return "sort";
  }
}

function reportSortOptions(value) {
  switch (value) {
    case "利用者":
      const sortOptions = [
        { value: "8", display: "最終排便日", key: "haiben" },
      ];
      return sortOptions;

    case "バイタル":
      const vitalSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "メイン", key: "mainf" },
        { value: "11", display: "体重", key: "weight" },
        { value: "12", display: "体温", key: "temperature" },
        { value: "13", display: "血圧高", key: "bpressureHigh" },
        { value: "14", display: "血圧低", key: "bpressureLow" },
        { value: "15", display: "脈拍", key: "pulse" },
        { value: "16", display: "呼吸", key: "kokyu" },
        { value: "17", display: "SPO2", key: "spo2" },
        { value: "18", display: "コメント", key: "comment" },
        { value: "19", display: "担当者", key: "tantoName" },
      ];
      return vitalSortOptions;

    case "食事摂取":
      const mealSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "種類", key: "kubunMeal" },
        { value: "11", display: "主食摂取量", key: "stapleIntake" },
        { value: "12", display: "副食摂取量", key: "sideIntake" },
        { value: "13", display: "有無", key: "shokuNasi" },
        { value: "14", display: "コメント", key: "comment" },
        { value: "15", display: "担当者", key: "tantoName" },
      ];
      return mealSortOptions;

    case "水分摂取":
      const suibunSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "種類", key: "syurui" },
        { value: "11", display: "量", key: "intake" },
        { value: "12", display: "コメント", key: "comment" },
        { value: "13", display: "担当者", key: "tantoName" },
      ];
      return suibunSortOptions;

    case "排泄":
      const haisetuSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "排泄区分", key: "haisetuKubun" },
        { value: "11", display: "種類", key: "shurui" },
        { value: "12", display: "有無", key: "haisetuf" },
        { value: "13", display: "量", key: "vol" },
        { value: "14", display: "状態", key: "joutai" },
        { value: "15", display: "コメント", key: "comment" },
        { value: "16", display: "担当者", key: "tantoName" },
      ];
      return haisetuSortOptions;

    case "入浴":
      const nyuyokuSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "種類", key: "nyuyokuNaiyou" },
        { value: "11", display: "コメント", key: "comment" },
        { value: "12", display: "担当者", key: "tantoName" },
      ];
      return nyuyokuSortOptions;

    case "服薬":
      const fukuyakuSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "薬名", key: "yakumei" },
        { value: "11", display: "コメント", key: "comment" },
        { value: "12", display: "担当者", key: "tantoName" },
      ];
      return fukuyakuSortOptions;

    case "提供記録":
      const kaigoSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "提供内容", key: "kaigoNaiyou" },
        { value: "11", display: "コメント", key: "comment" },
        { value: "12", display: "担当者", key: "tantoName" },
      ];
      return kaigoSortOptions;

    case "特記":
      const tokkiSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "コメント", key: "tokki" },
        { value: "11", display: "担当者", key: "tantoName" },
      ];
      return tokkiSortOptions;

    case "画像等":
      const gazouSortOptions = [
        { value: "8", display: "時間", key: "time" },
        { value: "9", display: "申し送り", key: "reportCaseTyuif" },
        { value: "10", display: "優先", key: "priorityf" },
        { value: "11", display: "区分", key: "kubun" },
        { value: "12", display: "コメント", key: "comment" },
        { value: "13", display: "担当者", key: "tantoName" },
      ];
      return gazouSortOptions;
  }
}

const rangePicker = {
  compareRangeMonths(startDate, endDate, onMoveToRight) {
    const startMonth = dayjs(startDate).month();
    const endMonth = dayjs(endDate).month();

    if (startMonth === endMonth) {
      onMoveToRight(true);
    }
  },
  showDatesInRightSide(isOpen, canMoveToRight, onMoveToRight) {
    setTimeout(() => {
      if (isOpen && canMoveToRight) {
        const leftRange = document.querySelector(".pljp-calendar-range-left");
        const rightRange = document.querySelector(".pljp-calendar-range-right");

        if (leftRange) {
          const prevMonth = leftRange.querySelector(
            ".pljp-calendar-prev-month-btn"
          );
          if (prevMonth) {
            prevMonth.click();
            onMoveToRight(false);
          }
        }

        if (rightRange) {
          const prevMonth = rightRange.querySelector(
            ".pljp-calendar-prev-month-btn"
          );
          if (prevMonth) {
            prevMonth.click();
          }
        }
      }
    }, 0);
  },
};

function isManagement() {
  const pathname = window.location.pathname;
  const paths = pathname.split("/");
  const [, adminPath] = paths;
  const pathPrefix = "management";

  return adminPath === pathPrefix || adminPath.includes(`${pathPrefix}-`);
}

function isLife() {
  const pathname = window.location.pathname;
  const paths = pathname.split("/");
  const [, adminPath] = paths;
  const pathPrefix = "life";

  return adminPath === pathPrefix || adminPath.includes(`${pathPrefix}-`);
}

function getPath() {
  if (isManagement()) return "/management";

  if (isLife()) return "/life";

  return "";
}

function getMenu() {
  if (isManagement()) return "menuMNGT";

  if (isLife()) return ConfigName.SWLIFE_MENU;

  return "menu";
}

function getToken() {
  if (isManagement()) return formatStorage("tokens").token;

  if (isLife()) return formatStorage(ConfigName.SWLIFE_TOKEN).token;

  return localStorage.getItem("jwtToken");
}

function getSwpsps() {
  if (isManagement()) return "swpspsMNGT";

  if (isLife()) return ConfigName.SWLIFE_SWPSPS;

  return "swpsps";
}

function formatStorage(value) {
  return isManagement()
    ? StorageUtils.getValue(value)
    : isLife()
      ? StorageUtils.getValue(value)
      : JSON.parse(localStorage.getItem(value));
}

function checkLogin() {
  if (isManagement()) {
    localStorage.removeItem("tokens");
    localStorage.removeItem("auth");
    window.location.replace("/management/login");
    return;
  }

  if (isLife()) {
    localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
    localStorage.removeItem(ConfigName.SWLIFE_AUTH);
    window.location.replace("/life/login");
    return;
  }

  localStorage.removeItem("jwtToken");
  localStorage.removeItem("doubleLogin");
  localStorage.removeItem("getAvatarResponse");
  localStorage.removeItem("getAvatarResponseData");
  window.location.replace("/login");
}

function getTid() {
  const swpspsKey = getSwpsps();
  const swpsps = formatStorage(swpspsKey);
  return swpsps.tid ? parseInt(swpsps.tid, 10) : null;
}

function getSid() {
  const menuKey = getMenu();
  const menu = formatStorage(menuKey);
  return menu.si ? parseInt(menu.si, 10) : null;
}

function convertYear(birthday, type) {
  const y = birthday.split("-")[0];
  const m = birthday.split("-")[1];
  const d = birthday.split("-")[2];

  if ((y >= 2019 && m >= 5 && d >= 1) || (y >= 2019 && m > 5) || y > 2019) {
    // 2019.5.1~
    return type == 1 ? "令和" : y - 2018;
  }
  else if (
    y > 1989 ||
    (y >= 1989 && m > 1) ||
    (y >= 1989 && m >= 1 && d >= 8)
  ) {
    // 1989.1.8~
    return type == 1 ? "平成" : y - 1988;
  }
  else if (y > 1926 || (y >= 1926 && m >= 12 && d >= 25)) {
    // 1926.12.25~
    return type == 1 ? "昭和" : y - 1925;
  }
  else if (
    y > 1912 ||
    (y >= 1912 && m > 7) ||
    (y >= 1912 && m >= 7 && d >= 30)
  ) {
    // 1912.7.30~
    return type == 1 ? "大正" : y - 1911;
  }
  else if (y > 1867) {
    // 1868.10.23~
    return type == 1 ? "明治" : y - 1867;
  }

  return "";
}

function convert_wareki(year, month, day) {
  if (year == "0001") {
    // return { t: '令和', n: "01", y: "0001", m: month, d: day }
    return { t: "令和", n: "01", y: "2019", m: month, d: day };
  }
  let tmp;
  year = parseInt(year);
  month = parseInt(month);
  day = parseInt(day);

  if (year >= 2019) {
    // 令和
    if (year === 2019) {
      if (month < 5) {
        return { t: "平成", n: 31, y: year, m: month, d: day };
      }
    }
    tmp = year - 2018;
    // tmp = '令和' + tmp;
    return { t: "令和", n: tmp, y: year, m: month, d: day };
  }
  else if (year >= 1989) {
    // 平成
    if (year === 1989) {
      if (month === 1) {
        if (day < 8) return { t: "昭和", n: 64, y: year, m: month, d: day };
      }
    }

    tmp = year - 1988;
    // tmp = '平成' + tmp;
    return { t: "平成", n: tmp, y: year, m: month, d: day };

  }
  else if (year >= 1926) {
    // 昭和
    if (year === 1926) {
      if (month < 12) {
        return { t: "大正", n: 15, y: year, m: month, d: day };
      }
      if (month === 12) {
        if (day < 25) return { t: "大正", n: 15, y: year, m: month, d: day };
      }
    }
    tmp = year - 1925;
    // tmp = '昭和' + tmp;
    return { t: "昭和", n: tmp, y: year, m: month, d: day };

  }
  else if (year >= 1912) {
    // 大正
    if (year === 1912) {
      if (month < 7) {
        return { t: "明治", n: 45, y: year, m: month, d: day };
      }

      if (month === 7) {
        if (day < 30) return { t: "明治", n: 45, y: year, m: month, d: day };
      }
    }

    tmp = year - 1911;
    // tmp = '大正' + tmp;
    return { t: "大正", n: tmp, y: year, m: month, d: day };
  }
  else if (year >= 1868 && month >= 10 && day >= 25) {
    // 明治
    tmp = year - 1867;
    // tmp = '明治' + tmp;
    return { t: "明治", n: tmp, y: year, m: month, d: day };
  }
  // 該当なし
  return { t: "令和", n: 1, y: 2019, m: 5, d: 1 };

}

function convert_wareki_2(jt, jn, y, m, d) {
  const tmp = {
    jt: jt ? jt : "令和",
    jn: jn ? parseInt(jn) : 1,
    y: y ? y : 2019,
    m: m ? m : 5,
    d: d ? parseInt(d) : 1,
    dis: "令和1年5月1日",
  };
  const dis = `${tmp.jt}${tmp.jn}年${tmp.m}月${tmp.d}日`;
  tmp.dis = dis;

  switch (tmp.jt) {
    case "令和":
      tmp.y = 2018 + tmp.jn;
      tmp.m = m ? m : 5;
      tmp.d = d ? d : 1;
      return tmp;

    case "平成":
      tmp.y = 1988 + tmp.jn;
      tmp.m = m ? parseInt(m) : 1;
      tmp.d = d ? d : 8;
      return tmp;

    case "昭和":
      tmp.y = 1925 + tmp.jn;
      tmp.m = m ? m : 12;
      tmp.d = d ? d : 25;
      return tmp;

    case "大正":
      tmp.y = 1911 + tmp.jn;
      tmp.m = m ? m : 7;
      tmp.d = d ? d : 30;
      return tmp;

    case "明治":
      tmp.y = 1867 + tmp.jn;
      tmp.m = m ? m : 10;
      tmp.d = d ? d : 25;
      return tmp;

    default:
      tmp.y = 2018 + tmp.jn;
      tmp.m = m ? parseInt(m) : 5;
      tmp.d = d ? parseInt(d) : 1;
      return tmp;
  }
}

function convert_wareki_3(dateString) {
  if (dateString) {
    const str = dateString.split("-");

    const y = str[0];
    const m = str[1];
    const d = str[2];

    const tmp1 = convert_wareki(parseInt(y), parseInt(m), parseInt(d));

    const tmp = { jt: tmp1.t, jn: tmp1.n, y, m, d, dis: "" };

    if (!dateString || y !== "0001") {
      const dis = `${tmp.jt}${parseInt(tmp.jn)}年${parseInt(tmp.m)}月${parseInt(
        tmp.d
      )}日`;
      tmp.dis = dis;
    }

    return tmp;
  }

  return { dis: "" };
}

function convert_wareki_4(dateString) {
  if (dateString) {
    const str = dateString.split("-");

    const y = str[0]; // 0001
    const m = str[1]; // 09
    const d = str[2]; // 01

    const tmp1 = convert_wareki(parseInt(y), parseInt(m), parseInt(d));
    const tmpGengo = { jt: tmp1.t, jn: tmp1.n };
    let gengo = "";

    if (tmpGengo.jt == "令和") {
      gengo = "R";
    }
    else if (tmpGengo.jt == "平成") {
      gengo = "H";
    }
    else if (tmpGengo.jt == "昭和") {
      gengo = "S";
    }
    else if (tmpGengo.jt == "大正") {
      gengo = "T";
    }
    else if (tmpGengo.jt == "明治") {
      gengo = "M";
    }
    else {
      gengo = "";
    }

    if (!dateString || y !== "0001") {
      const dis = `${gengo}${tmpGengo.jn}-${m}-${d}`;
      return dis;
    }

    return "";
  }

  return "";
}

function convert_jititai(data) {
  if (data) {
    if (data == "1") {
      return "自治体1";
    }
    if (data == "2") {
      return "自治体2";
    }
  }
  return "";
}

function formatBirthday(birthday, type) {
  const birthDate = new Date(birthday);
  const today = new Date();

  const yearDiff = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  const wareki = convert_wareki(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());

  let age = yearDiff;
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }
  if (type === "seireki") {
    const formattedDate = `${birthDate.getFullYear()}年${birthDate.getMonth() + 1}月${birthDate.getDate()}日`;
    return `${formattedDate} (${age}歳)`;
  }else if(type === "useslip") {
    const formattedDate = `${birthDate.getFullYear()}年${birthDate.getMonth() + 1}月${birthDate.getDate()}日`;
    return formattedDate
  }
  const formattedDate = `${wareki.t}${wareki.n}年${birthDate.getMonth() + 1}月${birthDate.getDate()}日`;
  return `${formattedDate} (${age}歳)`;

}
function formatSeireki(dateString) {
  if (dateString) {
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    const match = dateString.match(regex);

    if (match) {
      const year = parseInt(match[1]);
      const month = parseInt(match[2]);
      const day = parseInt(match[3]);

      return `${year}年${month}月${day}日`;
    }
  }

  return "";
}

function generateKey(key, outerIndex, innerIndex) {
  const indexRecord = outerIndex > 0 ? outerIndex + innerIndex + 2 : outerIndex + innerIndex + 1;
  const keyItem = `${key}_${outerIndex}_${innerIndex}_${indexRecord}`;
  return keyItem;
}

const fillArray = (arr, length, defaultValue) => {
  return arr?.concat(Array.from({ length: length - arr.length }, () => defaultValue));
};

const convertToListObject = (item, index, maxLengthOfPage, isCheckedColorWithOfficeName, color, listCheckedOfficeName, conditionFillColorRecord) => {
  let result = {};
  let resultDuplicateWithOfficeName = {};
  const pagePDF = Math.floor(index / 15) + 1;
  // const pagePDF = Math.ceil(index + 1 / 15);
  const titlePage = index >= 15 ? `_page${pagePDF}` : "";

  const paddedListPlan = fillArray(item.listPlan, 31, 0);
  const paddedListActual = fillArray(item.listActual, 31, 0);
  const keyValue = index >= 15 ? (index % maxLengthOfPage) : index

  paddedListPlan.forEach((value, i) => {
    result[`plan${keyValue}_${i}${titlePage}`] = `${value ? value : " "}`;
    resultDuplicateWithOfficeName[`plan${keyValue}_${i}${titlePage}`] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};
  });

  paddedListActual.forEach((value, i) => {
    result[`actual${keyValue}_${i}${titlePage}`] = `${value ? value : " "}`;
    resultDuplicateWithOfficeName[`actual${keyValue}_${i}${titlePage}`] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};
  });

  result[`sumListFPlan_${keyValue}${titlePage}`] = item.sumListPlan && item.sumListPlan > 0 ? `${item.sumListPlan}` : `${item.sumListFPlan > 0 ? item.sumListFPlan : " "}`;
  result[`sumListFActual_${keyValue}${titlePage}`] = item.sumListActual && item.sumListActual > 0 ? `${item.sumListActual}` : `${item.sumListFActual > 0 ? item.sumListFActual : " "}`;
  resultDuplicateWithOfficeName[`sumListFPlan_${keyValue}${titlePage}`] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};
  resultDuplicateWithOfficeName[`sumListFActual_${keyValue}${titlePage}`] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};

  const key1 = `時間${keyValue}${titlePage}`;
  const key2 = `サービス名${keyValue}${titlePage}`;
  const key3 = `事務所名${keyValue}${titlePage}`;
  const offerTime = item?.offerTime || " ";
  const formatOfferTime = offerTime.includes("~") ? offerTime.split("~")[0] + "～\n" + offerTime.split("~")[1] : offerTime;
  const serviceName = item?.serviceName || " ";
  const officeName = item?.officeName || " ";
  result[key1] = formatOfferTime;
  result[key2] = serviceName; 
  result[key3] = officeName;
  resultDuplicateWithOfficeName[key1] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};
  resultDuplicateWithOfficeName[key2] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};
  resultDuplicateWithOfficeName[key3] = item.backgroundColor ? {backgroundColor: item.backgroundColor} : {};
 
  return {resultDuplicateWithOfficeName: resultDuplicateWithOfficeName, result: result};
  
};

const handleMapListPDF = (listData, listSelectedColor, color) => {
  let duplicatedObjects = [];
  let number = listSelectedColor.length;
  for (let i = 0; i < number; i++) {
    duplicatedObjects.push(...listData.map(obj => ({
        ...obj,
        backgroundColor: listSelectedColor.find((item,index) => item.key === obj.officeName && index === i && item.value === "1" ) && !obj?.isSonota ? color : ""
    })));
  }
  return duplicatedObjects;
}

const handleConvertRecordListDataRiyouhform = (data, page, color, listCheckedOfficeName, conditionFillColorRecord, isPrintPDF=false) => {
  const maxLengthOfPage = 15;
  const quantityRecord = maxLengthOfPage * page;
  
  let result = {};
  let resultDuplicateWithOfficeNameColor = {};
  const objectDefault = {
    listActual : [0],
    listPlan: [0],
    sumListFActual: null,
    sumListFPlan: null
  }

  let dataUpdate;
  if(isPrintPDF) {
    dataUpdate =  data;
  }else {
    dataUpdate =  fillArray(data, quantityRecord, objectDefault);
  }
  dataUpdate.forEach((item, index) => {
    let tempResult = convertToListObject(item, index, maxLengthOfPage, false, color, listCheckedOfficeName, conditionFillColorRecord);
    result = {...result, ...tempResult.result};
    resultDuplicateWithOfficeNameColor = {...resultDuplicateWithOfficeNameColor, ...tempResult.resultDuplicateWithOfficeName};
  });

  return {
    recordTable: result,
    recordTableWithOfficeNameColor: resultDuplicateWithOfficeNameColor
  }
};

function generateAddNewKeyForObject(data, pageSize) {
  let total = data.flatMap(item => {
      const result = [item];
      for (let i = 2; i <= pageSize; i++) {
          result.push(`${item}_page${i}`);
      }
      return result;
  });
  return total;
}

function handleGenerateNewPage(data, pageSize,recordTableWithOfficeNameColor = {}) {
  let result = [];
  for (let i = 1; i <= pageSize; i++) {
    const newItem = {};
    for (const key in data[0]) {
        if (data[0].hasOwnProperty(key)) {
            newItem[`${key}${i > 1 ? `_page${i}` : ''}`] = data[0][key];
        }
    }
    result.push(newItem);
  }

  if(result.length > 0 && Object.keys(recordTableWithOfficeNameColor).length > 0) {
    result = result.map((item, index) => {
      return Object.keys(item).reduce((acc, key) => {
          acc[key] = { ...item[key], backgroundColor: recordTableWithOfficeNameColor[key]?.backgroundColor || item[key].backgroundColor };
          return acc;
      }, {});
    });
  }

  return result;
}


async function duplicatePDFPages(base64Pdf, pageSize) {
  try {
    const pdfBytes = Buffer.from(base64Pdf.split('base64,')[1], 'base64');

    const pdfDoc = await PDFDocument.load(pdfBytes);

    if (pdfDoc.getPageCount() < 1) {
      throw new Error('The PDF file has no pages.');
    }

    const pdfDocCopy = await PDFDocument.create();

    for (let i = 0; i < pageSize; i++) {
      const existingPages = await pdfDocCopy.copyPages(pdfDoc, pdfDoc.getPageIndices());
      existingPages.forEach(existingPage => {
        pdfDocCopy.addPage(existingPage);
      });
    }

    const modifiedBase64Pdf = 'data:application/pdf;base64,' + await pdfDocCopy.saveAsBase64();

    return modifiedBase64Pdf;
  } catch (error) {
    console.error('An error occurred:', error.message);
    return null;
  }
}

function convertToJapaneseEra(input) {

  const year = parseInt(input.substring(0, 4), 10);
  const month = parseInt(input.substring(4, 6), 10);

  let gengo;
  if (year >= 2019) {
      gengo = "令和";
  } else if (year >= 1989) {
      gengo = "平成";
  } else if (year >= 1926) {
      gengo = "昭和";
  } else if (year >= 1912) {
      gengo = "大正";
  } else if (year >= 1868) {
      gengo = "明治";
  } else {
      return "Không thể xác định thời kỳ";
  }

  const eraYear = year - (gengo === "令和" ? 2018 : (gengo === "平成" ? 1988 : (gengo === "昭和" ? 1925 : (gengo === "大正" ? 1911 : (gengo === "明治" ? 1867 : 1)))));

  const result = gengo + eraYear + "年" + month + "月";

  return result;
}

function convertToJapaneseEraDay(input) {
  const year = parseInt(input.split("-")[0], 10);
  const month = parseInt(input.split("-")[1], 10);
  const day = parseInt(input.split("-")[2], 10);

  let gengo;
  if (year >= 2019) {
    gengo = "令和";
  } else if (year >= 1989) {
    gengo = "平成";
  } else if (year >= 1926) {
    gengo = "昭和";
  } else if (year >= 1912) {
    gengo = "大正";
  } else if (year >= 1868) {
    gengo = "明治";
  } else {
    return "";
  }

  const eraYear = year - (gengo === "令和" ? 2018 : (gengo === "平成" ? 1988 : (gengo === "昭和" ? 1925 : (gengo === "大正" ? 1911 : (gengo === "明治" ? 1867 : 1)))));

  const result = gengo + eraYear + "年" + month + "月" + day + "日";

  return result;
}

function maskEvenCharacters(inputString) {
  return inputString.split('').map((char, index) => {
    return (index + 1) % 2 === 0 ? '*' : char;
  }).join('');
}

async function extractFirstPage(base64Pdf) {
  try {
    const pdfBytes = Buffer.from(base64Pdf.split('base64,')[1], 'base64');
    const pdfDoc = await PDFDocument.load(pdfBytes);

    const pdfDocCopy = await PDFDocument.create();
    
    const firstPage = await pdfDocCopy.copyPages(pdfDoc, [0]);
    
    pdfDocCopy.addPage(firstPage[0]);
    const extractedBase64Pdf = 'data:application/pdf;base64,' + await pdfDocCopy.saveAsBase64();

    return extractedBase64Pdf;
  } catch (error) {
    console.error('An error occurred:', error.message);
    return null;
  }
}

function handleUpdateOnlyOfficeName(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].color === "BLUE") {
      let findArr = arr.filter(item => item.color === "WHITE" && item.officeName === arr[i].officeName);
      if (findArr.length === 1) {
        let newElement = {
          color: "WHITE",
          serviceContent: findArr[0].serviceContent,
          isBlue: true,
          ...Object.fromEntries(
            Object.entries(findArr[0])
              .filter(([_, value]) => value !== null && value !== undefined)
          )
        };
        arr[i] = { ...arr[i], ...newElement };
        arr = arr.filter(item => item !== findArr[0]);
      }
    }
  }

  //sort case Over30days
  if(arr.some(item => !SPECIAL_TANISIKIBETU.includes(!item.sikyugendogakuKubun && !SPECIAL_TANISIKIBETU.includes(item.taniSikibetu) && item.color === "GREY" && item?.is30DayOver))) {
    arr.sort((item1, item2) => {
      const isGreyWithSpecialTaniSikibetu = (item) =>
          item.color === 'GREY' &&
          item.is30DayOver === true &&
          !SPECIAL_TANISIKIBETU.includes(item.taniSikibetu);
  
      const isBlueWithOver30days = (item) =>
          item.color === 'BLUE' &&
          item.is30DayOver === true;
  
      if ((item1.color === 'WHITE' || isBlueWithOver30days(item1)) && isGreyWithSpecialTaniSikibetu(item2)) {
          return -1;
      } else if ((item2.color === 'WHITE' || isBlueWithOver30days(item2)) && isGreyWithSpecialTaniSikibetu(item1)) {
          return 1;
      } else {
          return 0;
      }
  });
    
  }
  return arr;
}

const handleMapingDataListFormProvide = (result) => {
  let mergedResult = {};
  Object.keys(result).forEach((key) => {
      let index = parseInt(key);
      let mergedItems = result[index].itemsWithSameOffice.concat(result[index].itemsWithDifferentOffice);
      const pageNumber = Math.ceil(mergedItems.length / 22);
      const quantityRecord = 22 * pageNumber;
      mergedItems = fillArray(mergedItems, quantityRecord, objectDefaultListDataAttachedPDF);
      mergedResult[index.toString()] = mergedItems;
  });
  
  return mergedResult;
}

const handleMultipListDataFromListOfficeName = (data, listCheckedOfficeName) => {
  const pageNumber = listCheckedOfficeName.length;
  let newObject = {};
  for (let i = 0; i < pageNumber; i++) {
      const pageNumber = Math.ceil(data.length / 22);
      const quantityRecord = 22 * pageNumber;
      data = fillArray(data, quantityRecord, objectDefaultListDataAttachedPDF)
      newObject[i] = [...data];
  }
  return newObject;
}


const handleFormProvideListOfficeName = (data, listCheckedOfficeName) => {
     /**
   * 8: 事業所名: officeName
   * 16: サービス単位/金額
   * 21: 区分支給限度基準内単位数
   * 23: 費用総額 (+gray)
   * 25: 保険給付額 (+gray)
   * 26: 保険対象分(+gray)
   *  */
  let result = {};
 
  let listNotIncludesGray = ["サービス単位/金額", "区分支給限度基準内単位数", "区分支給限度基準を超える単位数"];
  let listKeysCalculator= ["サービス単位/金額", "区分支給限度基準内単位数", "費用総額", "保険給付額", "保険対象分", "区分支給限度基準を超える単位数", "全額負担分"]
  // Duyệt qua từng officeName trong listOfficeName
  listCheckedOfficeName.forEach((office, index) => {
    // Lọc những item có officeName giống với office
    let itemsWithSameOffice = data.filter(item => item["事業所名"] === office.key);
  
    // Lọc những item có officeName khác với listOfficeName && xóa những value bao gồm [], ()
    let itemsWithDifferentOffice = data.filter(item => item["事業所名"] !== office.key && !(item.color === 'WHITE' && !item.isBlue));
    itemsWithDifferentOffice = itemsWithDifferentOffice.map(item => {
        let newItem = { ...item };
        Object.keys(item).forEach((key) => {
            if (typeof item[key] === 'string') {
                newItem[key] = item[key].replace(/[\(\)\[\]]/g, '');
            }
        });
        return newItem;
    });

    // tính sum tất cả các key trong object 
    itemsWithDifferentOffice = itemsWithDifferentOffice.reduce((acc, item) => {
      Object.keys(item).forEach((key) => {
          if (key !== 'color' && listKeysCalculator.includes(key)) {
              if (listNotIncludesGray.includes(key) && item.color === 'GREY') {
                  return; // Bỏ qua nếu key tồn tại trong listNotIncludesGray và color là 'GREY'
              }
              acc[key] = (acc[key] || 0) + Number(item[key] || 0);
          }
      });

      acc["事業所名"] = "他事業所"//8
      acc["事業所番号"] = ""//9
      acc["サービス内容/種類"] = "合計"//10
      acc["サービスコード"]=""//11
      acc["単位数"]=""//12
      acc["割引適用後率"]=""//13
      acc["割引適用後単位数"] = ""//14
      acc["回数"]=""//15
      acc["給付管理単位数"] = ""//17
      acc["種類支給限度基準を超える単位数"] = ""//18
      acc["種類支給限度基準内単位数"] = ""//19
      // acc["区分支給限度基準を超える単位数"] = ""//20
      acc["単位数単価"] = ""//22
      acc["給付率"] = ""//24
      // acc["全額負担分"] = ""//27
      return acc;
    }, {});

     // kiểm tra giá trị 0 thì để thành rỗng
    for (let key in itemsWithDifferentOffice) {
      // Kiểm tra nếu giá trị bằng 0 thì gán giá trị rỗng
     if (itemsWithDifferentOffice[key] === 0) {
        itemsWithDifferentOffice[key] = "";
     }
    }

    // Đặt vào result
    result[index] = {
      itemsWithSameOffice,
      itemsWithDifferentOffice
    };

  });

  result = handleMapingDataListFormProvide(result);
  return result;
}

function handleIgnoreKeyOfObject(obj, arr) {
  const omit = Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)))
  return omit
}

function generateAddDuplicateNewKeyForObject(data, pageSize) {
  const result = { ...data }
  for (let i = 2; i <= pageSize; i++) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        result[`${key}_page${i}`] = data[key]
        if (key.includes("ページ番号")) {
          result[`${key}_page${i}`] = `${i}`
        }
      }
    }
  }
  return result
}

function updateObjectWithPageNumber(originalObject, pageNumber) {
  const updatedObject = {}
  for (let key in originalObject) {
    if (pageNumber > 1) {
      for (let i = 2; i < pageNumber; i++) {
        updatedObject[`${key}_page${i}`] = originalObject[`${key}_page${i}`] || ""
      }
      updatedObject[`${key}_page${pageNumber}`] = originalObject[key]
    } else {
      updatedObject[key] = originalObject[key]
    }
    if (pageNumber > 1) {
      updatedObject[key] = ""
    }
  }

  return updatedObject
}

const handleMapKeyFromArray = (data) => {
  let convertedArray = []
  data.forEach((item, index) => {
    for (let key in item) {
      let title = index !== 0 ? `${key}_${index}` : `${key}`
      convertedArray.push(`${title}`)
    }
  })
  return convertedArray
}

const handleConvertListDataAttached = (data) => {
  let result = {};
  data.forEach((item, index) => {
    Object.entries(item).forEach(([key, value]) => {
      const newKey = index > 0 ? `${key}_${index}` : key;
      result[newKey] = `${value}`;
    });
  });

  return result;
};

const handleDuplicateAddKeysForArray = (data, pageSize) => {
  
if (pageSize > 1) {
  let result = [];
  for (let i = 1; i <= pageSize; i++) {
      result = result.concat(data.map(item => i === 1 ? `${item}` : `${item}_page${i}`));
  }
  data = result;
}
return data
}

const handleGenerateColumnsDataAttachedPDF = (data, pageSize) => {
  let convertNewObjectToArray;
  // listdataAttached column
  let convertListDataAttached = handleMapKeyFromArray(data.listDataAttached);
  
  // list information and sumtotal: handle convert object to array column of list information and sumtotal;
  const newObjectKeysData = handleIgnoreKeyOfObject(data, ["listDataAttached"])
  convertNewObjectToArray = Object.keys(newObjectKeysData).map((item) => item)

  if (pageSize > 1) {
    // add new key for new page of list dataAttached
    convertListDataAttached = handleDuplicateAddKeysForArray(convertListDataAttached, pageSize)
  }

  const result = [...convertNewObjectToArray, ...convertListDataAttached]
  return result
}

const handleConvertListObjectToArray = (result) => {
  // Tạo một mảng để lưu trữ kết quả cuối cùng
  let mergedResult = [];

  // Duyệt qua từng phần tử trong biến result
  Object.keys(result).forEach((key) => {
      let index = parseInt(key);
      mergedResult = mergedResult.concat(result[index]);
  });

  return mergedResult;
}

const handleDuplicateListObject = (data, pageNumber, selectedRowKeys, listDataAttached) => {
  if(selectedRowKeys.key !== OfficeDialogPDF6 && selectedRowKeys.key !== OfficeDialogPDF8 && selectedRowKeys.key !== OfficeDialogPDF10) {
    // Tạo một đối tượng mới để lưu trữ kết quả cuối cùng
    let duplicatedObject = {};

    // Duyệt qua từng key trong objectSumOffice
    Object.keys(data).forEach((key) => {
        // Thêm key mới vào đối tượng duplicatedObject với đuôi là "_page{pageNumber}"
        for (let i = 1; i <= pageNumber; i++) {
            let newKey = i === 1 ? `${key}` : `${key}_page${i}`;
            duplicatedObject[newKey] = data[key];
        }
    });

    return duplicatedObject;
  }else {
    let newListDataAttached = {};
    let listNumberShowSumListOffice = [];
    let duplicatedObject = {};

    // Cập nhật những key tăng lên 1 trong listDataAttached
    Object.keys(listDataAttached).forEach(key => {
        let updatedKey = parseInt(key) + 1;
        newListDataAttached[updatedKey] = listDataAttached[key];
    });

    Object.keys(newListDataAttached).forEach(key => {
        let length = newListDataAttached[key].length;
        const maxLengthTable = 22;
        listNumberShowSumListOffice.push(Math.ceil(length /maxLengthTable) * parseInt(key));
    });
    const maxPageNumber = Math.max(...listNumberShowSumListOffice);

    // Duyệt qua từng key trong objectSumOffice
    Object.keys(data).forEach((key) => {
    // Thêm key mới vào đối tượng duplicatedObject với đuôi là "_page{pageNumber}"
      for (let i = 1; i <= maxPageNumber; i++) {
          let newKey = i === 1 ? `${key}` : `${key}_page${i}`;
          duplicatedObject[newKey] = listNumberShowSumListOffice.includes(i) ? data[key] : '';
      }
    });

    return duplicatedObject;
  }
  
}

const handleCalculatorLengthKeysObject = (data, maxLength) => {
  let totalLength = 0;

  Object.keys(data).forEach(key => {
      totalLength += data[key].length;
  });

  return totalLength / maxLength;
}

const removeKeyOfArray = (a, keyRemove ) => {
  let result = a.map((item) => {
    const keysWithout = Object.keys(item).filter((key) => !key.includes(keyRemove));
    return Object.fromEntries(keysWithout.map((key) => [key, item[key]]));
  });
  return result
}

const initTemplateDataAttachedPDF = async (
  getTemplateByScreen,
  useDays,
  riyouhyouForm,
  isFromManagement,
  isFromUseslipBetuhyou,
  newSettingFormSlip,
  selectedRowKeys,
  isCheckListOffice,
  dataOffice,
  isShowWaribiki,
  isRemovePageSize = false
) => {
    const { useDaysData } = riyouhyouForm;

    const kyufuData = await handleDataKyufu({dataAttached: riyouhyouForm.dataAttached, riyouhyouForm})
    // handle Beubyuoh PDF
    let newTemplateColumnslistDataAttachedPDF, templateSchemas, dataAttachedPDF;
    const maxLength = 22;
    
    // handle Beubyuoh PDF
    const template = Object.keys(getTemplateByScreen.pdfFormat).length > 0 ? getTemplateByScreen.pdfFormat : getTemplateByScreen.pdfDefault;
    let listCheckedOfficeName = (isFromManagement ? dataOffice : dataOffice || []).filter((item) => item.value === "1");
    const dataCloneAttaced = cloneDeep(isFromManagement ? handleMapingDataAttachedPDF(riyouhyouForm?.dataAttached) : riyouhyouForm?.dataAttached);
    let dataAttached = handleUpdateOnlyOfficeName(dataCloneAttaced || []);
    const sumColumn = (data, key, condition = () => true) => data.filter((e) => condition(e)).reduce((acc, item) => acc + (item[key] || 0), 0);
    const sumColumn26and27 = sumColumn(dataAttached, "externalSalary") + sumColumn(dataAttached, "burden");
    const sumColumn28 = `${riyouhyouForm.kubunsikyu}` || "";
    // const sumColumn28 = sumColumn(dataAttached, "unit", (e) => e.color !== "GREY");
    const sumColumn29 = dataAttached.some((item) => item.is30DayOver && item.serviceContent.includes("30日越"))
      ? sumColumn(dataAttached, "unitPrice", (e) => e.color !== "GREY" && e.color === "WHITE") -
        sumColumn(dataAttached, "unitPrice", (e) => e.color === "BLUE" && e.serviceContent.includes("30日越"))
      : sumColumn(dataAttached, "unitPrice", (e) => e.color !== "GREY" && e.color === "WHITE");
    const sumColumn30 = dataAttached.some((item) => item.is30DayOver && item.serviceContent.includes("30日越"))
    ? sumColumn(dataAttached, "benefitUnit", (e) => e.color !== "GREY" && e.color === "WHITE" && !e.is30DayOver) -
      sumColumn(dataAttached, "benefitUnit", (e) => e.color === "BLUE" && e.serviceContent.includes("30日越"))
    : sumColumn(dataAttached, "benefitUnit", (e) => e.color !== "GREY" && e.color === "WHITE");
    const sumColumn31 = sumColumn(dataAttached, "exceedingValue", (e) => e.color !== "GREY") > 0 ? sumColumn(dataAttached, "exceedingValue", (e) => e.color !== "GREY") : "" ;
    const sumColumn32 = sumColumn(dataAttached, "remainValue", (e) => e.color !== "GREY");
    const sumColumn33 = sumColumn(dataAttached, "totalCost");
    const sumColumn34 = sumColumn(dataAttached, "benefitValue");
    const sumColumn35 = sumColumn(dataAttached, "burden");
    const sumColumn36 = sumColumn(dataAttached, "externalSalary") > 0 ? sumColumn(dataAttached, "externalSalary") : "";
    const pageNumber = Math.ceil(dataAttached.length / maxLength);
    const quantityRecord = maxLength * pageNumber;

    const isServiceContentOver30days = (item) => (item.is30DayOver && item.color === "WHITE" ? true : false);
    const isServiceGrey30days = (item) => (item.is30DayOver && item.color === "GREY" ? true : false);

    const nameAfterChangeFor30days = (item) =>
      item.serviceContent.includes("30日越") || isServiceContentOver30days(item)
        ? item.serviceContent.includes("30日越")
          ? item.serviceContent.replace("越", "超")
          : `【30日超】${item.serviceContent}`
        : item.serviceContent;
    //21=>7-unitPrice //20 => 9-exceedingValue
    let newFormatDataAttached = (dataAttached.length > 0 ? dataAttached : Array.from({ length: 22 })).map((item) => ({
      事業所名: item ? item.officeName || "" : "", //8
      事業所番号:item ? item.officeNo || "" : "", //9
      "サービス内容/種類": item ? `${nameAfterChangeFor30days(item) ?? ""}` : "", //10,
      サービスコード: item ? nameAfterChangeFor30days(item).includes("30日超") ? "" : item.serviceCode || "" : "", //11
      単位数: item ? (ListTanisikibetu.includes(item.taniSikibetu) && !item.sikyugendogakuKubun) || isServiceContentOver30days(item) ? "" : item.unit || "" : "", //12 C003
      割引適用後率: item ? isShowWaribiki ? item.discountRate ?? "" : "" || "" : "", //13
      割引適用後単位数: item ? isShowWaribiki ? item.discountUnit ?? "" : "" || "" : "", //14
      回数: item ? ListTanisikibetu.includes(item.taniSikibetu) && !item.sikyugendogakuKubun ? "" : item.sumListPlan || "" : "", //15 C003
      "サービス単位/金額": item ? isServiceGrey30days(item) ? `[${item?.unitPrice + (item?.exceedingValue || 0)}]`  : nameAfterChangeFor30days(item).includes("30日超") && item.color !== "BLUE" ? "" : item.color === "BLUE" ? `(${item.unitPrice})` : item.color === "GREY" ? `[${item.unitPrice + (item?.exceedingValue || 0)}]` : item.unitPrice || "" : "", //16
      給付管理単位数: item ? isServiceContentOver30days(item) ? "" : item.color === "BLUE" ? item.benefitUnit > 0 ? `(${item.benefitUnit })` : "" : item.benefitUnit || "" : "", //17
      種類支給限度基準を超える単位数: "", //18
      種類支給限度基準内単位数: "", //19
      区分支給限度基準を超える単位数: item ? item.color === "GREY" ? item.exceedingValue > 0 ? `[${item.exceedingValue}]` : "" : item.exceedingValue || "" :"", //20
      区分支給限度基準内単位数: item ? nameAfterChangeFor30days(item).includes("30日超") ? "" : item.color === "GREY" && item.unitPrice > 0 ? `[${item.unitPrice}]` : item.color === "BLUE" || item.color === "WHITE" && item.isBlue ? item.remainValue > 0 ? item.remainValue : "" ?? "" : "" || "" : "", //16
      単位数単価: item ? item.unitCost || "" : "", //22
      費用総額: item ? item.totalCost || "" : "", //23
      給付率: item ? item.is30DayOver && item.serviceContent.includes("30日越") ? "" : item.benefit || "" : "", //24
      保険給付額: item ? item.benefitValue || "" : "", //25
      保険対象分: item ? item.burden || "" : "", //26
      全額負担分: item ? item.externalSalary || "" : "", //27
      color: item ? item.color : "",
      isBlue: item ? item.isBlue : ""
    }));

    let objectSumOffice = {
      "合計-区分支給限度基準額": `${sumColumn28}`, //28
      "合計-サービス単位/金額": `${sumColumn29}`, //29
      "合計-給付管理単位数": `${sumColumn30}`, //30
      "合計-区分支給限度基準を超える単位数": `${sumColumn31}`, //31
      "合計-区分支給限度基準内単位数": `${sumColumn32}`, //32
      "合計-費用総額": `${sumColumn33}`, //33
      "合計-保険給付額": `${sumColumn34}`, //34
      "合計-保険対象分": `${sumColumn35}`, //35
      "合計-全額負担分": `${sumColumn36}`, //36
    };

    const kohi1 = calculateSumDataKyufu({ kyufuData, index: 0, dataAttached: riyouhyouForm.dataAttached });
    const kohi2 = calculateSumDataKyufu({ kyufuData, index: 1, dataAttached: riyouhyouForm.dataAttached });
    const kohi3 = calculateSumDataKyufu({ kyufuData, index: 2, dataAttached: riyouhyouForm.dataAttached });

    let objectInforDataAttached = {
      利用者名: `${riyouhyouForm.riyousya.name ?? ""}`, //1
      タイトル: `${isFromUseslipBetuhyou ? "サービス利用票別表" : "サービス提供票別表"}`, //2
      居宅名: `${riyouhyouForm.serviceKikanMaster.jigyousyoName ?? ""}`, //3
      居宅事業所番号: `${riyouhyouForm.serviceKikanMaster.jigyousyoNo ?? ""}`, //4
      年月: `${convertToJapaneseEra(riyouhyouForm?.nengetu, "")}分`, //5
      自己負担分: `${sumColumn26and27 ?? ""}`, //6
      前月までの利用日数: `${useDaysData.lastMonthUsed ?? ""}`, //37
      当月の計画利用日数: `${useDaysData.currentMonth ?? ""}`, //38
      累計利用日数: `${useDaysData.cumulative ?? ""}`, //39
      公費負担額合計: `${kohi1 + kohi2 + kohi3}`, //40
      公費1種類: `${formatKyufuData(kyufuData, 0).syurui ?? ""}`, //41
      給付1: `${formatKyufuData(kyufuData, 0).kyufu}`, //42
      公費1負担額: `${formatKyufuData(kyufuData, 0).syurui !== "" ? kohi1 : ""}`, //43
      公費2種類: `${formatKyufuData(kyufuData, 1).syurui ?? ""}`, //44,
      給付2: `${formatKyufuData(kyufuData, 1).kyufu}`, //45
      公費2負担額: `${formatKyufuData(kyufuData, 1).syurui !== "" ? kohi2 : ""}`, //46
      公費3種類: `${formatKyufuData(kyufuData, 2).syurui ?? ""}`, //47 
      給付3: `${formatKyufuData(kyufuData, 2).kyufu}`, //48
      公費3負担額: `${formatKyufuData(kyufuData, 2).syurui !=="" ? kohi3 : ""}`, //49
      ページ番号: isRemovePageSize ? "" : "1",
      様: selectedRowKeys.value === "1" ? riyouhyouForm.settingsFormSlip.honorificTitle : newSettingFormSlip.honorificTitle,
    };

    // handle show pdf formProvide list officeName
    if(listCheckedOfficeName?.length > 0 && isCheckListOffice) {
      let pageNumberListFrovide, listAttachedFromProvide;

      // kiểm tra office dialog chọn có cùng với office6 trong dialog không?
      if(selectedRowKeys.key !== OfficeDialogPDF6 && selectedRowKeys.key !== OfficeDialogPDF8 && selectedRowKeys.key !== OfficeDialogPDF10) {
        // xử lý list attached table  phân thành nhiều trang(1)
        if(isFromManagement){
          listCheckedOfficeName = listCheckedOfficeName.filter((e) => riyouhyouForm.listCheckedOfficeName.some((el) => el.key.includes(e.key)));
        }
        listAttachedFromProvide = handleFormProvideListOfficeName(newFormatDataAttached, listCheckedOfficeName);
        pageNumberListFrovide = Object.keys(listAttachedFromProvide).length;
      }else {
        if(isFromManagement){
          //OFFICE 5 || 6 || 8 || 10 FROM MANAGEMENT
          listCheckedOfficeName = listCheckedOfficeName.filter((e) => riyouhyouForm.listCheckedOfficeName.some((el) => el.key.includes(e.key)));
        }
        listAttachedFromProvide = handleMultipListDataFromListOfficeName(newFormatDataAttached, listCheckedOfficeName);
        let lengthOfListAttachedFromProvide = handleCalculatorLengthKeysObject(listAttachedFromProvide, 22);
        pageNumberListFrovide = lengthOfListAttachedFromProvide;
      }
    
      let templateBasePDF;
       // Xử lý duplicate value
       if (pageNumberListFrovide > 1) {
        //Duplicate toàn bộ value của ObjectSumOffice và Infodata Attached khi trường hợp có nhiều page
        objectInforDataAttached = generateAddDuplicateNewKeyForObject(objectInforDataAttached, pageNumberListFrovide);
        objectSumOffice = handleDuplicateListObject(objectSumOffice, pageNumberListFrovide, selectedRowKeys, listAttachedFromProvide);

        // handle duplicate schemas
        templateSchemas = handleGenerateNewPage(template.schemas, pageNumberListFrovide);
        templateBasePDF = await duplicatePDFPages(template.basePdf, pageNumberListFrovide);
      } else {
        templateSchemas = template.schemas;
      }

       //Convert list attached table  từ object thành array(2)
       newFormatDataAttached = handleConvertListObjectToArray(listAttachedFromProvide);

       // handle remove key color and isBlue
       newFormatDataAttached = newFormatDataAttached.map(function(item) { 
         delete item.color;
         delete item.isBlue;
         return item; 
       });

      dataAttachedPDF = {
        listDataAttached: newFormatDataAttached,
        ...objectInforDataAttached,
        ...objectSumOffice,
      }
            
      newTemplateColumnslistDataAttachedPDF = handleGenerateColumnsDataAttachedPDF(dataAttachedPDF, pageNumberListFrovide);
      // handle template column
      dataAttachedPDF = { ...dataAttachedPDF, templateColumns: newTemplateColumnslistDataAttachedPDF };
      if(templateBasePDF) {
        template.basePdf = templateBasePDF;
      }

      // update template schemas and column
      template.schemas = templateSchemas;
      template.columns = dataAttachedPDF.templateColumns;

      
      template.sampledata = getTemplate("9", template.columns, dataAttachedPDF, null, null, pageNumberListFrovide);
    } 
    
    // handle show PDF all list beubyouh chỗ này
    else {
      let templateBasePDF;
      // Xử lý duplicate value
      if (pageNumber > 1) {
        objectInforDataAttached = generateAddDuplicateNewKeyForObject(objectInforDataAttached, pageNumber);
        objectSumOffice = updateObjectWithPageNumber(objectSumOffice, pageNumber);
        // handle duplicate schemas
        templateSchemas = handleGenerateNewPage(template.schemas, pageNumber);
        templateBasePDF = await duplicatePDFPages(template.basePdf, pageNumber);
      } else {
        templateSchemas = template.schemas;
      }
  
      // handle remove key color
      newFormatDataAttached = newFormatDataAttached.map(function(item) { 
        delete item.color;
        delete item.isBlue; 
        return item; 
      });

      // FillArray: Tạo những record ảo cho đủ 44 field trong 1 bảng;
      newFormatDataAttached = fillArray(newFormatDataAttached, quantityRecord, objectDefaultListDataAttachedPDF);
  
      dataAttachedPDF = {
        // Info dataAttached
        ...objectInforDataAttached,
        // sum total all column
        ...objectSumOffice,
        listDataAttached: newFormatDataAttached,
      };
      
      newTemplateColumnslistDataAttachedPDF = handleGenerateColumnsDataAttachedPDF(dataAttachedPDF, pageNumber)
      // handle template column
      dataAttachedPDF = { ...dataAttachedPDF, templateColumns: newTemplateColumnslistDataAttachedPDF };
      if(templateBasePDF) {
        template.basePdf = templateBasePDF;
      }
      // update template schemas and column
      template.schemas = templateSchemas;
      template.columns = dataAttachedPDF.templateColumns;
      template.sampledata = getTemplate("9", template.columns, dataAttachedPDF, null, null, pageNumber);
    }
    if(isRemovePageSize) {
      template.sampledata =  removeKeyOfArray(template.sampledata, "ページ番号")
    }
    return template;
};

const handleConvertDays = (listDays) => {
  let result = {};
  listDays.forEach((day, index) => {
      Object.keys(day).forEach((key) => {
          let newKey = `${key}_${index}`;
          if (index > 0) {
              newKey = `${key}_${index}`;
          }
          result[newKey] = day[key];
      });
  });

  // Kiểm tra nếu monday_5 không có giá trị, thêm giá trị rỗng cho nó
  if (!result.hasOwnProperty("sunday_5")) {
    result["sunday_5"] = "";
  } 
  
  if (!result.hasOwnProperty("monday_5")) {
    result["monday_5"] = "";
  }
  return result;
}

function getListDay({ nengetu }) {
  let days = [];
  const year = parseInt(nengetu.substring(0, 4));
  const month = parseInt(nengetu.substring(4)) - 1;

  let dateOfMonth = nengetu
    ? new Date(year, month, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  let lastDateOfMonth = new Date(year, month + 1, 0);

  let week = {};
  let day = {
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
  };

  while (dateOfMonth.getMonth() == month || dateOfMonth.getDate() === 1) {
    const dayOfWeek = dateOfMonth.getDay();

    day[Object.keys(day)[dayOfWeek]] = dateOfMonth.getDate();

    if (dayOfWeek === 6 || dateOfMonth.getDate() === lastDateOfMonth.getDate()) {
      week = { ...day };
      days.push(week);
      week = {};
      day = {
        sunday: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: ''
      };
    }

    dateOfMonth.setDate(dateOfMonth.getDate() + 1);
  }

  return days;
}

// Function to transform listSchedule to string
function transformListScheduleToString(listSchedule, isRental = false) {
  let resultString = "";
  listSchedule.forEach((schedule, index) => {
      const scheduleContent = (schedule.time ? `${schedule.time} ` : '') + schedule.serviceCodeType + (schedule.officeName && ( !schedule.serviceCodeType ? schedule.officeName : ("\n" + schedule.officeName))) + "\n";
      const rentalContent = (schedule.officeName ? `${schedule.officeName} ` : '') + (schedule.serviceName ? `${schedule.serviceName} ` : '') + schedule.time + "\n";
      resultString += isRental ? rentalContent : scheduleContent;
      if(!schedule.serviceCodeType && !isRental) {
        // Tách chuỗi thành mảng các dòng
        let lines = resultString.split('\n');
        let nonEmptyLines = lines.filter(line => line.trim() !== '');
        resultString = nonEmptyLines.join('\n');
        resultString += "\n";
      }
  });
  return resultString;
}

const handleConvertListOfficeSameDayToObject = (listOffice, listDaysOfMonth, isExcel) => {
  let sameDay = listDaysOfMonth;
  let listOfficeSameDay = listOffice;
  let listOfficeSameDayConverted = {};

  for (let key in sameDay) {
      let value = sameDay[key];
      if (value !== "" && value !== 0) {
          let date = value;
          let suffix = key.split('_').slice(1).join('_');
          let newKey = key + '_content';

          listOfficeSameDayConverted[isExcel ? value :newKey] = {
              date: date,
              listSchedule: listOfficeSameDay.filter(item => item.date === date).flatMap(item => item.listSchedule.map(schedule => ({
                time: schedule.time,
                officeName: schedule.officeName,
                serviceCodeType: schedule.serviceCodeType
            })))
          };
      } else {
          listOfficeSameDayConverted[isExcel ? value : key+'_content'] = "";
      }
  }
  return listOfficeSameDayConverted
}

const handleConvertListOfficeSameDay = (listSchedule, listDaysOfMonth, isExcel) => {
 
  let result = [];
  for (let i = 0; i < listSchedule[0]?.datePlan?.length; i++) {
      let date = i + 1;
      let listSameDays = [];
      for (let j = 0; j < listSchedule.length; j++) {
          let datePlan = listSchedule[j].datePlan;
          if (Number(datePlan[i]) > 0) {
              listSameDays.push({
                time: listSchedule[j].time || '',
                officeName: listSchedule[j].officeName || '',
                serviceCodeType: listSchedule[j].serviceCodeType || ''
              });
          }
      }
      let schedule = {
          date: date,
          listSchedule: listSameDays
      };
  
      result.push(schedule);
  }

  
  result = handleConvertListOfficeSameDayToObject(result, listDaysOfMonth, isExcel)

  for (let key in result) {
    if (result[key].listSchedule && result[key].listSchedule.length > 0) {
        result[key] = transformListScheduleToString(result[key].listSchedule);
    } else {
        result[key] = "";
    }
  }
  return result;
};

const handleConvertListRental = (itemRetail) => {
  let result = [];
  itemRetail.listRetal.forEach((item) => {
      let startDay = -1;
      let endDay = -1;
      let time = item.datePlan;
      for (let i = 0; i < time.length; i++) {
          if (time[i] !== '0') {
              if (startDay === -1) {
                  startDay = i + 1;
              }
              endDay = i + 1;
          }
      }
  
      let totalDays = '';
      if (startDay !== -1 && endDay !== -1) {
        totalDays = startDay + '日～' + endDay + '日';
      } else {
        totalDays = '1日～' + time.length + '日';
      }
  
      const newObject = item?.isEmpty ? {time: ''} : {
        time: itemRetail.isShowTimeRental ? totalDays : '',
        officeName: item.officeName,
        serviceCodeType: item.serviceCodeType,
        serviceName: item.serviceName
      }
      result.push(newObject);
  })
   
  let final = {
    rental : result.length > 0 ? transformListScheduleToString(result, true) : ""
  }

  return final;
};

const handleConvertDataNumberToStringObject = (listDaysOfMonth) => {
  let result = listDaysOfMonth;
  // convert value number to string
  for (let key in result) {
    result[key] = result[key] === 0 ? '' : result[key].toString();
  }
  return result;
}

const handleSetColorHoliday = (templateSchemas, holidayInMonth, listDaysOfMonth) => {
  let listDaysColor = [];

  // list ra những date Holidays
  for (let key in listDaysOfMonth) {
      let dayNumber = listDaysOfMonth[key];
      if (holidayInMonth.includes(parseInt(dayNumber))) {
        listDaysColor.push(key);
      }
  }

  // Cập nhật màu ngày lễ cho schema
  templateSchemas?.forEach(schema => {
    for (let key in schema) {
        if (listDaysColor.includes(key)) {
            if(!key.includes("saturday")) {
              schema[key].fontColor = fontColorSchedule;
            }
        }
    }
  });
  return templateSchemas;
}

const handleRemoveColorPrevHolidays = (templateSchemas) => {
  let updatedTemplate = templateSchemas.map(item => {
    let newItem = {};
    for (let key in item) {
        if (/\d/.test(key) && !key.includes('content')) { // Kiểm tra key chứa số và không chứa "content"
            if (key.includes('sunday') || key.includes('saturday')) {
                newItem[key] = item[key];
            } else {
              newItem[key] = {};
              for (let subKey in item[key]) {
                  if (subKey === 'fontColor') {
                      newItem[key][subKey] = "#000000";
                  } else {
                      newItem[key][subKey] = item[key][subKey];
                  }
              }
            }
        } else {
            newItem[key] = item[key];
        }
    }
    return newItem;
});

 return updatedTemplate;
}

const initTemplateSchedulePDF = async({getTemplateByScreen, itemSchedule}) => {
  // Xóa những ngày lễ rơi vào ngày thường lưu giữ trước đó trong DB
  const pdfFormat = getTemplateByScreen.pdfFormat;
  if(Object.keys(getTemplateByScreen.pdfFormat).length > 0) {
    pdfFormat.schemas = handleRemoveColorPrevHolidays(pdfFormat.schemas);
  }

  const template = Object.keys(getTemplateByScreen.pdfFormat).length > 0 ? pdfFormat : getTemplateByScreen.pdfDefault;
  const informationDataSchedulePDF = {
    userName: itemSchedule.name,
    userDate: `${convertToJapaneseEra(itemSchedule.monthYear, "")}分`,
    commentTitle: itemSchedule.commentTitle,
    commentContent: itemSchedule.commentContent
  }

  const listDaysOfMonth = handleConvertDays(itemSchedule.listDays);

  const newFormatDataSchedulePDF = {
    // list record office
    ...handleConvertListOfficeSameDay(itemSchedule.listSchedule, listDaysOfMonth),

    //listdays 
    ...handleConvertDataNumberToStringObject(listDaysOfMonth),

    // rental
    ...handleConvertListRental(itemSchedule),

    ...informationDataSchedulePDF
 }
 template.schemas = handleSetColorHoliday( template.schemas, itemSchedule.holidayInMonth, listDaysOfMonth);
 template.sampledata = getTemplate("12", template.columns, newFormatDataSchedulePDF, null, null, 0);

 return template;

}

const handleMapingDataAttachedPDF = (data) => {
  let newData = data.map((item) => ({
    ...item,
    serviceContent: item.color === "BLUE" ? item.serviceContent.replace("小計", "合計") : item.serviceContent,
  }));
  return newData;
};

const handleIsCheckRecordShowRental = (listTypeRentail, item) => {
  let result = false;
  const isValidTypeNotOfferTimeNumber = !!(!listTypeRentail.includes(item.serviceCode.substring(0, 2)) && !item.offerTime.includes(":") && item.listPlan.every(i => i === 0));
  const isValidType13 =  !!(["13"].includes(item.serviceCode.substring(0, 2)) && item.dataRow.serviceContent.includes('定期巡回') && item.listPlan.every(i => i === 0));

  if(listTypeRentail.includes(item.serviceCode.substring(0, 2)) || ( isValidTypeNotOfferTimeNumber || isValidType13)) {
    result = true;
  }
  return result;
}

const handleMapingDataSchedule = (data, isPlan, isShowTime, isShowOfficeName, isShowServiceSetting, isSortTime, riyouhyouForm) => {
  const listDataSonota = riyouhyouForm.listDataServiceSonota;
  const listTypeRentail = ["17", "67"];
  let newData;
  let compareData = [];
  if(data.length > 0) {
    newData = data.filter(i => i.isParent && i.sumListFPlan > 0).map((item) => ({
      datePlan: item.listPlan ? item.listPlan.map(i => String(i)) : Array(31).fill("0"),
      officeName: isShowOfficeName ? item.officeName : '',
      serviceName:  item.serviceName,
     
      serviceKikanMasterId: item.serviceKikanMasterId,
      time: (item.dataRow.startTimeStr === "00:00" && item.dataRow.endTimeStr === "00:00") || !isShowTime ? "" : `${item.dataRow.startTimeStr}～${item.dataRow.endTimeStr}`,
      serviceCodeType: isShowServiceSetting ? KAIGO_CODE_SHORT_TYPE.includes(item.dataRow.shuiruiCode) ? 'ショート' : item.dataRow.serviceCodeType : '', 
      isRental: handleIsCheckRecordShowRental(listTypeRentail, item)  ? true : false,
      furigana: item?.dataRow.furigana,
      shuiruiCode: item?.dataRow?.shuiruiCode,
      serviceCode: item?.serviceCode,
      offerTime: item?.offerTime,
      // startTime: item.startTime.replace(":00", ""),
      // endTime: item.endTime.replace(":00", ""),
      // isParent: item.isParent,
      // serviceCd: item.serviceCd,
      // dateActual: item.listActual ? item.listActual.map(i => String(i)) : item.listPlan.split(""),
    }));
    newData = newData.filter(item => !isPlan ? item.isRental : !item.isRental);
  }else {
    newData = [
      {
        datePlan: Array(31).fill("0"),
        // dateActual: Array(31).fill("0"),
        officeName: "",
        // serviceCd: "",
        serviceName: "",
        serviceKikanMasterId: "",
        serviceCodeType: "", 
        time: "",
        isEmpty: true
      }
    ]
  }

  let newDataSonota = [];
  if(listDataSonota.length > 0 && isPlan) {
    let dataSonota = listDataSonota.map(item => ({
      datePlan: item.formListDate.selectedDays.map(i => String(i)),
     
      time: item.offerTime === "00:00~00:00" || !isShowTime ? "" :  `${item.offerTime.split("~")[0]}～${item.offerTime.split("~")[1]}`,
      serviceCodeType: isShowServiceSetting ? item.serviceNaiyou || item.paletteName : '', 

      officeName: isShowOfficeName ? item.jigyousyoName : '',
      offerTime: item.offerTime,
      serviceCode: item.serviceCode,
      furigana: item?.serviceKikanMaster?.furigana || '',
      isSonota: true
    }))

    newDataSonota = dataSonota;
  }

  const sortData = (data, isSortTime) => {
    return _.sortBy(data, (e) => {
      let result;
      if (isSortTime) {
        result = [e?.offerTime, e?.shuiruiCode ? 0 : 1 , e?.officeName ? 0 : 1, e?.serviceCode ? 0 : 1];
      } else {
        result = [e?.shuiruiCode, e?.serviceCode, e?.furigana, e?.offerTime];
      }
      return result;
    });
  };
  
  newData = isPlan ? sortData(newData, isSortTime) : newData;
  newDataSonota = sortData(newDataSonota, isSortTime);
  
  compareData = _.flatMap([...newData, ...newDataSonota]);
  if (isSortTime && isPlan) {
    compareData = sortData(compareData, isSortTime);
  }

  return compareData;
}

const handleShowMultipUseslipAndDataAttachedPDF = async (blob1, blob2 = null, blob3 = null, blob4 = null, isManagement) => {

  const mergedPdfDoc = await PDFDocument.create();

  const pdfDoc1 = await PDFDocument.load(await blob1.arrayBuffer());
  // Copy trang từ Blob 1
  const copiedPages1 = await mergedPdfDoc.copyPages(pdfDoc1, [...Array(pdfDoc1.getPageCount()).keys()]);
  copiedPages1.forEach((page) => mergedPdfDoc.addPage(page));

  if(blob2) {
    const pdfDoc2 = await PDFDocument.load(await blob2.arrayBuffer());
    // Copy trang từ Blob 2
    const copiedPages2 = await mergedPdfDoc.copyPages(pdfDoc2, [...Array(pdfDoc2.getPageCount()).keys()]);
    copiedPages2.forEach((page) => mergedPdfDoc.addPage(page));
  }
 
  if (blob3 && blob4) {
    const pdfDoc3 = await PDFDocument.load(await blob3.arrayBuffer());
    const pdfDoc4 = await PDFDocument.load(await blob4.arrayBuffer());

    // Copy trang từ Blob 3
    const copiedPages3 = await mergedPdfDoc.copyPages(pdfDoc3, [...Array(pdfDoc3.getPageCount()).keys()]);
    copiedPages3.forEach((page) => mergedPdfDoc.addPage(page));

    // Copy trang từ Blob 4
    const copiedPages4 = await mergedPdfDoc.copyPages(pdfDoc4, [...Array(pdfDoc4.getPageCount()).keys()]);
    copiedPages4.forEach((page) => mergedPdfDoc.addPage(page));
  }

  // In tệp PDF mới
  const mergedPdfBytes = await mergedPdfDoc.save();
  const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

  if (isManagement) {
    return mergedBlob;
  } else {
    printJS(URL.createObjectURL(mergedBlob), "pdf");
  }
};

async function addPageNumbersToPDFAndPrint(blob, isPrint) {
  const pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
  
  const pageCount = pdfDoc.getPageCount();

  for (let i = 0; i < pageCount; i++) {
      const page = pdfDoc.getPage(i);

      const text = `${i + 1}`;

      page.drawText(text, {
          x: 810,
          y: 10,
          w: 9.24,
          h: 3.08,
          size: 8,
      });
  }

  const pdfBytes = await pdfDoc.save();
  let numberedBlob = new Blob([pdfBytes], { type: "application/pdf" });
  if(isPrint){
    return  printJS(URL.createObjectURL(numberedBlob), 'pdf');
  }else{
    return numberedBlob;
  }
}

const handleMultipPDFManagement = async (blobs, isNotPrint = false) => {
  const getPagesCount = async (blob) => {
    const pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
    return pdfDoc.getPageCount();
  };
  const mergedPdfDoc = await PDFDocument.create();
  if (blobs.length > 0) {
    for (const blob of blobs) {
      const pagesCount = await getPagesCount(blob);
      const pdfDoc = await PDFDocument.load(await blob.arrayBuffer());
      const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, [...Array(pagesCount).keys()]);
      copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
    }
    const mergedPdfBytes = await mergedPdfDoc.save();
    let mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    if (isNotPrint) {
      return mergedBlob;
    } else {
      printJS(URL.createObjectURL(mergedBlob), 'pdf');
    }
  }
}


const generateNewScheduleInfomationPDF = (props, selectedRowKeys) => {
  const isShowTime = selectedRowKeys?.value === '1' ? props?.riyouhyouForm?.settingsFormSchedule?.timeSetting : true;
  const isShowServiceSetting = selectedRowKeys?.value === '1' ?  props?.riyouhyouForm?.settingsFormSchedule?.serviceSetting : true;
  const isShowOfficeName = selectedRowKeys?.value === '1' ?  props?.riyouhyouForm?.settingsFormSchedule?.officeNameSetting : true;

  const isShowOfficeNameRental = selectedRowKeys?.value === '1' ?  props?.riyouhyouForm?.settingsFormSchedule?.officeNameForRent : true;
  const isShowTimeRental = selectedRowKeys?.value === '1' ?  props?.riyouhyouForm?.settingsFormSchedule?.timeForRent : true;
  const sortTime = selectedRowKeys?.value === '1' ?  props?.riyouhyouForm?.settingsFormSchedule?.printCommand === 0 ? true : false : true;
  

  const scheduleInfomationPDF = {
    name: props.riyouhyouForm?.riyousya?.name || "",
    monthYear: props?.riyouhyouForm?.nengetu,
    listSchedule: props.riyouhyou?.betuhyouDetailList ? handleMapingDataSchedule(props.riyouhyouForm?.listDataService, true, isShowTime, isShowOfficeName, isShowServiceSetting, sortTime, props.riyouhyouForm) : [],
    listRetal: props.riyouhyou?.betuhyouDetailList ? handleMapingDataSchedule(props.riyouhyouForm?.listDataService, false, isShowTimeRental, isShowOfficeNameRental, false, sortTime,props.riyouhyouForm) : [],
    commentContent: selectedRowKeys ? selectedRowKeys?.value === '1' ? props.riyouhyouForm?.settingsFormSchedule?.commentContent || "" : "" : props.riyouhyouForm?.settingsFormSchedule?.commentContent,
    commentTitle: selectedRowKeys ? selectedRowKeys?.value === '1' ? props.riyouhyouForm?.settingsFormSchedule?.commentTitle || "" : "" : props.riyouhyouForm?.settingsFormSchedule?.commentTitle,
    sortTime: props?.riyouhyouForm?.settingsFormSchedule?.printCommand || 0,
    listDays: getListDay({nengetu: props.riyouhyouForm.nengetu }),
    holidayInMonth: props?.riyouhyouForm?.listAllDays?.holidayInMonth,
    isShowTimeRental: isShowTimeRental,
    isModePlan: props.riyouhyouForm.keisanKubun === 0 ? true : false
  }

  return scheduleInfomationPDF;
}

function columnNumberToName(columnNumber) {
  let columnName = '';
  while (columnNumber > 0) {
    let remainder = columnNumber % 26;
    if (remainder === 0) {
      columnName = 'Z' + columnName;
      columnNumber = Math.floor(columnNumber / 26) - 1;
    } else {
      columnName = String.fromCharCode(64 + remainder) + columnName;
      columnNumber = Math.floor(columnNumber / 26);
    }
  }
  return columnName;
}
const downloadExcelXLS = async (base64Template, scheduleInfomationPDF, fileName) => {
  const { rental } = handleConvertListRental(scheduleInfomationPDF)
  const listDaysOfMonth = handleConvertDays(scheduleInfomationPDF.listDays);
  const dataOfficeDay = handleConvertListOfficeSameDay(scheduleInfomationPDF.listSchedule, listDaysOfMonth, true);
  try {
      const buffer = Buffer.from(base64Template, 'base64');
      const workbook = await XlsxPopulate.fromDataAsync(buffer);

      const sheet = workbook.sheet("Sheet1");

      //map data excel
      sheet.cell("B1").value(scheduleInfomationPDF.name);
      sheet.cell("G14").value(scheduleInfomationPDF.commentTitle);
      sheet.cell("G15").value(scheduleInfomationPDF.commentContent);
      sheet.cell("D15").value(rental);
      sheet.cell("H4").style({ fontColor: "131AD9" });
      sheet.cell("H6").style({ fontColor: "131AD9" });
      sheet.cell("H8").style({ fontColor: "131AD9" });
      sheet.cell("H10").style({ fontColor: "131AD9" });
      sheet.cell("H12").style({ fontColor: "131AD9" });

      sheet.range("A4:H13").value("");
      sheet.range("A14:C15").value("");

      sheet.cell("E1").value(`${convertToJapaneseEra(scheduleInfomationPDF.monthYear, "")}分`);

      sheet.range("B1:C1").merged(true);
      sheet.range("G14:H14").merged(true);
      sheet.range("G15:H15").merged(true);
      sheet.range("D15:F15").merged(true);


      sheet.range("G15:H15").style({
        wrapText: true
      });
      sheet.range("D15:F15").style({
        wrapText: true
      });


      scheduleInfomationPDF.listDays.forEach((dayObj, weekIndex) => {
        Object.keys(dayObj).forEach(day => {
            const dayValue = dayObj[day];
            let column;
            let row;
            switch (day) {
                case 'monday':
                    column = 'C';
                    break;
                case 'tuesday':
                    column = 'D';
                    break;
                case 'wednesday':
                    column = 'E';
                    break;
                case 'thursday':
                    column = 'F';
                    break;
                case 'friday':
                    column = 'G';
                    break;
                case 'saturday':
                    column = 'H';
                    break;
                case 'sunday':
                    column = 'A';
                    break;
                default:
                    break;
            }
            
            if(day === 'sunday' && dayValue === 1){
              if (dayValue !== "") {
                row = 2 * weekIndex + 4;
                sheet.cell(`${column}${row}`).value(dayValue);
              }
            }else{
              if(day !== 'sunday'){
                if (dayValue !== "") {
                  row = scheduleInfomationPDF.listDays[0]['sunday'] === 1 ? 2 * weekIndex + 4 : 2 * weekIndex + 4;
                  sheet.cell(`${column}${row}`).value(dayValue);
                }
              }else{
                if (dayValue !== "") {
                  row = scheduleInfomationPDF.listDays[0]['sunday'] === 1 ? 2 * weekIndex + 4 - 2 : 2 * weekIndex + 2;
                  sheet.cell(`${column}${row + 2}`).value(dayValue);
                }
              }
            }
            if (scheduleInfomationPDF.holidayInMonth.includes(dayValue)) {
              sheet.cell(`${column}${row}`).style({ fontColor: "E72323" });
            }
        });
    });
      const cellInfo = [];

      sheet.usedRange().forEach(cell => {
        const value = cell.value();
        const row = cell.rowNumber();
        const column = columnNumberToName(cell.columnNumber())
        cellInfo.push({ row, column, value });
      });

      cellInfo.forEach(cell => {
           Object.keys(dataOfficeDay).forEach(key => {
              const cellValue = dataOfficeDay[key];
              if(+cell.value === +key && key !== ""){
                  sheet.cell(`${cell.column}${cell.row + 1}`).value(cellValue);
                  sheet.cell(`${cell.column}${cell.row + 1}`).style({ wrapText: true });
              }
          });
      });
    

      // Create and download excel
      const excelBlob = await workbook.outputAsync();
      const blobUrl = URL.createObjectURL(new Blob([excelBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  } catch (error) {
      console.error("Error ", error);
  }
};


const formatKyufuData = (kyufuData, index) => {
  return {
    syurui: `${kyufuData[index]?.syurui ?? ""}`,
    kyufu: `${kyufuData[index]?.kyufu ?? ""}`,
    kohiNo: `${kyufuData[index]?.kohiNo ?? ""}`
  };
};

const calculateSumDataKyufu = ({ kyufuData, index, dataAttached }) => {
  const { kyufu, kohiNo } = formatKyufuData(kyufuData, index);
  let sumKohiFutan = 0;
  (dataAttached || []).forEach((e) => {
    if ((e.color === "BLUE" && !e.is30DayOver) || e.color === "GREY") {
      if (e.kohiNo.includes(kohiNo) && e.kohiPercent.includes(kyufu)) {
        const kohiNoArr = e.kohiNo.split(",");
        kohiNoArr.forEach((kohi, idx) =>{
          if(kohi === kohiNo){
            const kohiFutan = `kohiFutan${idx + 1}`;
            sumKohiFutan += e[kohiFutan];
          }
        })
      }
    }
  });
  return sumKohiFutan;
};


export {
  addSendGroupRecords, addZeroToDateString, changeSortTab, checkFileStyle, checkHoliday, checkIconUrl, checkLogin, checkTableField, clone, colSetting, convertArrayToString, convertDateToX, convertMessageByCode, convertStringInArr, convertTimeToIndex, convertToParameter, convertYear, convert_jititai,
  convert_wareki,
  convert_wareki_2,
  convert_wareki_3,
  convert_wareki_4, delSendGroupRecords, disPlanToString, exRiyousya, extractKubunSetting, extractRangeVital, extractRiyousyaInfo, filterUserByGroupLocal, formatBirthday, formatRecords, formatSeireki, formatStorage, gazouKubunExtract, genderStatus, getBase64, getDateInMonth, getDistance, getHolidayInMonth, getMenu, getPath, getSid, getSwpsps, getTid,
  getToken, getUrlVars, getUrlVarsNew, getValueIskeep, getValueLocalstorage, handleInputNumber, handleSpo2, isEmpty, isLife, isManagement, kanriQuerySearch, localStorageClearCus, localStorageClearCusLogout, modifyAfterInsert, openNotificationWithIcon, rangePicker, reportByRiyousyaFormat, reportParams, reportSortOptions, riyouAddSortDel,
  riyouMergeFormToState, setValueIsKeep, setValueLocalstorage, sortByDate, stateYougoMasterName, updateUrlParameter, urlTobase64, validateNumberAndLetter, vitalColSetting, zenkaiMerge,
  handleConvertRecordListDataRiyouhform,
  generateKey,
  fillArray,
  generateAddNewKeyForObject,
  handleGenerateNewPage,
  duplicatePDFPages,
  convertToJapaneseEra,
  convertToJapaneseEraDay,
  maskEvenCharacters,
  handleMapListPDF,
  defaultPrintOffice,
  extractFirstPage,
  initTemplateDataAttachedPDF,
  handleIgnoreKeyOfObject,
  handleMapingDataAttachedPDF,
  generateAddDuplicateNewKeyForObject,
  handleConvertListDataAttached,
  handleShowMultipUseslipAndDataAttachedPDF,
  handleMultipPDFManagement,
  addPageNumbersToPDFAndPrint,
  removeKeyOfArray,
  handleMapingDataSchedule,
  initTemplateSchedulePDF,
  getListDay,
  generateNewScheduleInfomationPDF,
  downloadExcelXLS
};


/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Form } from "@ant-design/compatible";
import { Button, Checkbox, Col, Divider, Input, Modal, Popover, Row } from "antd";
import dayjs from "dayjs";
import React, { Component } from "react";
import { ConstSet } from "../../../common/configs/constset";
import IryoKougakuFutanMasterModal from "./IryoKougakuFutanMasterModal";
import JPDateSelectPicker from "./JPDateSelectPicker";
import SelectOptionsForm from "./SelectOptionsForm";
import moment from "moment";

class HokenTableForm4Normal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      type: null,
      index: null,
      visibleNoKeyboard: false,
      keyType: "",
      value: "",
      visibleJougen: false,
      updateVisible: false,
      check: null,
      errorNameVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (data && prevProps.data && data.id !== prevProps.data.id) {
      this.setState({
        check: data.keisanF,
      });
    }
  }

  showDeleteConfirm = () => {
    const { index } = this.props;

    this.setState({
      visible: true,
      type: "riyouConcern",
      index,
    });
  };

  handleOk = () => {
    const { type, index } = this.state;

    this.props.handleDelete(type, index);

    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      type: null,
      index: null,
    });
  };

  handleSort = (type, sortType, index) => {
    this.props.handleSort(type, sortType, index);
  };

  // モーダル
  handleModalJougen = () => {
    this.setState({
      visibleJougen: true,
    });
  };
  onSelectJougen = (record) => {
    const { form } = this.props;
    // let keisan = 0;
    if (record) {
      if (record.item) {
        this.setState({
          check: record.item.includes("ア")
            ? 3
            : record.item.includes("イ")
              ? 1
              : record.item.includes("ウ")
                ? 2
                : 0,
        });
      }
      form.setFieldsValue({
        jogenFutangaku: record.futangaku,
        // keisanF: keisan,
      });
    }

    this.setState({
      visibleJougen: false,
    });
  };
  onHideJougen = () => {
    this.setState({
      visibleJougen: false,
    });
  };

  checkRequired(values) {
    let isError = false;
    const dataFrom = values.yukoDateFrom.format(ConstSet.DATE_FORMAT);
    const dataTo = values.yukoDateTo.format(ConstSet.DATE_FORMAT);
    if (
      !dataFrom ||
      dataFrom === "0001-01-01" ||
      !dataTo ||
      dataTo === "0001-01-01"
    ) {
      isError = true;
      this.setState({
        errorNameVisible: true,
      });
    }
    else {
      this.setState({
        errorNameVisible: false,
      });
    }
    return isError;
  }

  // OKクリック時
  formHandleInput = () => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      if (this.checkRequired(values)) return;
      // 警告 コメントアウト
      // if(!values.futansyaNo || values.futansyaNo == "0"
      //     || !values.jukyusyaNo || values.jukyusyaNo == "0"
      // ) {
      //     this.setState({
      //         updateVisible: true
      //     })
      // } else {
      this.props.update({ ...values, keisanF: this.state.check });
      // }
    });
  };
  // ok
  formCloseInput = () => {
    this.setState({
      updateVisible: false,
    });
    this.props.closeInput();
  };
  // modal閉じるok
  formUpdateCancel = () => {
    this.setState({
      updateVisible: false,
    });
  };
  // 強行保存ok
  formUpdateOk = () => {
    this.props.updateOk();
    setTimeout(() => {
      this.setState({
        updateVisible: false,
      });
    }, 100);
  };

  checkBoxOne = (string, check) => {
    switch (string) {
      case "ア":
        if (check) {
          this.setState({
            check: 3,
          });
        }
        else {
          this.setState({
            check: 0,
          });
        }
        break;
      case "イ":
        if (check) {
          this.setState({
            check: 1,
          });
        }
        else {
          this.setState({
            check: 0,
          });
        }
        break;
      case "ウ":
        if (check) {
          this.setState({
            check: 2,
          });
        }
        else {
          this.setState({
            check: 0,
          });
        }
        break;
    }
  };

  render() {
    const { visible, check } = this.state;
    const { getFieldDecorator, data } = this.props.form;

    return (
      (<Form
        onSubmit={this.handleSubmit}
        hideRequiredMark={true}
        // {...formItemLayout}
        labelAlign="left"
      >
        <Row className="title-inline">
          {/* hidden field place */}
          <Col span={24}>
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("id", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("riyousyaId", {
                rules: [
                  { required: false, message: "必須項目をセットして下さい" },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">上限負担額</span>
          </Col>
          <Col span={3}>
            {getFieldDecorator("jogenFutangaku", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(<Input style={{ width: 120 }} />)}
          </Col>
          <Col span={3} style={{ display: "flex", marginLeft: 10 }}>
            <span className="text-area-select-btn">
              <Button
                type="primary"
                style={{ marginLeft: 8 }}
                onClick={() => this.handleModalJougen()}
              >
                M
              </Button>
            </span>
          </Col>
          <Col span={2} style={{ marginTop: 5 }}>
            ＋α計算
          </Col>
          <Col span={8} style={{ marginLeft: 20, marginTop: 0 }}>
            <Checkbox
              checked={check == 3}
              onChange={(e) => this.checkBoxOne("ア", e.target.checked)}
            >
              ア
            </Checkbox>
            <Checkbox
              checked={check == 1}
              onChange={(e) => this.checkBoxOne("イ", e.target.checked)}
            >
              イ
            </Checkbox>
            <Checkbox
              checked={check == 2}
              onChange={(e) => this.checkBoxOne("ウ", e.target.checked)}
            >
              ウ
            </Checkbox>
            {/* {getFieldDecorator("keisanF")(
              // <Checkbox.Group
              //   // onChange={(value) => this.checkBoxOne(value)}
              //   options={[
              //     { label: "ア3", value: "3" },
              //     { label: "イ1", value: "1" },
              //     { label: "ウ2", value: "2" },
              //   ]}
              // />
            )} */}
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline" style={{ marginTop: 10 }}>
          <Col span={4} style={{ marginTop: 28 }}>
            <span className="title-red">認定有効期間</span>
          </Col>
          <Col span={15}>
            <div style={{ marginBottom: 8 }}>
              {getFieldDecorator("yukoDateFrom", {
                rules: [
                  {
                    required: false,
                    message: "必須項目をセットして下さい",
                  },
                ],
              })(
                <JPDateSelectPicker
                  keyId={data ? data.id : -1}
                  error={this.state.errorNameVisible}
                // style={errorNameVisible ? { border: "1px solid red" } : {}}
                />
              )}
            </div>
            <div>
              <span style={{ display: "flex" }}>
                {getFieldDecorator("yukoDateTo", {
                  rules: [
                    {
                      required: false,
                      message: "必須項目をセットして下さい",
                    },
                  ],
                })(
                  <JPDateSelectPicker
                    keyId={data ? data.id : -1}
                    error={this.state.errorNameVisible}
                  // style={errorNameVisible ? { border: "1px solid red" } : {}}
                  />
                )}
              </span>
            </div>
          </Col>
        </Row>
        <Divider style={{ margin: "9px 0 7px 0" }} />
        <Row className="title-inline">
          <Col span={4} style={{ marginTop: 5 }}>
            <span className="title">適用区分</span>
          </Col>
          <Col span={4}>
            {getFieldDecorator("hokenKubun", {
              rules: [
                { required: false, message: "必須項目をセットして下さい" },
              ],
            })(
              <SelectOptionsForm
                data={[
                  "17上位",
                  "18一般",
                  "19低所＿低所得I",
                  "19低所＿低所得II",
                ]}
                style={{ width: 200 }}
              />
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: "flex-end", marginTop: 20 }}>
          <Popover
            visible={this.state.updateVisible}
            title={false}
            content={
              <div style={{ overflow: "hidden" }}>
                <p>必須項目が未入力ですがそのまま登録しますか？</p>
                <div style={{ float: "right" }}>
                  <Button
                    key="update"
                    type={"primary"}
                    onClick={() => this.formUpdateOk()}
                    style={{ marginRight: 5, marginTop: 20 }}
                  >
                    OK
                  </Button>
                  <Button
                    key="cancelUpdate"
                    onClick={() => this.formUpdateCancel()}
                  >
                    キャンセル
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              key="input"
              type={"primary"}
              onClick={() => this.formHandleInput()}
            >
              OK
            </Button>
          </Popover>
          <Button
            key="cancelInput"
            style={{ marginLeft: 8 }}
            onClick={() => this.formCloseInput()}
          >
            キャンセル
          </Button>
        </Row>
        <IryoKougakuFutanMasterModal
          visible={this.state.visibleJougen}
          onSelect={this.onSelectJougen}
          onHide={this.onHideJougen}
        />
        <Modal
          open={visible}
          title="削除確認"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              キャンセル
            </Button>,
            <Button key="submit" type="primary"
              danger onClick={this.handleOk}>
              OK
            </Button>,
          ]}
        >
          <p>削除してもよろしいですか？</p>
        </Modal>
      </Form>)
    );
  }
}

export const HokenTableForm4 = Form.create({
  name: "input-riyou-syokujisen",
  // onValuesChange(props, changedFields) {
  //   const { form } = props;
  // },
  mapPropsToFields(props) {
    // let date = new Date();
    // if (props.data && props.data.date) {
    //   let propsDate = new Date(props.data.date);
    //   date = propsDate;
    // }

    // let serviceKikanMasterId = StorageUtils.getValue(getMenu()).si;

    return {
      id: Form.createFormField({
        value: props.data && props.data.id ? props.data.id : null,
      }),
      riyousyaId: Form.createFormField({
        value:
          props.data && props.data.riyousyaId
            ? props.data.riyousyaId
            : props.riyousyaId,
      }),
      // serviceKikanMasterId: Form.createFormField({
      //     value: props.data && props.data.serviceKikanMasterId ? props.data.serviceKikanMasterId : serviceKikanMasterId
      // }),
      hokenKubun: Form.createFormField({
        value: props?.data?.hokenKubun ?? "",
      }),
      jogenFutangaku: Form.createFormField({
        value: props?.data?.jogenFutangaku ?? "",
      }),
      yukoDateFrom: Form.createFormField({
        value:
          props.data && props.data.yukoDateFrom
            ? dayjs(props.data.yukoDateFrom)
            : dayjs("0001-01-01"),
      }),
      yukoDateTo: Form.createFormField({
        value:
          props.data && props.data.yukoDateTo
            ? dayjs(props.data.yukoDateTo)
            : dayjs("0001-01-01"),
      }),
      // keisanF: Form.createFormField({
      //   value: props?.data?.keisanF ? String(props.data.keisanF) : "0",
      // }),
    };
  },
})(HokenTableForm4Normal);

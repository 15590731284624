import React from "react";
import { Select } from "antd";

import { getValueIskeep } from "../../../common/function_common/functionCommon";

const Option = Select.Option;

export default class TeikyouSelect extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.props.handleChangeTeikyou(value);
  }

  render() {
    const { settingTeikyou, disabled } = this.props;
    let kaigoCodeLocal = getValueIskeep("kiroku_kaigo_naiyou");
    return (
      <Select
        // showSearch
        style={{ width: 120 }}
        defaultValue={kaigoCodeLocal ? kaigoCodeLocal : "全表示"}
        // optionFilterProp="children"
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        disabled={disabled}
        // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option key={1000} value={"全表示"}>
          {"全表示"}
        </Option>
        {settingTeikyou
          ? settingTeikyou.map((rel, index) => {
              return (
                <Option key={index} value={rel.kaigoNaiyouCode}>
                  {rel.kaigoNaiyou}
                </Option>
              );
            })
          : null}
      </Select>
    );
  }
}

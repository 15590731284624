import { BarsOutlined, EnterOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Modal } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  getValueLocalstorage,
  localStorageClearCus,
} from "../../function_common/functionCommon";

class RightMenu extends Component {
  constructor(props) {
    super(props);
    const menuInfo = JSON.parse(localStorage.getItem("menu"));
    const multipleSystem = menuInfo.multipleSystem;
    const officeAuth = menuInfo.officeAuth;
    const systems = menuInfo.menu.split(";"),
      displayMenu = [];
    systems.forEach((value, index) => {
      if (index === 0 || multipleSystem === "true") {
        if (index > 0) {
          displayMenu.push(<Menu.Divider key={index} />);
        }
        const menus = value.split(",");
        displayMenu.push(
          <Menu.Item key={"record" + menus[3]} sn={menus[2]} si={menus[3]} ti1={menus[0]} ti2={menus[1]}>
            <Link to={"/report?sn=" + menus[2] + "&si=" + menus[3]}>
              <span>{menus[0]}</span>
            </Link>
          </Menu.Item>
        );
        // 黒帯メニューに移動
        if (officeAuth === "1") {
          displayMenu.push(
            <Menu.Item key={"business" + index} sn={menus[2]} si={menus[3]} ti1={menus[0]} ti2={menus[1]}>
              <Link to={"/document-list"}>
                <span>{menus[1]}</span>
              </Link>
            </Menu.Item>
          );
        }
      }
    });

    this.state = {
      modalConfirmLogout: false,
      show: false,
      loading: false,
      visible: false,
      displayMenu,
      activekey: [this.props.current !== "record0" ? this.props.current : `record${menuInfo.si}`],
    };
  }

  handleClick = (e) => {
    if (e.key !== "LogOut" && e.key !== "UserManagement") {
      localStorage.setItem("currentmenu", e.key);
      const preMenu = JSON.parse(localStorage.getItem("menu"));
      if (preMenu) {
        preMenu.ti1 = e.item.props.ti1;
        preMenu.ti2 = e.item.props.ti2;
        preMenu.sn = e.item.props.sn;
        preMenu.si = e.item.props.si;
        localStorage.setItem("menu", JSON.stringify(preMenu));
      }
    }


    // reload if change report parameter
    if (window.location.pathname === "/report") {
      window.location.reload(true);
    }
  };

  showLogoutModal = (e) => {
    e.preventDefault();
    this.setState({
      visible: true,
    });
  };

  handleLogoutOk = () => {
    // this.setState({ loading: true });
    this.setState({
      loading: false,
      visible: false,
      modalConfirmLogout: false,
    });

    localStorageClearCus();

    window.location.href = "/login";
  };

  handleLogoutCancel = () => {
    this.setState({ visible: false });
  };

  render() {
    const { activekey } = this.state;
    const menu = (
      <Menu onClick={this.handleClick} selectedKeys={activekey}>
        {this.state.displayMenu}
        <Menu.Divider />
        <Menu.Item key="UserManagement">
          <Link to={"/user-management"}>
            <span>{"ユーザ管理"}</span>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="LogOut">
          <Link to={"/"} onClick={this.showLogoutModal}>
            {"ログアウト"}
          </Link>
        </Menu.Item>
      </Menu>
    );

    const { avatar, userName } = this.props;
    const { visible } = this.state;
    return (
      (<Col className={"h-right"} xs={{ span: 12 }}>
        <div className={"h-r-item"}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <a className="ant-dropdown-link">
              <BarsOutlined style={{ fontSize: "36px", padding: "2px", color: "#fff" }} />
            </a>
          </Dropdown>
        </div>
        <div className={"h-r-item"}>{avatar}</div>
        <div className={"h-r-item"}>
          <h2>{userName}</h2>
        </div>
        {getValueLocalstorage("mode") ? (
          <div className={"h-r-item"}>
            <Link to="/kanri/user">
              <Button type="dashed" style={{ marginTop: 8 }}>
                管理画面
                <EnterOutlined />
              </Button>
            </Link>
          </div>
        ) : null}
        <Modal
          open={visible}
          title="ログアウト確認"
          onOk={this.handleLogoutOk}
          onCancel={this.handleLogoutCancel}
          className={"logout-modal"}
        >
          <p>ログアウトします。よろしいですか？</p>
        </Modal>
      </Col>)
    );
  }
}


export default RightMenu;

import {
  ERROR_REQUEST,
  API_CALL_REQUEST,
  MULTIPLE_UPDATE_ERROR,
} from "../../actions/commonActions/actionName";
import {
  REPORT_RIYOUSYA_REQUEST,
  REPORT_RIYOUSYA_SUCCESS,
  REPORT_BAITARU_REQUEST,
  REPORT_BAITARU_SUCCESS,
  BAITARU_COPY,
  BAITARU_DELETE, // delete on local only
  KIROKU_UPDATE_REQUEST,
  KIROKU_UPDATE_SUCCESS,
  KIROKU_UPDATE_ERROR,
  KIROKU_INSERT_REQUEST,
  KIROKU_INSERT_SUCCESS,
  KIROKU_INSERT_ERROR,
  KIROKU_UPDATE_MUL_SUCCESS,
  KIROKU_INSERT_MUL_SUCCESS,
  KIROKU_DELETE_REQUEST,
  KIROKU_DELETE_SUCCESS,

  // GAZOU_SER_INSERT_REQUEST,
  // GAZOU_SER_INSERT_SUCCESS,

  // GAZOU_SER_UPDATE_REQUEST,
  // GAZOU_SER_UPDATE_SUCCESS,

  // GAZOU_SER_DELETE_REQUEST,
  // GAZOU_SER_DELETE_SUCCESS,
  REPORT_MEAL_REQUEST,
  REPORT_MEAL_SUCCESS,
  MEAL_COPY,
  MEAL_DELETE,
  REPORT_SUIBUN_REQUEST,
  REPORT_SUIBUN_SUCCESS,
  SUIBUN_COPY,
  SUIBUN_DELETE,
  REPORT_HAISETU_REQUEST,
  REPORT_HAISETU_SUCCESS,
  HAISETU_COPY,
  HAISETU_DELETE,
  REPORT_NYUYOKU_REQUEST,
  REPORT_NYUYOKU_SUCCESS,
  NYUYOKU_COPY,
  NYUYOKU_DELETE,
  REPORT_FUKUYAKU_REQUEST,
  REPORT_FUKUYAKU_SUCCESS,
  FUKUYAKU_COPY,
  FUKUYAKU_DELETE,
  REPORT_KAIGONAIYOU_REQUEST,
  REPORT_KAIGONAIYOU_SUCCESS,
  KAIGONAIYOU_COPY,
  KAIGONAIYOU_DELETE,
  REPORT_TOKKI_REQUEST,
  REPORT_TOKKI_SUCCESS,
  TOKKI_COPY,
  TOKKI_DELETE,
  REPORT_GAZOU_REQUEST,
  REPORT_GAZOU_SUCCESS,
  GAZOU_COPY,
  GAZOU_DELETE,
  REPORT_SEARCH_REQUEST,
  REPORT_SEARCH_SUCCESS,
  REPORT_FILTER_SUCCESS,
  REPORT_SEARCHBYNAME_REQUEST,
  REPORT_SEARCHBYNAME_SUCCESS,
  REPORT_SEARCHBYGENDER_REQUEST,
  REPORT_SEARCHBYGENDER_SUCCESS,
  REPORT_SORT,
  REPORT_REFRESH,
  REPORT_LOAD_ZENKAI_REQUEST,
  REPORT_LOAD_ZENKAI_SUCCESS,
} from "../../actions/reportActions/actionName.js";

import { isKanji, toKatakana } from "wanakana";
import _ from "lodash";
import sortAlgorithm from "../../common/function_common/kirokuSortAlg";
import { ConstSet } from "../../common/configs/constset.js";

import { modifyAfterInsert } from "../../common/function_common/functionCommon";
import { Transcode } from "../../common/configs/transcode";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  messages: [],
  tabName: "",
};

export default function reportReducers(state = INITIAL_STATE, action) {
  let tabName = "";
  switch (action.type) {
    case API_CALL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ERROR_REQUEST: // Error for all request
      return {
        ...state,
        loadingButtonUpdate: false,
        notifyDisplay: 2,
        notifyContent:
          action.payload.data &&
          action.payload.data.error &&
          action.payload.data.error.code
            ? action.payload.data.error.code
            : "エラーがあります",
        loading: false,
      };

    case KIROKU_INSERT_ERROR: // Error for insert request
      let messageInsert = null;
      if (
        action.payload.data &&
        action.payload.data.error &&
        action.payload.data.error.code
      ) {
        messageInsert = Transcode[action.payload.data.error.code];
      }
      return {
        ...state,
        loadingButtonUpdate: false,
        notifyDisplay: 2,
        notifyContent: messageInsert ? messageInsert : "エラーがあります",
        loading: false,
      };

    case KIROKU_UPDATE_ERROR: // Error for update request
      let messageUpdate = null;
      if (
        action.payload.data &&
        action.payload.data.error &&
        action.payload.data.error.code
      ) {
        messageUpdate = Transcode[action.payload.data.error.code];
      }
      return {
        ...state,
        loadingButtonUpdate: false,
        notifyDisplay: 2,
        notifyContent: messageUpdate ? messageUpdate : "エラーがあります",
        loading: false,
      };

    case REPORT_FILTER_SUCCESS: // FILTER LOCAL FUNCTION
      return {
        ...state,
        nyutaishoFilter: action.payload,
      };

    case REPORT_RIYOUSYA_REQUEST: // DONE
      return {
        ...state,
        loading: true,
      };
    case REPORT_RIYOUSYA_SUCCESS:
      return {
        ...state,
        nyutaisho: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    case REPORT_BAITARU_REQUEST: // DONE
      return {
        ...state,
        loading: true,
      };
    case REPORT_BAITARU_SUCCESS:
      return {
        ...state,
        baitaruTab: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    case REPORT_MEAL_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_MEAL_SUCCESS: //
      return {
        ...state,
        shokujiTab: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    // suibun
    case REPORT_SUIBUN_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_SUIBUN_SUCCESS: //
      return {
        ...state,
        suibun: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    // haisetu
    case REPORT_HAISETU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_HAISETU_SUCCESS: //
      return {
        ...state,
        haisetu: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    //nyuyoku
    case REPORT_NYUYOKU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_NYUYOKU_SUCCESS: //
      return {
        ...state,
        nyutaishoTab: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    //fukuyaku
    case REPORT_FUKUYAKU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_FUKUYAKU_SUCCESS: //
      return {
        ...state,
        fukuyaku: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    // kaigonaigo
    case REPORT_KAIGONAIYOU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_KAIGONAIYOU_SUCCESS: //
      return {
        ...state,
        kaigonaiyou: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    // tokki
    case REPORT_TOKKI_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_TOKKI_SUCCESS: //
      return {
        ...state,
        tokki: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    // gazou
    case REPORT_GAZOU_REQUEST: //
      return {
        ...state,
        loading: true,
      };
    case REPORT_GAZOU_SUCCESS: //
      return {
        ...state,
        gazou: sortAlgorithm(
          searchAll(action.payload, state.nyutaishoFilter)
        ).map((item, index) => ({
          ...item,
          index: index,
        })),
        loading: false,
      };

    case REPORT_SEARCH_REQUEST: // Search Function
      return {
        ...state,
        loading: true,
      };
    case REPORT_SEARCH_SUCCESS: // Search Function(local)
      if (localStorage.getItem("tabActive") === "利用者") {
        let riyousyaTabSearchAll = action.payload;
        return {
          ...state,
          nyutaisho: sortAlgorithm(
            searchAll(riyousyaTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "バイタル") {
        let baitaruTabSearchAll = action.payload;
        return {
          ...state,
          baitaruTab: sortAlgorithm(
            searchAll(baitaruTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "食事摂取") {
        let shokujiTabSearchAll = action.payload;
        return {
          ...state,
          shokujiTab: sortAlgorithm(
            searchAll(shokujiTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "水分摂取") {
        let suibunTabSearchAll = action.payload;
        return {
          ...state,
          suibun: sortAlgorithm(
            searchAll(suibunTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "排泄") {
        let haisetuTabSearchAll = action.payload;
        return {
          ...state,
          haisetu: sortAlgorithm(
            searchAll(haisetuTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "入浴") {
        let nyutaishoTabSearchAll = action.payload;
        return {
          ...state,
          nyutaishoTab: sortAlgorithm(
            searchAll(nyutaishoTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "服薬") {
        let fukuyakuTabSearchAll = action.payload;
        return {
          ...state,
          fukuyaku: sortAlgorithm(
            searchAll(fukuyakuTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "提供記録") {
        let kaigonaiyouTabSearchAll = action.payload;
        return {
          ...state,
          kaigonaiyou: sortAlgorithm(
            searchAll(kaigonaiyouTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "特記") {
        let tokkiTabSearchAll = action.payload;
        return {
          ...state,
          tokki: sortAlgorithm(
            searchAll(tokkiTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "画像等") {
        let gazouTabSearchAll = action.payload;
        return {
          ...state,
          gazou: sortAlgorithm(
            searchAll(gazouTabSearchAll, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else {
        return state;
      }

    case REPORT_SEARCHBYNAME_REQUEST: // GET DATA FROM NuytaishoRireki
      return {
        ...state,
        loading: true,
      };
    case REPORT_SEARCHBYNAME_SUCCESS:
      if (localStorage.getItem("tabActive") === "利用者") {
        let riyousyaTabSearchName = action.payload;
        return {
          ...state,
          nyutaisho: sortAlgorithm(
            searchAll(riyousyaTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "バイタル") {
        let baitaruTabSearchName = action.payload;
        return {
          ...state,
          baitaruTab: sortAlgorithm(
            searchAll(baitaruTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "食事摂取") {
        let shokujiTabSearchName = action.payload;
        return {
          ...state,
          shokujiTab: sortAlgorithm(
            searchAll(shokujiTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "水分摂取") {
        let suibunTabSearchName = action.payload;
        return {
          ...state,
          suibun: sortAlgorithm(
            searchAll(suibunTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "排泄") {
        let haisetuTabSearchName = action.payload;
        return {
          ...state,
          haisetu: sortAlgorithm(
            searchAll(haisetuTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "入浴") {
        let nyutaishoTabSearchName = action.payload;
        return {
          ...state,
          nyutaishoTab: sortAlgorithm(
            searchAll(nyutaishoTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "服薬") {
        let fukuyakuTabSearchName = action.payload;
        return {
          ...state,
          fukuyaku: sortAlgorithm(
            searchAll(fukuyakuTabSearchName, state.nyutaishoFilter)
          ),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "提供記録") {
        let kaigonaiyouTabSearchName = action.payload;
        return {
          ...state,
          kaigonaiyou: sortAlgorithm(
            searchAll(kaigonaiyouTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "特記") {
        let tokkiTabSearchName = action.payload;
        return {
          ...state,
          tokki: sortAlgorithm(
            searchAll(tokkiTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "画像等") {
        let gazouTabSearchName = action.payload;
        return {
          ...state,
          gazou: sortAlgorithm(
            searchAll(gazouTabSearchName, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else {
        return state;
      }

    case REPORT_SEARCHBYGENDER_REQUEST: // GET DATA FROM NuytaishoRireki
      return {
        ...state,
        loading: true,
      };
    case REPORT_SEARCHBYGENDER_SUCCESS: // 入浴tabに
      let nyutaishoTabSearchGender = action.payload;
      let gender = null;
      if (action.gender === "女性") {
        gender = 2;
      } else if (action.gender === "男性") {
        gender = 1;
      }

      return {
        ...state,
        nyutaishoTab: sortAlgorithm(
          searchAll(nyutaishoTabSearchGender, state.nyutaishoFilter)
        )
          .filter((item) => {
            if (gender !== null) {
              if (gender !== null && gender === item.riyousya.gender) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })
          .map((item, index) => ({
            ...item,
            index: index,
          })),
        loading: false,
      };

    case REPORT_SORT: // Sort function
      if (localStorage.getItem("tabActive") === "利用者") {
        let riyousyaTabSort = action.payload;
        return {
          ...state,
          nyutaisho: sortAlgorithm(
            searchAll(riyousyaTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "バイタル") {
        let baitaruTabSort = action.payload;
        return {
          ...state,
          baitaruTab: sortAlgorithm(
            searchAll(baitaruTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "食事摂取") {
        let shokujiTabSort = action.payload;
        return {
          ...state,
          shokujiTab: sortAlgorithm(
            searchAll(shokujiTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "水分摂取") {
        let suibunTabSort = action.payload;
        return {
          ...state,
          suibun: sortAlgorithm(
            searchAll(suibunTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "排泄") {
        let haisetuTabSort = action.payload;
        return {
          ...state,
          haisetu: sortAlgorithm(
            searchAll(haisetuTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "入浴") {
        let nyutaishoTabSort = action.payload;
        return {
          ...state,
          nyutaishoTab: sortAlgorithm(
            searchAll(nyutaishoTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "服薬") {
        let fukuyakuTabSort = action.payload;
        return {
          ...state,
          fukuyaku: sortAlgorithm(
            searchAll(fukuyakuTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "提供記録") {
        let kaigonaiyouTabSort = action.payload;
        return {
          ...state,
          kaigonaiyou: sortAlgorithm(
            searchAll(kaigonaiyouTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "特記") {
        let tokkiTabSort = action.payload;
        return {
          ...state,
          tokki: sortAlgorithm(
            searchAll(tokkiTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else if (localStorage.getItem("tabActive") === "画像等") {
        let gazouTabSort = action.payload;
        return {
          ...state,
          gazou: sortAlgorithm(
            searchAll(gazouTabSort, state.nyutaishoFilter)
          ).map((item, index) => ({
            ...item,
            index: index,
          })),
          loading: false,
        };
      } else {
        return state;
      }

    case REPORT_REFRESH:
      let tabActive = localStorage.getItem("tabActive");
      switch (tabActive) {
        case "利用者":
          return {
            ...state,
            nyutaisho: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "バイタル":
          return {
            ...state,
            baitaruTab: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "食事摂取":
          return {
            ...state,
            shokujiTab: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "水分摂取":
          return {
            ...state,
            suibun: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "排泄":
          return {
            ...state,
            haisetu: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "入浴":
          return {
            ...state,
            nyutaishoTab: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "服薬":
          return {
            ...state,
            fukuyaku: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "提供記録":
          return {
            ...state,
            kaigonaiyou: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        case "特記":
          return {
            ...state,
            tokki: action.payload.map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };
        default:
          return {
            ...state,
            loading: false,
          };
      }

    case BAITARU_COPY:
      return {
        ...state,
        baitaruTab: copy(state.baitaruTab, action.baitaruRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case BAITARU_DELETE:
      return {
        ...state,
        baitaruTab: del(state.baitaruTab, action.baitaruRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case MEAL_COPY:
      return {
        ...state,
        shokujiTab: copy(state.shokujiTab, action.mealRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case MEAL_DELETE:
      return {
        ...state,
        shokujiTab: del(state.shokujiTab, action.mealRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case SUIBUN_COPY:
      return {
        ...state,
        suibun: copy(state.suibun, action.suibunRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case SUIBUN_DELETE:
      return {
        ...state,
        suibun: del(state.suibun, action.suibunRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case HAISETU_COPY:
      return {
        ...state,
        haisetu: copy(state.haisetu, action.haisetuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case HAISETU_DELETE:
      return {
        ...state,
        haisetu: del(state.haisetu, action.haisetuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case NYUYOKU_COPY:
      return {
        ...state,
        nyutaishoTab: copy(state.nyutaishoTab, action.nyuyokuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case NYUYOKU_DELETE:
      return {
        ...state,
        nyutaishoTab: del(state.nyutaishoTab, action.nyuyokuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case FUKUYAKU_COPY:
      return {
        ...state,
        fukuyaku: copy(state.fukuyaku, action.fukuyakuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case FUKUYAKU_DELETE:
      return {
        ...state,
        fukuyaku: del(state.fukuyaku, action.fukuyakuRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case KAIGONAIYOU_COPY:
      return {
        ...state,
        kaigonaiyou: copy(state.kaigonaiyou, action.kaigonaiyouRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case KAIGONAIYOU_DELETE:
      return {
        ...state,
        kaigonaiyou: del(state.kaigonaiyou, action.kaigonaiyouRecord).map(
          (item, index) => ({
            ...item,
            index: index,
          })
        ),
      };

    case TOKKI_COPY:
      return {
        ...state,
        tokki: copy(state.tokki, action.tokkiRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case TOKKI_DELETE:
      return {
        ...state,
        tokki: del(state.tokki, action.tokkiRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    //
    case GAZOU_COPY:
      return {
        ...state,
        gazou: copy(state.gazou, action.gazoRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case GAZOU_DELETE:
      return {
        ...state,
        gazou: del(state.gazou, action.gazoRecord).map((item, index) => ({
          ...item,
          index: index,
        })),
      };

    case KIROKU_UPDATE_REQUEST:
      return {
        ...state,
        loadingButtonUpdate: true,
        notifyDisplay: 0,
      };

    case KIROKU_UPDATE_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            baitaruTab: state.baitaruTab.map((item) => {
              if (item.vitalId) {
                if (
                  action.paramsUpdate.vitalIds
                    .split(",")
                    .includes("" + item.vitalId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.vitalId === item.vitalId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            shokujiTab: state.shokujiTab.map((item) => {
              if (item.mealId) {
                if (
                  action.paramsUpdate.mealIds
                    .split(",")
                    .includes("" + item.mealId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.mealId === item.mealId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            suibun: state.suibun.map((item) => {
              if (item.suibunId) {
                if (
                  action.paramsUpdate.suibunIds
                    .split(",")
                    .includes("" + item.suibunId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.suibunId === item.suibunId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            haisetu: state.haisetu.map((item) => {
              if (item.haisetuId) {
                if (
                  action.paramsUpdate.haisetuIds
                    .split(",")
                    .includes("" + item.haisetuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.haisetuId === item.haisetuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (item.nyuyokuId) {
                if (
                  action.paramsUpdate.nyuyokuIds
                    .split(",")
                    .includes("" + item.nyuyokuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.nyuyokuId === item.nyuyokuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            fukuyaku: state.fukuyaku.map((item) => {
              if (item.fukuyakuId) {
                if (
                  action.paramsUpdate.fukuyakuIds
                    .split(",")
                    .includes("" + item.fukuyakuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.fukuyakuId === item.fukuyakuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (item.kaigoId) {
                if (
                  action.paramsUpdate.kaigoIds
                    .split(",")
                    .includes("" + item.kaigoId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.kaigoId === item.kaigoId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            tokki: state.tokki.map((item) => {
              if (item.tokkiId) {
                if (
                  action.paramsUpdate.tokkiIds
                    .split(",")
                    .includes("" + item.tokkiId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.tokkiId === item.tokkiId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            gazou: state.gazou.map((item) => {
              if (item.fileManagementId) {
                if (
                  action.paramsUpdate.fileManagementId ===
                  item.fileManagementId.toString()
                ) {
                  return Object.assign(item, action.payload); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        default:
          return {
            ...state,
          };
      }

    case KIROKU_INSERT_REQUEST:
      return {
        ...state,
        loadingButtonUpdate: true,
        notifyDisplay: 0,
        loading: true,
      };

    case KIROKU_INSERT_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            baitaruTab: state.baitaruTab.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            shokujiTab: state.shokujiTab.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            suibun: state.suibun.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            haisetu: state.haisetu.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            fukuyaku: state.fukuyaku.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            tokki: state.tokki.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "保存しました",
            gazou: state.gazou.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
            loading: false,
          };

        default:
          return {
            ...state,
          };
      }

    /**
     * Multiple row update place
     */
    case MULTIPLE_UPDATE_ERROR: // Error for all request
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            baitaruTab: JSON.parse(JSON.stringify(state.baitaruTab)),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            shokujiTab: JSON.parse(JSON.stringify(state.shokujiTab)),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            suibun: JSON.parse(JSON.stringify(state.suibun)),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            haisetu: JSON.parse(JSON.stringify(state.haisetu)),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            nyutaishoTab: JSON.parse(JSON.stringify(state.nyutaishoTab)),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            fukuyaku: JSON.parse(JSON.stringify(state.fukuyaku)),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            kaigonaiyou: JSON.parse(JSON.stringify(state.kaigonaiyou)),
          };

        default:
          return {
            ...state,
          };
      }

    case KIROKU_UPDATE_MUL_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            baitaruTab: state.baitaruTab.map((item) => {
              if (item.vitalId) {
                if (
                  action.paramsUpdate.vitalIds
                    .split(",")
                    .includes("" + item.vitalId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.vitalId === item.vitalId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            shokujiTab: state.shokujiTab.map((item) => {
              if (item.mealId) {
                if (
                  action.paramsUpdate.mealIds
                    .split(",")
                    .includes("" + item.mealId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.mealId === item.mealId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            suibun: state.suibun.map((item) => {
              if (item.suibunId) {
                if (
                  action.paramsUpdate.suibunIds
                    .split(",")
                    .includes("" + item.suibunId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.suibunId === item.suibunId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            haisetu: state.haisetu.map((item) => {
              if (item.haisetuId) {
                if (
                  action.paramsUpdate.haisetuIds
                    .split(",")
                    .includes("" + item.haisetuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.haisetuId === item.haisetuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (item.nyuyokuId) {
                if (
                  action.paramsUpdate.nyuyokuIds
                    .split(",")
                    .includes("" + item.nyuyokuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.nyuyokuId === item.nyuyokuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            fukuyaku: state.fukuyaku.map((item) => {
              if (item.fukuyakuId) {
                if (
                  action.paramsUpdate.fukuyakuIds
                    .split(",")
                    .includes("" + item.fukuyakuId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.fukuyakuId === item.fukuyakuId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (item.kaigoId) {
                if (
                  action.paramsUpdate.kaigoIds
                    .split(",")
                    .includes("" + item.kaigoId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.kaigoId === item.kaigoId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            tokki: state.tokki.map((item) => {
              if (item.tokkiId) {
                if (
                  action.paramsUpdate.tokkiIds
                    .split(",")
                    .includes("" + item.tokkiId)
                ) {
                  return Object.assign(
                    item,
                    action.payload.find((x) => x.tokkiId === item.tokkiId)
                  ); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            gazou: state.gazou.map((item) => {
              if (item.fileManagementId) {
                if (
                  action.paramsUpdate.fileManagementId ===
                  item.fileManagementId.toString()
                ) {
                  return Object.assign(item, action.payload); // @TODO: change update by infor input at local to server info return
                } else {
                  return item;
                }
              } else {
                return item;
              }
            }),
          };

        default:
          return {
            ...state,
          };
      }

    case KIROKU_INSERT_MUL_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            baitaruTab: state.baitaruTab.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            shokujiTab: state.shokujiTab.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            suibun: state.suibun.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            haisetu: state.haisetu.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            nyutaishoTab: state.nyutaishoTab.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            fukuyaku: state.fukuyaku.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            kaigonaiyou: state.kaigonaiyou.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            tokki: state.tokki.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            gazou: state.gazou.map((item) => {
              if (item.index === parseInt(action.paramsUpdate.index, 10)) {
                return modifyAfterInsert(action.payload, item);
              } else {
                return item;
              }
            }),
          };

        default:
          return {
            ...state,
          };
      }
    // END Multiple row update place

    //
    case KIROKU_DELETE_REQUEST:
      return {
        ...state,
        loadingButtonUpdate: true,
        notifyDisplay: 0,
        loading: true,
      };

    case KIROKU_DELETE_SUCCESS:
      tabName = localStorage.getItem("tabActive");
      switch (tabName) {
        case ConstSet.KIROKU_VITAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            baitaruTab: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_MEAL:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            shokujiTab: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_SUIBUN:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            suibun: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_HAISETU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            haisetu: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_NYUYOKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            nyutaishoTab: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),

            loading: false,
          };

        case ConstSet.KIROKU_FUKUYAKU:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            fukuyaku: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_KAIGO:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            kaigonaiyou: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_TOKKI:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            tokki: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        case ConstSet.KIROKU_FILE:
          return {
            ...state,
            loadingButtonUpdate: false,
            notifyDisplay: 1,
            notifyContent: "削除しました",
            gazou: sortAlgorithm(
              searchAll(action.payload, state.nyutaishoFilter)
            ).map((item, index) => ({
              ...item,
              index: index,
            })),
            loading: false,
          };

        default:
          return {
            ...state,
          };
      }

    case REPORT_LOAD_ZENKAI_REQUEST:
      return {
        ...state,
        loadingZenkai: true,
      };
    case REPORT_LOAD_ZENKAI_SUCCESS:
      return {
        ...state,
        zenkai: action.payload,
        loadingZenkai: false,
      };

    default:
      return state;
  }
}

/**
 * Search All Function - include searchByName
 *
 * @param {*} arraySearch
 * @param {*} roomList // Get from state = state.nyutaishoFilter
 */
function searchAll(arraySearch, roomList) {
  // get data filter
  let dataFilter = JSON.parse(localStorage.getItem("is_keepMNGT"));
  if (arraySearch && dataFilter) {
    let tabActive = localStorage.getItem("tabActive");
    // SEARCH BY BUNRUI
    if (dataFilter.search.field10 && dataFilter.search.checkbox1) {
      let valueKey = dataFilter.search.field10.split("=")[0];

      if (valueKey === "分類名1" && dataFilter.search.field11) {
        arraySearch = arraySearch.filter(
          (item) => item.riyousya.bunrui1 === dataFilter.search.field11
        );
      }

      if (valueKey === "分類名2" && dataFilter.search.field11) {
        arraySearch = arraySearch.filter(
          (item) => item.riyousya.bunrui2 === dataFilter.search.field11
        );
      }

      if (valueKey === "分類名3" && dataFilter.search.field11) {
        arraySearch = arraySearch.filter(
          (item) => item.riyousya.bunrui3 === dataFilter.search.field11
        );
      }

      if (valueKey === "分類名4" && dataFilter.search.field11) {
        arraySearch = arraySearch.filter(
          (item) => item.riyousya.bunrui4 === dataFilter.search.field11
        );
      }

      if (valueKey === "分類名5" && dataFilter.search.field11) {
        arraySearch = arraySearch.filter(
          (item) => item.riyousya.bunrui5 === dataFilter.search.field11
        );
      }
    }

    // SEARCH BY nyutaisho type
    if (dataFilter.search.field2 && dataFilter.search.checkbox2) {
      // Because kubun field has been dubplicated for gazou tab,
      // Check if is gazou tab, kubun changed by kubunRiyou
      if (tabActive && tabActive === "画像等") {
        arraySearch = arraySearch.filter(
          (item) => item.kubunRiyou === dataFilter.search.field2
        );
      } else {
        arraySearch = arraySearch.filter(
          (item) => item.kubun === dataFilter.search.field2
        );
      }
    }

    // SEARCH BY ROOM
    if (
      dataFilter.search.field3 &&
      dataFilter.search.checkbox3 &&
      roomList &&
      roomList.roomMasters
    ) {
      var roomList = roomList.roomMasters.filter(
        (item) => item.bunrui === dataFilter.search.field3
      );

      // Algorithm filter room in array
      arraySearch = arraySearch.filter((item) => {
        for (let j = 0; j < roomList.length; j++) {
          if (item.room === roomList[j].room) {
            return true;
          }
        }

        return false;
      });
    }

    if (arraySearch && dataFilter.searchName) {
      arraySearch = arraySearch.filter((item) => {
        if (isKanji(dataFilter.searchName)) {
          // kanjiで漢字検索のみ
          if (_.startsWith(item.riyousya.name, dataFilter.searchName))
            return true;
          else return false;
        } else {
          // 他の場合すべて検索
          if (_.startsWith(item.riyousya.name, dataFilter.searchName))
            return true;

          if (
            _.startsWith(
              item.riyousya.furigana,
              toKatakana(dataFilter.searchName)
            )
          ) {
            return true;
          }

          return false;
        }
      });
    }

    if (arraySearch && dataFilter.gender) {
      let gender = null;
      if (dataFilter.gender === "女性") {
        gender = 2;
      } else if (dataFilter.gender === "男性") {
        gender = 1;
      }
      arraySearch = arraySearch.filter((item) => {
        if (gender !== null) {
          if (gender === item.riyousya.gender) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    }
  }

  return arraySearch;
}

/**
 *
 * @param {*} originArray
 * @param {*} index
 */
function copy(originArray, record) {
  let returnCopyArray = [];
  for (let tmpCopy in originArray) {
    if (originArray[tmpCopy].index === record.index) {
      returnCopyArray.push(originArray[tmpCopy]);
      returnCopyArray.push(record);
      record.index = 0;
    } else {
      returnCopyArray.push(originArray[tmpCopy]);
    }
  }

  return returnCopyArray;
}

/**
 *
 * @param {*} originArray
 * @param {*} index
 */
function del(originArray, record) {
  originArray = originArray.filter((item) => {
    return item.index !== record.index;
  });

  return originArray;
}

/**
 * recordIdで削除
 *
 * @param {*} originArray
 * @param {*} recordId
 */
// function delByRecordId(originArray, recordId) {
//   originArray = originArray.filter(item => {
//     return item.vitalId !== recordId
//   })

//   return originArray;
// }

/* eslint-disable brace-style */
/* eslint-disable indent */
import { seikatuCheckActionTypes } from "../action_types";

import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";

function seikatuCheck(state = {}, action) {
  switch (action.type) {
    case seikatuCheckActionTypes.SAVE_SEIKATU_CHECK_REQUEST:
      return { ...state, loading: true };
    case seikatuCheckActionTypes.SAVE_SEIKATU_CHECK_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        seikatuCheck: action.seikatuCheck,
      };
    case seikatuCheckActionTypes.SAVE_SEIKATU_CHECK_ERROR:
      return { ...state, loading: false };
    case seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_REQUEST:
      return { loading: true };
    case seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_SUCCESS:
      return {
        ...state,
        expandedRowKeys: null,
        seikatuCheck: action.seikatuCheck,
        loading: false,
      };
    case seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_ID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        seikatuCheck: null,
        loading: false,
      };
    case seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_KKID_REQUEST:
      return { loading: true };
    case seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_KKID_SUCCESS:
      return {
        ...state,
        expandedRowKeys: null,
        seikatuCheck: action.seikatuCheck,
        loading: false,
      };
    case seikatuCheckActionTypes.LOAD_SEIKATUCHECK_BY_KKID_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        seikatuCheck: null,
        loading: false,
      };
    case seikatuCheckActionTypes.LOAD_SEIKATU_CHECK_TO_QUOTE:
    case seikatuCheckActionTypes.LOAD_SEIKATU_CHECK_TO_QUOTE_SUCCESS:
      return {
        ...state,
        seikatuChecks: action.seikatuChecks,
      };
    case seikatuCheckActionTypes.LOAD_SEIKATU_CHECK_TO_QUOTE_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        seikatuChecks: [],
      };
    case seikatuCheckActionTypes.GET_IDS_REQUEST:
      return { ...state };
    case seikatuCheckActionTypes.GET_IDS_ERROR:
      return { ...state };
    case seikatuCheckActionTypes.GET_IDS_SUCCESS:
      return { ...state, idsSeikatuCheck: action.idsSeikatuCheck };
    case seikatuCheckActionTypes.GET_ADL_INYO:
      return { ...state, loading: true };
    case seikatuCheckActionTypes.GET_ADL_INYO_SUCCESS:
      openNotificationWithIcon("success", "引用しました");
      return {
        ...state,
        loading: false,
      };
    case seikatuCheckActionTypes.GET_ADL_INYO_ERROR:
      openNotificationWithIcon("error", "記録がありません");
      return { ...state, loading: false };
    default:
      return { ...state };
  }
}

export default seikatuCheck;

import React from "react";
import { ArrowDownOutlined } from '@ant-design/icons';
import { Table, Spin, Tooltip } from "antd";
import MediaQuery from "react-responsive";

import { getDateInMonth } from "../../../common/function_common/functionCommon";
import { disPlanToString } from "../../../common/function_common/functionCommon";

const expandedRowRender = (record) => (
  <p style={{ marginBottom: "0em" }}>{record.description}</p>
);
const title = () => "Here is title";
const showHeader = true;
const footer = () => "Here is footer";
const scroll = { y: 240 };
const pagination = { position: "bottom" };

export default class Riyousya extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      title: undefined,
      showHeader,
      footer: undefined,
      // rowSelection: {},
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      expandRowClick: [],
      columns: [],
      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",
    };

    //bind
    this.handleClickRow = this.handleClickRow.bind(this);
  }

  componentDidMount() {
    this.setState({});
  }

  handleToggle = (prop) => {
    return (enable) => {
      this.setState({ [prop]: enable });
    };
  };

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value });
  };

  handleExpandChange = (enable) => {
    this.setState({
      expandedRowRender: enable ? expandedRowRender : undefined,
    });
  };

  handleTitleChange = (enable) => {
    this.setState({ title: enable ? title : undefined });
  };

  handleHeaderChange = (enable) => {
    this.setState({ showHeader: enable ? showHeader : false });
  };

  handleFooterChange = (enable) => {
    this.setState({ footer: enable ? footer : undefined });
  };

  handleRowSelectionChange = (enable) => {
    this.setState({ rowSelection: enable ? {} : undefined });
  };

  handleScollChange = (enable) => {
    this.setState({ scroll: enable ? scroll : undefined });
  };

  handleDataChange = (hasData) => {
    this.setState({ hasData });
  };

  handlePaginationChange = (e) => {
    const { value } = e.target;
    this.setState({
      pagination: value === "none" ? false : { position: value },
    });
  };

  onRowClick(key, index, event) {
    var keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    } else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  handleClickRow(key) {
    if (key === this.state.activeListIndex) {
      this.setState({
        activeListIndex: null,
      });
    } else {
      this.setState({
        activeListIndex: key,
      });
    }
  }

  render() {
    const state = this.state;
    const { nyutaisho, loading, isDisLongText } = this.props;

    const columns = [
      {
        title: "状況",
        dataIndex: "col1",
        key: "col1",
        width: 50,
        className: "joutaiCol",
      },
      {
        title: "日",
        dataIndex: "col2",
        key: "col2",
        width: 70,
      },
      {
        title: "曜",
        dataIndex: "col3",
        key: "col3",
        width: 70,
      },
      {
        title: "便回数",
        dataIndex: "col8",
        key: "col8",
        width: 100,
      },
      {
        title: "尿回数",
        dataIndex: "col9",
        key: "col9",
        width: 100,
      },
      {
        title: "尿測",
        dataIndex: "col10",
        key: "col10",
        width: 100,
      },
      {
        title: "水分摂取量",
        dataIndex: "col11",
        key: "col11",
        width: 110,
      },
      {
        title: "朝",
        dataIndex: "col12",
        key: "col12",
        width: 60,
      },
      {
        title: "昼",
        dataIndex: "col13",
        key: "col13",
        width: 60,
      },
      {
        title: "夕",
        dataIndex: "col14",
        key: "col14",
        width: 60,
      },
      {
        title: "間",
        dataIndex: "col15",
        key: "col15",
        width: 60,
      },
      {
        title: "本日の予定",
        dataIndex: "col16",
        key: "col16",
        className: `${isDisLongText ? "dis-text-long" : "dis-text-short"}`,
        // width: 200,
      },
    ];

    /**
     * handle date
     */
    let data = [];
    if (nyutaisho) {
      for (let i = 0; i < nyutaisho.length; i++) {
        let color = "";
        if (nyutaisho[i].nyuType != "空") {
          if (nyutaisho[i].kubun === "入所") {
            color = "nuytaisho-yellow";
          } else if (nyutaisho[i].kubun === "ショート") {
            color = "nuytaisho-blue";
          }
        }

        let riyousyaPlan = disPlanToString(
          null,
          nyutaisho[i].riyousya_plan_yotei,
          nyutaisho[i].riyousya_plan_sonota
        );

        data.push({
          key: i,
          col1: (
            <div className={`ntk-riyousya-status ${color} icon-table`}>
              {nyutaisho[i].nyuType ? (
                nyutaisho[i].nyuType == "空" ? (
                  ""
                ) : (
                  nyutaisho[i].nyuType
                )
              ) : (
                <ArrowDownOutlined />
              )}
            </div>
          ),
          col2: (
            <div onClick={() => this.onRowClick(i)} className={"icon-table"}>
              {nyutaisho[i].date ? nyutaisho[i].date.split("-")[2] : ""}
            </div>
          ),
          col3: (
            <div
              onClick={() => this.onRowClick(i)}
              className={`icon-table${nyutaisho[i].holidayClass}`}
            >
              {nyutaisho[i].date
                ? getDateInMonth(
                    nyutaisho[i].date.split("-")[0],
                    nyutaisho[i].date.split("-")[1],
                    nyutaisho[i].date.split("-")[2]
                  )
                : ""}
            </div>
          ),
          col8: nyutaisho[i].countHaiben,
          col9: nyutaisho[i].countHainyo,
          col10: nyutaisho[i].totalVol,
          col11: nyutaisho[i].totalSuibun,
          col12: nyutaisho[i].asaMeal,
          col13: nyutaisho[i].hiruMeal,
          col14: nyutaisho[i].yuuMeal,
          col15: nyutaisho[i].kanMeal,
          col16: <div>{riyousyaPlan}</div>,
        });
      }
    }

    return (
      <div>
        <MediaQuery query="(min-device-width: 0px)">
          {(matches) => {
            if (matches) {
              return (
                <div>
                  <Spin spinning={loading}>
                    <Table
                      {...this.state}
                      showHeader={true}
                      expandIconAsCell={false}
                      expandIconColumnIndex={-1}
                      bordered={true}
                      size="small"
                      pagination={{ position: "bottom", pageSize: 50 }}
                      scroll={{ x: 1400, y: 800 }}
                      className="table-kiroku-ri"
                      // onRow={(record, index) => {
                      //     return {
                      //         onClick: (event) => {
                      //             this.onRowClick(record, index, event)
                      //         }
                      //     }
                      // }}

                      // expandedRowKeys={this.state.expandRowClick}

                      columns={columns}
                      dataSource={state.hasData ? data : null}
                    />
                  </Spin>
                </div>
              );
            } else {
              return null;
            }
          }}
        </MediaQuery>
      </div>
    );
  }
}

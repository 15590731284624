import { ConfigName } from "../../../config-name";

export default {
  error: {
    code: null,
    message: "",
  },
  config: {
    menu: {
      // TODO set default menu here
    },
    values: {},
  },
  [ConfigName.SWLIFE_AUTH]: {
    isUserLoggedIn: false,
    loading: false,
    dataMode: "",
    gmk: "",
    menu: "",
    mfs: "",
    sessionTimeout: "",
  },
  document: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
  },
  plan1: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  plan2: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  weeklyPlan: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  monitoringLIFE: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  meeting: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  progressLIFE: {
    data: [],
    metaData: {
      current: 1,
      pageSize: 20,
      total: 0,
    },
    loading: false,
    reload: false,
    type: "", // getAll | getOne | create | update | remove
  },
  inquiryRequest: {
    loadingData: false,
    yougoNaiyou: [],
    tantoMaster: [],
    serviceKikan: [],
    serviceSyuruiMaster: [],
    dataInquiryRequest: {},
    syoukaiIraiDetailList: [],
    SyoukiaDate: [],
    idsInquiRequest: [],
    reload: false,
    dataList: [],
    metaDataList: {},
  },
  monitoringManagement: {
    createBatch: {},
    list: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 100,
      total: 0,
    },
    keepValue: {},
    isReload: false,
  },
  jokusoPlan: {
    jokusoPlanList: [],
  },
};

import { CloseOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Spin, Tabs } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { loadKeikaKiroku } from "../../actions/keikaKirokuActions/actionCreators";
import { changeValuePlan1, loadKyotakuPlan1 } from "../../actions/kyotakuPlan1Actions/actionCreators";
import {
  changeValuePlan2,
  loadKyotakuPlan2,
} from "../../actions/kyotakuPlan2Actions/actionCreators";
import { loadMonitoring } from "../../actions/monitoringActions/actionCreators";
import { getRiyousya } from "../../actions/riyousyaActions/actionCreators";
import { loadSisetuDailyPlan } from "../../actions/sisetuDailyPlanActions/actionCreators";
import { loadTantoKaigi } from "../../actions/tantoKaigiActions/actionCreators";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderRiyousya from "../../common/component/SubHeaderRiyousya";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import QuotePlan2Modal from "../documentList/inputMonitoring/UI/QuotePlanModal";
import "./DocumentList.css";
import { convertSortDataToParams } from "./DocumentListRecord";
import DocumentListRecordTableByRiyousya from "./UI/DocumentListRecordTableByRiyousya";
import LastQuoteModal from "./UI/LastQuoteModal";
import Toolbar1 from "./UI/Toolbar1";

const { TabPane } = Tabs;
const dataKey = "showreportlist2";
const defaultSort = {
  key1: "0",
  value1: "asc",
  key2: "0",
  value2: "asc",
  key3: "0",
  value3: "asc",
  key4: "0",
  value4: "asc",
};
// const columns = [{ dataIndex: "date", key: "date" }];
const currentMenu = localStorage.getItem("currentmenu") || "";
class DocumentListRiyousya extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props.match;
    let showReportList2 = JSON.parse(localStorage.getItem(dataKey));
    if (!showReportList2) {
      showReportList2 = {
        tabActive: "1",
        inUsed: false,
        sort1: defaultSort,
        sort2: defaultSort,
        sort3: defaultSort,
        sort4: defaultSort,
        sort5: defaultSort,
        sort6: defaultSort,
      };
      localStorage.setItem(dataKey, JSON.stringify(showReportList2));
    }
    else {
      if (!showReportList2.inUsed) {
        showReportList2.inUsed = false;
      }
      if (!showReportList2.sort1) {
        showReportList2.sort1 = defaultSort;
      }
      if (!showReportList2.sort2) {
        showReportList2.sort2 = defaultSort;
      }
      if (!showReportList2.sort3) {
        showReportList2.sort3 = defaultSort;
      }
      if (!showReportList2.sort4) {
        showReportList2.sort4 = defaultSort;
      }
      if (!showReportList2.sort5) {
        showReportList2.sort5 = defaultSort;
      }
      if (!showReportList2.sort6) {
        showReportList2.sort6 = defaultSort;
      }
      localStorage.setItem(dataKey, JSON.stringify(showReportList2));
    }
    this.state = {
      riyousyaId: params.riyousyaId,
      riyousyaInfo: null,
      tabActive: showReportList2.tabActive,
      sort1: showReportList2.sort1,
      sort2: showReportList2.sort2,
      sort3: showReportList2.sort3,
      sort4: showReportList2.sort4,
      sort5: showReportList2.sort5,
      sort6: showReportList2.sort6,
      page1: 1,
      page2: 1,
      page3: 1,
      page4: 1,
      page5: 1,
      page6: 1,
      lastQuoteVisible: false,
      addOrCopyVisible: false,
      visibleQuotePlan2: false,
      count: 0,
    };
  }

  async getReportRiyousya(value) {
    if (value) {
      this.props.history.push("/document-list/" + value);
      await this.setState({
        riyousyaId: value,
      });
      this.props.getRiyousyaInfo(value);
      this.loadData();
    }
  }

  loadData() {
    switch (this.state.tabActive) {
      case "1":
        this.props.loadKyotakuPlan1(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(this.state.sort1),
            page: this.state.page1,
          },
          (callback) => {
            this.setState({
              count: callback.meta ? callback.meta.total : 0,
            });
          }
        );
        // this.setState({
        //   count: metaPlan1 ? metaPlan1.total : 0
        // })
        break;
      case "2":
        this.props.loadKyotakuPlan2(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(this.state.sort2),
            page: this.state.page2,
          },
          (callback) => {
            this.setState({
              count: callback.meta ? callback.meta.total : 0,
            });
          }
        );
        break;
      case "3":
        this.props.loadSisetuDailyPlan(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(this.state.sort3),
            page: this.state.page3,
          },
          (callback) => {
            this.setState({
              count: callback.meta ? callback.meta.total : 0,
            });
          }
        );
        break;
      case "4":
        this.props.loadMonitoring(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(this.state.sort4),
            page: this.state.page4,
          },
          (callback) => {
            this.setState({
              count: callback.meta ? callback.meta.total : 0,
            });
          }
        );
        break;
      case "5":
        this.props.loadTantoKaigi(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(this.state.sort5),
            page: this.state.page5,
          },
          (callback) => {
            this.setState({
              count: callback.meta ? callback.meta.total : 0,
            });
          }
        );
        break;
      case "6":
        this.props.loadKeikaKiroku(
          {
            riyousyaId: this.state.riyousyaId,
            sort: convertSortDataToParams(this.state.sort6),
            page: this.state.page6,
          },
          (callback) => {
            this.setState({
              count: callback.meta ? callback.meta.total : 0,
            });
          }
        );
        break;

      default:
        break;
    }
  }

  componentDidMount() {
    this.props.getRiyousyaInfo(this.state.riyousyaId);
    this.loadData();
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.riyousya && newProps.riyousya.id == this.state.riyousyaId) {
      this.setState({
        riyousayInfo: {
          riyousya: newProps.riyousya,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.needReload && !prevProps.needReload) {
      this.loadData();
    }
  }

  async handleChangeTab(tab) {
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    showReportList.tabActive = tab;
    localStorage.setItem(dataKey, JSON.stringify(showReportList));
    await this.setState({
      tabActive: tab,
    });
    this.loadData();
  }

  async handleChangeSort(value) {
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    switch (this.state.tabActive) {
      case "1":
        showReportList.sort1 = value;
        await this.setState({
          sort1: value,
        });
        break;
      case "2":
        showReportList.sort2 = value;
        await this.setState({
          sort2: value,
        });
        break;
      case "3":
        showReportList.sort3 = value;
        await this.setState({
          sort3: value,
        });
        break;
      case "4":
        showReportList.sort4 = value;
        await this.setState({
          sort4: value,
        });
        break;
      case "5":
        showReportList.sort5 = value;
        await this.setState({
          sort5: value,
        });
        break;
      case "6":
        showReportList.sort6 = value;
        await this.setState({
          sort6: value,
        });
        break;
      default:
        break;
    }
    this.loadData();
    localStorage.setItem(dataKey, JSON.stringify(showReportList));
  }

  handlePageChangePlan1(page) {
    this.setState({
      page1: page,
    });
    this.props.loadKyotakuPlan1(
      {
        riyousyaId: this.state.riyousyaId,
        sort: convertSortDataToParams(this.state.sort1),
        page,
      },
      () => { }
    );
  }

  handlePageChangePlan2(page) {
    this.setState({
      page2: page,
    });
    this.props.loadKyotakuPlan2(
      {
        riyousyaId: this.state.riyousyaId,
        sort: convertSortDataToParams(this.state.sort2),
        page,
      },
      () => { }
    );
  }

  handlePageChangeDailyPlan(page) {
    this.setState({
      page3: page,
    });
    this.props.loadSisetuDailyPlan(
      {
        riyousyaId: this.state.riyousyaId,
        sort: convertSortDataToParams(this.state.sort3),
        page,
      },
      () => { }
    );
  }
  handlePageChangeMonitoring(page) {
    this.setState({
      page4: page,
    });
    this.props.loadMonitoring(
      {
        riyousyaId: this.state.riyousyaId,
        sort: convertSortDataToParams(this.state.sort4),
        page,
      },
      () => { }
    );
  }
  handlePageChangeTantoKaigi(page) {
    this.setState({
      page5: page,
    });
    this.props.loadTantoKaigi(
      {
        riyousyaId: this.state.riyousyaId,
        sort: convertSortDataToParams(this.state.sort5),
        page,
      },
      () => { }
    );
  }
  handlePageChangeKeikaKiruku(page) {
    this.setState({
      page6: page,
    });
    this.props.loadKeikaKiroku(
      {
        riyousyaId: this.state.riyousyaId,
        sort: convertSortDataToParams(this.state.sort6),
        page,
      },
      () => { }
    );
  }

  redirectById() {
    if (this.state.tabActive === "1") {
      this.props.history.push(
        "/input-kyotaku-plan1/" + this.state.riyousyaId + "/new"
      );
    }
    else if (this.state.tabActive === "3") {
      this.props.history.push(
        "/input-sisetu-daily-plan/" + this.state.riyousyaId + "/new"
      );
    }
    else if (this.state.tabActive === "4") {
      this.props.history.push(
        "/input-monitoring/" + this.state.riyousyaId + "/new"
      );
    }
    else if (this.state.tabActive === "5") {
      this.props.history.push(
        "/input-tanto-kaigi/" + this.state.riyousyaId + "/new"
      );
    }
    else if (this.state.tabActive === "6") {
      this.props.history.push(
        "/input-keika-kiroku/" + this.state.riyousyaId + "/new"
      );
    }
    else {
      this.props.history.push(
        "/input-kyotaku-plan2/" + this.state.riyousyaId + "/new"
      );
    }
  }

  showModalAddOrCopy() {
    const { dataPlan1, dataPlan2 } = this.props;
    switch (this.state.tabActive) {
      case "1":
        if (_.isEmpty(dataPlan1)) {
          this.redirectById();
        }
        else {
          this.setState({
            addOrCopyVisible: true,
          });
        }
        break;
      case "2":
        if (_.isEmpty(dataPlan2)) {
          this.redirectById();
        }
        else {
          this.setState({
            addOrCopyVisible: true,
          });
        }
        break;
      case "5":
      case "6":
      case "3":
        this.redirectById();
        break;
      case "4":
        this.setState({
          visibleQuotePlan2: true,
        });
        break;
      default:
        break;
    }
  }

  hideModalAddOrCopy() {
    this.setState({
      addOrCopyVisible: false,
    });
  }

  showModalLastQuote() {
    this.setState({
      addOrCopyVisible: false,
      lastQuoteVisible: true,
    });
  }

  hideModalLastQuote() {
    this.setState({
      lastQuoteVisible: false,
    });
  }
  hideModalQuotePlan2() {
    this.setState({
      visibleQuotePlan2: false,
    });
  }

  handleSelectLastQuote(data) {
    if (data) {
      if (this.state.tabActive === "1") {
        this.props.changeValuePlan1(true);
        this.props.history.push(
          "/input-kyotaku-plan1/" +
          this.state.riyousyaId +
          "/new?copy-from=" +
          data.id
        );
      }
      else {
        this.props.changeValuePlan2(true);
        this.props.history.push(
          "/input-kyotaku-plan2/" +
          this.state.riyousyaId +
          "/new?copy-from=" +
          data.id
        );
      }
    }
    this.setState({
      visibleQuotePlan2: false,
    });
  }
  handleSelectPlan2Quote(data) {
    if (data) {
      this.props.history.push(
        "/input-monitoring/" +
        this.state.riyousyaId +
        "/new?copy-from=" +
        data.id
      );
    }
    this.setState({
      lastQuoteVisible: false,
    });
  }
  render() {
    const {
      dataPlan1,
      dataPlan2,
      dataDailyPlan,
      monitorings,
      tantoKaigis,
      keikaKirokus,
    } = this.props;
    const pagePlan1 = {
      onChange: (page) => {
        this.handlePageChangePlan1(page);
      },
      total: this.props.metaPlan1.total,
      current: this.props.metaPlan1.page,
      pageSize: this.props.metaPlan1.pageSize,
    };
    const pagePlan2 = {
      onChange: (page) => {
        this.handlePageChangePlan2(page);
      },
      total: this.props.metaPlan2.total,
      current: this.props.metaPlan2.page,
      pageSize: this.props.metaPlan2.pageSize,
    };
    const pageDailyPlan = {
      onChange: (page) => this.handlePageChangeDailyPlan(page),
      total: this.props.metaPlan3.total,
      current: this.props.metaPlan3.page,
      pageSize: this.props.metaPlan3.pageSize,
    };
    const pageMonitoring = {
      onChange: (page) => this.handlePageChangeMonitoring(page),
      total: this.props.metaMonitoring.total,
      current: this.props.metaMonitoring.page,
      pageSize: this.props.metaMonitoring.pageSize,
    };
    const pageTantoKaigi = {
      onChange: (page) => this.handlePageChangeTantoKaigi(page),
      total: this.props.metaTantoKaigi.total,
      current: this.props.metaTantoKaigi.page,
      pageSize: this.props.metaTantoKaigi.pageSize,
    };
    const pageKeikaKiroku = {
      onChange: (page) => this.handlePageChangeKeikaKiruku(page),
      total: this.props.metaKeikaKiroku.total,
      current: this.props.metaKeikaKiroku.page,
      pageSize: this.props.metaKeikaKiroku.pageSize,
    };
    // const data = Array.isArray(yougoMasterRoomNaiyou) ? yougoMasterRoomNaiyou.map((room) => {
    //   return {
    //     key: room.key,
    //     room: room.naiyou,
    //   };
    // }) : [];
    // handlePageChangeKeikaKiruku
    return (
      (<CommonPageContainer
        id={"document-list-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <Row className={"submenu"}>
          <SubHeaderV2
            TopLeft={
              <SubHeaderTitleV2
                title={"書類一覧（利用者別）"}
                iconType={"file-text"}
                count={true}
                countNum={this.state.count ? this.state.count : 0}
              />
            }
            TopRight={
              !currentMenu.includes("business1") ? (
                <Button
                  onClick={() => {
                    this.props.history.push("/document-list");
                  }}
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    padding: "0 8px 42px 0",
                    backgroundColor: "#424242",
                    border: "none",
                  }}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    <UndoOutlined />
                  </span>
                  <span>{"戻る"}</span>
                </Button>
              ) : (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.setItem("currentmenu", "record0");
                    window.open("", "_parent", "");
                    window.close();
                  }}
                  style={{
                    color: "white",
                    textAlign: "center",
                    float: "right",
                    padding: "0 8px",
                    backgroundColor: "#424242",
                    border: "none",
                  }}
                >
                  <span style={{ display: "block", fontWeight: "bold" }}>
                    <CloseOutlined />
                  </span>
                  <span>{"閉じる"}</span>
                </Button>
              )
            }
            IncBottom={
              <SubHeaderRiyousya
                style={{ display: "inline" }}
                riyousayInfo={this.state.riyousayInfo}
                reportRiyousya={(value) => this.getReportRiyousya(value)}
              />
            }
          />
        </Row>
        <div className={"document-table"}>
          <Row style={{ padding: "10px 0", justifyContent: "flex-end" }}>
            <Toolbar1
              isUserMode={true}
              metaPlan3={this.props.metaPlan3}
              metaMonitoring={this.props.metaMonitoring}
              tabActive={this.state.tabActive}
              sort1={this.state.sort1}
              sort2={this.state.sort2}
              sort3={this.state.sort3}
              sort4={this.state.sort4}
              sort5={this.state.sort5}
              sort6={this.state.sort6}
              showModalAddOrCopy={() => this.showModalAddOrCopy()}
              onChangeSort={(sort) => this.handleChangeSort(sort)}
              loading={this.props.loading}
            />
          </Row>
          <Row>
            <Spin spinning={this.props.loading}>
              <Tabs
                activeKey={this.state.tabActive}
                type="card"
                onChange={(activeKey) => this.handleChangeTab(activeKey)}
              >
                <TabPane tab="計画書１" key="1">
                  <DocumentListRecordTableByRiyousya
                    tabName={"計画書１"}
                    isUserMode={true}
                    tab="plan1"
                    tabActive={this.state.tabActive}
                    data={dataPlan1}
                    history={this.props.history}
                    pagination={pagePlan1}
                  />
                </TabPane>
                <TabPane tab="計画書２" key="2">
                  <DocumentListRecordTableByRiyousya
                    tabName={"計画書２"}
                    tabActive={this.state.tabActive}
                    isUserMode={true}
                    history={this.props.history}
                    tab="plan2"
                    data={dataPlan2}
                    pagination={pagePlan2}
                  />
                </TabPane>
                <TabPane tab="日課表" key="3">
                  <DocumentListRecordTableByRiyousya
                    tabName={"日課表"}
                    tabActive={this.state.tabActive}
                    isUserMode={true}
                    history={this.props.history}
                    tab="daily-plan"
                    data={dataDailyPlan}
                    pagination={pageDailyPlan}
                  />
                </TabPane>
                <TabPane tab="モニタリング" key="4">
                  <DocumentListRecordTableByRiyousya
                    tabName={"モニタリング"}
                    isUserMode={true}
                    tab="monitoring"
                    tabActive={this.state.tabActive}
                    data={monitorings}
                    history={this.props.history}
                    pagination={pageMonitoring}
                  />
                </TabPane>
                <TabPane tab="担当者会議" key="5">
                  <DocumentListRecordTableByRiyousya
                    tabName={"担当者会議"}
                    isUserMode={true}
                    tab="tanto-kaigi"
                    tabActive={this.state.tabActive}
                    data={tantoKaigis}
                    history={this.props.history}
                    pagination={pageTantoKaigi}
                  />
                </TabPane>
                <TabPane tab="施設経過記録" key="6">
                  <DocumentListRecordTableByRiyousya
                    tabName={"施設経過記録"}
                    isUserMode={true}
                    tab="keika-kiroku"
                    tabActive={this.state.tabActive}
                    data={keikaKirokus}
                    history={this.props.history}
                    pagination={pageKeikaKiroku}
                  />
                </TabPane>
              </Tabs>
            </Spin>
          </Row>
          <Modal
            className={"modal-add-coppy"}
            open={this.state.addOrCopyVisible}
            title={"追加選択ダイアログ"}
            onCancel={() => this.hideModalAddOrCopy()}
            footer={[
              <Button
                key="add"
                type={"primary"}
                onClick={() => this.redirectById()}
              >
                追加
              </Button>,
              <Button
                key="copy"
                type={"primary"}
                onClick={() => this.showModalLastQuote()}
              >
                複製追加
              </Button>,
              <Button key="canceladd" onClick={() => this.hideModalAddOrCopy()}>
                キャンセル
              </Button>,
            ]}
          />
          <QuotePlan2Modal
            visible={this.state.visibleQuotePlan2}
            title={"計画書２の引用"}
            riyousyaId={this.state.riyousyaId}
            handleSelectLastQuote={(data) => this.handleSelectPlan2Quote(data)}
            hideModalQuotePlan2={() => this.hideModalQuotePlan2()}
          ></QuotePlan2Modal>
          <LastQuoteModal
            visible={this.state.lastQuoteVisible}
            title={"複製追加ダイアログ"}
            riyousyaId={this.state.riyousyaId}
            tab={this.state.tabActive}
            handleSelectLastQuote={(data) => this.handleSelectLastQuote(data)}
            hideModalLastQuote={() => this.hideModalLastQuote()}
          />
        </div>
      </CommonPageContainer>)
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.documentList.loading,
    riyousya: state.riyousya.riyousya,
    dataPlan1: state.documentList.dataPlan1,
    dataPlan2: state.documentList.dataPlan2,
    dataDailyPlan: state.documentList.dataDailyPlan,
    monitorings: state.documentList.monitorings,
    metaMonitoring: state.documentList.metaMonitoring,
    tantoKaigis: state.documentList.tantoKaigis,
    metaTantoKaigi: state.documentList.metaTantoKaigi,
    keikaKirokus: state.documentList.keikaKirokus,
    metaKeikaKiroku: state.documentList.metaKeikaKiroku,
    metaPlan1: state.documentList.metaPlan1,
    metaPlan2: state.documentList.metaPlan2,
    metaPlan3: state.documentList.metaDailyPlan,
    needReload: state.documentList.needReload,
  };
};
/**
 *
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return {
    loadKyotakuPlan1: (data, callback) =>
      dispatch(loadKyotakuPlan1(data, callback)),
    loadKyotakuPlan2: (data, callback) =>
      dispatch(loadKyotakuPlan2(data, callback)),
    loadSisetuDailyPlan: (data, callback) =>
      dispatch(loadSisetuDailyPlan(data, callback)),
    loadMonitoring: (data, callback) =>
      dispatch(loadMonitoring(data, callback)),
    loadTantoKaigi: (data, callback) =>
      dispatch(loadTantoKaigi(data, callback)),
    loadKeikaKiroku: (data, callback) =>
      dispatch(loadKeikaKiroku(data, callback)),
    getRiyousyaInfo: (riyousyaId) => dispatch(getRiyousya(riyousyaId)),
    changeValuePlan2: (type) => dispatch(changeValuePlan2(type)),
    changeValuePlan1: (type) => dispatch(changeValuePlan1(type)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentListRiyousya);

export const HaisetsuActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  HAISETSU_LIFE_YOUGO_SUCCESS: "HAISETSU_LIFE_YOUGO_SUCCESS",
  HAISETSU_LIFE_YOUGO_ERROR: "HAISETSU_LIFE_YOUGO_ERROR",

  LOAD_HAISETSU_SUCCESS: "LOAD_HAISETSU_SUCCESS",
  LOAD_HAISETSU_ERROR: "LOAD_HAISETSU_ERROR",

  REMOVE_HAISETSU_REQUEST: "REMOVE_HAISETSU_REQUEST",
  REMOVE_HAISETSU_SUCCESS: "REMOVE_HAISETSU_SUCCESS",
  REMOVE_HAISETSU_ERROR: "REMOVE_HAISETSU_ERROR",

  SAVE_HAISETSU_SIEN_SUCCESS: "SAVE_HAISETSU_SIEN_SUCCESS",
  SAVE_HAISETSU_SIEN_ERROR: "SAVE_HAISETSU_SIEN_ERROR",

  LOAD_HAISETU_TO_QUOTE_SUCCESS: "LOAD_HAISETU_TO_QUOTE_SUCCESS",
  LOAD_HAISETU_TO_QUOTE: "LOAD_HAISETU_TO_QUOTE",

  SET_NEW_DATA: "SET_NEW_DATA",
  GET_LIST_IDS_HAISETU: "GET_LIST_IDS_HAISETU",

  LOAD_HAISETU_BY_IDS_REQUEST: "LOAD_HAISETU_BY_IDS_REQUEST",
  LOAD_HAISETU_BY_IDS_SUCCESS: "LOAD_HAISETU_BY_IDS_SUCCESS",
  LOAD_HAISETU_BY_IDS_ERROR: "LOAD_HAISETU_BY_IDS_ERROR",
  CLEAR_HAISETU_BY_IDS: "CLEAR_HAISETU_BY_IDS",
};

import axios from "axios";
import "whatwg-fetch";
import { apiUrls } from "../../../common/configs/common-url";
import { ConfigName } from "../../../config-name";
import { StorageUtils } from "../../utils";

const KobetsuApi = {
  async loadKobetsuDetail(params) {
    const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
    const res = await axios
      .get(apiUrls.GET_KOBETSU_BYID, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          riyousyaId: params.riyousyaId,
          id: params.id,
          fromDate: params.fromDate,
          toDate: params.toDate,
        },
      });
    return res.data;
  },

  async loadKobetsu(params) {
    const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
    const res = await axios
      .get(apiUrls.GET_KOBETSU_LIST, {
        params: {
          sid: menu.si,
          systemName: menu.sn,
          riyousyaId: params.riyousyaId,
          inUsed: params.inUsed,
          searchName: params.searchName,
          page: params.page,
          sort: params.sort,
        },
      });
    return res.data;
  },

  async postKobetsuCopy(params) {
    const res = await axios
      .post(apiUrls.COPY_KOBETSU, {
        params,
      });
    return res.data;
  },

  async createKobetsu(data, riyousyaId) {
    // const key = getMenu();
    const res = await axios
      .post(apiUrls.CREATE_KOBETSU, {
        id: data.id,

        riyousyaId,
        sid: StorageUtils.getValue(ConfigName.SWLIFE_MENU).si,

        createDate: data.createDate,
        lastTimeCreateDate: data.lastTimeCreateDate,
        firstTimeCreateDate: data.firstTimeCreateDate,
        careLevel: data.careLevel,
        createTantoId: data.createTantoId ? data.createTantoId : 0,
        impairedDegree: data.impairedDegree,
        dementiaDegree: data.dementiaDegree,
        userRequest: data.userRequest,
        userFamilyRequest: data.userFamilyRequest,
        userSocialParticipationNotice: data.userSocialParticipationNotice,
        userEnvironmentNotice: data.userEnvironmentNotice,
        mainMedicd: data.mainMedicd,
        mainSyoubyoumei: data.mainSyoubyoumei,
        onsetDate: data.onsetDate,
        latestAdmissionDate: data.latestAdmissionDate,
        latestDischargeDate: data.latestDischargeDate,
        progress: data.progress,
        complicationsControlNotices: data.complicationsControlNotices,
        implementationStatus: data.implementationStatus,
        shortGoalAchievement: data.shortGoalAchievement,
        longGoalAchievement: data.longGoalAchievement,
        plannerTantoId: data.plannerTantoId ? data.plannerTantoId : 0,
        notNotice: data.notNotice,
        tokki: data.tokki,
        change: data.change,
        reEvaluation: data.reEvaluation,
        kakuteif: data.kakuteif ? 1 : 0,

        complicationsControls: data.complicationsControls,
        goalFunctionalTrainings: data.goalFunctionalTrainings,
        kobetukinouKunren2Programs: data.kobetukinouKunren2Programs,
      });
    return res.data;
  },
  async updateKobetsu(data, riyousyaId) {
    const res = await axios
      .put(apiUrls.UPDATE_KOBETSU + "?id=" + data.id, {
        riyousya_id: riyousyaId,
        service_kikan_master_id: StorageUtils.getValue(ConfigName.SWLIFE_MENU)
          .si,

        create_date: data.create_date,
        last_time_create_date: data.last_time_create_date,
        first_time_create_date: data.last_time_create_date,
        care_level: data.care_level,
        create_tanto_id: data.create_tanto_id,
        impaired_degree: data.impaired_degree,
        dementia_degree: data.dementia_degree,
        user_request: data.user_request,
        user_family_request: data.user_family_request,
        user_social_participation_notice: data.user_social_participation_notice,
        user_environment_notice: data.user_environment_notice,
        main_medicd: data.main_medicd,
        main_syoubyoumei: data.main_syoubyoumei,
        onset_date: data.onset_date,
        latest_admission_date: data.latest_admission_date,
        latest_discharge_date: data.latest_discharge_date,
        progress: data.progress,
        complications_control_notices: data.complications_control_notices,
        implementation_status: data.implementation_status,
        short_goal_achievement: data.short_goal_achievement,
        long_goal_achievement: data.long_goal_achievement,
        planner_tanto_id: data.planner_tanto_id,
        not_notice: data.not_notice,
        tokki: data.tokki,
        change: data.change,
        re_evaluation: data.re_evaluation,
        kakuteif: data.kakuteif ? 1 : 0,

        kobetukinou_kunren2_id: data.kobetukinou_kunren2_id,

        // complications_control
        // kobetukinou_kunren2_id: data.kobetukinou_kunren2_id,
        complications_control_complications_medicd: data.complications_control_complications_medicd,
        complications_control_sort_num: data.complications_control_sort_num,

        // goal_functional_training
        // kobetukinou_kunren2_id: data.kobetukinou_kunren2_id,
        goal_functional_training_kubun: data.goal_functional_training_kubun,
        goal_functional_training_icf_cd: data.goal_functional_training_icf_cd,
        goal_functional_training_sort_num: data.goal_functional_training_sort_num,

        // kobetukinou_kunren2_program
        // kobetukinou_kunren2_id: data.kobetukinou_kunren2_id,
        kobetukinou_kunren2_program_sien_cd: data.kobetukinou_kunren2_program_sien_cd,
        kobetukinou_kunren2_program_note: data.kobetukinou_kunren2_program_note,
        kobetukinou_kunren2_program_times: data.kobetukinou_kunren2_program_times,
        kobetukinou_kunren2_program_min: data.kobetukinou_kunren2_program_min,
        kobetukinou_kunren2_program_personnel: data.kobetukinou_kunren2_program_personnel,
        kobetukinou_kunren2_program_sort_num: data.kobetukinou_kunren2_program_sort_num,
      });
    return res.data;
  },
  async deleteKobetsu(id) {
    const res = await axios
      .delete(apiUrls.DELETE_KOBETSU, {
        params: {
          id,
        },
      });
    return res.data;
  },
  async getKobetsuById(id) {
    const res = await axios
      .get(apiUrls.GET_KOBETSU_BYID, {
        params: {
          id,
        },
      });
    return res.data;
  },
  async getLatestKobetsu(riyousyaId) {
    const sid = StorageUtils.getValue(ConfigName.SWLIFE_MENU).si;
    try {
      const res = await axios
        .get(apiUrls.GET_LATEST_KOBETSU, {
          params: {
            sid,
            riyousyaId,
          },
        });
      return res.data;
    }
    catch (err) {
      return null;
    }
  },
  async getFirstKobetsu(riyousyaId) {
    const sid = StorageUtils.getValue(ConfigName.SWLIFE_MENU).si;
    try {
      const res = await axios
        .get(apiUrls.GET_FIRST_KOBETSU, {
          params: {
            sid,
            riyousyaId,
          },
        });
      return res.data;
    }
    catch (err) {
      return null;
    }
  },
};

export { KobetsuApi };

import React from "react";
import { EnterOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

// import '@ant-design/compatible/assets/index.css';


import { Col, Row, InputNumber, Checkbox, Radio, Button, Popover } from "antd";

import {
  handleInputNumber,
  convertArrayToString,
  extractRangeVital,
} from "../function_common/functionCommon";
import TantoMasterKB from "../model-kiroku/TantoMasterKB";
import CommentKB from "../model-kiroku/CommentKB";
import InputSelectKB from "../model-kiroku/InputSelectKB";
import TimeKBCus from "../component/TimeKBCus";

const RadioGroup = Radio.Group;
class NormalVitalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportCaseTyuif: this.props.data.reportCaseTyuif,
      mainf: this.props.data.mainf,
      type: null,
      extendValue: false,
      visibleNoKeyboard: false,
    };

    this.onChangeTime = this.onChangeTime.bind(this);
    this.onClickRctf = this.onClickRctf.bind(this);
    this.onClickMainf = this.onClickMainf.bind(this);
    this.onChangeTem = this.onChangeTem.bind(this);
    this.onChangeBph = this.onChangeBph.bind(this);
    this.onChangeBpl = this.onChangeBpl.bind(this);
    this.onChangePulse = this.onChangePulse.bind(this);
    this.onChangePulsef = this.onChangePulsef.bind(this);
    this.onChangeSpo2B = this.onChangeSpo2B.bind(this);
    this.onChangeSpo2A = this.onChangeSpo2A.bind(this);
    this.onChangeKokyu = this.onChangeKokyu.bind(this);
    this.onChangeKokyuf = this.onChangeKokyuf.bind(this);
    this.onChangeEtc1 = this.onChangeEtc1.bind(this);
    this.onChangeSelectEtc1 = this.onChangeSelectEtc1.bind(this);
    this.onChangeEtc2 = this.onChangeEtc2.bind(this);
    this.onChangeSelectEtc2 = this.onChangeSelectEtc2.bind(this);
    this.onChangeEtc3 = this.onChangeEtc3.bind(this);
    this.onChangeSelectEtc3 = this.onChangeSelectEtc3.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.getHandleTantoMasterKB = this.getHandleTantoMasterKB.bind(this);
    this.onChangeCommentSelect = this.onChangeCommentSelect.bind(this);

    this.getYougoMasterEditState = this.getYougoMasterEditState.bind(this);
    this.getYougoMasterEditCancel = this.getYougoMasterEditCancel.bind(this);
    this.getYougoMasterEditSave = this.getYougoMasterEditSave.bind(this);

    this.getYougoMasterEctState = this.getYougoMasterEctState.bind(this);
    this.getYougoMasterEctCancel = this.getYougoMasterEctCancel.bind(this);
    this.getYougoMasterEctSave = this.getYougoMasterEctSave.bind(this);

    this.handleType = this.handleType.bind(this);
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.data !== newProps.data) {
      this.props.form.resetFields();
    }
  }

  onChangeTime(time, timeString) {
    this.props.handleFormInput(timeString, "time");
  }

  onClickRctf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "reportCaseTyuif");
  }

  onClickMainf(e) {
    this.props.handleFormInput(e.target.checked ? 1 : 0, "mainf");
  }

  onChangeTem(value) {
    this.props.handleFormInput(value ? value : "0", "temperature");
  }

  onChangeWeight = (value) => {
    this.props.handleFormInput(value ? value : "0", "weight");
  };

  onChangeBph(value) {
    this.props.handleFormInput(value, "bpressureHigh");
  }

  onChangeBpl(value) {
    this.props.handleFormInput(value, "bpressureLow");
  }

  onChangePulse(value) {
    this.props.handleFormInput(value, "pulse");
  }

  onChangePulsef(e) {
    this.props.handleFormInput(e.target.value, "pulsef");
  }

  onChangeSpo2B(value) {
    this.props.handleFormInput(value, "spo2B");
  }

  onChangeSpo2A(value) {
    this.props.handleFormInput(value, "spo2A");
  }

  onChangeKokyu(value) {
    this.props.handleFormInput(value, "kokyu");
  }

  onChangeKokyuf(e) {
    this.props.handleFormInput(e.target.value, "kokyuf");
  }

  onChangeEtc1(value, type) {
    if (type && type === "select" && this.props.data.etc1) {
      value = this.props.data.etc1 + "、" + value;
    }

    this.props.handleFormInput(value, "etc1");
  }

  onChangeSelectEtc1(value) {
    this.props.handleFormInput(value, "etc1");
  }

  onChangeEtc2(value, type) {
    if (type && type === "select" && this.props.data.etc2) {
      value = this.props.data.etc2 + "、" + value;
    }

    this.props.handleFormInput(value, "etc2");
  }

  onChangeSelectEtc2(value) {
    this.props.handleFormInput(value, "etc2");
  }

  onChangeEtc3(value, type) {
    if (type && type === "select" && this.props.data.etc3) {
      value = this.props.data.etc3 + "、" + value;
    }

    this.props.handleFormInput(value, "etc3");
  }

  onChangeSelectEtc3(value) {
    this.props.handleFormInput(value, "etc3");
  }

  onChangeComment(e) {
    this.props.handleFormInput(e.target.value, "comment");
  }

  onChangeCommentSelect(value, type) {
    if (type && type === "select" && this.props.data.comment) {
      value = this.props.data.comment + "、" + value;
    }

    this.props.handleFormInput(value, "comment");
  }

  getHandleTantoMasterKB(value) {
    this.props.handleFormInput(value, "tantoId");
  }

  handleInput(value) {
    value = value ? value : "0";
    let checkValue = handleInputNumber(value, this.state.value, true);
    this.props.handleFormInput(checkValue, this.state.type);
    this.setState({
      value: checkValue,
    });
  }

  getYougoMasterEditState(index) {
    this.props.yougoMasterEditState(index);
  }

  getYougoMasterEditCancel = (index) => {
    this.props.yougoMasterEditCancel(index);
  };

  getYougoMasterEditSave(naiyou) {
    this.props.yougoMasterEditSave(naiyou);
  }

  getYougoMasterEctState(index) {
    this.props.yougoMasterEctState(index);
  }

  getYougoMasterEctCancel(index) {
    this.props.yougoMasterEctCancel(index);
  }

  getYougoMasterEctSave(type, naiyou) {
    this.props.yougoMasterEctSave(type, naiyou);
  }

  handleType(type) {
    this.setState({
      type: type,
      value: null,
      visibleNoKeyboard: true,
    });
  }

  /**
   *
   * @param {dateTime} time
   * @param {dataTimeToString} timeString
   */
  onChangeTimeMulModeNew = (timeString) => {
    this.props.handleFormInput(timeString, "time");
  };

  /**
   * getCurrentTime
   *
   */
  handleSetCurrentTime = () => {
    let currentTime = new Date().toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });

    this.props.handleFormInput(currentTime, "time");
  };

  handleEnter = () => {
    this.setState({
      visibleNoKeyboard: false,
    });
  };

  onVisibleChangePopover = () => {
    const { visibleNoKeyboard } = this.state;

    if (visibleNoKeyboard) {
      this.setState({
        visibleNoKeyboard: false,
      });
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      tantoMaster,
      data,
      yougoMasterNa,
      vitalEct,
      ect1Type,
      ect2Type,
      ect3Type,
      yougoMasterEctSelect1,
      yougoMasterEctSelect2,
      yougoMasterEctSelect3,
      vitalCol,
      vitalHighLow,
      zenkeiRecord,
    } = this.props;

    const { visibleNoKeyboard, type } = this.state;

    let temperature = null;
    let bpressureHigh = null;
    let bpressureLow = null;
    let pulse = null;
    let respiration = null;

    if (vitalHighLow) {
      let extractRange = extractRangeVital(vitalHighLow);
      temperature = extractRange.temperature;
      bpressureHigh = extractRange.bpressureHigh;
      bpressureLow = extractRange.bpressureLow;
      pulse = extractRange.pulse;
      respiration = extractRange.respiration;
    }

    let zenkaiSpo2A = "";
    let zenkaiSpo2B = "";
    if (zenkeiRecord && zenkeiRecord[0] && zenkeiRecord[0].spo2) {
      zenkaiSpo2A = zenkeiRecord[0].spo2.split("-")[0]
        ? zenkeiRecord[0].spo2.split("-")[0]
        : "";
      zenkaiSpo2B = zenkeiRecord[0].spo2.split("-")[1]
        ? zenkeiRecord[0].spo2.split("-")[1]
        : "";
    }

    const numberKB = (
      <Row className="number-pro">
        <Col span={18}>
          <Row>
            <Button onClick={() => this.handleInput("7")}>7</Button>
            <Button onClick={() => this.handleInput("8")}>8</Button>
            <Button onClick={() => this.handleInput("9")}>9</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("4")}>4</Button>
            <Button onClick={() => this.handleInput("5")}>5</Button>
            <Button onClick={() => this.handleInput("6")}>6</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("1")}>1</Button>
            <Button onClick={() => this.handleInput("2")}>2</Button>
            <Button onClick={() => this.handleInput("3")}>3</Button>
          </Row>
          <Row>
            <Button onClick={() => this.handleInput("0")}>0</Button>
            <Button onClick={() => this.handleInput(".")}>.</Button>
            <Button disabled>-</Button>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Button
              onClick={() => this.handleInput("消去")}
              style={{ padding: "0px" }}
            >
              消去
            </Button>
          </Row>
          <Row>
            <Button
              onClick={this.handleEnter}
              style={{ padding: "0px", height: 150 }}
            >
              <EnterOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
    );

    return (
      (<Form layout="vertical" hideRequiredMark>
        <Row>
          <Col className="kdf-label" span={4}>
            <span>時間</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item className="form-item-300">
              <TimeKBCus
                timeString={data && data.time ? data.time : null}
                handleInput={this.onChangeTimeMulModeNew}
              />
              <Button
                className="kdf-number-k"
                type="primary"
                onClick={this.handleSetCurrentTime}
              >
                現時刻
              </Button>
            </Form.Item>
            <Form.Item className="form-item-100">
              {getFieldDecorator("reportCaseTyuif", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  className="kdf-f-extent"
                  checked={data && data.reportCaseTyuif ? true : false}
                  onChange={this.onClickRctf}
                >
                  申送
                </Checkbox>
              )}
            </Form.Item>
            <Form.Item className="form-item-100">
              {getFieldDecorator("mainf", {
                rules: [{ required: false }],
              })(
                <Checkbox
                  className="kdf-f-extent"
                  checked={data && data.mainf ? true : false}
                  onChange={this.onClickMainf}
                >
                  メイン
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* update weight */}
        {vitalCol && vitalCol[0] === "True" ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>体重</span>
            </Col>
            <Col className="kdf-f-item" span={17}>
              <Form.Item>
                {getFieldDecorator("weight", {
                  rules: [{ required: false }],
                  initialValue:
                    data.weight && data.weight > 0 ? data.weight : null,
                })(<InputNumber min={0} onChange={this.onChangeWeight} />)}
                <Popover
                  visible={
                    visibleNoKeyboard && type === "weight" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  type="weight"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    className="kdf-number-k"
                    onClick={() => this.handleType("weight")}
                    type="primary"
                  >
                    入力
                  </Button>
                </Popover>
                <span style={{ paddingLeft: 4 }}>
                  {zenkeiRecord &&
                  zenkeiRecord[0] &&
                  zenkeiRecord[0].weight !== 0
                    ? zenkeiRecord[0].weight
                    : ""}
                </span>
              </Form.Item>
            </Col>
            <Col span={3} style={{ paddingTop: 7 }}>
              <span>正常値</span>
            </Col>
          </Row>
        ) : null}
        {vitalCol && vitalCol[1] === "True" ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>体温</span>
            </Col>
            <Col className="kdf-f-item" span={17}>
              <Form.Item>
                {getFieldDecorator("temperature", {
                  rules: [{ required: false }],
                  initialValue:
                    data.temperature && data.temperature > 0
                      ? data.temperature
                      : null,
                })(<InputNumber min={0} onChange={this.onChangeTem} />)}
                <Popover
                  visible={
                    visibleNoKeyboard && type === "temperature" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  type="temperature"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    className="kdf-number-k"
                    onClick={() => this.handleType("temperature")}
                    type="primary"
                  >
                    入力
                  </Button>
                </Popover>
                <span style={{ paddingLeft: 4 }}>
                  {zenkeiRecord &&
                  zenkeiRecord[0] &&
                  zenkeiRecord[0].temperature !== 0
                    ? zenkeiRecord[0].temperature
                    : ""}
                </span>
              </Form.Item>
            </Col>
            <Col span={3} style={{ paddingTop: 7 }}>
              <span>{temperature}</span>
            </Col>
          </Row>
        ) : null}
        {vitalCol && vitalCol[2] === "True" ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>血圧</span>
              <span style={{ float: "right", padding: 4 }}>高</span>
            </Col>
            <Col className="kdf-f-item" span={17}>
              <Form.Item className="form-item-200">
                {getFieldDecorator("bpressureHigh", {
                  rules: [{ required: false }],
                  initialValue:
                    data.bpressureHigh && data.bpressureHigh > 0
                      ? data.bpressureHigh
                      : null,
                })(<InputNumber min={0} onChange={this.onChangeBph} />)}
                <Popover
                  visible={
                    visibleNoKeyboard && type === "bpressureHigh" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    className="kdf-number-k"
                    type="primary"
                    onClick={() => this.handleType("bpressureHigh")}
                  >
                    入力
                  </Button>
                </Popover>
                <span style={{ paddingLeft: 4 }}>
                  {zenkeiRecord &&
                  zenkeiRecord[0] &&
                  zenkeiRecord[0].bpressureHigh !== 0
                    ? zenkeiRecord[0].bpressureHigh
                    : ""}
                </span>
              </Form.Item>

              <Form.Item className="form-item-250">
                <span style={{ paddingRight: 4 }}>低</span>
                {getFieldDecorator("bpressureLow", {
                  rules: [{ required: false }],
                  initialValue:
                    data.bpressureLow && data.bpressureLow > 0
                      ? data.bpressureLow
                      : null,
                })(<InputNumber min={0} onChange={this.onChangeBpl} />)}
                <Popover
                  visible={
                    visibleNoKeyboard && type === "bpressureLow" ? true : false
                  }
                  placement="bottomRight"
                  content={numberKB}
                  trigger="click"
                  onVisibleChange={this.onVisibleChangePopover}
                >
                  <Button
                    className="kdf-number-k"
                    type="primary"
                    onClick={() => this.handleType("bpressureLow")}
                  >
                    入力
                  </Button>
                </Popover>
                <span style={{ paddingLeft: 4 }}>
                  {zenkeiRecord &&
                  zenkeiRecord[0] &&
                  zenkeiRecord[0].bpressureLow !== 0
                    ? zenkeiRecord[0].bpressureLow
                    : ""}
                </span>
              </Form.Item>
            </Col>
            <Col span={3}>
              <div>{bpressureHigh}</div>
              <div>{bpressureLow}</div>
            </Col>
          </Row>
        ) : null}
        {vitalCol && (vitalCol[3] === "True" || vitalCol[4] === "True") ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>脈拍</span>
            </Col>
            <Col className="kdf-f-item" span={17}>
              {vitalCol && vitalCol[3] === "True" ? (
                <Form.Item className="form-item-200">
                  {getFieldDecorator("pulse", {
                    rules: [{ required: false }],
                    initialValue:
                      data.pulse && data.pulse > 0 ? data.pulse : null,
                  })(<InputNumber min={0} onChange={this.onChangePulse} />)}
                  <Popover
                    visible={
                      visibleNoKeyboard && type === "pulse" ? true : false
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.handleType("pulse")}
                    >
                      入力
                    </Button>
                  </Popover>
                  <span style={{ paddingLeft: 4 }}>
                    {zenkeiRecord &&
                    zenkeiRecord[0] &&
                    zenkeiRecord[0].pulse !== 0
                      ? zenkeiRecord[0].pulse
                      : ""}
                  </span>
                </Form.Item>
              ) : null}

              {vitalCol && vitalCol[4] === "True" ? (
                <Form.Item className="form-item-250">
                  {getFieldDecorator("pulsef", {
                    rules: [{ required: false }],
                    initialValue: data.pulsef,
                  })(
                    <RadioGroup
                      onChange={this.onChangePulsef}
                      className="kdf-f-extent"
                    >
                      <Radio value={"整"}>整</Radio>
                      <Radio value={"不整"}>不整</Radio>
                      <Radio value={"無し"}>無し</Radio>
                    </RadioGroup>
                  )}
                </Form.Item>
              ) : null}
            </Col>
            <Col span={3} style={{ paddingTop: 7 }}>
              <span>{pulse}</span>
            </Col>
          </Row>
        ) : null}
        {vitalCol && (vitalCol[5] === "True" || vitalCol[6] === "True") ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>呼吸</span>
            </Col>
            <Col className="kdf-f-item" span={17}>
              {vitalCol && vitalCol[5] === "True" ? (
                <Form.Item className="form-item-200">
                  {getFieldDecorator("kokyu", {
                    rules: [{ required: false }],
                    initialValue:
                      data.kokyu && data.kokyu > 0 ? data.kokyu : null,
                  })(<InputNumber min={0} onChange={this.onChangeKokyu} />)}
                  <Popover
                    visible={
                      visibleNoKeyboard && type === "kokyu" ? true : false
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.handleType("kokyu")}
                    >
                      入力
                    </Button>
                  </Popover>
                  <span style={{ paddingLeft: 4 }}>
                    {zenkeiRecord &&
                    zenkeiRecord[0] &&
                    zenkeiRecord[0].kokyu !== 0
                      ? zenkeiRecord[0].kokyu
                      : ""}
                  </span>
                </Form.Item>
              ) : null}

              {vitalCol && vitalCol[6] === "True" ? (
                <Form.Item className="form-item-250">
                  {getFieldDecorator("kokyuf", {
                    rules: [{ required: false }],
                    initialValue: data.kokyuf,
                  })(
                    <RadioGroup
                      onChange={this.onChangeKokyuf}
                      className="kdf-f-extent"
                    >
                      <Radio value={"整"}>整</Radio>
                      <Radio value={"不整"}>不整</Radio>
                      <Radio value={"無し"}>無し</Radio>
                    </RadioGroup>
                  )}
                </Form.Item>
              ) : null}
            </Col>
            <Col span={3} style={{ paddingTop: 7 }}>
              <span>{respiration}</span>
            </Col>
          </Row>
        ) : null}
        {vitalCol && vitalCol[7] === "True" ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>SPO2</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <div>
                <Form.Item className="form-item-200">
                  {getFieldDecorator("spo2B", {
                    rules: [{ required: false }],
                    initialValue: data.spo2
                      ? data.spo2.toString().split("-")[0]
                      : null,
                  })(<InputNumber min={0} onChange={this.onChangeSpo2B} />)}
                  <Popover
                    visible={
                      visibleNoKeyboard && type === "spo2B" ? true : false
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.handleType("spo2B")}
                    >
                      入力
                    </Button>
                  </Popover>
                  <span style={{ paddingLeft: 4 }}>{zenkaiSpo2A}</span>
                </Form.Item>
                <Form.Item className="form-item-250">
                  <span style={{ paddingRight: 4 }}>〜</span>
                  {getFieldDecorator("spo2A", {
                    rules: [{ required: false }],
                    initialValue:
                      data.spo2 &&
                      data.spo2 !== "" &&
                      data.spo2.toString().split("-")[1]
                        ? data.spo2.split("-")[1]
                        : null,
                  })(<InputNumber min={0} onChange={this.onChangeSpo2A} />)}
                  <Popover
                    visible={
                      visibleNoKeyboard && type === "spo2A" ? true : false
                    }
                    placement="bottomRight"
                    content={numberKB}
                    trigger="click"
                    onVisibleChange={this.onVisibleChangePopover}
                  >
                    <Button
                      className="kdf-number-k"
                      type="primary"
                      onClick={() => this.handleType("spo2A")}
                    >
                      入力
                    </Button>
                  </Popover>
                  <span style={{ paddingLeft: 4 }}>{zenkaiSpo2B}</span>
                </Form.Item>
              </div>
            </Col>
          </Row>
        ) : null}
        {vitalEct &&
        ect1Type &&
        vitalEct.split(",")[0] &&
        vitalEct.split(",")[0].split("=")[1] ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>{vitalEct.split(",")[0].split("=")[1]}</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item>
                {ect1Type === 1 ? ( // number input
                  (<div>
                    {getFieldDecorator("etc1", {
                      rules: [{ required: false }],
                      initialValue: data.etc1,
                    })(<InputNumber onChange={this.onChangeSelectEtc1} />)}
                    <Popover
                      visible={
                        visibleNoKeyboard && type === "etc1" ? true : false
                      }
                      placement="bottomRight"
                      content={numberKB}
                      trigger="click"
                      onVisibleChange={this.onVisibleChangePopover}
                    >
                      <Button
                        className="kdf-number-k"
                        type="primary"
                        onClick={() => this.handleType("etc1")}
                      >
                        入力
                      </Button>
                    </Popover>
                  </div>)
                ) : ect1Type === 4 ? ( // select Case
                  (<CommentKB
                    yougoMasterEditState={this.getYougoMasterEctState}
                    yougoMasterEditCancel={this.getYougoMasterEctCancel}
                    yougoMasterEditSave={this.getYougoMasterEctSave.bind(
                      this,
                      "3"
                    )}
                    yougoMasterNa={yougoMasterEctSelect1}
                    rowNum={2}
                    pstion="topRight"
                    handleTextareaKB={this.onChangeEtc1}
                    defaultValue={data.etc1}
                  />)
                ) : (
                  // ect1Type = text case
                  (<InputSelectKB
                    value={data.etc1}
                    handleInputSelect={this.onChangeSelectEtc1}
                    data={convertArrayToString(yougoMasterEctSelect1, "naiyou")}
                  />)
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {vitalEct &&
        ect2Type &&
        vitalEct.split(",")[1] &&
        vitalEct.split(",")[1].split("=")[1] ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>{vitalEct.split(",")[1].split("=")[1]}</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item>
                {ect2Type === 1 ? ( // number input
                  (<div>
                    {getFieldDecorator("etc1", {
                      rules: [{ required: false }],
                      initialValue: data.etc2,
                    })(<InputNumber onChange={this.onChangeSelectEtc2} />)}
                    <Popover
                      visible={
                        visibleNoKeyboard && type === "etc2" ? true : false
                      }
                      placement="bottomRight"
                      content={numberKB}
                      trigger="click"
                      onVisibleChange={this.onVisibleChangePopover}
                    >
                      <Button
                        className="kdf-number-k"
                        type="primary"
                        onClick={() => this.handleType("etc2")}
                      >
                        入力
                      </Button>
                    </Popover>
                  </div>)
                ) : ect2Type === 4 ? ( // select Case
                  (<CommentKB
                    yougoMasterEditState={this.getYougoMasterEctState}
                    yougoMasterEditCancel={this.getYougoMasterEctCancel}
                    yougoMasterEditSave={this.getYougoMasterEctSave.bind(
                      this,
                      "2"
                    )}
                    yougoMasterNa={yougoMasterEctSelect2}
                    rowNum={2}
                    pstion="topRight"
                    handleTextareaKB={this.onChangeEtc2}
                    defaultValue={data.etc2}
                  />)
                ) : (
                  // ect1Type = text case
                  (<InputSelectKB
                    value={data.etc2}
                    handleInputSelect={this.onChangeSelectEtc2}
                    data={convertArrayToString(yougoMasterEctSelect2, "naiyou")}
                  />)
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {vitalEct &&
        ect3Type &&
        vitalEct.split(",")[2] &&
        vitalEct.split(",")[2].split("=")[1] ? (
          <Row>
            <Col className="kdf-label" span={4}>
              <span>{vitalEct.split(",")[2].split("=")[1]}</span>
            </Col>
            <Col className="kdf-f-item" span={20}>
              <Form.Item>
                {ect3Type === 1 ? ( // number input
                  (<div>
                    {getFieldDecorator("etc3", {
                      rules: [{ required: false }],
                      initialValue: data.etc3,
                    })(<InputNumber onChange={this.onChangeSelectEtc3} />)}
                    <Popover
                      visible={
                        visibleNoKeyboard && type === "etc3" ? true : false
                      }
                      placement="bottomRight"
                      content={numberKB}
                      trigger="click"
                      onVisibleChange={this.onVisibleChangePopover}
                    >
                      <Button
                        className="kdf-number-k"
                        type="primary"
                        onClick={() => this.handleType("etc3")}
                      >
                        入力
                      </Button>
                    </Popover>
                  </div>)
                ) : ect3Type === 4 ? ( // select Case
                  (<CommentKB
                    yougoMasterEditState={this.getYougoMasterEctState}
                    yougoMasterEditCancel={this.getYougoMasterEctCancel}
                    yougoMasterEditSave={this.getYougoMasterEctSave.bind(
                      this,
                      "3"
                    )}
                    yougoMasterNa={yougoMasterEctSelect3}
                    rowNum={2}
                    pstion="topRight"
                    handleTextareaKB={this.onChangeEtc3}
                    defaultValue={data.etc3}
                  />)
                ) : (
                  // ect1Type = text case
                  (<InputSelectKB
                    value={data.etc3}
                    handleInputSelect={this.onChangeSelectEtc3}
                    data={convertArrayToString(yougoMasterEctSelect3, "naiyou")}
                  />)
                )}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col className="kdf-label" span={4}>
            <span>コメント</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <CommentKB
                yougoMasterEditState={this.getYougoMasterEditState}
                yougoMasterEditCancel={this.getYougoMasterEditCancel}
                yougoMasterEditSave={this.getYougoMasterEditSave}
                yougoMasterNa={yougoMasterNa}
                rowNum={4}
                pstion="topRight"
                handleTextareaKB={this.onChangeCommentSelect}
                defaultValue={data.comment}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginBottom: 48 }}>
          <Col className="kdf-label" span={4}>
            <span>担当者</span>
          </Col>
          <Col className="kdf-f-item" span={20}>
            <Form.Item>
              <TantoMasterKB
                tantoMaster={tantoMaster}
                widthCode={100}
                widthText={200}
                value={data}
                handleTantoMasterKB={this.getHandleTantoMasterKB}
                styleCus="left"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>)
    );
  }
}

const VitalForm = Form.create()(NormalVitalForm);
export default VitalForm;

import _, { cloneDeep, isNumber } from "lodash";
import { KAIGO_CODE_SPECIAL, RENTAL_TYPE, calListDataShow, calTotalSmallTotal, calculateFutan, checkAndRefillBaseOnType21, checkKohiNo, createListExpandCode, fillListData, filterMonthlyExpandCode, generateUuid, handleOkTenkey, roundDecimal } from "../function";
import { handleCalculateServiceCodeMain } from "../calculate";
import { RequestUtils } from "../../../utils";
import { ApiPaths } from "../../../constants";
import { getHolidayJPInMonth } from "../../../common/function_common/functionCommon";


const CODE1 = ["5", "6", "8", "9", "B", "C"];
const CODE2 = ["6", "7"];
const CODE3 = ["処遇改善", "特定処遇改善", "ベースアップ等支援", "サービス提供体制"];


const handleOkAddService = (listDataRecord, isFromApi = false, data, listColorMaster) => {
  const result = {
    listDataService: [],
    expandCodeNotParent: [],
  };
  const isCheckAddRecord =
    data?.betuhyouDetailList && data?.betuhyouDetailList.length;

  let parentId = generateUuid();
  listDataRecord.forEach((e) => {
    const { newExpandCode, newExpandCodeChild, newAdditionList, listDataChildrenFilter, additionByList } = filterMonthlyExpandCode({
      formValues: e.formValues,
      expandCode: e.expandCode,
      props: null,
    });
    const mergedArray = [...newExpandCode, ...newExpandCodeChild];

    // Lọc các phần tử có serviceCode không trùng nhau
    const uniqueArray = mergedArray.filter((item, index, self) => index === self.findIndex((t) => t.serviceCode === item.serviceCode));
    const resultArrSoft = _.sortBy(uniqueArray, "serviceCode");
    e.formValues.additionList = newAdditionList;
    e.formValues.additionByList = additionByList;

    const fillData = fillListData({
      listDate: e.listDate,
      countDate: e.countDate,
      mainCode: e.mainCode,
      formValues: e.formValues,
      nengetu: data.nengetu,
      listValueDay: undefined,
      isGetDataWeekly: e.isGetDataWeekly,
    });

    const serviceCodeMain = handleCalculateServiceCodeMain({
      e,
      isPlan: true,
      fillData,
      props: { listColorMaster },
      isCheckAddRecord,
      listDataChildrenFilter,
      parentId,
      isFromApi,
    });

    // create expand code
    const { expandCodeNotParentAdd, expandCodeAdd } = createListExpandCode({
      expandCode: resultArrSoft,
      formValues: e.formValues,
      mainCode: e.mainCode,
      fillData,
      selectedRow: undefined,
      parentId,
      listDataChildrenFilter,
      isCheckAddRecord,
      isGetDataWeekly: e.isGetDataWeekly,
      isPlan: true,
      props: {},
      serviceCodeMain,
      isFromApi,
    });


    const serviceCodeMainArray = Array.isArray(serviceCodeMain) ? serviceCodeMain : [serviceCodeMain];
    const expandCodeAddArray = Array.isArray(expandCodeAdd) ? expandCodeAdd : [expandCodeAdd];
    const expandCodeNotParentAddArray = Array.isArray(expandCodeNotParentAdd) ? expandCodeNotParentAdd : [expandCodeNotParentAdd];

    const sortData = [...serviceCodeMainArray, ...expandCodeAddArray, ...expandCodeNotParentAddArray];


    const listDataService =
      !KAIGO_CODE_SPECIAL.includes(e.mainCode.shuruiCode)
        ? sortData
        : checkAndRefillBaseOnType21({
          sortData,
          serviceCodeMain,
          isPlan: true,
          isKeepFirst: e.isKeepFirst,
          isKeepLast: e.isKeepLast,
        });
    let expandCodeNotParent = [...expandCodeNotParentAdd];
    expandCodeNotParent = _.sortBy(expandCodeNotParent, ["dataRow.komokuCode"]);
    result.listDataService.push(...listDataService);
    result.expandCodeNotParent.push(...expandCodeNotParent);
    parentId++;
  });

  const listDataShow = calListDataShow({
    dataService: result.listDataService,
    changeSort: true,
    changeFilter: 0,
  });
  const newListDataService = (listDataShow || []).flatMap((e) => e);
  return {
    listDataService: newListDataService,
    listDataNotParent: result.expandCodeNotParent,
    listDataShow,
  };
};

function calculatorDays(listDate, countDate, countList) {
  const listDays = new Array(countDate).fill(0);
  const convertToListDays = listDays.map((value, index) => (listDate.includes(index + 1) ? 1 : value));
  const convertCountList = countList.map((item) => ({ date: parseInt(item.date.split("-")[2]), count: item.count }));

  for (let i = 0; i < convertCountList.length; i++) {
    const { date, count } = convertCountList[i];
    if (convertToListDays[date - 1] !== 0) {
      convertToListDays[date - 1] = count;
    }
  }

  const sumlistDays = convertToListDays.reduce((result, value) => (result += value), 0);
  return { selectedDays: convertToListDays, sumlistDays };
}

async function  calculateCase11(dataAttachedFull, record, smallTotal, dataAttached, kubunsikyu, listDataService, mode, isMutilOver, res, value, timeString) {
  let result = {
    totalUnitPrice: kubunsikyu,
    totalBenefitUnit: kubunsikyu,
    case19: 0,
    exceedingValue: smallTotal.case19,
    totalBurden: 0,
    totalExternalSalary: 0,
    totalCase19: smallTotal.totalCase19,
  };
  let conditionRecord = 0;
  if ((record.shuiruiCode === "15" && CODE2.includes(record.sisetukubun)) || (record.shuiruiCode === "16" && CODE1.includes(record.sisetukubun))) {
    const filterMainRecord = dataAttachedFull.filter((e) => e.officeName === record.officeName);
    const filterMainRecordClone = cloneDeep(filterMainRecord);
    const kubunsikyuOver = record.benefitUnitClone + smallTotal.case19;
    const { mainBenefitUnit, count } = calCount(record, kubunsikyuOver, isMutilOver, smallTotal, dataAttached, dataAttachedFull, mode, false, listDataService);
    filterMainRecordClone.forEach((e) => {
      if (e.isParent) {
        e.benefitUnit = mainBenefitUnit;
      }
      if (e.color === "BLUE" && !e.is30DayOver) {
        e.benefitUnit = _.sumBy(
          filterMainRecordClone.filter((e) => e.color === "WHITE"),
          "benefitUnit",
        )
        e.exceedingValue =
          e.benefitUnitClone > Math.abs(smallTotal.case19)
            ? e.benefitUnit - kubunsikyuOver
            : e.unitPrice;
        conditionRecord = e.exceedingValue;
      }
    })
  } else {
    conditionRecord = record.benefitUnit > Math.abs(smallTotal.case19) ? Math.abs(smallTotal.case19) : record.benefitUnit;
  }
  if (value.el.kubunGendoOver !== conditionRecord) {
    handleOkTenkey({ 
      dataAttached, 
      exceedingValue: value.el.kubunGendoOver, 
      record, 
      props: {
        riyouhyouForm:{
          hokenSelected: res.riyouKaigohoken
        }
      }, 
      mode, 
      dataAttachedFull,
      kubunsikyu: res.riyouKaigohoken.kubunsikyu,
      isMutilOver, 
      smallTotal, 
      isInput: false,
      calCount,
      listDataService,
      value,
      timeString
    });
    const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(dataAttached);
    result.totalBurden = totalBurden;
    result.totalExternalSalary = totalExternalSalary;
    result.totalAAAAA = totalAAAAA;
    result.totalUnitPrice = smallTotal?.totalUnitPrice - record.exceedingValue;
    result.totalBenefitUnit = smallTotal?.totalBenefitUnit - record.exceedingValue;
    result.case19 = smallTotal.case19 + record.exceedingValue;
    result.exceedingValue = smallTotal.case19;
    return { result };
  } else {
    if (smallTotal.case19 < 0) {
      //pattern3,4
      if (!isMutilOver) {
        if (
          (record.shuiruiCode === "15" && CODE2.includes(record.sisetukubun)) ||
          (record.shuiruiCode === "16" && CODE1.includes(record.sisetukubun))
        ) {
          const filterMainRecord = dataAttachedFull.filter((e) => e.officeName === record.officeName);
          let remainValueTotal = 0;
          const { mainBenefitUnit, count } = calCountOver(listDataService, record, kubunsikyu, mode, smallTotal, isMutilOver, dataAttached);
          filterMainRecord.forEach((e) => {
            //partern 3
            //serviceTeikyoKyoka === C136
            if (!e.serviceTeikyoKyoka || e.isParent) {
              e.benefitUnit = e.isParent
                ? mainBenefitUnit
                : e.color === "BLUE"
                  ? _.sumBy(
                    filterMainRecord.filter((e) => e.color === "WHITE"),
                    "benefitUnit",
                  )
                  : e.benefitUnit;

              switch (e.color) {
                case "BLUE":
                  e.exceedingValue = e.benefitUnit - kubunsikyu;
                  e.remainValue = e.unitPrice - e.exceedingValue;
                  remainValueTotal = e.remainValue;
                  e.benefitValue = Math.floor((Math.floor(e.remainValue * e.unitCost) * e.benefit) / 100);
                  e.burden = Math.floor(e.remainValue * e.unitCost) - e.benefitValue;
                  e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                  e.totalCost = fixedNumber((e.unitPrice - e.exceedingValue) * e.unitCost);
                  break;
                case "GREY":
                  if (CODE3.some((i) => e.serviceContent.includes(i))) {
                    const objOver = filterMainRecord
                      .filter((i) => e.target.includes(i.id) && i.color !== "BLUE" && i.color !== "WHITE")
                      .map((el) => el.unitPrice);
                    e.unitPrice = Math.round((remainValueTotal + _.sum(objOver)) * e.percent);
                    e.exceedingValue = e.prevUnit - e.unitPrice;
                    e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                    e.unit = e.unitPrice / e.sumListPlan;
                    e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                    e.benefitValue = Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                    e.burden = Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                  } else e.exceedingValue = 0;
                  break;

                default:
                  break;
              }
            } else {
              // patern4;
              // C136 !== null;
              if (CODE3.some((i) => e.serviceContent.includes(i))) {
                //const { count } = calCountOver(listDataService, record, kubunsikyu, mode, smallTotal, isMutilOver, dataAttached);
                e.exceedingValue = e.discountUnit ? count * e.discountUnit : count * e.unit;
                e.unitPrice = e.unitPrice - e.exceedingValue;
                e.remainValue = null;
                e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                e.burden = e.color === "GREY" && Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
              }
            }
          });
          const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(dataAttached);
          result.totalUnitPrice = record.remainValue;
          result.totalBurden = totalBurden;
          result.totalExternalSalary = totalExternalSalary;
          result.totalAAAAA = totalAAAAA;
        } else {
          //pattern1,2
          const filterDataAttached = dataAttached.filter((e) => e.officeName === record.officeName);
          let remainValueTotal = 0;
          const sortDataAttached = _.sortBy(filterDataAttached, (e) => {
            return [e.color === "BLUE" ? 0 : 1, e.priority];
          });

          //loop
          const { count } = calCountOver(listDataService, record, kubunsikyu, mode, smallTotal, isMutilOver, dataAttached);
          sortDataAttached.forEach((e) => {
            //pattern 1
            //C136 = serviceTeikyoKyoka dùng để tính count cho element GREY pattern 2
            if (!e.serviceTeikyoKyoka) {
              switch (e.color) {
                case "BLUE":
                  e.exceedingValue = e.benefitUnit - kubunsikyu;
                  e.remainValue = e.benefitUnit + smallTotal.case19;
                  remainValueTotal = e.remainValue;
                  e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                  e.benefitValue = Math.floor((Math.floor(e.remainValue * e.unitCost) * e.benefit) / 100);
                  e.burden = Math.floor(e.remainValue * e.unitCost) - e.benefitValue;
                  e.totalCost = fixedNumber((e.unitPrice - e.exceedingValue) * e.unitCost);
                  break;
                case "GREY":
                  if (CODE3.some((i) => e.serviceContent.includes(i))) {
                    const objOver = filterDataAttached
                      .filter((i) => e.target.includes(i.id) && i.color !== "BLUE" && i.color !== "WHITE")
                      .map((el) => el.unitPrice);
                    e.unitPrice = Math.round((remainValueTotal + _.sum(objOver)) * e.percent);
                    e.exceedingValue = e.prevUnit - e.unitPrice;
                    e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                    e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                    e.unit = e.unitPrice / e.sumListPlan;
                    e.benefitValue = Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                    e.burden = Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                  } else e.exceedingValue = 0;
                  break;
                default:
                  break;
              }
            } else {
              // pattern 2
              //C136 !== null
              const targetSkipItem = e.serviceFillDataList?.some((i) => i.code == "C003=03");
              if (CODE3.some((i) => e.serviceContent.includes(i)) && !targetSkipItem) {
                e.exceedingValue = e.discountUnit ? count * e.discountUnit : count * e.unit;
                e.remainValue = null;
                e.unitPrice = e.discountUnit ? e.discountUnit * e.sumListPlan - e.exceedingValue : e.unit * e.sumListPlan - e.exceedingValue;
                e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                e.burden = e.color === "GREY" && Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
              } else e.exceedingValue = 0;
            }

            if (e.color !== "BLUE") return;
          });
          const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(sortDataAttached);
          result.totalBurden = totalBurden;
          result.totalExternalSalary = totalExternalSalary;
          result.totalAAAAA = totalAAAAA;
        }
      } else {
        if (
          (record.shuiruiCode === "15" && CODE2.includes(record.sisetukubun)) ||
          (record.shuiruiCode === "16" && CODE1.includes(record.sisetukubun))
        ) {
          const filterMainRecord = dataAttachedFull.filter((e) => e.officeName === record.officeName);

          let remainValueTotal = 0;
          const kubunsikyuOver = record.benefitUnit + smallTotal.case19;
          const { mainBenefitUnit, count } = calCount(record, kubunsikyuOver, isMutilOver, smallTotal, dataAttached, dataAttachedFull, mode, false, listDataService);
          filterMainRecord.forEach((e) => {
            //partern 3
            //serviceTeikyoKyoka === C136
            if (!e.serviceTeikyoKyoka || e.isParent) {
              e.benefitUnit = e.isParent
                ? mainBenefitUnit
                : e.color === "BLUE"
                  ? _.sumBy(
                    filterMainRecord.filter((e) => e.color === "WHITE"),
                    "benefitUnit",
                  )
                  : e.color === "WHITE" && e?.target?.length ?
                    Math.round(mainBenefitUnit * e.percent)
                    : e.benefitUnit;
              switch (e.color) {
                case "BLUE":
                  e.exceedingValue =
                    e.benefitUnitClone > Math.abs(smallTotal.case19)
                      ? e.benefitUnit - kubunsikyuOver
                      : e.unitPrice;
                  e.remainValue =
                    e.unitPrice - e.exceedingValue;
                  remainValueTotal = e.remainValue;
                  e.benefitValue = Math.floor((Math.floor(e.remainValue * e.unitCost) * e.benefit) / 100);
                  e.burden = Math.floor(e.remainValue * e.unitCost) - e.benefitValue;
                  e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                  e.totalCost = fixedNumber((e.unitPrice - e.exceedingValue) * e.unitCost);
                  break;
                case "GREY":
                  if (CODE3.some((i) => e.serviceContent.includes(i))) {
                    const objOver = filterMainRecord
                      .filter((i) => e.target.includes(i.id) && i.color !== "BLUE" && i.color !== "WHITE")
                      .map((el) => el.unitPrice);
                    e.unitPrice = Math.round((remainValueTotal + _.sum(objOver)) * e.percent) > 0 ? Math.round((remainValueTotal + _.sum(objOver)) * e.percent) : 0;
                    e.exceedingValue = e.prevUnit - e.unitPrice;
                    e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                    e.unit = e.unitPrice / e.sumListPlan;
                    e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                    e.benefitValue = Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                    e.burden = Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                  } else e.exceedingValue = 0;
                  break;

                default:
                  break;
              }
            } else {
              // patern4;
              // C136 !== null;
              if (CODE3.some((i) => e.serviceContent.includes(i))) {
                e.exceedingValue = count * e.unit;
                e.unitPrice = e.unitPrice - e.exceedingValue;
                e.remainValue = null;
                e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                e.burden = e.color === "GREY" && Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
              }
            }
          });
          const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(dataAttached);
          result.totalUnitPrice = smallTotal?.totalUnitPrice - record.exceedingValue,
            result.case19 = record.benefitUnitClone > Math.abs(smallTotal.case19) ? 0 : smallTotal.case19 + record.benefitUnitClone,
            result.totalBenefitUnit = result.case19 === 0 ? kubunsikyu : smallTotal?.totalBenefitUnit - record.benefitUnitClone,
            result.totalBurden = totalBurden;
          result.totalExternalSalary = totalExternalSalary;
          result.totalAAAAA = totalAAAAA;
        } else {
          //pattern1,2
          const filterDataAttached = dataAttached.filter((e) => e.officeName === record.officeName);
          let remainValueTotal = 0;
          const sortDataAttached = _.sortBy(filterDataAttached, (e) => {
            return [e.color === "BLUE" ? 0 : 1, e.priority];
          });
          //loop
          sortDataAttached.forEach((e) => {
            //pattern 1
            //C136 = serviceTeikyoKyoka dùng để tính count cho element GREY pattern 2
            if (!e.serviceTeikyoKyoka) {
              switch (e.color) {
                case "BLUE":
                  if (e.benefitUnit && !e.is30DayOver) {
                    e.exceedingValue = e.benefitUnit > Math.abs(smallTotal.case19) ? Math.abs(smallTotal.case19) : e.benefitUnit;
                    e.remainValue = e.benefitUnit > Math.abs(smallTotal.case19) ? e.benefitUnit + smallTotal.case19 : 0;
                    remainValueTotal = e.remainValue;
                    e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                    e.benefitValue = Math.floor((Math.floor(e.remainValue * e.unitCost) * e.benefit) / 100);
                    e.burden = Math.floor(e.remainValue * e.unitCost) - e.benefitValue;
                    e.totalCost = fixedNumber((e.unitPrice - e.exceedingValue) * e.unitCost);
                  }
                  break;
                case "GREY":
                  if (CODE3.some((i) => e.serviceContent.includes(i))) {
                    const objOver = filterDataAttached.filter((i) => e.target.includes(i.id) && i.color !== "BLUE").map((el) => el.unitPrice);
                    e.unitPrice = Math.round((remainValueTotal + _.sum(objOver)) * e.percent) > 0 ? Math.round((remainValueTotal + _.sum(objOver)) * e.percent) : 0;
                    e.exceedingValue = (e?.exceedingValue || 0) + e.prevUnit - e.unitPrice;
                    e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                    e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                    e.unit = e.unitPrice / e.sumListPlan;
                    e.benefitValue = Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                    e.burden = Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                  } else e.exceedingValue = e.exceedingValuePrev && e.exceedingValuePrev > 0 ? e.exceedingValuePrev : 0;
                  break;
                default:
                  break;
              }
            } else {
              const kubunsikyuOver = record.benefitUnit + smallTotal.case19;

              switch (e.color) {
                case "BLUE":
                  e.exceedingValue = e.benefitUnit;
                  e.remainValue = e.benefitUnit - e.exceedingValue;
                  remainValueTotal = e.remainValue;
                  e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                  e.benefitValue = Math.floor((Math.floor(e.remainValue * e.unitCost) * e.benefit) / 100);
                  e.burden = Math.floor(e.remainValue * e.unitCost) - e.benefitValue;
                  e.totalCost = fixedNumber((e.unitPrice - e.exceedingValue) * e.unitCost);
                  break;
                case "GREY":
                  const targetSkipItem = e.serviceFillDataList?.some((i) => i.code == "C003=03");
                  if (CODE3.some((i) => e.serviceContent.includes(i)) && !targetSkipItem) {
                    const { count } = calCount(record, kubunsikyuOver, isMutilOver, smallTotal, dataAttached, dataAttachedFull, mode, false, listDataService);
                    e.exceedingValue = e.exceedingValuePrev && e.exceedingValuePrev > 0 ? (count * e.unit) + e.exceedingValuePrev : count * e.unit;
                    e.remainValue = null;
                    e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                    e.unitPrice = e.unit * e.sumListPlan - e.exceedingValue;
                    e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                    e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                    e.burden = e.color === "GREY" && Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                  } else {
                    if (!remainValueTotal) {
                      e.exceedingValue = e.unit;
                      e.remainValue = null;
                      e.externalSalary = roundDecimal(e.exceedingValue * e.unitCost);
                      e.unitPrice = 0;
                      e.totalCost = fixedNumber(e.unitPrice * e.unitCost);
                      e.benefitValue = e.color === "GREY" && Math.floor((Math.floor(e.unitPrice * e.unitCost) * e.benefit) / 100);
                      e.burden = e.color === "GREY" && Math.floor(Math.floor(e.unitPrice * e.unitCost) - e.benefitValue);
                    } else e.exceedingValue = e.exceedingValuePrev && e.exceedingValuePrev > 0 ? e.exceedingValuePrev : 0;
                  }
                  break;
                default:
                  break;
              }
            }

            if (e.color !== "BLUE") return;
          });
          const { totalBurden, totalExternalSalary, totalAAAAA } = calTotalTable(dataAttached);
          result.totalBurden = totalBurden;
          result.totalExternalSalary = totalExternalSalary;
          result.totalAAAAA = totalAAAAA;
          result.totalUnitPrice = smallTotal?.totalUnitPrice - record.exceedingValue;
          result.totalBenefitUnit = smallTotal?.totalBenefitUnit - record.exceedingValue;
          result.case19 = smallTotal.case19 + record.exceedingValue;
          result.exceedingValue = smallTotal.case19;
        }
       return { result };
      }
      return { result };
    }
    return { result };
  }
}
function fixedNumber(number) {
  if (+(number % 1).toFixed(12) === 0.999999999999 || +(number % 1).toFixed(12) === 0.999999999998) {
    return parseInt(Math.round(number));
  } else if (!number) {
    return number;
  } else {
    return parseInt(number);
  }
}

function generateId() {
  let id = "";
  const length = 7;
  for (let i = 0; i < length; i++) {
    id += Math.floor(Math.random() * 10);
  }
  return id;
}

const calculateDateAttachedTableTypeNormal = async (mode, listDataService, kubunsikyu, res, timeString) => {
  // check ở đây
  const greyBlockList = [];
  const whiteBlockList = [];

  //call api for 15.1 case
  const kyufuData = await RequestUtils.callApi({
    method: "GET",
    path: `${ApiPaths.UseSlip.GetKyufuAttachedTable}?riyousyaId=${res.riyousyaId}&nengetu=${res.nengetu}`,
  });
  //filter grey vs white block
  const idList = listDataService.map((e) => e.idService);
  const attachedTableCal = await RequestUtils.callApi({
    method: "POST",
    path: `${ApiPaths.UseSlip.GetCalculation}`,
    body: {
      idList: idList,
    }, 
  });

  attachedTableCal.sort((a, b) => {
    return parseInt(a.kaigoMasterId, 10) - parseInt(b.kaigoMasterId, 10);
  });

  const obj = attachedTableCal.reduce((acc, curr) => {
    acc[curr.kaigoMasterId] = curr;
    return acc;
  }, {});

  const sortNewListDataService = _.sortBy(listDataService, "idService");
  const converObjectList = Object.keys(obj);
  const result = converObjectList.filter((item) => !sortNewListDataService.some((x) => x.idService === Number(item)));
  const resultObject = sortNewListDataService.filter((item) => !converObjectList.includes(item.idService + ""));

  sortNewListDataService.forEach((e, i) => {
    if (resultObject.length > 0) {
      resultObject.forEach((element, index) => {
        if (result.length > 0 && resultObject.length > 0 && e.idService === element.idService) {
          let attachedTableCalculation =
            obj[result[index]]?.attachedTableCalculation.length > 1 ? obj[result[index]]?.attachedTableCalculation[1] : null;
          const subject = {
            subject1: attachedTableCalculation?.subject1 ?? {},
            subject2: attachedTableCalculation?.subject2 ?? {},
            priority: attachedTableCalculation?.priority ?? null,
          };

          if (
            obj[result[index]]?.attachedTableCalculation[0]?.additionName === "限度額外" ||
            obj[result[index]]?.attachedTableCalculation[0]?.additionName === "サービス提供体制"
          )
            greyBlockList.push({
              ...e,
              color: "GREY",
              ...subject,
            });
          else
            whiteBlockList.push({
              ...e,
              color: "WHITE",
              ...subject,
            });
        } else {
          let attached = obj[e.idService]?.attachedTableCalculation.length > 1 ? obj[e.idService]?.attachedTableCalculation[1] : null;
          const subject = {
            subject1: attached?.subject1 ?? {},
            subject2: attached?.subject2 ?? {},
            priority: attached?.priority ?? null,
          };

          if (
            obj[e.idService]?.attachedTableCalculation[0]?.additionName === "限度額外" ||
            obj[e.idService]?.attachedTableCalculation[0]?.additionName === "サービス提供体制"
          ){
            const greyBlockListId = greyBlockList.map(i => i.id);
            if(!greyBlockListId.includes(e.id)){
              greyBlockList.push({
                ...e,
                color: "GREY",
                ...subject,
              });
            }
          }
          else{
            const whiteBlockListId = whiteBlockList.map(i => i.id);
            if(!whiteBlockListId.includes(e.id)){
              whiteBlockList.push({
                ...e,
                color: "WHITE",
                ...subject,
              });
            }
          }
        }
      });
    } else {
      let attached = obj[e.idService]?.attachedTableCalculation.length > 1 ? obj[e.idService]?.attachedTableCalculation[1] : null;
      const subject = {
        subject1: attached?.subject1 ?? {},
        subject2: attached?.subject2 ?? {},
        priority: attached?.priority ?? null,
      };

      if (
        obj[e.idService]?.attachedTableCalculation[0]?.additionName === "限度額外" ||
        obj[e.idService]?.attachedTableCalculation[0]?.additionName === "サービス提供体制"
      )
        greyBlockList.push({
          ...e,
          color: "GREY",
          ...subject,
        });
      else
        whiteBlockList.push({
          ...e,
          color: "WHITE",
          ...subject,
        });
    }
  });

  const groupFilterArr = _.groupBy(whiteBlockList, (e) => {
    return [e.serviceCode, e.officeName, e.tani];
  });

  //8
  const whiteBlockListAfterSum = Object.keys(groupFilterArr).map((e) => groupFilterArr[e]); //group white 1

  whiteBlockListAfterSum.forEach((e) => {
    if (e.length > 1) {
      const sumWithInitial = e.reduce((accumulator, currentValue) => {
        return mode ? accumulator + currentValue.sumListFPlan : accumulator + currentValue.sumListFActual;
      }, 0);
      mode ? (e[0].sumListFPlan = sumWithInitial) : (e[0].sumListFActual = sumWithInitial);
      e.splice(1, e.length);
    }
  });

  const whiteBlockListSorted = _.sortBy(
    whiteBlockListAfterSum.flatMap((e) => e),
    (e) => {
      return [e.dataRow.shuiruiCode, e.jigyousyoId, e.isParent ? 0 : 1, e.serviceCode];
    },
  );

  const greyBlockListSorted = _.sortBy(greyBlockList, (e) => {
    return [e.dataRow.shuiruiCode, e.jigyousyoId, e.serviceCode];
  });
  let greyBlockListUnique = [];

  greyBlockListSorted.forEach((obj) => {
    let index = greyBlockListUnique.findIndex((item) => item.serviceCode === obj.serviceCode && item.officeName === obj.officeName);
    if (index !== -1) {
      greyBlockListUnique[index].sumListFPlan += obj.sumListFPlan;
      greyBlockListUnique[index].sumListFActual += obj.sumListFActual;
    } else {
      greyBlockListUnique.push({ ...obj, serviceCode: obj.serviceCode, sumListFPlan: obj.sumListFPlan, sumListFActual: obj.sumListFActual });
    }
  });

  //push sub-total
  const whiteBlockListGroup = _.groupBy(whiteBlockListSorted, (e) => {
    return e.officeName ? e.officeName : e.dataRow.ryakuServiceKikan;
  });
  const dataGroup = Object.keys(whiteBlockListGroup).map((e) => [...whiteBlockListGroup[e], { color: "BLUE" }]);
  dataGroup.push(greyBlockListUnique);

  let tableData = dataGroup.flatMap((e) => e);

  const subTotal = {
    unitPrice: 0,
    benefitUnit: 0,
  };

  const calUnit = (e) => {
    if (e.isParent) {
      if (+e.dataRow.shuiruiCode === 17) return e.dataRow.tani ? +e.dataRow.tani : e.mainCode.tani;
      return +e.mainCode.tani;
    } else {
      let result = e.tani;
      if (e.serviceFillDataList && e.serviceFillDataList[0]?.additionName === "計算コード") result = `${result}`;
      return result;
    }
  };

  const checkShuiruiCode = (shuiruiCode) => {
    let kyufu = 0;
    kyufuData.forEach((e) => {
      if (e.serviceSyurui.includes(shuiruiCode) && e.kyufu > kyufu) kyufu = e.kyufu;
    });
    return kyufu;
  };

  tableData = tableData.filter(item => !(item.color === 'GREY' && (item?.dataRow?.shuiruiCode === "17" || item?.dataRow?.shuiruiCode === "67")))

  const dataAttached = tableData
    .filter((e) => e.serviceCode || e.color)
    .map((e) => {
      if (e.color === "BLUE") {
        const result = { ...subTotal, color: "BLUE" };
        subTotal.unitPrice = 0;
        subTotal.benefitUnit = 0;
        return result;
      } else {
        const discount = [0, 100].includes(e.discount) ? null : e.discount;
        const sumListPlan = mode ? e.sumListFPlan : e.sumListFActual;
        const unit = calUnit(e);
        const discountUnit = discount && Math.round((unit * discount) / 100);
        const calculatorSumListPlan = (RENTAL_TYPE.includes(e.dataRow?.shuiruiCode) || RENTAL_TYPE.includes(e.dataRow?.shuruiCode)) && sumListPlan ? 1 : sumListPlan;
        const unitPrice = discount ? discountUnit * calculatorSumListPlan : unit * calculatorSumListPlan;
        const kyufukanriTani = e.isParent ? e.mainCode.kyufukanriTani : e.kyufukanriTani;
        const benefitUnit = kyufukanriTani ? kyufukanriTani * calculatorSumListPlan : unitPrice;
        const unitCost = (+e.price).toFixed(2);
        const result = {
          dataRow: e.dataRow,
          additionTime: e.additionTime ? e.additionTime : null,
          color: e.color,
          parentId: e.parentId + "",
          subject1: e?.subject1,
          subject2: e?.subject2,
          priority: e?.priority,
          idService: e.idService,
          officeName: e.officeName, //1
          officeNo: e.dataRow.jigyousyoNoServiceKikan, //2
          serviceContent: e.serviceName, //3
          serviceCode: e.serviceCode, //4
          unit, //5
          discountRate: discount, //6
          discountUnit, //7
          sumListPlan, //8
          unitPrice, //9
          kyufukanriTani: kyufukanriTani,
          kasanKanouCodeMap: e.kasanKanouCodeMap,
          isParent: e.isParent,
          taniSikibetu: e.taniSikibetu,
          santeiTani: e.santeiTani,
          sisetukubun: e.sisetukubun,
          terminal: e.terminal,
          hiwariSikibetu: e.hiwariSikibetu,
          serviceTeikyonaiyo: e.serviceTeikyonaiyo,
          serviceTeikyoKyoka: e.serviceTeikyoKyoka,
          kikanJiki: e.kikanJiki,
          sogei: e.sogei,
          ryoyoshoku: e.ryoyoshoku,
          serviceRiyoJoken: e.serviceRiyoJoken,
          nintishoSenmoncare: e.nintishoSenmoncare,
          sikyugendogakuKubun: e.sikyugendogakuKubun,
          shuiruiCode: e.dataRow.shuiruiCode,
          discount: e.discount,
          price: e.price,
          serviceKikanMasterId: e.serviceKikanMasterId,
          is30DayOver: e.is30DayOver,
          serviceFillDataList: e.serviceFillDataList,
          serviceId: e.serviceId,
          id: e.id,
          seikatusoudaninHaiti: e.seikatusoudaninHaiti,
          serviceRiyoJoken: e.serviceRiyoJoken,
          jikanEntyou: e.jikanEntyou,
          gaibuserviceShuruicode: e.gaibuserviceShuruicode,
          tuho24: e.tuho24,
          rihaTeikyo: e.rihaTeikyo,
          tokuteiShogukaizen: e.tokuteiShogukaizen,
          rihamane: e.rihamane,
          prevUnit: unit,
          mainCode: e.mainCode,
        };

        switch (e.color) {
          case "GREY":
            result.price = e.price;
            result.discount = e.discount;
            result.unitCost = !!unitCost ? unitCost : null;
            result.totalCost = !!unitCost
              ? unitPrice < 0
                ? Math.floor((unitPrice * -1 * unitCost).toFixed(2)) * -1
                : Math.floor((unitPrice * unitCost).toFixed(2))
              : null;
            result.benefit = !timeString?.naiyou?.includes("含む")
              ? res?.riyouKaigohoken?.kyufu
              : Math.max(checkShuiruiCode(e.dataRow.shuiruiCode), res?.riyouKaigohoken?.kyufu); //15
            result.benefitUnitGrey = benefitUnit;
            result.externalSalary = 0;
            result.benefitValue =
              +(Math.abs((unitPrice - (result.exceedingValue ?? 0)) * unitCost) % 1).toFixed(12) === 0.999999999999
                ? parseInt(((Math.round((unitPrice - (result.exceedingValue ?? 0)) * unitCost * 100) / 100) * res?.riyouKaigohoken?.kyufu) / 100)
                : parseInt((parseInt((unitPrice - (result.exceedingValue ?? 0)) * unitCost) * res?.riyouKaigohoken?.kyufu) / 100);
            result.burden =
              (+(Math.abs((unitPrice - (result.exceedingValue ?? 0)) * unitCost) % 1).toFixed(12) === 0.999999999999
                ? Math.round((unitPrice - (result.exceedingValue ?? 0)) * unitCost * 100) / 100
                : parseInt((unitPrice - (result.exceedingValue ?? 0)) * unitCost)) - result.benefitValue;
            result.sumListPlan = sumListPlan;
            result.listPlan = e.listPlan;
            result.is30DayOver = e.is30DayOver;
            result.dataRow = e.dataRow;
            result.kohiNo = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: res?.riyouKaigohoken?.kyufu}).kohiNo;
            result.kohiPercent = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: res?.riyouKaigohoken?.kyufu}).percentKohi;
            break;
          case "WHITE":
            result.price = e.price;
            result.discount = e.discount;
            result.benefitUnit = benefitUnit;
            result.offerTime = e.offerTime;
            result.listPlan = e.listPlan;
            result.sumListPlan = sumListPlan;
            result.is30DayOver = e.is30DayOver;
            result.dataRow = e.dataRow;
            break;
          default:
            break;
        }

        if (e.isParent) {
          subTotal.sisetukubun = e.sisetukubun;
          subTotal.idService = e.idService;
        }

        subTotal.officeName = e.officeName;
        subTotal.id = e.id;
        subTotal.price = e.price;
        subTotal.discount = e.discount;
        subTotal.shuiruiCode = e.dataRow.shuiruiCode;
        subTotal.serviceTeikyoKyoka = e.serviceTeikyoKyoka;
        subTotal.officeNo = e.dataRow.jigyousyoNoServiceKikan;
        subTotal.kyufukanriTani = e.mainCode.kyufukanriTani;
        subTotal.dataRow = e.dataRow;
        subTotal.serviceContent = `${e.dataRow.serviceContentType || ""}　小計`;
        subTotal.unitPrice += unitPrice; //9
        subTotal.remainValue = subTotal.unitPrice; //12
        subTotal.benefitUnit += benefitUnit; //10
        subTotal.unitCost = !!unitCost ? unitCost : null; //13
        subTotal.totalCost = !!unitCost ? roundDecimal((subTotal.unitPrice * subTotal.unitCost)) : null; //14 ///////////
        subTotal.benefit = !timeString?.naiyou?.includes("含む")
          ? res?.riyouKaigohoken?.kyufu
          : Math.max(checkShuiruiCode(e.dataRow.shuiruiCode), res?.riyouKaigohoken?.kyufu); //15
        subTotal.benefitValue = roundDecimal((roundDecimal((subTotal.remainValue * subTotal.unitCost)) * res?.riyouKaigohoken?.kyufu) / 100);
        subTotal.burden = Math.floor((subTotal.remainValue * subTotal.unitCost).toFixed(2)) - subTotal.benefitValue;
        subTotal.externalSalary = 0;
        subTotal.parentId = e.parentId;
        subTotal.is30DayOver = e.is30DayOver;
        subTotal.kohiNo = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: res?.riyouKaigohoken?.kyufu}).kohiNo;
        subTotal.kohiPercent = checkKohiNo({element: e, kyufuUser: kyufuData, kyufuHoken: res?.riyouKaigohoken?.kyufu}).percentKohi;
        return result;
      }
    });

  // lấy ra những thằng cần sửa
  const overLimitCodeList = dataAttached.filter((e) => typeof e.unit === "string");
  const sortOverLimitCodeList = _.sortBy(overLimitCodeList, "priority");
  sortOverLimitCodeList.forEach((e) => {
    let lastUnit = 0;
    let lastBenefitUnit = 0;

    const target = [];
    const percent = ["08", "09"].includes(e.taniSikibetu) ? +Number.parseFloat(+e.unit / 1000).toFixed(3) : +e.unit / 100;

    const filterOfficeName = tableData.filter((record) => record.officeName === e.officeName && record.priority < e.priority);

    filterOfficeName.forEach((element, index) => {
      let condition = false;
      const compareCode = element.kasanKanouCodeMap ? Object.values(element.kasanKanouCodeMap).includes(e.serviceCode) : false;
      const benefitUnitObject = dataAttached.filter(
        (data) => data.color !== "BLUE" && data.officeName === e.officeName && e.priority > data.priority,
      )[index];
      const benefitUnit = benefitUnitObject.unitPrice;
      const benefitUniCal = benefitUnitObject.benefitUnit;
      if (Object.keys(e.subject1).length) {
        Object.keys(e.subject1).forEach((key) => {
          if (e.subject1[key].includes(element[key]) && !condition) condition = true;
        });
        if (Object.keys(e.subject2).length) {
          const obj = {};
          Object.keys(e.subject2).forEach((key) => {
            obj[key] = `${element[key]}`;
          });
          condition = _.isEqual(e.subject2, obj) ? true : false;
          if (condition || compareCode) {
            lastUnit += benefitUnit;
            lastBenefitUnit += benefitUniCal;
            target.push(element.id);
          }
        } else {
          // chi co subject 1
          if (condition || compareCode) {
            lastUnit += benefitUnit;
            lastBenefitUnit += benefitUniCal;
            target.push(element.id);
          }
        }
      } else {
        const obj = {};
        Object.keys(e.subject2).forEach((key) => {
          obj[key] = `${element[key]}`;
        });
        condition = _.isEqual(e.subject2, obj) ? true : false;

        if (condition) {
          lastUnit += benefitUnit;
          lastBenefitUnit += benefitUniCal;
          target.push(element.id);
        }
      }
    });
    const unit = Math.round(lastUnit * percent);
    const benefitUnitCal = Math.round(lastBenefitUnit * percent);
    const discountUnit = e.discountRate && Math.round((unit * e.discountRate) / 100);
    const unitPrice = e.discountRate ? discountUnit * e.sumListPlan : unit * e.sumListPlan;
    const remainValue = unitPrice;
    const benefitUnit = e.color === "WHITE" && (e.kyufukanriTani ?? benefitUnitCal);
    const unitCost = e.color !== "WHITE" && (+e.price).toFixed(2);
    const subTotal = dataAttached.find((subTotal) => e.parentId === subTotal.parentId && subTotal.color === "BLUE");
    if (e.color === "WHITE") {
      subTotal.discountUnit = discountUnit && subTotal.discountUnit && (subTotal.discountUnit - e.discountUnit + discountUnit) > 0 ? subTotal.discountUnit - e.discountUnit + discountUnit : "";
      subTotal.unitPrice = subTotal.unitPrice - e.unitPrice + unitPrice;
      subTotal.benefitUnit = subTotal.benefitUnit - e.benefitUnit + benefitUnit;
      subTotal.remainValue = subTotal.remainValue - e.unit + remainValue;
      subTotal.totalCost = roundDecimal(subTotal.unitPrice * subTotal.unitCost);
      subTotal.benefitValue = roundDecimal((roundDecimal(subTotal.remainValue * subTotal.unitCost) * res?.riyouKaigohoken?.kyufu) / 100);
      subTotal.burden = roundDecimal(subTotal.remainValue * subTotal.unitCost) - subTotal.benefitValue;
    }
    e.unit = unit;
    e.prevUnit = unit;
    e.unitPrice = unitPrice;
    e.unitCost = !!unitCost ? unitCost : null;
    e.benefitUnit = benefitUnit;
    e.percent = percent;
    e.target = target;
    e.totalCost = e.color === "GREY" && !!unitCost ? parseInt(unitPrice * unitCost) : null;
    e.discountUnit = discountUnit && discountUnit;
    e.benefitValue = e.color === "GREY" && parseInt((parseInt((e.unitPrice - (e.exceedingValue ?? 0)) * e.unitCost) * res?.riyouKaigohoken?.kyufu) / 100);
    e.burden = e.color === "GREY" && parseInt((e.unitPrice - (e.exceedingValue ?? 0)) * e.unitCost) - e.benefitValue;
  });
  let smallTotal = {
    totalUnitPrice: 0,
    totalBenefitUnit: 0,
    totalBurden: 0,
  };
  dataAttached.forEach((e) =>{
    e.benefitUnitClone = e.benefitUnit;
    e.unitPriceClone = e.unitPrice;
    e.exceedingValuePrev = e.exceedingValue;
    e.prevUnit = e.unit;
    e.remainValuePrev = e.remainValue;
    e.benefitValuePrev = e.benefitValue;
    e.externalSalaryPrev = e.externalSalary;
  });
  const newDataAttached = await Promise.all(dataAttached.map(async (e) => {
    await calculateFutan({ element: e, kyufuHoken: res?.riyouKaigohoken?.kyufu, timeString });
    if (e.color === "BLUE") {
      smallTotal.totalUnitPrice += e.unitPrice;
      smallTotal.totalBenefitUnit += e.benefitUnit;
      e.case19 = kubunsikyu - e.benefitUnit;
      e.benefitUnitClone = e.benefitUnit;
    }
    e.benefitUnitClone = e.benefitUnit;
    e.unitPriceClone = e.unitPrice;
    e.exceedingValuePrev = e.exceedingValue;
    e.prevUnit = e.unit;
    e.remainValuePrev = e.remainValue;
    e.benefitValuePrev = e.benefitValue;
    e.externalSalaryPrev = e.externalSalary;
    return e;
  }));
  newDataAttached.forEach((e)=>{
    smallTotal.totalBurden += (e.color === "BLUE" || e.color === "GREY") && e.burden;
  })
  smallTotal.totalExternalSalary = 0;
  smallTotal.totalAAAAA = smallTotal.totalBurden + smallTotal.totalExternalSalary ?? null;
  smallTotal.case19 = kubunsikyu - smallTotal.totalBenefitUnit;
  smallTotal.totalCase19 = kubunsikyu - smallTotal.totalBenefitUnit;

  const dataAttachedFilter = newDataAttached.filter((e) => e.unitPrice !== 0);
  return { dataAttached: dataAttachedFilter, smallTotal };
};

function changeDataTime(nengetu, callback) {
  const dateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const firstDateOfMonth = nengetu
    ? new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1)
    : new Date(new Date().getFullYear(), new Date().getMonth(), 1);

  const days = [];
  const sundays = [];
  const saturdays = [];
  while (firstDateOfMonth.getMonth() == dateOfMonth.getMonth()) {
    days.push({ date: firstDateOfMonth.getDate(), dayOfWeek: firstDateOfMonth.getDay() });
    if (firstDateOfMonth.getDay() == 0) {
      sundays.push(firstDateOfMonth.getDate());
    }
    else if (firstDateOfMonth.getDay() == 6) {
      saturdays.push(firstDateOfMonth.getDate());
    }
    firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
  }
  callback(saturdays, sundays);
  return days;
}

function checkColor(nengetu, callback) {
  const listHoliday = nengetu
    ? getHolidayJPInMonth(parseInt(nengetu.substring(4)), nengetu.substring(0, 4))
    : getHolidayJPInMonth(new Date().getMonth() + 1, new Date().getFullYear());
  callback(listHoliday);
}
function calCountOver(listDataService, record, kubunsikyu, mode, smallTotal, isMutilOver, dataAttached) {
  let count = 0;
  let mainBenefitUnit = 0;
  const whiteList = listDataService.filter((e) => e.officeName === record.officeName && e.sikyugendogakuKubun && !e.is30DayOver);
  if (!isMutilOver) {
    if (mode) {
      const { totalCount, indexCount } = calculateCount({
        isPlan: true,
        record,
        kubunsikyu,
        dataAttached,
        listDataService
      });
      const listDayClone = cloneDeep(whiteList[0].listPlan);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      mainBenefitUnit = benefit;
      count = totalCount;
    } else {
      const { totalCount, indexCount } = calculateCount({
        isPlan: false,
        record,
        kubunsikyu,
        dataAttached,
        listDataService
      });
      const listDayClone = cloneDeep(whiteList[0].listActual);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      mainBenefitUnit = benefit;
      count = totalCount;
    }
  } else {
    if (mode) {
      const { totalCount, indexCount } = calculateCount({
        isPlan: true,
        record,
        kubunsikyu,
        dataAttached,
        listDataService
      });
      count = totalCount;
      const listDayClone = cloneDeep(whiteList[0].listPlan);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      mainBenefitUnit = benefit;
    } else {
      const { totalCount, indexCount } = calculateCount({
        isPlan: false,
        record,
        kubunsikyu,
        dataAttached,
        listDataService
      });
      const listDayClone = cloneDeep(whiteList[0].listActual);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      mainBenefitUnit = benefit;
      count = totalCount;
    }
  }
  const findTekioy = listDataService.find((e) => e.serviceName.includes("サービス提供体制") && record.officeName === e.officeName && !e.is30DayOver);
  let unCount = 0;
  let countTekio = 0
  if (mode) {
    findTekioy?.listPlan?.forEach((e) => e === 0 ? unCount++ : countTekio++);
  } else {
    findTekioy?.listActual?.forEach((e) => e === 0 ? unCount++ : countTekio++);
  }
  if (countTekio > 0 && count > countTekio) {
    count = countTekio
  }
  return { count, mainBenefitUnit };
}

const calculateMainBenefit = ({ firstIndex, secondIndex, listDay, whiteList }) => {
  const firstSum = listDay.slice(0, firstIndex + 1).reduce((acc, curr) => acc + curr, 0) * whiteList.mainCode.kyufukanriTani;
  const secondSum = listDay.slice(firstIndex + 1, secondIndex + 1).reduce((acc, curr) => acc + curr, 0) * whiteList.mainCode.tani;
  return firstSum + secondSum;
}

const calculateCount = ({ isPlan, record, kubunsikyu, dataAttached, listDataService, isTenkey }) => {
  const whiteList = listDataService.filter((e) => e.officeName === record.officeName && e.sikyugendogakuKubun && !e.is30DayOver);
  let count = kubunsikyu <= 0 ? 0 : -1;
  let mainBenefitUnit = 0;
  let result = [];
  const parentDayByTani = whiteList.map((item) => {
    const { listPlan } = item;
    const { listActual } = item;
    let kyufukanriTani = 0;
    const itemFind = dataAttached?.find((el) => el.serviceContent === item.serviceName);
    if (item.isParent) {
      if (item.mainCode.kyufukanriTani) kyufukanriTani = isTenkey ? item.mainCode.tani : item.mainCode.kyufukanriTani;
      else if (item.discount) kyufukanriTani = Math.round((item.mainCode.tani * item.discount) / 100);
      else kyufukanriTani = item.mainCode.tani;
    } else {
      if (item.discount) kyufukanriTani = Math.round(((isNumber(item.tani) ? item.tani : itemFind?.unit) * item.discount) / 100);
      else kyufukanriTani = isNumber(item.tani) ? item.tani : (itemFind?.unit ?? 0);
      kyufukanriTani = isNumber(kyufukanriTani) ? kyufukanriTani : (itemFind?.unit ?? 0);
    }
    const result = (isPlan ? listPlan : listActual).map((plan, indx) => {
      if (kyufukanriTani !== 0) {
        return plan * kyufukanriTani;
      } else {
        return 0;
      }
    });
    return result;
  });
  for (let col = 0; col < parentDayByTani[0]?.length; col++) {
    let sum = 0;
    let count = 0;

    for (let row = 0; row < parentDayByTani?.length; row++) {
      if (parentDayByTani[row][col] !== 0) {
        sum += parentDayByTani[row][col];
        count++;
      }
    }

    if (count !== 0) {
      if (result.length === 0) {
        result.push(sum);
      } else {
        result.push(result[result.length - 1] + sum);
      }
    }
  }
  let indexCount = 0;
  result.forEach((e, i) => {
    if (e > kubunsikyu) {
      count++;
    }
    if (count === 0) {
      indexCount = i;
    }
  });
  return { totalCount: count, indexCount };
};

const calTotalTable = (currentData) => {
  let totalBurden = 0;
  let totalExternalSalary = 0;
  currentData.forEach((e) => {
    totalBurden += e.color !== "WHITE" && e.burden;
    totalExternalSalary += e.color !== "WHITE" && e.externalSalary;
  });
  const totalAAAAA = totalBurden + totalExternalSalary;

  return { totalBurden, totalExternalSalary, totalAAAAA };
};

function calCount(i, kubunsikyu, isMutilOver, smallTotal, dataAttached, dataAttachedFull, mode, isTenkey, listDataService) {
  let count = 0;
  let mainBenefitUnit = 0;
  const whiteList = listDataService.filter((e) => e.officeName === i.officeName && e.sikyugendogakuKubun && !e.is30DayOver);

  if (!isMutilOver) {
    if (mode) {
      const { totalCount, indexCount } = calculateCount({
        isPlan: true, record: i, kubunsikyu,
        dataAttached: dataAttachedFull
        , listDataService, isTenkey
      });
      const listDayClone = cloneDeep(whiteList[0].listPlan);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      mainBenefitUnit = benefit;
      count = totalCount;
    } else {
      const { totalCount, indexCount } = calculateCount({
        isPlan: false,
        record: i,
        kubunsikyu,
        dataAttached: dataAttachedFull,
        listDataService,
        isTenkey
      });
      const listDayClone = cloneDeep(whiteList[0].listActual);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      mainBenefitUnit = benefit;
      count = totalCount;
    }
  } else {
    let countPlan = 0;
    let countActual = 0;
    whiteList[0].listPlan.forEach((e) => e && countPlan++);
    whiteList[0].listActual.forEach((e) => e && countActual++);
    const dataAttachedWhite = dataAttachedFull.find((el) => el.serviceContent === whiteList[0].serviceName);
    if (mode) {
      const { totalCount, indexCount } = calculateCount({
        isPlan: true,
        record: i,
        kubunsikyu,
        dataAttached: dataAttachedFull,
        listDataService,
        isTenkey
      });
      const listDayClone = cloneDeep(whiteList[0].listPlan);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      count = totalCount
      if (count === countPlan) {
        mainBenefitUnit = dataAttachedWhite.unitPrice;
      } else {
        mainBenefitUnit = benefit;
      }
    } else {
      const { totalCount, indexCount } = calculateCount({
        isPlan: false,
        record: i,
        kubunsikyu,
        dataAttached: dataAttachedFull,
        listDataService,
        isTenkey
      });
      const listDayClone = cloneDeep(whiteList[0].listActual);
      const newListDay = listDayClone.sort((a, b) => {
        if (a === 0 && b !== 0) {
          return 1;
        } else if (a !== 0 && b === 0) {
          return -1;
        } else {
          return 0;
        }
      });
      count = totalCount;
      const benefit = calculateMainBenefit({ firstIndex: indexCount, secondIndex: totalCount + indexCount, listDay: newListDay, whiteList: whiteList[0] });
      if (count === countActual) {
        mainBenefitUnit = dataAttachedWhite.unitPrice;
      } else {
        mainBenefitUnit = benefit;
      }
    }
  }
  const findTekioy = listDataService.find((e) => e.serviceName.includes("サービス提供体制") && i.officeName === e.officeName);
  let unCount = 0;
  let countTekioPlan = 0
  let countTekioActual = 0
  if (mode) {
    findTekioy?.listPlan?.forEach((e) => e === 0 ? unCount++ : countTekioPlan++);
  } else {
    findTekioy?.listActual?.forEach((e) => e === 0 ? unCount++ : countTekioActual++);
  }
  if (mode) {
    if (countTekioPlan > 0 && count > countTekioPlan) {
      count = countTekioPlan
    }
  } else {
    if (countTekioActual > 0 && count > countTekioActual) {
      count = countTekioActual
    }
  }
  return { count, mainBenefitUnit };
}
export {
  generateId,
  checkColor,
  changeDataTime,
  calculatorDays,
  handleOkAddService,
  calculateDateAttachedTableTypeNormal,
  calculateCase11,
  calCount
};
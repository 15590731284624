import { Button, Modal, Row, Spin } from "antd";
import React from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { loadAllDocumentList } from "../../actions/documentList/actionCreators";
import { loadKyotakuPlan1 } from "../../actions/kyotakuPlan1Actions/actionCreators";
import { loadKyotakuPlan2 } from "../../actions/kyotakuPlan2Actions/actionCreators";
import { loadSisetuDailyPlan } from "../../actions/sisetuDailyPlanActions/actionCreators";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";
import MenuBusinessV2 from "../../common/component/UI/MenuBusinessV2";
import "./DocumentList.css";
import DocumentListRecordTable from "./UI/DocumentListRecordTable";
import LastQuoteModal from "./UI/LastQuoteModal";
import Toolbar1 from "./UI/Toolbar1";

const dataKey = "showreportlist";
const defaultSort = {
  key1: "0",
  value1: "asc",
  key2: "0",
  value2: "asc",
  key3: "0",
  value3: "asc",
  key4: "0",
  value4: "asc",
};

export function convertSortDataToParams(sortData) {
  const sortParams = {};
  if (sortData.key1 !== "0") sortParams[sortData.key1] = sortData.value1;
  if (sortData.key2 !== "0") sortParams[sortData.key2] = sortData.value2;
  if (sortData.key3 !== "0") sortParams[sortData.key3] = sortData.value3;
  if (sortData.key4 !== "0") sortParams[sortData.key4] = sortData.value4;
  return sortParams;
}

class DocumentListRecord extends React.Component {
  constructor(props) {
    let showReportList = JSON.parse(localStorage.getItem(dataKey));
    if (!showReportList) {
      showReportList = {
        inUsed: false,
        sort: defaultSort,
        searchName: "",
      };
      localStorage.setItem(dataKey, JSON.stringify(showReportList));
    }

    super(props);
    this.state = {
      inUsed: showReportList.inUsed,
      searchName: showReportList.searchName,
      sort: showReportList.sort,
      page: 1,
      riyousyaId: null,
      lastQuoteVisible: false,
      addOrCopyVisible: false,
    };
  }

  loadData() {
    const used = this.state.inUsed ? 1 : null;
    this.props.loadAllDocumentList({
      inUsed: used,
      searchName: this.state.searchName,
      sort: convertSortDataToParams(this.state.sort),
      page: this.state.page,
    });
  }
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.needReload && !prevProps.needReload) {
      this.loadData();
    }
  }

  async handleChangeTab(tab) {
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    showReportList.tabActive = tab;
    localStorage.setItem(dataKey, JSON.stringify(showReportList));
    await this.setState({
      tabActive: tab,
    });
    this.loadData();
  }

  async handleChangeInUsed(value) {
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    showReportList.inUsed = value;
    localStorage.setItem(dataKey, JSON.stringify(showReportList));
    await this.setState({
      inUsed: value,
    });
    this.loadData();
  }

  async handleChangeSearchName(value) {
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    showReportList.searchName = value;
    localStorage.setItem(dataKey, JSON.stringify(showReportList));
    await this.setState({
      searchName: value,
    });
    this.loadData();
  }

  async handleChangeSort(value) {
    const showReportList = JSON.parse(localStorage.getItem(dataKey));
    showReportList.sort = value;
    await this.setState({
      sort: value,
    });
    // switch (this.state.tabActive) {
    //   case "1":
    //     showReportList.sort1 = value;
    //     await this.setState({
    //       sort1: value
    //     });
    //     break;
    //   case "2":
    //     showReportList.sort2 = value;
    //     await this.setState({
    //       sort2: value
    //     });
    //     break;
    //   case "3":
    //     showReportList.sort3 = value;
    //     await this.setState({
    //       sort3: value
    //     });
    //     break;
    //   default: break;
    // }
    this.loadData();
    localStorage.setItem(dataKey, JSON.stringify(showReportList));
  }
  handlePageChange(page) {
    this.setState({
      page,
    });
    this.props.loadAllDocumentList({
      inUsed: this.state.inUsed,
      searchName: this.state.searchName,
      sort: convertSortDataToParams(this.state.sort),
      page,
    });
  }
  handlePageChangePlan1(page) {
    this.setState({
      page1: page,
    });
    this.props.loadKyotakuPlan1(
      {
        inUsed: this.state.inUsed,
        searchName: this.state.searchName,
        sort: convertSortDataToParams(this.state.sort1),
        page,
      },
      () => { }
    );
  }

  handlePageChangePlan2(page) {
    this.setState({
      page2: page,
    });
    this.props.loadKyotakuPlan2(
      {
        inUsed: this.state.inUsed,
        searchName: this.state.searchName,
        sort: convertSortDataToParams(this.state.sort2),
        page,
      },
      () => { }
    );
  }

  handlePageChangeDailyPlan(page) {
    this.setState({
      page3: page,
    });
    this.props.loadSisetuDailyPlan(
      {
        inUsed: this.state.inUsed,
        searchName: this.state.searchName,
        sort: convertSortDataToParams(this.state.sort3),
        page,
      },
      () => { }
    );
  }

  redirectById() {
    if (this.state.tabActive === "1") {
      this.props.history.push(
        "/input-kyotaku-plan1/" + this.state.riyousyaId + "/new"
      );
    }
    else if (this.state.tabActive === "2") {
      this.props.history.push(
        "/input-kyotaku-plan2/" + this.state.riyousyaId + "/new"
      );
    }
  }

  async showModalAddOrCopy(riyid, id) {
    if (id)
      this.setState({
        addOrCopyVisible: true,
        riyousyaId: riyid,
      });
    else {
      await this.setState({
        riyousyaId: riyid,
      });
      this.redirectById();
    }
  }

  hideModalAddOrCopy() {
    this.setState({
      addOrCopyVisible: false,
    });
  }

  showModalLastQuote() {
    this.setState({
      addOrCopyVisible: false,
      lastQuoteVisible: true,
    });
  }

  hideModalLastQuote() {
    this.setState({
      lastQuoteVisible: false,
    });
  }

  handleSelectLastQuote(data) {
    if (data) {
      if (this.state.tabActive === "1") {
        this.props.history.push(
          "/input-kyotaku-plan1/" +
          this.state.riyousyaId +
          "/new?copy-from=" +
          data.id
        );
      }
      else {
        this.props.history.push(
          "/input-kyotaku-plan2/" +
          this.state.riyousyaId +
          "/new?copy-from=" +
          data.id
        );
      }
    }
    this.setState({
      lastQuoteVisible: false,
    });
  }

  render() {
    // const w = window.innerWidth;
    const { dataDocs, metaDocs } =
      this.props;
    const pageDocs = {
      onChange: (page) => {
        this.handlePageChange(page);
      },
      total: this.props.metaDocs.total,
      current: this.props.metaDocs.page,
      pageSize: this.props.metaDocs.pageSize,
    };
    // const pagePlan1 = {
    //   onChange: (page) => {
    //     this.handlePageChangePlan1(page);
    //   },
    //   total: this.props.metaPlan1.total,
    //   current: this.props.metaPlan1.page,
    //   pageSize: this.props.metaPlan1.pageSize,
    // };
    // const pagePlan2 = {
    //   onChange: (page) => {
    //     this.handlePageChangePlan2(page);
    //   },
    //   total: this.props.metaPlan2.total,
    //   current: this.props.metaPlan2.page,
    //   pageSize: this.props.metaPlan2.pageSize,
    // };
    // const pageDailyPlan = {
    //   onChange: (page) => this.handlePageChangeDailyPlan(page),
    //   total: this.props.metaPlan3.total,
    //   current: this.props.metaPlan3.page,
    //   pageSize: this.props.metaPlan3.pageSize,
    // };
    return (
      (<CommonPageContainer
        id={"document-list-page"}
        sessionTimeout={this.props.sessionTimeout}
      >
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 768px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"書類管理一覧"}
                        iconType={"file-text"}
                        count={true}
                        countNum={metaDocs?.total ? metaDocs.total : 0}
                      />
                    }
                    TopRight={<MenuBusinessV2 isDocument={true} />}
                  />
                );
              }
              {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={"書類管理一覧"}
                        iconType={"file-text"}
                        count={true}
                        countNum={metaDocs?.total ? metaDocs.total : 0}
                      />
                    }
                  />
                );
              }
            }}
          </MediaQuery>
        </Row>
        <div className={"document-table"}>
          <div style={{ padding: "10px 0", marginBottom: 8 }}>
            <Toolbar1
              inUsed={this.state.inUsed}
              searchName={this.state.searchName}
              sort={this.state.sort}
              onChangeInUsed={(checked) => this.handleChangeInUsed(checked)}
              onChangeSearchName={(searchName) =>
                this.handleChangeSearchName(searchName)
              }
              onChangeSort={(sort) => this.handleChangeSort(sort)}
              loading={this.props.loading}
            />
          </div>
          <div>
            <Spin spinning={this.props.loading}>
              <DocumentListRecordTable
                tabActive={this.state.tabActive}
                isUserMode={false}
                tab="plan1"
                data={dataDocs}
                history={this.props.history}
                pagination={pageDocs}
                showModalLastQuote={(riyousyaId) =>
                  this.showModalLastQuote(riyousyaId)
                }
                showModalAddOrCopy={(riyid, id) =>
                  this.showModalAddOrCopy(riyid, id)
                }
              />
            </Spin>
          </div>

          <Modal
            className={"modal-add-coppy"}
            open={this.state.addOrCopyVisible}
            title={"追加選択ダイアログ"}
            onCancel={() => this.hideModalAddOrCopy()}
            footer={[
              <Button
                key="add"
                type={"primary"}
                onClick={() => this.redirectById()}
              >
                追加
              </Button>,
              <Button
                key="copy"
                type={"primary"}
                onClick={() => this.showModalLastQuote()}
              >
                複製追加
              </Button>,
              <Button key="canceladd" onClick={() => this.hideModalAddOrCopy()}>
                キャンセル
              </Button>,
            ]}
          />
          <LastQuoteModal
            visible={this.state.lastQuoteVisible}
            title={"複製追加ダイアログ"}
            riyousyaId={this.state.riyousyaId}
            tab={this.state.tabActive}
            handleSelectLastQuote={(data) => this.handleSelectLastQuote(data)}
            hideModalLastQuote={() => this.hideModalLastQuote()}
          />
        </div>
        <MediaQuery query="(min-device-width: 768px)">
          {(matches) => {
            if (matches) {
              return <div></div>;
            }
            {
              return (
                <Row
                  style={{ height: "55px" }}
                  className={"submenu menu-document-footer"}
                >
                  <SubHeaderV2
                    FooterMobile={<MenuBusinessV2 isDocument={true} />}
                  />
                </Row>
              );
            }
          }}
        </MediaQuery>
      </CommonPageContainer>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.documentList.loading,
    dataDocs: state.documentList.dataDocs,
    metaDocs: state.documentList.metaDocs,
    dataPlan1: state.documentList.dataPlan1,
    dataPlan2: state.documentList.dataPlan2,
    dataDailyPlan: state.documentList.dataDailyPlan,
    metaPlan1: state.documentList.metaPlan1,
    metaPlan2: state.documentList.metaPlan2,
    metaPlan3: state.documentList.metaDailyPlan,
    needReload: state.documentList.needReload,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadKyotakuPlan1: (data, callback) =>
      dispatch(loadKyotakuPlan1(data, callback)),
    loadKyotakuPlan2: (data, callback) =>
      dispatch(loadKyotakuPlan2(data, callback)),
    loadSisetuDailyPlan: (data, callback) =>
      dispatch(loadSisetuDailyPlan(data, callback)),
    loadAllDocumentList: (data) => dispatch(loadAllDocumentList(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DocumentListRecord);

import { PlusSquareOutlined, UndoOutlined } from "@ant-design/icons";
import { Drawer, Row } from "antd";
import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { CommonPageContainer } from "../../common/component/IdleTimerPage";
import SubHeaderTitleV2 from "../../common/component/SubHeaderTitleV2";
import SubHeaderV2 from "../../common/component/SubHeaderV2";

import PopupUploadFile from "../../common/form-kiroku/PopupUploadFile";
import {
  extractKubunSetting,
  getPath,
  getValueIskeep,
  setValueIsKeep,
} from "../../common/function_common/functionCommon";

import "./RiyousyaShiryou.css";
import ShiryouRiyousya from "./UI/shiryouRiyousya";

class RiyousyaShiryou extends Component {
  constructor(props) {
    super(props);

    //
    const { match, location } = this.props;
    const query = new URLSearchParams(location.search);

    let processType = getValueIskeep("fileRiyouDisType");
    // Update to localstorage if not save yet
    if (!processType) {
      processType = "riyousyaSiryou";
      setValueIsKeep("fileRiyouDisType", "riyousyaSiryou");
    }

    let selectKubunChild = getValueIskeep("fileRiyouDisChild");
    if (!selectKubunChild) {
      selectKubunChild = "noinput";
    }

    this.state = {
      url: match.url,
      backLink: query.get("backLink"),
      riyousyaId: match.params.riyousyaId,
      flagSeting: false, // check if setting have not been loading before, load setting oneTime,
      pageNo: 1,
      processType, // riyousyaSiryou vs kirokuTenpu,
      selectKubunRiyou: selectKubunChild,
      selectKubunKiroku: selectKubunChild,
      showPopupUploadFile: false,

      record: null,
    };

    this.loadFilePreview = this.loadFilePreview.bind(this);
    this.openPopupUploadFile = this.openPopupUploadFile.bind(this);
  }

  /**
   *
   */
  componentDidMount() {
    const {
      pageNo,
      riyousyaId,
      processType,
      selectKubunRiyou,
      selectKubunKiroku,
    } = this.state;

    // Load riyousya detail
    this.props.getRiyousya(riyousyaId);

    this.props.loadSetting((data) => {
      // LoadSetting
      let kubun = "";
      let setting = "";
      if (processType === "riyousyaSiryou") {
        if (selectKubunRiyou) {
          kubun = selectKubunRiyou;
        }
        else {
          setting = data && data.gazouRiyouKubun ? data.gazouRiyouKubun : "";
          kubun = extractKubunSetting(setting, 0, 1);

          this.setState({
            selectKubunRiyou: kubun,
            flagSeting: true,
          });

          // saveToLocalStorage
          setValueIsKeep("fileRiyouDisChild", kubun);
        }
      }

      if (processType === "kirokuTenpu") {
        if (selectKubunKiroku) {
          kubun = selectKubunKiroku;
        }
        else {
          setting = data && data.gazouKubun ? data.gazouKubun : "";
          kubun = extractKubunSetting(setting, 0, 1);

          this.setState({
            selectKubunKiroku: kubun,
            flagSeting: true,
          });

          // saveToLocalStorage
          setValueIsKeep("fileRiyouDisChild", kubun);
        }
      }

      this.props.loadData(pageNo, riyousyaId, processType, kubun);
    });
  }

  /**
   *
   */
  componentDidUpdate(preProps) {
    const { setting } = this.props;
    const {
      flagSeting,
      pageNo,
      riyousyaId,
      processType,
      selectKubunRiyou,
      selectKubunKiroku,
    } = this.state;

    if (setting !== preProps.setting && !flagSeting) {
      /**
       * load data
       */
      let kubun = "";
      if (processType === "riyousyaSiryou") {
        if (selectKubunRiyou) {
          kubun = selectKubunRiyou;
        }
        else {
          const gazouRiyouKubun =
            setting && setting.gazouRiyouKubun ? setting.gazouRiyouKubun : "";
          kubun = extractKubunSetting(gazouRiyouKubun, 0, 1);
        }
      }

      if (processType === "kirokuTenpu") {
        if (selectKubunKiroku) {
          kubun = selectKubunKiroku;
        }
        else {
          const gazouKubun =
            setting && setting.gazouKubun ? setting.gazouKubun : "";
          kubun = extractKubunSetting(gazouKubun, 0, 1);
        }
      }

      this.props.loadData(pageNo, riyousyaId, processType, kubun);

      this.setState({
        flagSeting: true,
        // selectKubunRiyou: tmpSelectKubunRiyou,
        // selectKubunKiroku: tmpSelectKubunKiroku
      });
    }
  }

  loadNewPage = (pageNo) => {
    const { setting } = this.props;
    const {
      riyousyaId,
      processType,
      selectKubunRiyou,
      selectKubunKiroku,
    } = this.state;
    let kubun = "";
    if (processType === "riyousyaSiryou") {
      if (selectKubunRiyou) {
        kubun = selectKubunRiyou;
      }
      else {
        const gazouRiyouKubun =
          setting && setting.gazouRiyouKubun ? setting.gazouRiyouKubun : "";
        kubun = extractKubunSetting(gazouRiyouKubun, 0, 1);
      }
    }
    if (processType === "kirokuTenpu") {
      if (selectKubunKiroku) {
        kubun = selectKubunKiroku;
      }
      else {
        const gazouKubun =
          setting && setting.gazouKubun ? setting.gazouKubun : "";
        kubun = extractKubunSetting(gazouKubun, 0, 1);
      }
    }
    this.props.loadData(pageNo, riyousyaId, processType, kubun);

    this.setState({
      page: pageNo
    });
  }

  tabParentChange = (key) => {
    const { riyousyaId } = this.state;
    const { setting } = this.props;

    const gazouRiyouKubun =
      setting && setting.gazouRiyouKubun ? setting.gazouRiyouKubun : "";
    const gazouKubun = setting && setting.gazouKubun ? setting.gazouKubun : "";
    const tmpRiyouKubun = extractKubunSetting(gazouRiyouKubun, 0, 1);
    const tmpKirokuKubun = extractKubunSetting(gazouKubun, 0, 1);
    const tpmKubun = key === "kirokuTenpu" ? tmpRiyouKubun : tmpKirokuKubun;

    this.props.loadData(1, riyousyaId, key, tpmKubun);

    this.setState({
      pageNo: 1,
      processType: key,
      selectKubunRiyou: tmpRiyouKubun,
      selectKubunKiroku: tmpKirokuKubun,
    });

    // saveToLocalStorage
    setValueIsKeep("fileRiyouDisType", key);
    setValueIsKeep("fileRiyouDisChild", tpmKubun);
  };

  tabChildChange = (key) => {
    const { riyousyaId, processType } = this.state;

    this.props.loadData(1, riyousyaId, processType, key);

    this.setState({
      pageNo: 1,
    });

    // saveToLocalStorage
    setValueIsKeep("fileRiyouDisChild", key);

    if (processType === "kirokuTenpu") {
      this.setState({
        selectKubunKiroku: key,
      });
    }
    else {
      this.setState({
        selectKubunRiyou: key,
      });
    }
  };

  async openPopupUploadFile(record) {
    await this.loadFilePreview(record);

    this.setState({
      showPopupUploadFile: true,
      record,
    });
  }

  loadFilePreview(record) {
    this.props.loadFilePreview(
      Object.assign(record, { fileManagementId: record.id }),
      (data) => {
        this.setState({ filePreview: data });
      }
    );
  }

  /**
   * Close Form
   */
  closePopupUploadFile = () => {
    this.setState({
      showPopupUploadFile: false,
      filePreview: null,
    });
  };

  /**
   *
   */
  download = () => {
    this.props.download(this.state.record.id, this.state.record.file_name);
  };

  handleGoBack = (e) => {
    e.preventDefault();

    this.props.history.goBack();
  };

  /**
   *
   */
  render() {
    const {
      sessionTimeout,
      setting,
      shiryou,
      loading,
      page_total,
      page,
      riyousyaDetail,
    } = this.props;
    const { processType, selectKubunRiyou, selectKubunKiroku, riyousyaId } =
      this.state;

    const path = getPath();
    return (
      (<CommonPageContainer
        id="riyousya-shiryou-page"
        sessionTimeout={sessionTimeout}
      >
        <Row className={"submenu"}>
          <MediaQuery query="(min-device-width: 0px)">
            {(matches) => {
              if (matches) {
                return (
                  <SubHeaderV2
                    TopLeft={
                      <SubHeaderTitleV2
                        title={`${riyousyaDetail ? riyousyaDetail.name : ""}さんのファイルフォルダ`}
                        iconType="file"
                      />
                    }
                    TopRight={
                      <div className="submenu-t-r">
                        <Link
                          to={"/"}
                          onClick={this.handleGoBack}
                          className="sub-top-right-item"
                        >
                          <span>
                            <UndoOutlined />
                          </span>
                          <span> 戻る</span>
                        </Link>
                        {processType && processType !== "kirokuTenpu" ? (
                          <Link
                            to={`${path}/riyousya-shiryou-input/${riyousyaId}/new`}
                            className="sub-top-right-item"
                          >
                            <span>
                              <PlusSquareOutlined />
                            </span>
                            <span> 追加</span>
                          </Link>
                        ) : null}
                      </div>
                    }
                  />
                );
              }
              /**
                 * @TODO design UI for mobile
                 *
                 * import SubHeaderMenuMobile from '../../common/component/SubHeaderMenuMobile';
                 */
              return null;

            }}
          </MediaQuery>
        </Row>
        <Row>
          <ShiryouRiyousya
            gazouRiyouKubun={
              setting && setting.gazouRiyouKubun
                ? setting.gazouRiyouKubun + ";未設定_noinput"
                : ""
            }
            gazouKubun={
              setting && setting.gazouKubun
                ? setting.gazouKubun + ";未設定_noinput"
                : ""
            }
            processType={processType}
            selectKubunRiyou={selectKubunRiyou}
            selectKubunKiroku={selectKubunKiroku}
            shiryou={shiryou}
            loading={loading}
            tabParentChange={this.tabParentChange}
            tabChildChange={this.tabChildChange}
            pageTotal={page_total}
            page={page}
            fileDisplay={this.openPopupUploadFile}
            history={this.props.history}
            loadNewPage={this.loadNewPage}
          />
        </Row>

        <Drawer
          closable
          onClose={this.closePopupUploadFile}
          open={this.state.showPopupUploadFile}
          destroyOnClose
          rootClassName="kiroku-drawer"
          placement="left"
        >
          <PopupUploadFile
            record={this.state.record}
            onCancel={this.closePopupUploadFile}
            download={this.download}
            filePreview={this.state.filePreview}
          />
        </Drawer>
      </CommonPageContainer>)
    );
  }
}

export default RiyousyaShiryou;

/* eslint-disable react/prop-types */
import React, { Suspense, memo } from "react";
import { Redirect, Route } from "react-router-dom";

const PublicLayout = ({
  component: Component,
  isUserLoggedIn,
  jwtTokenType,
  ...other
}) => (
  <Route
    {...other}
    render={() =>
      !isUserLoggedIn ? (
        <div className="public-layout">
          <Suspense fallback={null}>
            <main>
              <Component />
            </main>
          </Suspense>
        </div>
      ) : jwtTokenType == "SuperAdmin" ? (
        <Redirect to="/management/kanri/dashboard" />
      ) : (
        <Redirect to="/management/dengon" />
      )
    }
  />
);

const _PublicLayout = memo(PublicLayout);
export { _PublicLayout as PublicLayout };

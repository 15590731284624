export const apiUrls = {
  INIT: "",
  API_PORT: 9000,
  LOGIN: "/api/v1/login",
  LOGOUT: "/api/v1/logout",
  DECODE_TOKEN: "/api/v1/token",
  MAINTAINANCE_MODE: "/api/v1/maintenance-mode",

  // RIHABILI
  RIHABILI_LOAD_ALL: "/api/v1/rihabiri-all",
  RIHABILI_LOAD_LIFE: "/api/v1/rihabiri-life",
  RIHABILI_LOAD_LIFE_RIYOU: "/api/v1/rihabiri-life/", // {id}
  RIHABILI_LOAD_KYOMI_RIYOU: "/api/v1/rihabiri-kyoumi/", // {id}
  RIHABILI_LOAD_KYOMI_RIYOU_BY_ID: "/api/v1/rihabiri-kyoumi-by-id",
  RIHABILI_CREATE_KYOMI: "/api/v1/rihabiri-kyoumi-create",
  RIHABILI_LOAD_TANTOKAIGI_RIYOU: "/api/v1/rihabiri-kaigi/", // {id}
  RIHABILI_LOAD_TANTOKAIGI_RIYOU_BY_ID: "/api/v1/rihabiri-kaigi-by-id",
  RIHABILI_CREATE_TANTOKAIGI: "/api/v1/rihabiri-kaigi-create",
  RIHABILI_UPDATE_TANTOKAIGI: "/api/v1/rihabiri-kaigi-update",
  GET_DATA_KIKAN_MASTER_RIHABILI: "/api/v1/service-kikan-master-name/", // {id}
  GET_KAIGI_TEMPLATE: "/api/v1/rihabiri-get-kaigi-template",
  CREATE_KAIGI_TEMPLATE: "/api/v1/rihabiri-kaigi-template-save",
  RIHABILI_COPY: "/api/v1/rehabili-copy",

  RIHABILI_LOAD_PROCESS_RIYOU: "/api/v1/rihabiri-process/", // {id}
  RIHABILI_LOAD_PROCESS_RIYOU_BY_ID: "/api/v1/rihabiri-process-by-id",
  RIHABILI_CREATE_PROCESS: "/api/v1/rihabiri-process-create",
  GET_SETTING_MASTER_PROCESS: "/api/v1/get-setting-master-process",

  GET_NEEDS_ITEM_LIST: "/api/v1/needs-item-list",

  RIHABILI_CREATE_LIFE: "/api/v1/rihabiri/life-create-and-modify",
  RIHABILI_LOAD_LIFE_RPSDEL: "/api/v1/rihabiri/rehaplansub-del/",

  KANRI_SEARCH_DATA: "/api/v1/kanri-search-data", // /api/v1/kanri-search-data?type=users
  KANRI_MENU_SETTING: "/api/v1/kanri-user-setting",
  KANRI_MENU_SETTING_LIFE: "/api/v1/kanri-user-setting-life",
  KANRI_USERS_LIST: "/api/v1/kanri-users-list", // GET AND POST
  KANRI_FILES_LIST: "/api/v1/kanri-files-list", // GET AND POST
  KANRI_FILES_BY_IDS: "/api/v1/kanri-file-by-ids",
  KANRI_FILES_SINGLE_RECORD: "/api/v1/file-single-record",
  KANRI_DENGON_LIST: "/api/v1/kanri-dengon-list", // GET AND POST
  KANRI_DENGON_BY_IDS: "/api/v1/kanri-dengon-by-ids",
  KANRI_DENGON_SINGLE_RECORD: "/api/v1/dengon-single-record",
  KANRI_MOSHIOKURI_LIST: "/api/v1/kanri-moshiokuri-list", // GET AND POST
  KANRI_MOSHIOKURI_BY_IDS: "/api/v1/kanri-moshiokuri-by-ids",
  KANRI_MOSHIOKURI_SINGLE_RECORD: "/api/v1/moshiokuri-single-record",
  KANRI_OPENSL_LIST: "/api/v1/kanri-opensl-list", // GET AND POST
  KANRI_OPENSL_BY_IDS: "/api/v1/kanri-opensl-by-ids",
  KANRI_OPENSL_SINGLE_RECORD: "/api/v1/opensl-single-record",
  KANRI_KYM_LIST: "/api/v1/kanri-kym-list", // GET AND POST
  KANRI_KYM_BY_IDS: "/api/v1/kanri-kym-by-ids",
  KANRI_KYM_SINGLE_RECORD: "/api/v1/kym-single-record",
  KANRI_SETTINGMASTER_LIST: "/api/v1/kanri-sm-list", // GET AND POST
  KANRI_YOUGOMASTER_LIST: "/api/v1/kanri-ym-list", // GET AND POST
  KANRI_LIFEYOUGOMASTER_LIST: "/api/v1/kanri-lym-list", // GET AND POST
  KANRI_TANTOMASTER_LIST: "/api/v1/kanri-tm-list", // GET AND POST
  KANRI_USERRIYOUSYALINK_LIST: "/api/v1/kanri-url-list", // GET AND POST

  GET_RIYOUSYAMONTHLY_LIST: "/api/v1/riyousya-monthly/list",
  GET_RIYOUSYAMONTHLY_EDIT: "/api/v1/riyousya-monthly/edit",
  GET_RIYOUSYAMONTHLY_ADD: "/api/v1/riyousya-monthly/add",
  GET_RIYOUSYAMONTHLY_DEL: "/api/v1/riyousya-monthly/del/",

  GET_RIYOUSYA_LIST_RECORD_COPY: "/api/v1/riyousya-record-copy",

  // CRUD User Table
  USER_ADD: "/api/v1/users-insert",
  USER_UPDATE: "/api/v1/users-update",
  USER_DEL: "/api/v1/users-del",
  GET_USER_LOGIN_DETAIL: "/api/v1/user-information",

  // FILE
  FILE_UPDATE: "/api/v1/kanri-file-edit",
  FILE_ADD: "/api/v1/kanri-file-create",

  // DENGON
  DENGON_UPDATE: "/api/v1/kanri-dengon-edit",
  DENGON_ADD: "/api/v1/kanri-dengon-create",
  DENGON_DELETE: "/api/v1/kanri-dengon-delete",

  // ADL
  GET_REPORT_ADL_BY_RIYOUSYA: "/api/v1/report-adl-list",
  DELETE_REPORT_ADL: "/api/v1/delete-report-adl",
  GET_ADL_SETTING_MASTER: "/api/v1/get-setting-master",
  GET_ADL_MASTER: "/api/v1/get-adl-master",
  GET_REPORT_ADL_BY_iD: "/api/v1/report-adl-get-by-id",
  UPDATE_REPORT_ADL: "/api/v1/update-report-adl",

  // MOSHIOKURI
  MOSHIOKURI_UPDATE: "/api/v1/kanri-moshiokuri-edit",
  MOSHIOKURI_ADD: "/api/v1/kanri-moshiokuri-create",
  MOSHIOKURI_DELETE: "/api/v1/kanri-moshiokuri-delete",

  // OPEN_SCREEN_LOG
  OPENSL_UPDATE: "/api/v1/kanri-opensl-edit",
  OPENSL_ADD: "/api/v1/kanri-opensl-create",
  OPENSL_DELETE: "/api/v1/kanri-opensl-delete",

  // KAIGO_YOUGO_MASTER
  KYM_UPDATE: "/api/v1/kanri-kym-edit",
  KYM_ADD: "/api/v1/kanri-kym-create",
  KYM_DELETE: "/api/v1/kanri-kym-delete",

  //
  POST_TANTO_MASTER: "/api/v1/tantomaster-insert",
  PUT_TANTO_MASTER: "/api/v1/tantomaster-update",
  DEL_TANTO_MASTER: "/api/v1/tantomaster-del",

  //
  POST_USER_RIYOUSYA_LINK: "/api/v1/userriyousyalink-insert",
  PUT_USER_RIYOUSYA_LINK: "/api/v1/userriyousyalink-update",
  DEL_USER_RIYOUSYA_LINK: "/api/v1/userriyousyalink-del",

  GET_RIYOUSYA_LIST: "/api/v1/riyousya-list",
  GET_RIYOUSYA_DETAIL: "/api/v1/riyousya-detail",
  GET_RIYOUSYA_SHIRYOU: "/api/v1/file-riyousya", // pageNo=1&pageSize=20&mainSID=59&riyousyaId=286&processType=riyousyaSiryou&selectKubun=hoka
  RIYOU_UPDATE_SERVER: "/api/v1/riyousya-update",
  RIYOU_UPDATEOPEN_SERVER: "/api/v1/riyousya-update-open",
  RIYOU_MEAL_ABOVE_LIST: "/api/v1/riyou-syokujisen",
  RIYOU_MEAL_BELOW_LIST: "/api/v1/riyou-shokuji-rireki",

  RIYOU_HOKEN_ABOVE_LIST: "/api/v1/riyou-kaigohoken",
  RIYOU_HOKEN_ABOVE_SAVE: "/api/v1/riyou-kaigohoken/update",
  RIYOU_HOKEN_ABOVE_DEL: "/api/v1/riyou-kaigohoken/delete",

  RIYOU_HOKEN_BELOW_LIST: "/api/v1/riyou-kohijoho",
  RIYOU_HOKEN_BELOW_SAVE: "/api/v1/riyou-kohijoho/update",
  RIYOU_HOKEN_BELOW_DEL: "/api/v1/riyou-kohijoho/delete",

  // RoomMaster
  GET_ROOM_LIST: "/api/v1/room-list",
  SAVE_ROOM_LIST: "/api/v1/save-room-list",
  CREATE_ROOM_MASTER: "/api/v1/create-room",
  UPDATE_ROOM_MASTER: "/api/v1/update-room",
  GET_ROOM_MASTER_INIT: "/api/v1/room-init",

  GET_MESSAGE_LIST: "/api/v1/message-data-list",
  GET_MESSAGE_ADD: "/api/v1/message-data-add",
  GET_MESSAGE_GROUP: "/api/v1/send-group",
  GET_USER_LIST: "/api/v1/users-list",
  SENDGROUP_GETALL: "/api/v1/sendgroup-getall",
  SENDGROUP_UPDATE: "/api/v1/sendgroup-update",

  RIYOUSYA_SEARCH: "/api/v1/riyousya",
  RIYOUSYA_SEARCH2: "/api/v1/riyousya2",
  RIYOUSYA_SEARCH_LINE: "/api/v1/LIFE/riyousya",
  RIYOUSYA_GET: "/api/v1/riyousyaId",
  GET_LIST_RIYOUSYA_ID: "/api/v1/riyousyaIds",
  RIYOUSYA_SEARCH_ADD: "/api/v1/find-riyousya-add",
  RIYOUSYA_SAVE_ADD: "/api/v1/save-riyousya-add",

  GET_TANTO_MASTER_LIST: "/api/v1/tanto-master-list",
  GET_TANTO_MASTER_LIST2: "/api/v1/tanto-master-list2",
  GET_TANTO_MASTER_LIST_KIKAN: "/api/v1/tanto-master-list-bykikan",
  GET_TANTO_MASTER_BY_ID: "/api/v1/tanto-master-by-id",

  // GET_YOUGO_MASTER_NAIYOU_ALL: "/api/v1/yougo-master-All",
  GET_YOUGO_MASTER_NAIYOU: "/api/v1/yougomaster-naiyou",
  GET_YOUGO_MASTER_ECT: "/api/v1/yougomaster-ect",
  POST_YOUGO_MASTER: "/api/v1/yougomaster-insert",
  PUT_YOUGO_MASTER: "/api/v1/yougomaster-update",
  DEL_YOUGO_MASTER: "/api/v1/yougomaster-del",

  SETTING_MASTER_LOAD: "/api/v1/settingmaster", // /api/v1/settingmaster?page=1&limit=1000&settingName=請求書ま&naiyou=&settingNames=P64KG_請求書ま_205,P64NS_請求書ま_59,P64TT_請求書ま_58

  YOUGOMASTER_MASTER_LOAD: "/api/v1/yougomaster", // ?page=2&limit=50&listName=&naiyou=&listNames=グループ'

  // GET_YOUGO_MASTER_NAIYOU_ALL: "/api/v1/yougo-master-All",
  GET_LIFE_YOUGO_MASTER_NAIYOU: "/api/v1/life-yougomaster-naiyou",
  GET_LIFE_YOUGO_MASTER_ECT: "/api/v1/life-yougomaster-ect",
  POST_LIFE_YOUGO_MASTER: "/api/v1/life-yougomaster-insert",
  PUT_LIFE_YOUGO_MASTER: "/api/v1/life-yougomaster-update",
  DEL_LIFE_YOUGO_MASTER: "/api/v1/life-yougomaster-del",

  GET_BYOUMEI_MASTER_LIST: "/api/v1/byoumei-master",
  CREATE_BYOUMEI_MASTER: "/api/v1/byoumei-master-create",
  UPDATE_BYOUMEI_MASTER: "/api/v1/byoumei-master-update",
  GET_BYOUMEI_MASTER_INIT: "/api/v1/byoumei-master-init",
  UPDATE_LIST_BYOUMEI: "/api/v1/byoumei-master-list",

  GET_DRUG_MASTER_LIST: "/api/v1/drug-master",
  CREATE_DRUG_MASTER: "/api/v1/drug-master-create",
  UPDATE_DRUG_MASTER: "/api/v1/drug-master-update",
  GET_DRUG_MASTER_INIT: "/api/v1/drug-master-init",
  UPDATE_LIST_DRUG: "/api/v1/drug-master-list",

  GET_MACHINE_MASTER_LIST: "/api/v1/machine-master",
  CREATE_MACHINE_MASTER: "/api/v1/machine-master-create",
  UPDATE_MACHINE_MASTER: "/api/v1/machine-master-update",
  GET_MACHINE_MASTER_INIT: "/api/v1/machine-master-init",
  UPDATE_LIST_MACHINE: "/api/v1/machine-master-list",

  GET_SERVICE_SYURUI_MASTER_LIST: "/api/v1/service-syurui-master",
  GET_SERVICE_KIKAN_MASTER_LIST: "/api/v1/service-kikan-master",
  UPDATE_LIST_SERVICE_KIKAN_MASTER: "/api/v1/service-kikan-master-update-list",

  GET_IRYOU_KIKAN_MASTER_LIST: "/api/v1/iryou-kikan-master",
  GET_IRYOU_KIKAN_MASTER_INIT: "/api/v1/iryou-kikan-master-init",
  CREATE_IRYOU_KIKAN_MASTER: "/api/v1/iryou-kikan-master-create",
  UPDATE_IRYOU_KIKAN_MASTER: "/api/v1/iryou-kikan-master-update",
  UPDATE_LIST_IRYOU_KIKAN_MASTER: "/api/v1/iryou-kikan-master-list-update",

  GET_KAIGO_YOUGO_MASTER: "/api/v1/kaigo-yougo-list",
  GET_KAIGO_YOUGO_MASTER_ALL: "/api/v1/kaigo-yougo-list-all",
  GET_KAIGO_YOUGO_MASTER_LIST: "/api/v1/kaigo-yougo-all",
  GET_KAIGO_YOUGO_MASTER_ID: "/api/v1/kaigo-yougo-get",
  UPDATE_KAIGO_YOUGO_MASTER: "/api/v1/kaigo-yougo-update",
  UPDATE_KAIGO_YOUGO_LIST_MASTER: "/api/v1/kaigo-yougo-list-update",
  GET_KAIGO_YOUGO_MAX_SORT: "/api/v1/kaigo-yougo-max-sort",
  GET_SISETU_KAIGO_NAIYOU_MASTER: "/api/v1/sisetu-kaigo-naiyou-list",
  GET_SISETU_KAIGO_NAIYOU_LIST_MASTER: "/api/v1/sisetu-kaigo-naiyou-list-all",
  SAVE_SISETU_KAIGO_NAIYOU_MASTER: "/api/v1/sisetu-kaigo-naiyou-list-save",
  GET_SISETU_KAIGO_NAIYOU_INIT: "/api/v1/sisetu-kaigo-naiyou-init",
  CREATE_SISETU_KAIGO_NAIYOU: "/api/v1/sisetu-kaigo-naiyou-create",
  UPDATE_SISETU_KAIGO_NAIYOU: "/api/v1/sisetu-kaigo-naiyou-update",

  UPDATE_MESSAGE_READF: "/api/v1/message-mitayo",
  GET_AVATAR: "/api/v1/avatar",

  NYUTAISHO_BASIC_DATA: "/api/v1/report-basic",
  GAIHAKU_RIREKI: "/api/v1/report-basic/",
  GET_HAIBEN_DAY: "/api/v1/report-get-haiben-day",

  REPORT_RIYOUSYA: "/api/v1/report-riyousya-list",
  REPORT_VITAL: "/api/v1/report-vital-list",
  REPORT_SHUUKEI: "/api/v1/report-shuukei",

  REPORT_VITAL_UPDATE: "/api/v1/report-vital-update",
  REPORT_VITAL_INSERT: "/api/v1/report-vital-insert",
  REPORT_VITAL_DELETE: "/api/v1/report-vital-delete",

  REPORT_MEAL_UPDATE: "/api/v1/report-meal-update",
  REPORT_MEAL_INSERT: "/api/v1/report-meal-insert",
  REPORT_MEAL_DELETE: "/api/v1/report-meal-delete",

  REPORT_SUIBUN_UPDATE: "/api/v1/report-suibun-update",
  REPORT_SUIBUN_INSERT: "/api/v1/report-suibun-insert",
  REPORT_SUIBUN_DELETE: "/api/v1/report-suibun-delete",
  REPORT_SUIBUN_INSERT_UPDATE: "/api/v1/report-suibun/addeditmul",

  REPORT_HAISETU_UPDATE: "/api/v1/report-haisetu-update",
  REPORT_HAISETU_INSERT: "/api/v1/report-haisetu-insert",
  REPORT_HAISETU_DELETE: "/api/v1/report-haisetu-delete",

  REPORT_NYUYOKU_UPDATE: "/api/v1/report-nyuyoku-update",
  REPORT_NYUYOKU_INSERT: "/api/v1/report-nyuyoku-insert",
  REPORT_NYUYOKU_DELETE: "/api/v1/report-nyuyoku-delete",

  REPORT_FUKUYAKU_UPDATE: "/api/v1/report-fukuyaku-update",
  REPORT_FUKUYAKU_INSERT: "/api/v1/report-fukuyaku-insert",
  REPORT_FUKUYAKU_DELETE: "/api/v1/report-fukuyaku-delete",
  REPORT_FUKUYAKU_INSERT_UPDATE: "/api/v1/report-fukuyaku/addeditmul",

  REPORT_KAIGO_UPDATE: "/api/v1/report-kaigonaiyou-update",
  REPORT_KAIGO_INSERT: "/api/v1/report-kaigonaiyou-insert",
  REPORT_KAIGO_DELETE: "/api/v1/report-kaigonaiyou-delete",
  REPORT_KAIGO_INSERT_UPDATE: "/api/v1/report-kaigonaiyou/addeditmul",

  REPORT_TOKKI_UPDATE: "/api/v1/report-tokki-update",
  REPORT_TOKKI_INSERT: "/api/v1/report-tokki-insert",
  REPORT_TOKKI_DELETE: "/api/v1/report-tokki-delete",

  REPORT_GAZOU_UPDATE: "/api/v1/report-filemanagement-update",
  REPORT_GAZOU_INSERT: "/api/v1/report-filemanagement-insert",
  REPORT_GAZOU_DELETE: "/api/v1/report-filemanagement-delete",

  FILE_DEL_MUL: "/api/v1/report-filemanagement-delete-mul ",

  REPORT_MEAL: "/api/v1/report-meal-list",
  REPORT_SUIBUN: "/api/v1/report-suibun-list",
  REPORT_HAISETU: "/api/v1/report-haisetu-list",
  REPORT_NYUYOKU: "/api/v1/report-nyuyoku-list",
  REPORT_FUKUYAKU: "/api/v1/report-fukuyaku-list",
  REPORT_KAIGONAIYOU: "/api/v1/report-kaigonaiyou-list",
  REPORT_TOKKI: "/api/v1/report-tokki-list",
  REPORT_GAZOU: "/api/v1/file-management-list",

  DAILY_PLAN_WEEKLY: "/api/v1/sisetu-daily-plan-weekly",

  NYUTAISHO_DAILYPLAN: "/api/v1/sisetu-daily-plan-new",
  NYUTAISHO_DAILYPLAN_NEW: "/api/v1/sisetu-daily-plan-new",
  NYUTAISHO_FILTER: "/api/v1/report-filter",

  NYUTAISHO_ZENKAI_LOAD: "/api/v1/previous-vital",

  DOWNLOAD_FILE_KIROKU: "/api/v1/file-management-url",
  LOAD_FILE: "/api/v1/file",
  LOAD_FILE_PREVIEW: "/api/v1/file-preview",

  NYUTAISHO_BETWEEN_DATES: "/api/v1/nyutaisho-rireki-between-dates",
  NYUTAISHO_RIREKI_LIST_BYDATE: "/api/v1/nyutaisho-rireki-list-bydate",
  NYUTAISHO_RIREKI_UPDATE: "/api/v1/nyutaisho-rireki-update",
  NYUTAISHO_RIREKI_INSERT: "/api/v1/nyutaisho-rireki-insert",
  NYUTAISHO_RIREKI_DELETE: "/api/v1/nyutaisho-rireki-delete",
  NYUTAISHO_RIREKI_QUOTE: "/api/v1/nyutaisho-rireki-quote",

  GAIHAKU_GAISYUTU_RIREKI_BYDATE: "/api/v1/gaihaku-gaisyutu-rireki-bydate",
  GAIHAKU_GAISYUTU_RIREKI_UPDATE: "/api/v1/gaihaku-gaisyutu-rireki-update",

  GET_SISETU_DAILY_PLAN_LIST: "/api/v1/sisetu-daily-plan-doc-list",
  GET_SISETU_DAILY_PLAN_BY_ID: "/api/v1/sisetu-daily-plan-by-id",
  // GET_SISETU_DAILY_PLAN_TEMPLATE: "/api/v1/sisetu-daily-plan-template", updated 2020-04-09
  SAVE_SISETU_DAILY_PLAN: "/api/v1/save-sisetu-daily-plan",
  DELETE_SISETU_DAILY_PLAN: "/api/v1/delete-sisetu-daily-plan",

  // updated 2020-04-09
  SAVE_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE:
    "/api/v1/sisetu-daily-kaigo-naiyo-temp-save",
  GET_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE_NAME:
    "/api/v1/sisetu-daily-kaigo-service-temp-name",
  GET_SISETU_DAILY_KAIGO_SERVICE_TEMPLATE:
    "/api/v1/sisetu-daily-kaigo-service-temp",
  SAVE_SISETU_DAILY_ACT_TEMPLATE: "/api/v1/sisetu-daily-act-temp-save",
  GET_SISETU_DAILY_ACT_TEMPLATE: "/api/v1/sisetu-daily-act-temp",

  GET_RIYOUSYA_PLAN: "/api/v1/riyousya-plan",
  CREATE_RIYOUSYA_PLAN: "/api/v1/riyousyaPlan-insert",
  UPDATE_RIYOUSYA_PLAN: "/api/v1/riyousyaPlan-update",
  DELETE_RIYOUSYA_PLAN: "/api/v1/riyousyaPlan-delete",

  GET_EVENT_PLAN: "/api/v1/event-plan",
  CREATE_EVENT_PLAN: "/api/v1/eventPlan-insert",
  UPDATE_EVENT_PLAN: "/api/v1/eventPlan-update",
  DELETE_EVENT_PLAN: "/api/v1/eventPlan-delete",

  GET_SYSTEM_NAMES: "/api/v1/system-control-list",
  GET_SYSTEM_CONTROL_SISETU: "/api/v1/system-control-list-sisetu",
  GET_SETTING_MASTER: "/api/v1/setting-master",
  POST_SETTING_MASTER: "/api/v1/settingmaster-insert",
  PUT_SETTING_MASTER: "/api/v1/settingmaster-update",
  DEL_SETTING_MASTER: "/api/v1/settingmaster-del",

  GET_OPEN_SCREEN_LOG: "/api/v1/get-open-screen-log",
  SAVE_OPEN_SCREEN_LOG: "/api/v1/save-open-screen-log",

  GET_REPORT_CASE: "/api/v1/report-case-list",
  CREATE_REPORT_CASE: "/api/v1/report-case-insert",
  UPDATE_REPORT_CASE: "/api/v1/report-case-update",
  DELETE_REPORT_CASE: "/api/v1/report-case-delete",
  UPDATE_KAKUNIN_COMMENT: "/api/v1/kakunin-comment-update",
  GET_REPORT_CASE_BY_ID: "/api/v1/report-case-by-id",
  GET_REPORT_CASE_START: "/api/v1/moshiokuri",

  GET_ALL_DOCUMENT_LIST: "/api/v1/doc-list",

  GET_KYOTAKU_PLAN1_LIST: "/api/v1/kyotaku-plan1-list",
  GET_KYOTAKU_PLAN1_BY_ID: "/api/v1/kyotaku-plan1",
  CREATE_KYOTAKU_PLAN1: "/api/v1/kyotaku-plan1",
  UPDATE_KYOTAKU_PLAN1: "/api/v1/kyotaku-plan1",
  DELETE_KYOTAKU_PLAN1: "/api/v1/kyotaku-plan1",
  GET_KYOTAKU_PLAN1_TO_QUOTE: "/api/v1/kyotaku-plan1-quote",

  GET_KYOTAKU_PLAN2_LIST: "/api/v1/kyotaku-plan2-list",
  GET_KYOTAKU_PLAN2_BY_ID: "/api/v1/kyotaku-plan2",
  CREATE_KYOTAKU_PLAN2: "/api/v1/kyotaku-plan2",
  UPDATE_KYOTAKU_PLAN2: "/api/v1/kyotaku-plan2",
  DELETE_KYOTAKU_PLAN2: "/api/v1/kyotaku-plan2",
  GET_KYOTAKU_PLAN2_TO_QUOTE: "/api/v1/kyotaku-plan2-quote",

  GET_VIEW_PLANS_BY_RIYOUSYA: "/api/v1/view-riyousya-plans",

  // 利用介護保険
  GET_RIYOU_KAIGOHOKEN_LIST: "/api/v1/riyou-kaigohoken-list",
  CHECK_HOKEN_NO: "/api/v1/check-hokensya-no",

  SYOKICHI_ITEM: "/api/v1/kiroku-syokichi",
  SYOKICHI_UPDATE: "/api/v1/kiroku-syokichi-insert-update",

  EVENT_PLAN_RIYOUSYA: "/api/v1/event-riyousya-plan",

  //
  REPORT_VITAL_BY_ID: "/api/v1/report-vital/", // {id}
  REPORT_MEAL_BY_ID: "/api/v1/report-meal/", // {id}
  REPORT_SUIBUN_BY_ID: "/api/v1/report-suibun/", // {id}
  REPORT_HAISETU_BY_ID: "/api/v1/report-haisetu/", // {id}
  REPORT_NYUYOKU_BY_ID: "/api/v1/report-nyuyoku/", // {id}
  REPORT_FUKUYAKU_BY_ID: "/api/v1/report-fukuyaku/", // {id}
  REPORT_KAIGO_BY_ID: "/api/v1/report-kaigonaiyou/", // {id}
  REPORT_TOKKI_BY_ID: "/api/v1/report-tokki/", // {id}
  REPORT_FILE_BY_ID: "/api/v1/report-filemanagement/", // {id}

  SETTING_MASTER_ALL: "/api/v1/setting-all", // ?systemId=59
  SETTING_MASTER_ALL_NEW: "/api/v1/setting-new", // ?systemId=59&singleTextTitle=bunsui_set&singleText=sw利用者分類表示設定
  KIHON_ADL_MASTER_ALL: "/api/v1/kihon-adl-list",

  ZIP_MASTER_SEARCH: "/api/v1/zip-master-search", // ?text=730-0021

  RIYOU_SYOKUJISEN_DEL: "/api/v1/riyou-syokujisen/delete", // ?ids="1,2,3,4,5"
  RIYOU_SHOKUJI_DEL: "/api/v1/riyou-shokuji/delete", // ?ids="1,2,3,4,5"
  RIYOU_SYOKUJISEN_INPUT: "/api/v1/riyou-syokujisen/update",
  RIYOU_SHOKUJI_INPUT: "/api/v1/riyou-shokuji/update",

  // 3S UPDATE
  GET_DUTY_REPORT: "/api/v1/duty-report-list",
  CREATE_DUTY_REPORT: "/api/v1/duty-report-insert",
  EDIT_DUTY_REPORT: "/api/v1/duty-report-update",
  STATISTICAL_OUT_IN: "/api/v1/statistical-out-in",
  STATISTICAL_OUT_IN_BYKIND: "/api/v1/statistical-out-in-bykind",
  PEOPLE_OUT_IN_BYKIND: "/api/v1/people-out-in-list-bykind",

  GET_MONITORING_BY_RIYOUSYA: "/api/v1/monitoring-list",
  DELETE_MONITORING: "/api/v1/monitoring",
  GET_MONITORING_BY_ID: "/api/v1/monitoring-by-id",
  UPDATE_MONITORING: "/api/v1/monitoring-update",
  CREATE_MONITORING: "/api/v1/monitoring-insert",

  GET_KEIKA_KIROKU: "/api/v1/keika-kiroku-list",
  DELETE_KEIKA_KIROKU: "/api/v1/keika-kiroku",
  LOAD_KEIKA_KIROKU_BY_DATE: "/api/v1/keika-kiroku-list-by-date",
  GET_KEIKA_KIROKU_BY_ID: "/api/v1/keika-kiroku-get-by-id",
  UPDATE_KEIKA_KIROKU: "/api/v1/keika-kiroku-create", // please add keika kiroku id when update
  CREATE_KEIKA_KIROKU: "/api/v1/keika-kiroku-create", // keika kiroku id is null will create

  GET_TANTO_KAIGI_BY_RIYOUSYA: "/api/v1/tanto-kaigi-list",
  DELETE_TANTO_KAIGI: "/api/v1/tanto-kaigi",
  LOAD_TANTO_KAIGI_BY_DATE: "/api/v1/tanto-kaigi-list-between-date",
  GET_TANTO_KAIGI_BY_iD: "/api/v1/tanto-kaigi-get-by-id",
  CREATE_TANTO_KAIGI: "/api/v1/create-tanto-kaigi",
  UPDATE_TANTO_KAIGI: "/api/v1/update-tanto-kaigi",
  GET_TANTO_KAIGI_TEMPLATE: "/api/v1/get-tanto-kaigi-template",
  CREATE_TANTO_KAIGI_TEMPLATE: "/api/v1/tanto-kaigi-template-save",
  GET_DATA_KIKAN_MASTER: "/api/v1/service-kikan-master-name",

  GET_DOCTOR_MASTER_LIST: "/api/v1/doctor-master-list",
  GET_DOCTOR_MASTER_MAX_SORT: "/api/v1/doctor-master-max-sort",
  GET_DOCTOR_MASTER_INIT: "/api/v1/doctor-master-init",
  CREATE_DOCTOR_MASTER: "/api/v1/doctor-master-create",
  UPDATE_DOCTOR_MASTER: "/api/v1/doctor-master-update",
  UPDATE_LIST_DOCTOR_MASTER: "/api/v1/doctor-master-update-list",

  GET_MEDISYOUBYOUMEI: "/api/v1/get-medi-syoubyomei-master",
  GET_MEDISYOUBYOUMEI_CODE: "/api/v1/get-medi-syoubyomei-by-code",

  GET_RIYOU_CONCERN_LIST: "/api/v1/get-list-riyou-concern",

  GET_KOBETSU_LIST: "/api/v1/kobetsu-list",
  GET_KOBETSU_BYID: "/api/v1/kobetsubyriyouid",
  CREATE_KOBETSU: "/api/v1/kobetsu",
  COPY_KOBETSU: "/api/v1/kobetsu-copy",
  UPDATE_KOBETSU: "/api/v1/kobetsu",
  DELETE_KOBETSU: "/api/v1/kobetsu",
  GET_LATEST_KOBETSU: "api/v1/kobetsu-latest",
  GET_FIRST_KOBETSU: "api/v1/kobetsu-first",

  EXPORT_CSV_ALL: "/api/v1/life-export-data",
  EXPORT_CSV_RIYOU: "/api/v1/life-export-riyou",
  EXPORT_CSV_KOBETSU: "/api/v1/life-export-kobetsu",
  EXPORT_CSV_KEIKAKU: "/api/v1/life-export-seikatu",
  EXPORT_CSV_JOKUSO: "/api/v1/life-export-jokuso",

  GET_MASTER_NEW_CODE: "/api/v1/get-master-new-code",
  CANCEL_CREATE_MASTER: "/api/v1/cancel-create-master",

  // 医療保険
  IRYOUHOKEN_GET_LIST: "/api/v1/iryou-hoken-get-list",
  UPDATE_IRYOUHOKEN: "/api/v1/iryou-hoken-update",
  DELETE_IRYOUHOKEN: "/api/v1/iryou-hoken-delete",
  GET_IRYOUHOKEN_BY_NO: "/api/v1/iryou-hoken-master-get-by-no",
  // 高額医療費負担上限
  IRYO_KOUGAKU_FUTAN_GET_LIST: "/api/v1/kougaku-iryouhi-get-list",
  UPDATE_IRYO_KOUGAKU_FUTAN: "/api/v1/kougaku-iryouhi-update",
  DELETE_IRYO_KOUGAKU_FUTAN: "/api/v1/kougaku-iryouhi-delete",
  // 介護保険マスタ
  KAIGOHOKEN_MASTER_LIST: "/api/v1/kaigohokenmaster/list",
  GET_KAIGOHOKEN_MASTER_MAX_SORT: "/api/v1/kaigohokenmaster-max-sort",
  GET_KAIGOHOKEN_MASTER_INIT: "/api/v1/kaigohokenmaster-init",
  CREATE_KAIGOHOKEN_MASTER: "/api/v1/kaigohokenmaster-create",
  UPDATE_KAIGOHOKEN_MASTER: "/api/v1/kaigohokenmaster-update",
  UPDATE_LIST_KAIGOHOKEN_MASTER: "/api/v1/kaigohokenmaster-update-list",
  // 公費負担マスタ
  GET_KOHIFUTAN_MASTER: "/api/v1/kohifuntan-master/list",
  GET_KOHIFUTAN_MASTER2: "/api/v1/get-kohifuntan-master2",
  GET_KOHIFUTAN_MASTER_MAX_SORT: "/api/v1/kohifutanmaster-max-sort",
  GET_KOHIFUTAN_MASTER_INIT: "/api/v1/kohifutanmaster-init",
  CREATE_KOHIFUTAN_MASTER: "/api/v1/kohifutanmaster-create",
  UPDATE_KOHIFUTAN_MASTER: "/api/v1/kohifutanmaster-update",
  UPDATE_LIST_KOHIFUTAN_MASTER: "/api/v1/kohifutanmaster-update-list",
  // 医療保険マスタ
  GET_IRYOUHOKEN_LIST: "/api/v1/iryouhoken-master-get-list",
  GET_IRYOUHOKEN_INIT: "/api/v1/iryouhoken-master-init",
  CREATE_IRYOUHOKEN_MASTER: "/api/v1/iryouhoken-master-create",
  UPDATE_IRYOUHOKEN_MASTER: "/api/v1/iryouhoken-master-update",
  UPDATE_LIST_IRYOUHOKEN_MASTER: "/api/v1/iryouhoken-master-update-list",
  // 医療高額療養負担額マスタ
  IRYO_KOUGAKU_FUTAN_MASTER_GET_LIST: "/api/v1/iryo-kougaku-master-get-list",

  ITAKUTANTO_MASTER_LIST: "/api/v1/itaku-tanto-master/list",
  CREATE_ITAKUTANTO_MASTER: "/api/v1/itaku-tanto-master-create",
  UPDATE_ITAKUTANTO_MASTER: "/api/v1/itaku-tanto-master-update",
  GET_ITAKUTANTO_MASTER_INIT: "/api/v1/itaku-tanto-master-init",

  GET_HOKEN_NAME: "/api/v1/get-hoken-name",

  GET_JIGYOUSYO_NAME: "/api/v1/service-kikan-master-name2",

  // 公費マスタ
  GET_KOHI_MASTER: "/api/v1/get-kohi-master",

  // RiyouChart
  SAVE_TEMPLATE_RIYOU_CHART: "/api/v1/riyou-chart-template-save",
  LOAD_TEMPLATE_RIYOU_CHART: "/api/v1/riyou-chart-template-load",
  DELETE_TEMPLATE_RIYOU_CHART: "/api/v1/riyou-chart-template-delete",
};

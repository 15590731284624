import { lazy } from "react";
import CheckMissInput from "../pages/UsageTicketManagement/components/check-miss-input";

// document
const DocumentList = lazy(() => import("../pages/Document/list"));
const DocumentTabs = lazy(() => import("../pages/Document/tabs"));

// pdfme
const PDFmeDesign = lazy(() => import("../common/PDFme"));

// plan
const Plan1Input = lazy(() => import("../pages/Plan1/input"));
const Plan1View = lazy(() => import("../pages/Plan1/view"));
const Plan2Input = lazy(() => import("../pages/Plan2/input"));
const Plan2View = lazy(() => import("../pages/Plan2/view"));

// weekly plan
const WeeklyPlanInputSchedule = lazy(() =>
  import("../pages/WeeklyPlan/input-schedule")
);
const ViewWeeklyPlan = lazy(() => import("../pages/WeeklyPlan/view"));
const WeeklyPlanInputRental = lazy(() =>
  import("../pages/WeeklyPlan/input-rental")
);
// plan1
//  InquiryEequest
const InquiryRequestInput = lazy(() => import("../pages/InquiryRequest/input"));
const InquiryRequestView = lazy(() => import("../pages/InquiryRequest/view"));
// progress
const KeikaKirokuInput = lazy(() => import("../pages/Progress/input"));
const KeikaKirokuView = lazy(() => import("../pages/Progress/view"));
const WeeklyPlanDetail = lazy(() =>
  import("../pages/WeeklyPlan/WeeklyPlanDetail")
);
const InputKadaiSeiri = lazy(() => import("../pages/Task/inputKadaiSeiri"));
const MonitoringManagementInput = lazy(() =>
  import("../pages/MonitoringManagement/input")
);
// metting
const TantoKaigiInput = lazy(() => import("../pages/Meeting/input"));
const TantoKaigiView = lazy(() => import("../pages/Meeting/view"));
// monitoring
const MonitoringInput = lazy(() => import("../pages/Monitoring/input"));
const MonitoringView = lazy(() => import("../pages/Monitoring/view"));

// schedule
const UseSlipInput = lazy(() => import("../pages/UseSlip/input"));
const ViewUseSlip = lazy(() => import("../pages/UseSlip/ViewUseSlip"));

const AttachedTable = lazy(() =>import("../pages/UseSlip/AttachedTable/index"));
const ViewAttachedTable = lazy(() => import("../pages/UseSlip/ViewUseSlip/ViewAttachedTable"))

const ViewSonota = lazy(() => import("../pages/UseSlip/ViewUseSlip/ViewSonota"))

// facesheet
const YobouFacesheetInput = lazy(() => import("../pages/Facesheet/input"));
const YobouFacesheetView = lazy(() => import("../pages/Facesheet/view"));

// kihonCheck
const KihonCheckInput = lazy(() => import("../pages/KihonCheck/input"));
const KihonCheckView = lazy(() => import("../pages/KihonCheck/view"));

// yobousienPlan
const YobousienPlanInput = lazy(() => import("../pages/YobousienPlan/input"));
const YobousienPlanView = lazy(() => import("../pages/YobousienPlan/view"));

// hyoukahyou
const HyoukahyouInput = lazy(() => import("../pages/Hyoukahyou/input"));
const HyoukahyouView = lazy(() => import("../pages/Hyoukahyou/view"));

// dengon
const DengonContainer = lazy(() =>
  import("../../components/dengon/dengonContainer")
);
// riyousya
const RiyousyaContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaContainer,
  }))
);
const RiyousyaDetailContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaDetailContainer,
  }))
);
const RiyousyaUpdateContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaUpdateContainer,
  }))
);
const RiyousyaHokenContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaHokenContainer,
  }))
);
const RiyousyaShokujiContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaShokujiContainer,
  }))
);
const RiyousyaShiryouContainer = lazy(() =>
  import("../../components/riyousyaShiryou/riyousyaShiryouContainer").then(
    (module) => ({
      default: module.RiyousyaShiryouContainer,
    })
  )
);
const RiyousyaAddContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaAddContainer,
  }))
);
const InputRiyousyaShiryouContainer = lazy(() =>
  import("../../components/riyousyaShiryou/riyousyaShiryouContainer").then(
    (module) => ({
      default: module.InputRiyousyaShiryouContainer,
    })
  )
);
// adl
const AdlIndexContainer = lazy(() =>
  import("../../components/adl/AdlContainer").then((module) => ({
    default: module.AdlIndexContainer,
  }))
);
const AdlInputContainer = lazy(() =>
  import("../../components/adl/AdlContainer").then((module) => ({
    default: module.AdlInputContainer,
  }))
);
const AdlDetailContainer = lazy(() =>
  import("../../components/adl/AdlContainer").then((module) => ({
    default: module.AdlDetailContainer,
  }))
);
// progress
const ProgressRecordContainer = lazy(() =>
  import("../../components/progress/ProgressRecordContainer").then(
    (module) => ({
      default: module.ProgressRecordContainer,
    })
  )
);
const ProgressRecordRiyousyaContainer = lazy(() =>
  import("../../components/progress/ProgressRecordContainer").then(
    (module) => ({
      default: module.ProgressRecordRiyousyaContainer,
    })
  )
);
const InputReportCaseContainer = lazy(() =>
  import("../../components/progress/ProgressRecordContainer").then(
    (module) => ({
      default: module.InputReportCaseContainer,
    })
  )
);
// Task
const TaskInput = lazy(() => import("../pages/Task/input"));
const TaskView = lazy(() => import("../pages/Task/view"));
// kanri
const KanriContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriContainer,
  }))
);
const KanriUserContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserContainer,
  }))
);
const KanriUserSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserSaveContainer,
  }))
);
const KanriSettingMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriSettingMasterContainer,
  }))
);
const KanriSettingMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriSettingMasterSaveContainer,
  }))
);
const KanriYougoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriYougoMasterContainer,
  }))
);
const KanriYougoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriYougoMasterSaveContainer,
  }))
);
const KanriLifeYougoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriLifeYougoMasterContainer,
  }))
);
const KanriLifeYougoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriLifeYougoMasterSaveContainer,
  }))
);
const KanriKaigoYougoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriKaigoYougoMasterContainer,
  }))
);
const KanriKaigoYougoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriKaigoYougoMasterSaveContainer,
  }))
);
const KanriTantoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriTantoMasterContainer,
  }))
);
const KanriTantoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriTantoMasterSaveContainer,
  }))
);
const KanriUserRiyousyaLinkContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserRiyousyaLinkContainer,
  }))
);
const KanriUserRiyousyaLinkSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserRiyousyaLinkSaveContainer,
  }))
);
const KanriFileContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriFileContainer,
  }))
);
const KanriFileSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriFileSaveContainer,
  }))
);
const KanriSendGroupContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriSendGroupContainer,
  }))
);
const KanriDengonDataContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriDengonDataContainer,
  }))
);
const KanriDengonDataSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriDengonDataSaveContainer,
  }))
);
const KanriMoshiokuriLinkContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriMoshiokuriLinkContainer,
  }))
);
const KanriMoshiokuriLinkSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriMoshiokuriLinkSaveContainer,
  }))
);
const KanriOpenScreenLogContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriOpenScreenLogContainer,
  }))
);
const KanriOpenScreenLogSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriOpenScreenLogSaveContainer,
  }))
);

// logout
const LogoutContainer = lazy(() =>
  import("../../components/logout/logoutContainer").then((module) => ({
    default: module.LogoutContainer,
  }))
);

// user-management
const UserManagementContainer = lazy(() =>
  import("../../components/userManagement/UserManagementContainer").then(
    (module) => ({
      default: module.UserManagementContainer,
    })
  )
);
const InputUserManagement = lazy(() =>
  import("../../components/userManagement/UserManagementContainer").then(
    (module) => ({
      default: module.InputUserManagement,
    })
  )
);
const SendGroupContainer = lazy(() =>
  import("../../components/sendGroup/SendGroupContainer").then((module) => ({
    default: module.SendGroupContainer,
  }))
);

const UsageTicketManagement = lazy(() =>
  import("../pages/UsageTicketManagement")
);

const JokusoPlanViewPDFmeDesign = lazy(() => import("../../life/common/PDFme"));


const routes = [
  // document
  {
    path: "/management/documents",
    component: DocumentList,
  },
  {
    path: "/management/documents/:riyousyaId",
    component: DocumentTabs,
  },

  // pdfme
  {
    path: "/management/:screen/:riyousyaId/:id/pdfmeDesign/:pdfId",
    component: PDFmeDesign,
  },

  // plan
  {
    path: "/management/input-plan1/:riyousyaId/:kyotakuPlan1Id",
    component: Plan1Input,
  },
  {
    path: "/management/view-plan1/:riyousyaId/:kyotakuPlan1Id",
    component: Plan1View,
  },
  {
    path: "/management/input-plan2/:riyousyaId/:kyotakuPlan2Id",
    component: Plan2Input,
  },
  {
    path: "/management/view-plan2/:riyousyaId/:kyotakuPlan2Id",
    component: Plan2View,
  },
  // input-InquiryEequest
  {
    path: "/management/input-inquiry-request/:riyousyaId/:inquiryRequestId",
    component: InquiryRequestInput,
  },
  {
    path: "/management/view-inquiry-request/:riyousyaId/:inquiryRequestId",
    component: InquiryRequestView,
  },

  // monitoring management
  {
    path: "/management/monitoring-management",
    component: MonitoringManagementInput,
  },
  // degon
  {
    path: "/management/dengon",
    component: DengonContainer,
  },
  {
    path: "/management",
    component: DengonContainer,
  },
  // riyousya
  {
    path: "/management/riyousya",
    component: RiyousyaContainer,
  },
  {
    path: "/management/riyousya-detail/:riyousyaId",
    component: RiyousyaDetailContainer,
  },
  {
    path: "/management/riyousya-update/:riyousyaId",
    component: RiyousyaUpdateContainer,
  },
  {
    path: "/management/riyousya-hoken/:riyousyaId",
    component: RiyousyaHokenContainer,
  },
  {
    path: "/management/riyousya-shokuji/:riyousyaId",
    component: RiyousyaShokujiContainer,
  },
  // riyousya-shiryou
  {
    path: "/management/riyousya-shiryou/:riyousyaId",
    component: RiyousyaShiryouContainer,
  },
  {
    path: "/management/riyousya-add",
    component: RiyousyaAddContainer,
  },
  {
    path: "/management/riyousya-shiryou-input/:riyousyaId/:fileManagementId",
    component: InputRiyousyaShiryouContainer,
  },
  // adl
  {
    path: "/management/report-adl/:riyousyaId",
    component: AdlIndexContainer,
  },
  {
    path: "/management/report-adl/:riyousyaId/:adlId",
    component: AdlInputContainer,
  },
  {
    path: "/management/report-adl-view/:riyousyaId/:adlId",
    component: AdlDetailContainer,
  },
  // progress
  {
    path: "/management/progress",
    component: ProgressRecordContainer,
  },
  {
    path: "/management/progress/:riyousyaId",
    component: ProgressRecordRiyousyaContainer,
  },
  {
    path: "/management/input-keika-kiroku/:riyousyaId/:keikaKirokuId",
    component: KeikaKirokuInput,
  },
  {
    path: "/management/view-keika-kiroku/:riyousyaId/:recordId",
    component: KeikaKirokuView,
  },
  {
    path: "/management/input-tanto-kaigi/:riyousyaId/:tantoKaigiId",
    component: TantoKaigiInput,
  },
  {
    path: "/management/view-tanto-kaigi/:riyousyaId/:tantoKaigiId",
    component: TantoKaigiView,
  },
  {
    path: "/management/input-report-case/:riyousyaId/:reportCaseId",
    component: InputReportCaseContainer,
  },
  {
    path: "/management/input-monitoring/:riyousyaId/:monitoringId",
    component: MonitoringInput,
  },
  {
    path: "/management/view-monitoring/:riyousyaId/:monitoringId",
    component: MonitoringView,
  },
  // kanri
  {
    path: "/management/kanri",
    component: KanriContainer,
  },
  {
    path: "/management/kanri/dashboard",
    component: KanriContainer,
  },
  {
    path: "/management/kanri/user",
    component: KanriUserContainer,
  },
  {
    path: "/management/kanri/user/:userId",
    component: KanriUserSaveContainer,
  },
  {
    path: "/management/kanri/userriyousyalink",
    component: KanriUserRiyousyaLinkContainer,
  },
  {
    path: "/management/kanri/userriyousyalink/:urlId",
    component: KanriUserRiyousyaLinkSaveContainer,
  },
  {
    path: "/management/kanri/tantomaster",
    component: KanriTantoMasterContainer,
  },
  {
    path: "/management/kanri/tantomaster/:tmId",
    component: KanriTantoMasterSaveContainer,
  },
  {
    path: "/management/kanri/settingmaster",
    component: KanriSettingMasterContainer,
  },
  {
    path: "/management/kanri/settingmaster/:smId",
    component: KanriSettingMasterSaveContainer,
  },
  {
    path: "/management/kanri/yougomaster",
    component: KanriYougoMasterContainer,
  },
  {
    path: "/management/kanri/yougomaster/:ymId",
    component: KanriYougoMasterSaveContainer,
  },
  {
    path: "/management/kanri/lifeyougomaster",
    component: KanriLifeYougoMasterContainer,
  },
  {
    path: "/management/kanri/lifeyougomaster/:lymId",
    component: KanriLifeYougoMasterSaveContainer,
  },
  {
    path: "/management/kanri/kaigoyougomaster",
    component: KanriKaigoYougoMasterContainer,
  },
  {
    path: "/management/kanri/kaigoyougomaster/:kymId",
    component: KanriKaigoYougoMasterSaveContainer,
  },
  {
    path: "/management/kanri/dengondata",
    component: KanriDengonDataContainer,
  },
  {
    path: "/management/kanri/dengondata/:dengonId",
    component: KanriDengonDataSaveContainer,
  },
  {
    path: "/management/kanri/moshiokurilink",
    component: KanriMoshiokuriLinkContainer,
  },
  {
    path: "/management/kanri/moshiokurilink/:moshiokuriId",
    component: KanriMoshiokuriLinkSaveContainer,
  },
  {
    path: "/management/kanri/file",
    component: KanriFileContainer,
  },
  {
    path: "/management/kanri/file/:fileId",
    component: KanriFileSaveContainer,
  },
  {
    path: "/management/kanri/sendgroup",
    component: KanriSendGroupContainer,
  },
  {
    path: "/management/kanri/openscreenlog",
    component: KanriOpenScreenLogContainer,
  },
  {
    path: "/management/kanri/openscreenlog/:openId",
    component: KanriOpenScreenLogSaveContainer,
  },
  // weekly-plan
  {
    path: "/management/input-weekly-schedule/:riyousyaId/:weeklyPlanId",
    component: WeeklyPlanInputSchedule,
  },
  {
    path: "/management/weekly/details/:riyousyaId/:weeklyPlanId",
    component: WeeklyPlanDetail,
  },
  {
    path: "/management/weekly/details/:riyousyaId/:weeklyPlanId/isFromUseslip=true",
    component: WeeklyPlanDetail,
  },
  {
    path: "/management/view-weekly-plan/:riyousyaId/:weeklyPlanId",
    component: ViewWeeklyPlan,
  },
  {
    path: "/management/input-rental/:riyousyaId/:weeklyPlanId",
    component: WeeklyPlanInputRental,
  },
  {
    path: "/management/input-rental/:riyousyaId/:weeklyPlanId/isFromUseslip=true",
    component: WeeklyPlanInputRental,
  },
  // Task
  {
    path: "/management/input-task/:riyousyaId/:kadaiTaskId",
    component: TaskInput,
  },
  {
    path: "/management/view-task/:riyousyaId/:kadaiTaskId",
    component: TaskView,
  },
  {
    path: "/management/input-kadai-seiri/:riyousyaId/:kadaiSeiriId",
    component: InputKadaiSeiri,
  },
  {
    path: "/management/input-use-slip/:riyousyaId/:riyouhyouId/:monthYear",
    component: UseSlipInput,
  },
  {
    path: "/management/view-use-slip/:riyousyaId/:riyouhyouId/:monthYear",
    component: ViewUseSlip,
  },
  {
    path: "/management/input-use-slip/attached/:riyousyaId/:id/:monthYear/:isPlan",
    component: AttachedTable,
  },
  {
    path: "/management/view-use-slip/attached/:riyousyaId/:riyouhyouId/:monthYear",
    component: ViewAttachedTable
  },
  {
    path: "/management/view-sonota/:riyousyaId/:riyouhyouId/:monthYear",
    component: ViewSonota
  },
  {
    path: "/management/input-facesheet/:riyousyaId/:id",
    component: YobouFacesheetInput,
  },
  {
    path: "/management/view-facesheet/:riyousyaId/:id",
    component: YobouFacesheetView,
  },
  // kihonCheck
  {
    path: "/management/input-kihon-check/:riyousyaId/:id",
    component: KihonCheckInput,
  },
  {
    path: "/management/view-kihon-check/:riyousyaId/:id",
    component: KihonCheckView,
  },
  // yobousienPlan
  {
    path: "/management/input-yobousien-plan/:riyousyaId/:id",
    component: YobousienPlanInput,
  },
  {
    path: "/management/view-yobousien-plan/:riyousyaId/:id",
    component: YobousienPlanView,
  },
  // hyoukahyou
  {
    path: "/management/input-hyoukahyou/:riyousyaId/:id",
    component: HyoukahyouInput,
  },
  {
    path: "/management/view-hyoukahyou/:riyousyaId/:id",
    component: HyoukahyouView,
  },
  // logout
  {
    path: "/management/logout",
    component: LogoutContainer,
  },

  // user-management
  {
    path: "/management/user-management",
    component: UserManagementContainer,
  },
  {
    path: "/management/user-management/:userId",
    component: InputUserManagement,
  },
  {
    path: "/management/send-group",
    component: SendGroupContainer,
  },
  {
    path: "/management/usage-ticket-management",
    component: UsageTicketManagement,
  },
  {
    path: "/management/usage-ticket-management/error-miss-input",
    component: CheckMissInput,
  },
  {
    path:"/management/:screen/:riyousyaId/:riyouhyouId/pdfmeRiyouhyou/:pdfId",
    component: JokusoPlanViewPDFmeDesign
  }
];

export default routes.map((route) => {
  route.type = "private";
  return route;
});

import { Form } from "@ant-design/compatible";
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Input, Modal, Radio, Row } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import React from "react";
import FuriganaKeyBoard from "../../../../common/component/FuriganaKeyBoard";
import SearchConditions from "../../../../common/component/SearchConditions";
import SortConditions from "../../../../common/component/SortConditions";
import { ConstSet } from "../../../../common/configs/constset";
import {
  changeSortTab,
  getDateInMonth,
  reportSortOptions,
  setValueIsKeep,
  setValueLocalstorage,
} from "../../../../common/function_common/functionCommon";
import { guid } from "../../../../common/timePicker/formatTimePicker";
import datePickerJp from "../../../../data/datePickerJp";
import JikantaiSelect from "../JikantaiSelect";
import TeikyouSelect from "../TeikyouSelect";

const FA = require("react-fontawesome");
const ButtonGroup = Button.Group;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export default class Advanced extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: dayjs(this.props.nyutaishoDate, "YYYY-M-D"),
      visible: false,
      resetFurigana: false,
      visibleModalSearch: false,
      visibleSort: false,
      furigana: "",
      visibleMulInputMode: false,
    };

    // bind place
    this.handleNextDate = this.handleNextDate.bind(this);
    this.handlePreviewDate = this.handlePreviewDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.showModalFuriKey = this.showModalFuriKey.bind(this);
    this.handleSearchRiyouOk = this.handleSearchRiyouOk.bind(this);
    this.handleSearchRiyouCancel = this.handleSearchRiyouCancel.bind(this);
    this.onChange = this.onChange.bind(this);
    this.showModalSearchConditions = this.showModalSearchConditions.bind(this);
    this.onChangeFurigana = this.onChangeFurigana.bind(this);

    this.getHandleState = this.getHandleState.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.getHandleFurigana = this.getHandleFurigana.bind(this);
    this.onChangeInputMode = this.onChangeInputMode.bind(this);
    this.handleMulInputModal = this.handleMulInputModal.bind(this);
  }

  // Action click next date button
  handleNextDate() {
    const date = this.state.startDate.add(1, "days");
    // localStorage.setItem('nyuDate', dayjs(date.toDate()).format(ConstSet.DATE_FORMAT));
    this.setState({
      startDate: date,
    });

    this.props.handleDateChange(
      dayjs(date.toDate()).format(ConstSet.DATE_FORMAT)
    );
  }

  // Action click next date button
  handlePreviewDate() {
    const date = this.state.startDate.subtract(1, "days");
    // localStorage.setItem('nyuDate', dayjs(date.toDate()).format(ConstSet.DATE_FORMAT));

    this.setState({
      startDate: date,
    });

    this.props.handleDateChange(
      dayjs(date.toDate()).format(ConstSet.DATE_FORMAT)
    );
  }

  // Action select date
  handleChange(date) {
    if (date) {
      // localStorage.setItem('nyuDate', dayjs(date.toDate()).format(ConstSet.DATE_FORMAT));
      this.setState({
        startDate: date,
      });

      this.props.handleDateChange(
        dayjs(date.toDate()).format(ConstSet.DATE_FORMAT)
      );
    }
  }

  toggleForm = () => {
    this.props.onExpandForm(false);
  };

  showModalFuriKey = () => {
    this.setState({
      visible: true,
      resetFurigana: true,
      furigana: "",
    });
  };

  handleSearchRiyouOk = () => {
    this.props.searchByName(this.state.furigana);

    this.setState({
      visible: false,
      resetFurigana: false,
    });
  };

  handleSearchRiyouCancel = () => {
    this.setState({
      visible: false,
      resetFurigana: false,
    });

    // Reset directly input form
    const { filterData } = this.props;
    filterData.searchName = "";

    this.props.handleState(filterData);
  };

  showModalSearchConditions = () => {
    this.setState({
      visibleModalSearch: true,
    });
  };

  handleModalSearchOk = () => {
    this.props.nyutaishoSearch();

    this.setState({
      visibleModalSearch: false,
    });
  };

  handleModalSearchCancel = () => {
    this.props.handleState(JSON.parse(localStorage.getItem("is_keep")));
    this.setState({
      visibleModalSearch: false,
    });
  };

  showModalSortConditions = () => {
    this.setState({
      visibleSort: true,
    });
  };

  handleSortOk = () => {
    this.props.handleSort();
    this.setState({
      visibleSort: false,
    });
  };

  handleSortCancel = () => {
    this.props.handleState(JSON.parse(localStorage.getItem("is_keep")));
    this.setState({
      visibleSort: false,
    });
  };

  onChange(e) {
    this.props.handleTableView(e.target.checked);
  }

  onDisEventPlan = () => {
    this.props.setDisplayEventPlan();
  };

  onChangeFurigana(e) {
    const { filterData } = this.props;
    filterData.searchName = e.target.value;

    this.props.handleState(filterData);

    /**
     * @TODO Clear State ???
     */
  }

  /**
   *
   */
  searchByName = () => {
    this.props.searchByName(this.state.furigana);
  };

  /**
   *
   */
  clearInput = () => {
    const { filterData } = this.props;
    filterData.searchName = "";

    this.props.handleState(filterData);

    // search
    this.props.searchByName(filterData.searchName);

    /**
     * @TODO Clear State ???
     */
  };

  /**
   *
   */
  handleRefresh = () => {
    this.props.handleRefresh();
    const { filterData } = this.props;
    filterData.search.checkbox1 = false;
    filterData.search.field10 = null;
    filterData.search.field11 = null;
    filterData.search.checkbox2 = false;
    filterData.search.field2 = null;
    filterData.search.checkbox3 = false;
    filterData.search.field3 = null;
    filterData.sort.key1 = "0";
    filterData.sort.value1 = "1";
    filterData.sort.key2 = "0";
    filterData.sort.value2 = "1";
    filterData.sort.key3 = "0";
    filterData.sort.value3 = "1";
    filterData.sort.key4 = "0";
    filterData.sort.value4 = "1";
    filterData.sort.key5 = "0";
    filterData.sort.value5 = "1";
    filterData.searchName = "";
    filterData.jikantai = "全表示";

    this.props.handleState(filterData);

    /**
     * @TODO Clear State ???
     */
  };

  getJikantaiFilter = (startTime, endTime) => {
    this.props.handleDateChange(
      this.state.startDate.format("YYYY-MM-DD"),
      this.state.startDate.format("YYYY-MM-DD"),
      startTime,
      endTime
    );
  };

  getJikantaiState = (value) => {
    this.props.jikantaiState(value);
  };

  getHandleChangeTeikyou = (value) => {
    this.props.handleTeikyou(value);
  };

  /**
   * Update state between component
   */
  getHandleState = (filterData) => {
    this.props.handleState(filterData);
  };

  /**
   *
   * @param {*} furi
   */
  getHandleFurigana = (furi) => {
    const { filterData } = this.props;
    filterData.searchName = furi;

    this.props.handleState(filterData);

    /**
     * @TODO Clear State ???
     */

    this.setState({
      furigana: furi,
    });
  };

  onChangeMealType = (e) => {
    this.props.handleMealType(e.target.value);

    setValueIsKeep("mealType", e.target.value);
  };

  onChangeHaisetuKubun = (e) => {
    this.props.handleHaisetuKubun(e.target.value);
    setValueLocalstorage("haisetuType", e.target.value);
  };

  onChangeGender = (e) => {
    this.props.handleGender(e.target.value);
  };

  onChangeInputMode = (e) => {
    this.props.inputModeHandleChange(e.target.checked);
  };

  onChangeMulInputAllMode = (e) => {
    this.props.MulInputModeHandleChange(e.target.checked);
  };

  handleMulInputModal(type) {
    // type = 1 一括入力、type = 2 予定一括入力、type = 3 一括削除
    this.props.handleMulInputModal(type);
  }

  toggleOpenLongText = () => {
    this.props.toggleOpenLongText();
  };

  render() {
    const {
      displayChecked,
      isDisEventPlan,
      nyutaishoFilter,
      tabActive,
      jikantai,
      jikantaiSb,
      jikantaiH,
      jikantaiN,
      jikantaiF,
      jikantaiK,
      jikantaiT,
      jikantaiG,
      filterData,
      mealType,
      haisetuKubun,
      gender,
      inputMode,
      mulInputAllMode,
      isDisLongText,
    } = this.props;

    let settingNaiyo1 = null;
    let settingJikanTai = null;
    let settingSBJikanTai = null;
    let settingHJikanTai = null;
    let settingNJikanTai = null;
    let settingFJikanTai = null;
    let settingKJikanTai = null;
    let settingTJikanTai = null;
    let settingGJikanTai = null;
    let settingTeikyou = null;

    let yougoMaster11 = null;
    let yougoMaster12 = null;
    let yougoMaster13 = null;
    let yougoMaster14 = null;
    let yougoMaster15 = null;

    let yougoMaster21 = null;

    let burunTitle = "";

    if (nyutaishoFilter) {
      settingNaiyo1 = nyutaishoFilter.settingNaiyo1
        ? nyutaishoFilter.settingNaiyo1.split(",")
        : "";
      yougoMaster11 = nyutaishoFilter.yougoMaster11
        ? nyutaishoFilter.yougoMaster11.split(";")
        : "";
      yougoMaster12 = nyutaishoFilter.yougoMaster12
        ? nyutaishoFilter.yougoMaster12.split(";")
        : "";
      yougoMaster13 = nyutaishoFilter.yougoMaster13
        ? nyutaishoFilter.yougoMaster13.split(";")
        : "";
      yougoMaster14 = nyutaishoFilter.yougoMaster14
        ? nyutaishoFilter.yougoMaster14.split(";")
        : "";
      yougoMaster15 = nyutaishoFilter.yougoMaster15
        ? nyutaishoFilter.yougoMaster15.split(";")
        : "";
      yougoMaster21 = nyutaishoFilter.yougoMaster21
        ? nyutaishoFilter.yougoMaster21.split(";")
        : "";
      burunTitle = nyutaishoFilter.burunTitle ? nyutaishoFilter.burunTitle : "";
      settingJikanTai = nyutaishoFilter.jikantai
        ? nyutaishoFilter.jikantai.split(";")
        : "";
      settingSBJikanTai = nyutaishoFilter.sbJikantai
        ? nyutaishoFilter.sbJikantai.split(";")
        : "";
      settingHJikanTai = nyutaishoFilter.hJikantai
        ? nyutaishoFilter.hJikantai.split(";")
        : "";
      settingNJikanTai = nyutaishoFilter.nJikantai
        ? nyutaishoFilter.nJikantai.split(";")
        : "";
      settingFJikanTai = nyutaishoFilter.fJikantai
        ? nyutaishoFilter.fJikantai.split(";")
        : "";
      settingKJikanTai = nyutaishoFilter.kJikantai
        ? nyutaishoFilter.kJikantai.split(";")
        : "";
      settingTJikanTai = nyutaishoFilter.tJikantai
        ? nyutaishoFilter.tJikantai.split(";")
        : "";
      settingGJikanTai = nyutaishoFilter.gJikantai
        ? nyutaishoFilter.gJikantai.split(";")
        : "";
      settingTeikyou =
        nyutaishoFilter && nyutaishoFilter.teikyou
          ? nyutaishoFilter.teikyou
          : null;
    }

    const dataFilter =
      (filterData.search.field11 && filterData.search.field10
        ? filterData.search.field10.split("=")[1] +
        "=" +
        filterData.search.field11 +
        " / "
        : "") +
      (filterData.search.field2
        ? "絞込区分: " + filterData.search.field2 + " / "
        : "") +
      (filterData.search.field3 ? "部屋分類:" + filterData.search.field3 : "");

    const sortTabName = changeSortTab(tabActive);
    const sortData = filterData[sortTabName];
    const sortOptions = reportSortOptions(tabActive);

    return (
      (<Form
        onSubmit={this.handleSearch}
        layout="inline"
        className={"a-filter-form"}
      >
        {/* New filter inline */}
        <Row className="div-inline">
          <div className="div-inline-content" style={{ marginRight: "auto" }}>
            {/* (0) date selection place */}
            <FormItem className={"sa-f-item"} style={{ marginRight: "10px" }}>
              <ButtonGroup className={"sf-datapicker"}>
                <Button
                  icon={<CaretLeftOutlined />}
                  onClick={this.handlePreviewDate}
                  style={{ color: "#fff", background: "#00838F" }}
                  disabled={mulInputAllMode}
                />
                <DatePicker
                  style={{ maxWidth: "125px" }}
                  value={this.state.startDate}
                  onChange={this.handleChange}
                  locale={datePickerJp}
                  readOnly={true}
                  suffixIcon={
                    <span className="text-base-color">
                      (
                      {getDateInMonth(
                        this.state.startDate.format("YYYY-MM-DD").split("-")[0],
                        this.state.startDate.format("YYYY-MM-DD").split("-")[1],
                        this.state.startDate.format("YYYY-MM-DD").split("-")[2]
                      )}
                      )
                    </span>
                  }
                  className="rn-datepicker"
                  disabled={mulInputAllMode}
                />
                <Button
                  icon={<CaretRightOutlined />}
                  onClick={this.handleNextDate}
                  style={{ color: "#fff", background: "#00838F" }}
                  disabled={mulInputAllMode}
                />
              </ButtonGroup>
            </FormItem>
            <FormItem className={"sa-f-item"}>
              {tabActive !== "利用者" ? (
                <Checkbox onClick={this.onChangeInputMode} checked={inputMode}>
                  入力
                </Checkbox>
              ) : null}
              {
                tabActive !== "利用者" &&
                  tabActive !== "特記" &&
                  tabActive !== "画像等" ? (<Checkbox onClick={this.onChangeMulInputAllMode} checked={mulInputAllMode} >一括入力モード</Checkbox>)
                  :
                  null
              }

              {mulInputAllMode &&
                tabActive !== "利用者" &&
                tabActive !== "特記" &&
                tabActive !== "画像等" ?
                (
                  <span>
                    <Button style={{ backgroundColor: "red", color: "white" }} onClick={this.handleMulInputModal.bind(this, 1)} disabled={this.props.disabledIkkatsuBtn}>
                      一括入力
                    </Button>
                    {tabActive === "提供記録" ? (
                      <Button
                        style={{ backgroundColor: "blue", color: "white" }}
                        onClick={this.handleMulInputModal.bind(this, 2)}
                        disabled={this.props.disabledIkkatsuBtn}
                      >
                        予定通り
                      </Button>
                    ) : null}

                    <Button
                      style={{ backgroundColor: "gray", color: "white" }}
                      onClick={this.handleMulInputModal.bind(this, 3)}
                      disabled={this.props.disabledIkkatsuBtn}
                    >
                      一括削除
                    </Button>
                  </span>
                ) : null}
            </FormItem>
          </div>
          <div className="div-inline-content">
            {tabActive === "利用者" ? (
              <Row style={{ position: "relative", top: 4 }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "smaller",
                    position: "absolute",
                    top: "-8px",
                    left: "16px",
                    zIndex: "1",
                    backgroundColor: "white",
                    lineHeight: "1.5em",
                    width: "75px",
                  }}
                >
                  {"絞り込み条件"}
                </span>
                <span
                  className="form-filtfer-search-condition"
                  style={{ minWidth: 300, height: 32 }}
                >
                  {dataFilter ? dataFilter : "\u00A0"}
                </span>
                <Button
                  disabled={mulInputAllMode}
                  onClick={this.showModalSearchConditions}
                  type="primary"
                >
                  <SettingOutlined /> 条件
                </Button>
              </Row>
            ) : null}

            {
              /* tab 食事摂取 */
              tabActive === "食事摂取" ? (
                <FormItem className="form-item-border">
                  <RadioGroup
                    onChange={this.onChangeMealType}
                    value={mealType}
                    disabled={mulInputAllMode}
                  >
                    <Radio value={"全表示"}>全表示</Radio>
                    <Radio value={"朝"}>朝</Radio>
                    <Radio value={"昼"}>昼</Radio>
                    <Radio value={"夕"}>夕</Radio>
                    <Radio value={"間食"}>間食</Radio>
                    <Radio value={"経管栄養"}>経管栄養</Radio>
                  </RadioGroup>
                </FormItem>
              ) : null
            }

            {
              /* tab  */
              tabActive === "排泄" ? (
                <FormItem className="form-item-border">
                  <RadioGroup
                    onChange={this.onChangeHaisetuKubun}
                    value={haisetuKubun}
                    disabled={mulInputAllMode}
                  >
                    <Radio value={"両方"}>両方</Radio>
                    <Radio value={"排尿"}>排尿</Radio>
                    <Radio value={"排便"}>排便</Radio>
                  </RadioGroup>
                </FormItem>
              ) : null
            }

            {
              /* tab  */
              tabActive === "入浴" ? (
                <FormItem className="form-item-border">
                  <RadioGroup
                    disabled={mulInputAllMode}
                    onChange={this.onChangeGender}
                    value={gender}
                  >
                    <Radio value={"両方"}>両方</Radio>
                    <Radio value={"女性"}>女性</Radio>
                    <Radio value={"男性"}>男性</Radio>
                  </RadioGroup>
                </FormItem>
              ) : null
            }

            {
              /* tab */
              tabActive === "提供記録" ? (
                <FormItem label="提供内容" style={{ marginRight: 8 }}>
                  <TeikyouSelect
                    settingTeikyou={settingTeikyou}
                    handleChangeTeikyou={(value) =>
                      this.getHandleChangeTeikyou(value)
                    }
                    disabled={mulInputAllMode}
                  // jikantai={jikantaiH}
                  />
                </FormItem>
              ) : null
            }

            {
              /* tab baitaru */
              tabActive === "バイタル" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantai}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }
            {
              /* tab baitaru */
              tabActive === "水分摂取" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingSBJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiSb}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }
            {
              /* tab baitaru */
              tabActive === "排泄" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingHJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiH}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }
            {
              /* tab baitaru */
              tabActive === "入浴" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingNJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiN}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }
            {
              /* tab baitaru */
              tabActive === "服薬" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingFJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiF}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }
            {
              /* tab baitaru */
              tabActive === "提供記録" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingKJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiK}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }

            {
              /* tab baitaru */
              tabActive === "特記" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingTJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiT}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }

            {
              /* tab baitaru */
              tabActive === "画像等" ? (
                <FormItem label="時間帯">
                  <JikantaiSelect
                    settingJikanTai={settingGJikanTai}
                    jikantaiState={(value) => this.getJikantaiState(value)}
                    jikantai={jikantaiG}
                    disabled={mulInputAllMode}
                  />
                </FormItem>
              ) : null
            }
          </div>
          <div className="div-inline-content">
            <FormItem>
              <Button
                onClick={this.showModalFuriKey}
                disabled={mulInputAllMode}
              >
                <FA name="keyboard" key={guid()} />
              </Button>
              <Input
                value={filterData.searchName}
                onChange={this.onChangeFurigana}
                style={{
                  width: "100px",
                }}
                disabled={mulInputAllMode}
              />
              <Button onClick={this.clearInput} disabled={mulInputAllMode}>
                <UndoOutlined />
              </Button>
              <Button
                disabled={mulInputAllMode}
                onClick={this.searchByName}
                style={{ marginLeft: 4 }}
                type="primary"
              >
                <SearchOutlined /> 検索
              </Button>
            </FormItem>
          </div>
          <div className="div-inline-content">
            <FormItem>
              <Button
                disabled={mulInputAllMode}
                onClick={this.showModalSortConditions}
                type="primary"
              >
                <FA name="sort" key={guid()} style={{ paddingRight: "4px" }} />
                並替
              </Button>
            </FormItem>
          </div>
          <div className="div-inline-content">
            {tabActive !== "利用者" ? (
              <Row style={{ position: "relative", top: 4 }}>
                <span
                  style={{
                    color: "black",
                    fontSize: "smaller",
                    position: "absolute",
                    top: "-8px",
                    left: "16px",
                    zIndex: "1",
                    backgroundColor: "white",
                    lineHeight: "1.5em",
                    width: "75px",
                  }}
                >
                  {"絞り込み条件"}
                </span>
                <span
                  className="form-filtfer-search-condition"
                  style={{ minWidth: 300, height: 32 }}
                >
                  {dataFilter ? dataFilter : "\u00A0"}
                </span>
                <Button
                  disabled={mulInputAllMode}
                  onClick={this.showModalSearchConditions}
                  type="primary"
                >
                  <SettingOutlined /> 条件
                </Button>
              </Row>
            ) : null}
          </div>
          <div className="div-inline-content">
            {
              /* tab 利用者 */
              tabActive === "利用者" ? (
                <span>
                  <FormItem>
                    <Checkbox
                      onChange={this.onDisEventPlan}
                      checked={isDisEventPlan}
                    >
                      施設予定表示
                    </Checkbox>
                  </FormItem>
                  <FormItem>
                    <Checkbox onChange={this.onChange} checked={displayChecked}>
                      集計表示
                    </Checkbox>
                  </FormItem>
                </span>
              ) : null
            }

            <FormItem>
              {!inputMode && !mulInputAllMode ? (
                <Button type="primary" onClick={this.toggleOpenLongText}>
                  {isDisLongText ? (
                    <span>
                      <MinusOutlined /> 格納
                    </span>
                  ) : (
                    <span>
                      <PlusOutlined /> 展開
                    </span>
                  )}
                </Button>
              ) : null}
            </FormItem>
          </div>
        </Row>
        {/* end new filter inline */}
        <Modal
          title="ふりがな検索"
          open={this.state.visible}
          onOk={this.handleSearchRiyouOk}
          onCancel={this.handleSearchRiyouCancel}
          className={"hiragana-model"}
          width={560}
        >
          <FuriganaKeyBoard
            furigana={(furi) => this.getHandleFurigana(furi)}
            resetFurigana={this.onChangeFurigana}
            furiganaState={this.state.furigana}
          />
        </Modal>
        <Modal
          title="絞り込み検索"
          open={this.state.visibleModalSearch}
          onOk={this.handleModalSearchOk}
          onCancel={this.handleModalSearchCancel}
          className={"search-model"}
          okText={"検索"}
          cancelText={"閉じる"}
        >
          <SearchConditions
            settingNaiyo1={settingNaiyo1}
            yougoMaster11={yougoMaster11}
            yougoMaster12={yougoMaster12}
            yougoMaster13={yougoMaster13}
            yougoMaster14={yougoMaster14}
            yougoMaster15={yougoMaster15}
            yougoMaster21={yougoMaster21}
            handleUpdateState={(filterData) => this.getHandleState(filterData)}
            filterData={filterData}
          />
        </Modal>
        <Modal
          title="並び替え"
          open={this.state.visibleSort}
          onOk={this.handleSortOk}
          onCancel={this.handleSortCancel}
          className={"sort-model"}
        >
          <SortConditions
            burunTitle={burunTitle}
            handleUpdateState={(filterData) => this.getHandleState(filterData)}
            filterData={filterData}
            sortData={sortData}
            sortOptions={sortOptions}
            tabActive={tabActive}
          />
        </Modal>
      </Form>)
    );
  }
}

import { HaisetsuActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";

function haisetsu(state = {}, action) {
  switch (action.type) {
    case HaisetsuActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case HaisetsuActionTypes.HAISETSU_LIFE_YOUGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        haisetuLifeYougo: action.data,
      };
    }
    case HaisetsuActionTypes.HAISETSU_LIFE_YOUGO_ERROR: {
      return {
        ...state,
        loading: false,
        haisetuLifeYougo: null,
      };
    }

    case HaisetsuActionTypes.LOAD_HAISETSU_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case HaisetsuActionTypes.LOAD_HAISETSU_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case HaisetsuActionTypes.SAVE_HAISETSU_SIEN_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case HaisetsuActionTypes.SAVE_HAISETSU_SIEN_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case HaisetsuActionTypes.LOAD_HAISETU_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        haisetuDetails: action.data,
      };
    }

    case HaisetsuActionTypes.LOAD_HAISETU_TO_QUOTE:
      openNotificationWithIcon("success", "前回引用を実施しました");
      return {
        ...state,
        data: action.data,
      };

    case HaisetsuActionTypes.SET_NEW_DATA:
      return {
        ...state,
        data: null,
      };

    case HaisetsuActionTypes.GET_LIST_IDS_HAISETU: {
      return {
        ...state,
        loading: false,
        haisetuIds: action.ids,
      };
    }

    case HaisetsuActionTypes.LOAD_HAISETU_BY_IDS_REQUEST:
      return { ...state, loading: true };
    case HaisetsuActionTypes.LOAD_HAISETU_BY_IDS_SUCCESS: {
      return {
        ...state,
        haisetuList: action.haisetuList,
        loading: false,
      };
    }
    case HaisetsuActionTypes.LOAD_HAISETU_BY_IDS_ERROR:
      openNotificationWithIcon("error", action.error.message);
      return {
        ...state,
        haisetuList: [],
        loading: false,
      };
    case HaisetsuActionTypes.CLEAR_HAISETU_BY_IDS:
      return {
        ...state,
        haisetuList: [],
      };

    default: {
      return { ...state };
    }
  }
}

export default haisetsu;
